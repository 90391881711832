<template>
<AppView>
  <Button @click.native="() => startContract('joeStart')">Start</Button>
  <Button @click.native="() => startContract('joeClaim1')">Claim 1 NFT</Button>
  <Button @click.native="() => startContract('joeClaim10')">Claim 10 NFT</Button>
  <ContractPopUp v-if='displayContractPopUp'
                 :_kind="contractKind"
                 @close="displayContractPopUp = false"/>
</AppView>
</template>

<script>
import Button from '../components/Common/Button/Button'
import AppView from '../components/Layout/AppView'
import ContractPopUp from '../components/Common/PopUp/ContractPopUp'

export default {
  name: 'HelpJoe',
  components: { ContractPopUp, AppView, Button },
  data () {
    return {
      displayContractPopUp: false,
      contractKind: ''
    }
  },
  methods: {
    startContract (contract) {
      console.log(contract)
      this.contractKind = contract
      this.displayContractPopUp = true
    }
  }
}
</script>

<style scoped>

</style>
