<template>
  <ul class="card-grid">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: 'CardGrid'
}
</script>

<style scoped>

.card-grid {
  display: grid;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  list-style: none;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 33px;
  grid-auto-rows: 350px;
  min-width: 350px;
  max-width: 1200px; /* work on max width integration for collections */
  margin: 20px auto;
  padding: 0;
}

.card-grid li.spotlight {
  grid-column: span 2;
  grid-row: span 2;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {

  .card-grid li.spotlight {
    grid-column: 1;
    grid-row: 1;
  }
}
</style>
