<template>
  <div class="currency-input">
    <label>
      <ASALogo :asa-id="asaId" v-if="asaId > 0"/>
      <span v-else>All</span>
      <template v-if="!options || !options.hideName">
        <template v-if="asaId !== 1"> ${{asaIdToName(asaId)}}</template>
      </template>
      <unicon name="angle-down" />
    </label>
    <select ref="select" v-model="asaId" @change="$emit('input', asaId)">
      <option v-if="options && options.authorizeAll" value="0">All</option>
      <option v-for="asa of asas" :key="asa.id" :value="asa.id">
        {{asa.name}}<template v-if="asa.id !== 1">: #{{asa.id}}</template>
      </option>
    </select>
  </div>
</template>
<script>
import ASALogo from '../../Assets/ASALogo'

export default {
  name: 'CurrencyInput',
  components: { ASALogo },
  props: ['value', 'options'],
  data () {
    return {
      asaId: this.value,
      asas: [],
      id: Math.round(Math.random() * 10000)
    }
  },
  methods: {
    asaIdToName (id) {
      switch (id) {
        case 1:
          return 'ALGO'
        case 1096015467:
          return 'PEPE'
        case 793124631:
          return 'gALGO'
        case 31566704:
          return 'USDC'
        case 230946361:
          return 'GEMS'
        case 1065092715:
          return 'COSG'
        case 445905873:
          return 'ALC'
        case 435335235:
          return 'CRSD'
        case 386192725:
          return 'goBTC'
        case 386195940:
          return 'goETH'
        case 137594422:
          return 'HDL'
        case 700965019:
          return 'VEST'
        case 796425061:
          return 'COOP'
        case 444108880:
          return 'TREES'
        case 753890862:
          return 'PRSMS'
        case 947923645:
          return 'XUT'
        case 403499324:
          return 'GP'
        case 400593267:
          return 'Finite'
        case 226701642:
          return 'YLDY'
        case 900652777:
          return 'BANK'
        case 841126810:
          return 'STBL2'
        case 760037151:
          return 'xUSD'
        case 672913181:
          return 'goUSD'
        case 684649988:
          return 'GARD'
        case 312769:
          return 'USDt'
        case 523683256:
          return 'AKITA'
        case 417708610:
          return 'DEGEN'
        case 329110405:
          return 'TACO'
        case 511028589:
          return 'Jimmy'
        case 756578163:
          return 'MOIST'
        default:
          return ''
      }
    }
  },
  mounted () {
    this.$http.get('/api/currency').then((l) => {
      this.asas = l.data.metadata.sort((a, b) => {
        // Set the order of your currencies
        const order = [1, 1096015467, 793124631, 31566704, 230946361, 1065092715, 445905873, 435335235, 386192725,
          386195940, 137594422, 700965019, 796425061, 444108880, 753890862, 947923645, 403499324, 400593267, 226701642,
          900652777, 841126810, 760037151, 672913181, 684649988, 312769, 523683256, 417708610,
          329110405, 511028589, 756578163]

        // Find the index of 'a' and 'b' in our order array
        const aIdx = order.indexOf(a.id)
        const bIdx = order.indexOf(b.id)

        // If 'a' and 'b' are in our order array, sort them accordingly
        // If 'a' is not in our order array, it should come after 'b'
        // If 'b' is not in our order array, 'a' should come before 'b'
        if (aIdx !== -1 && bIdx !== -1) {
          return aIdx - bIdx
        } else if (aIdx !== -1) {
          return -1
        } else if (bIdx !== -1) {
          return 1
        }

        // If 'a' and 'b' are not in our order array, sort them based on id
        return a.id - b.id
      })
    })
  }
}
</script>

<style scoped>
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }

  select {
    position: absolute;
    left:0px;
    top:0px;
    width:100%;
    height:100%;
    opacity:0;
    cursor:pointer;
  }

  select:focus{
    outline: none;
  }

  .asa-logo {
    font-size: xx-large;
    margin-right: 5px;
  }

  .unicon {
    fill: var(--textlight);
  }

  .currency-input:hover .unicon {
    fill: var(--text);
  }
</style>
