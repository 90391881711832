<template>
  <AppView id="authenticate" class="flex-center full-height stepper">
    <transition :name="transition" mode="out-in">
      <component v-bind:is="views[step]" @next="next" @previous="previous" @error="errorHandler" :error="error" :_viewData="viewData"></component>
    </transition>
  </AppView>
</template>

<script>
import AuthenticateWallet from '@/views/User/Authenticate/AuthenticateWallet'
import AuthenticateUnlock from '@/views/User/Authenticate/AuthenticateUnlock'
import AuthenticateAddresses from '@/views/User/Authenticate/AuthenticateAddresses'
import AuthenticateChallenge from '@/views/User/Authenticate/AuthenticateChallenge'
import ErrorAuthenticate from '@/views/User/Authenticate/ErrorAuthenticate'
import AppView from '@/components/Layout/AppView'
export default {
  name: 'authenticate',
  components: { AppView },
  data () {
    return {
      views: [AuthenticateWallet, AuthenticateUnlock, AuthenticateAddresses, AuthenticateChallenge, ErrorAuthenticate],
      step: 0,
      transition: 'next',
      error: false,
      viewData: {}
    }
  },
  methods: {
    next (data) {
      if (data) {
        Object.keys(data).forEach(key => {
          this.$set(this.viewData, key, data[key])
        })
      }
      this.step += 1
      this.transition = 'next'
    },
    previous (step) {
      if (typeof step !== 'undefined') {
        this.step = step
      } else {
        this.step -= 1
      }
      this.transition = 'previous'
    },
    errorHandler (error) {
      this.loading = false
      this.step = this.views.length - 1
      this.transition = 'next'
      this.error = error
    }
  }
}
</script>

<style scoped>

</style>
