import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function bid (APIName, from, tokenId, price) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()

    const message = {
      token_id: tokenId,
      price: price
    }
    const parsed = await Utils._callServer('/api/bid/check', message)

    console.log('Bid - check', parsed)
    const to = parsed.to
    const amount = parsed.amount

    const txns = []

    if (!parsed.check) {
      txns.push(
        algosdk.makeAssetTransferTxnWithSuggestedParams(
          from, from,
          undefined, undefined,
          0, undefined,
          parsed.token_id, suggestedParams)
      )
    }

    if (parsed.asa_id === 1) {
      txns.push(algosdk.makePaymentTxnWithSuggestedParams(from, to, amount, undefined, undefined, suggestedParams))
    } else {
      txns.push(algosdk.makePaymentTxnWithSuggestedParams(from, to, 2000, undefined, undefined, suggestedParams))
      txns.push(algosdk.makeAssetTransferTxnWithSuggestedParams(from, to, undefined, undefined, amount, undefined, parsed.asa_id, suggestedParams))
    }

    const appArgs = []
    appArgs.push(new TextEncoder().encode('bid'))
    const foreignAssets = [tokenId]
    if (parsed.asa_id !== 1) {
      foreignAssets.push(parsed.asa_id)
    }
    txns.push(
      algosdk.makeApplicationNoOpTxn(
        from,
        suggestedParams,
        parsed.app_id, appArgs,
        (parsed.prev_bid_leader) ? [parsed.prev_bid_leader] : [],
        undefined, foreignAssets,
        undefined, undefined, undefined)
    )

    const signedTxs = await API.signGroup(txns, txns.map(x => true))
    console.log('Bid - signed', signedTxs)

    const message2 = {
      token_id: tokenId,
      signed_tx: signedTxs,
      price: amount
    }
    return Utils._callServer('/api/transaction/bid/validation', message2)
  } catch (e) {
    console.log('Bid - error', e)
    throw e
  }
}

export default bid
