<template>
  <div class="market-body">
    <Section>
      <template v-slot:header>
        <h3>Algo Leagues' sets</h3>
        <TextualButton to="/shops/AlgoLeagues/Shuffles">See Listings</TextualButton>
      </template>
      <CollectionGrid>
        <CollectionCard v-for="(season, index) in seasons" :key="season.token_id" :collection="season" :style="`animation-delay: ${100 + (index * 50)}ms`"/>
      </CollectionGrid>
    </Section>
    <HotSales :asa-id="445905873" asa-name="ALC"/>
    <Section>
      <template v-slot:header>
        <h3>Explore our universe</h3>
        <TextualButton to="/shops/AlgoLeagues/Sales">See More</TextualButton>
      </template>
      <CardGrid>
        <Card v-for="(sale, index) in algoLeaguesSales" :key="sale.token_id" :data="sale" :style="`animation-delay: ${100 + (index * 50)}ms`"/>
      </CardGrid>
    </Section>
  </div>
</template>

<script>
import HotSales from './HotSales'
import CardGrid from '../Cards/CardGrid'
import Section from '../Layout/Section'
import TextualButton from '../Common/Button/TextualButton'
import Card from '../Cards/Card'
import CollectionGrid from '@/components/Collection/CollectionGrid.vue'
import CollectionCard from '@/components/Collection/CollectionCard.vue'

export default {
  name: 'AlgoLeaguesBody',
  components: { HotSales, CardGrid, Section, TextualButton, Card, CollectionGrid, CollectionCard },
  data () {
    return {
      algoLeaguesSales: [],
      seasons: []
    }
  },
  methods: {
    getData () {
      this.algoLeaguesSales = []
      const salesParams = {
        start: 0,
        end: 8,
        nsfw: false,
        sort: 'price',
        displayUnverifiedArtist: false
      }
      this.$http('/api/sales/AlgoLeaguesOfficial', { params: salesParams }).then((response) => {
        if (response.data && response.data.sales) {
          this.algoLeaguesSales = response.data.sales
        }
      }).catch(err => {
        console.log(err)
      })
      this.$http('/api/collections/PZNGYF4Y25GGO674BW4CRDHFKOKHMHZXSFXIKMYPEJCQAUTDH52WV24XTY', { params: salesParams }).then((response) => {
        if (response.data && response.data.metadata) {
          this.seasons = response.data.metadata
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style scoped>

</style>
