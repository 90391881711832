<template>
  <div id="app-view">
    <div class="view-header">
      <slot name="header"></slot>
    </div>
    <div class="view-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppView'
}
</script>

<style scoped>
.view-header{
  min-height: 90px;
  background: linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, var(--background)), var(--lightgradient);
  overflow: hidden;
}

.view-body{
  max-width: 1200px; /* width: 1200px // max width is much better but then collection needs changing*/
  justify-items: center;
  margin: 0 auto;
}

.view-body h1{
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 4em;
  text-transform: uppercase;
  color: var(--text);
  text-align: left;
  font-weight: 700;
  margin-top: 0;
}

.view-body h2{
  text-transform: uppercase;
  font-family: 'Roboto Condensed', sans-serif;
  color: var(--text);
  margin: 0;
  font-size: 2em;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .view-header{
    min-height: 90px;
  }

  .view-body{
    width: 100%;
    margin: 0;
    overflow: hidden;
  }

  .view-body h1{
    font-size: 3em;
    margin: 0;
  }

  .view-body h2{
    padding: 0 25px;
  }
}
</style>
