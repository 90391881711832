/* eslint-disable no-undef */
const algosdk = require('algosdk-latest')
let LEDGER = 'MainNet'

module.exports = class AlgoSignerAPI {
  static name = 'algosigner'
  static async connect (callback = () => {}, errorCallback = () => {}) {
    if (typeof AlgoSigner === 'undefined') {
      alert('AlgoSigner is not installed. Please install it.')
      return
    }

    const d = await AlgoSigner.connect()
    return d
  }

  static logout (callback = () => { }, errorCallback = () => { }) {
    return new Promise((resolve, reject) => { resolve() })
  }

  static async account (callback = () => { }, errorCallback = () => { }) {
    LEDGER = 'MainNet'
    await AlgoSignerAPI.connect()
    const d = await AlgoSigner.accounts({
      ledger: LEDGER
    })
    if (d.length === 0) {
      throw new Error({ code: 'contract-error-address' })
    }
    return d
  }

  static async sign (txn, getUint8 = false) {
    console.log('Algosigner - sign', txn)
    if (txn.note && typeof txn.note === 'string') txn.note = AlgoSigner.encoding.stringToByteArray(txn.note)
    console.log('Algosigner - done')
    const binaryTx = txn.toByte()
    // eslint-disable-next-line no-undef
    const base64Tx = AlgoSigner.encoding.msgpackToBase64(binaryTx)
    // eslint-disable-next-line no-undef
    const d = await AlgoSigner.signTxn([
      {
        txn: base64Tx
      }
    ])

    const stx = d[0]
    if (getUint8) {
      stx.blob = AlgoSigner.encoding.base64ToMsgpack(stx.blob)
    }
    return stx
  }

  static async signGroup (txns, isSigning, isGrouped = true) {
    const base64Txs = []
    if (txns.length === 1) {
      console.log('Algosigner - sign Group', txns)
      base64Txs.push({ txn: btoa(String.fromCharCode.apply(null, txns[0].toByte())) })
      isSigning.push(true)
    } else {
      console.log('Algosigner - sign Group', txns)
      let txngroup
      if (isGrouped) {
        txngroup = algosdk.assignGroupID(txns)
      }
      console.log('Algosigner - sign Group group id', txngroup)
      for (let i = 0; i < txns.length; i++) {
        if (isGrouped) {
          txns[i].group = txngroup[i].group
        }
        base64Txs.push({ txn: btoa(String.fromCharCode.apply(null, txns[i].toByte())) })
        if (!isSigning[i]) {
          base64Txs[i].signers = []
        }
      }
    }

    console.log('Algosigner - sign Group base 64 transactions', base64Txs)
    // eslint-disable-next-line no-undef,no-labels
    const signed = await AlgoSigner.signTxn(base64Txs)
    return signed.filter((e, i) => isSigning[i])
  }
}
