<template>
  <span>
    #{{ shortAddress }}
  </span>
</template>

<script>
export default {
  name: 'Address',
  props: {
    address: String
  },
  computed: {
    shortAddress () {
      return this.address?.slice(0, 5).toLowerCase() + '.' + this.address?.slice(-5).toLowerCase()
    }
  }
}
</script>

<style scoped>
</style>
