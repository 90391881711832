<template>
  <AppView>
    <div class="head">
      <h1>Create Staking</h1>
      <div class="two-columns">
        <div>
          Selected NFT:
        </div>
        <div>
          <template v-if="!selectedNFT">
            Select an NFT below
          </template>
          <ListCard :data="selectedNFT" v-else/>
        </div>
        <div>
          Airdrop Unit:
        </div>
        <CurrencyInput v-model="asaId"/>
        <div>
          Airdrop Amount:
        </div>
        <IntInput
          :default-value="airdropAmount"
          :delay="0"
          :max="10000000000000" :min="1" :step="1"
          class="small"
          @val="(val) => { this.airdropAmount = val }"
        />
        <div>
          Airdrop Interval:
        </div>
        <div class="flex-center">
          <IntInput
            :default-value="airdropTime"
            :delay="0"
            :max="10000000000000" :min="1" :step="1"
            class="small"
            @val="(val) => { this.airdropTime = val }"
          />
          <Select :defaultValue="airdropTimeUnit" :options="airdropTimeUnitOptions" @selected="(d) => this.airdropTimeUnit = d" />
        </div>
        <div>
          Number of airdrop sessions:
        </div>
        <IntInput
          :default-value="airdropNumber"
          :delay="0"
          :max="10000000000000" :min="1" :step="1"
          class="small"
          @val="(val) => { this.airdropNumber = val }"
        />
      </div>
      <Button @click.native.stop.prevent="create">Create</Button>
    </div>
    <div class="nft-list">
      <div class="nft-list-head">
        <TextualButton class="reverse" @click.native="previousPage" v-if="this.start>0">
          <unicon name="angle-left" />{{$t('common.button.back')}}
        </TextualButton>
        <span v-else></span>
        <span>{{start + 1}}-{{start + increment + 1}}</span>
        <TextualButton @click.native="nextPage" v-if="this.morePage">
          {{$t('common.button.next')}}<unicon name="angle-right" />
        </TextualButton>
        <span v-else></span>
      </div>
      <ListCardGrid v-if="!this.isLoadingPage">
        <ListCard v-for="(nft, index) in nfts" :key="nft.token_id" :data="nft" :style="`animation-delay: ${100 + (index * 50)}ms`">
          <Button @click.native.stop.prevent="() => { selectedNFT = nft }" v-if="!selectedNFT || selectedNFT.token_id !== nft.token_id">
            Select
          </Button>
          <Button @click.native.stop.prevent="selectedNFT = undefined" class="error-btn" v-else-if="selectedNFT && selectedNFT.token_id === nft.token_id">
            Unselect
          </Button>
        </ListCard>
      </ListCardGrid>
      <LinearSpinner v-else/>
    </div>
    <ContractPopUp :_contract-params="contractParams" :_kind="'createStaking'" v-if="displayContractPopUp" @close="displayContractPopUp = false"/>
  </AppView>
</template>

<script>
import ListCard from '@/components/Cards/ListCard'
import Button from '@/components/Common/Button/Button'
import TextualButton from '@/components/Common/Button/TextualButton'
import LinearSpinner from '@/components/Common/Spinner/LinearSpinner'
import AppView from '@/components/Layout/AppView'
import ListCardGrid from '@/components/Cards/ListCardGrid'
import IntInput from '@/components/Common/Input/IntInput'
import CurrencyInput from '@/components/Common/Input/CurrencyInput'
import Select from '@/components/Common/Input/Select'
import { mapState } from 'vuex'
import ContractPopUp from '../../components/Common/PopUp/ContractPopUp'

export default {
  name: 'CreateStaking',
  components: {
    ContractPopUp,
    Select,
    CurrencyInput,
    IntInput,
    ListCardGrid,
    AppView,
    LinearSpinner,
    TextualButton,
    Button,
    ListCard
  },
  data () {
    return {
      nfts: [],
      start: 0,
      end: 16,
      increment: 9,
      morePage: false,
      isLoadingPage: false,
      selectedNFT: undefined,
      asaId: 1,
      airdropNumber: 1,
      airdropAmount: 1,
      airdropTime: 1,
      airdropTimeUnit: 'd',
      airdropTimeUnitOptions: [
        { name: 'hours', value: 'h' },
        { name: 'days', value: 'd' }],
      nsfw: false,
      displayContractPopUp: false,
      contractPopUpState: 'loading',
      contractPopUpMessage: '',
      contractParams: {}
    }
  },
  computed: {
    ...mapState(['address']),
    creationUrl () {
      return `/api/creation/${this.address}`
    }
  },
  methods: {
    create () {
      this.contractParams = {
        nft: this.selectedNFT.token_id,
        time: (this.airdropTimeUnit === 'h') ? this.airdropTime : this.airdropTime * 24,
        amount: this.airdropAmount,
        number: this.airdropNumber,
        asa_id: this.asaId
      }
      this.displayContractPopUp = true
    },
    previousPage () {
      if (this.isLoadingPage) return
      this.start -= this.increment
      this.start = (this.start < 0) ? 0 : this.start
      this.refreshPage()
    },
    nextPage () {
      if (this.isLoadingPage) return
      this.start += this.increment
      this.refreshPage()
    },
    refreshPage () {
      const params = {
        start: this.start,
        end: this.start + this.increment,
        nsfw: this.nsfw
      }
      this.nfts = []
      this.isLoadingPage = true
      this.$http.get(this.creationUrl, {
        params: params
      }).then(({ data }) => {
        this.isLoadingPage = false
        console.log(data)
        if (data?.metadata.length) {
          this.nfts = data.metadata.filter(x => x)
          this.morePage = !data.bool_end
        }
      }).catch(() => {
        this.isLoadingPage = false
      })
    }
  },
  beforeMount () {
    if (!['PZNGYF4Y25GGO674BW4CRDHFKOKHMHZXSFXIKMYPEJCQAUTDH52WV24XTY', 'TST2PF3APFSTOQS57TTILKU3IV5O4RCU7XK6SXBS4VFDRG2APTD3QOOF7I', 'IL2CNQOSJRI3XJNKQW3BJ2JICZ6RGI752NSJEQ52U6GKWBX5ZZYZ7MI7LQ', 'VXNVFUF5BTXJXM6GFRJ4J57KKAXNNT5AQ6IKI4RTYBFJGHOCVWYLJSU36Y'].includes(this.address)) {
      this.$router.push('/user/listings')
    }
  },
  mounted () {
    this.refreshPage()
  }
}
</script>

<style scoped>

.head {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.head h1 {
  margin-bottom: 25px;
}

.head li {
  list-style: none;
  min-width: 400px;
}

.two-columns {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.nft-list-head {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 50px;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 25px;
  width: 400px;
  text-align: center;
}

.button-container {
  margin: 0;
}

.button-container button {
  margin: 5px;
}

.list-card {
  position: absolute;
}

.list-card .overview{
  width: 100%;
}

.list-card button {
  margin: 0;
  width: 140px;
  fill: var(--foreground);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
</style>
