<template>
  <Section id="ownedNfts">
    <template v-slot:header>
      <span></span>
      <ToggleNSFW v-model="nsfw" v-if="hasNsfw"/>
    </template>
    <CardGrid v-if="nftList.length">
        <Card v-for="(nft, index) in nftList.filter(x => x)" :key="nft.token_id" :data="nft" :style="`animation-delay: ${100 + ((index % 4) * 50)}ms`" @startContract="(data) => newContract(data)" :_me="me"/>
    </CardGrid>
    <template v-else-if="id">
      <h4>This user doesn't own any NFTs</h4>
    </template>
    <template v-else>
      <h4>You don't own any NFTs. Mint, or explore the <router-link to="/">marketplace</router-link> and buy</h4>
    </template>
    <ContractPopUp v-if='displayContractPopUp' :_kind="contractKind" :_price='contractPrice' :_is-algo=false :_tokenId='contractTokenId' @done='() => {}' @close="displayContractPopUp = false" :_isAlgo="isContractAlgo"></ContractPopUp>
    <infinite-loading
      spinner="waveDots"
      @infinite="infiteListHandler"
      :identifier="infiniteId"
      :forceUseInfiniteWrapper="true"
      force-use-infinite-wrapper=".card-grid">
      <span slot="no-results"></span>
      <span slot="no-more">
      </span>
    </infinite-loading>
  </Section>
</template>

<script>
import { mapState } from 'vuex'
import Section from '@/components/Layout/Section'
import InfiniteLoading from 'vue-infinite-loading'
import Card from '@/components/Cards/Card'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import CardGrid from '@/components/Cards/CardGrid'
import ToggleNSFW from '@/components/Common/Button/ToggleNSFW'

export default {
  name: 'OwnedNft',
  components: { ToggleNSFW, CardGrid, Card, Section, InfiniteLoading, ContractPopUp },
  props: ['id'],
  data: () => {
    return {
      nftList: [],
      infiniteId: 0,
      increment: 16,
      contractKind: '',
      contractPrice: 1,
      contractTokenId: 0,
      isContractAlgo: true,
      displayContractPopUp: false,
      nsfw: false,
      hasNsfw: false
    }
  },
  computed: {
    ...mapState([
      'userId',
      'wallet'
    ]),
    galleryId () {
      return this.id || this.userId
    },
    ownedNFTUrl () {
      return `/api/gallery/${this.galleryId}`
    },
    me () {
      return !this.id
    }
  },
  watch: {
    nsfw () {
      this.reload()
    }
  },
  methods: {
    reload () {
      this.nftList = []
      this.infiniteId++
    },
    newContract (data) {
      this.contractKind = data.kind
      this.contractPrice = data.price
      this.contractTokenId = data.tokenId
      this.isContractAlgo = true
      this.displayContractPopUp = true
    },
    infiteListHandler ($state) {
      const url = this.ownedNFTUrl
      this.$http.get(url, {
        params: {
          start: this.nftList.length,
          end: this.nftList.length + this.increment,
          nsfw: this.nsfw
        }
      }).then(({ data }) => {
        if (data?.metadata.length) {
          this.nftList.push(...data.metadata)
          if (data?.metadata.length < this.increment) {
            $state.complete()
          } else {
            $state.loaded()
          }
        } else {
          $state.complete()
        }
      })
    }
  },
  mounted () {
    this.$http.get(this.ownedNFTUrl, {
      params: {
        start: 0,
        end: 1,
        nsfw: true
      }
    }).then(({ data }) => {
      if (data?.metadata.length) this.hasNsfw = true
    })
  }
}
</script>

<style scoped>

</style>
