<template>
<AppView>
  <h1>Close Auction</h1>
  <p>Enter the app id of the auction to close underneath</p>
  <input type="number" v-model="appId"/>
  <Button @click.native="displayContractPopUp = true">Close</Button>
  <ContractPopUp
    v-if="displayContractPopUp"
    _kind="auctionClose"
    :_contract-params="{ appId: appId }"
    @close="displayContractPopUp = false"
  />
</AppView>
</template>

<script>
import AppView from '../components/Layout/AppView.vue'
import Button from '../components/Common/Button/Button.vue'
import ContractPopUp from '../components/Common/PopUp/ContractPopUp.vue'

export default {
  name: 'EditCollection',
  components: { ContractPopUp, Button, AppView },
  data () {
    return {
      appId: 0,
      state: 'initial',
      displayContractPopUp: false
    }
  }
}
</script>

<style scoped>

</style>
