<template>
  <div class="view">
    <TermsOfUseEn/>
  </div>
</template>

<script>
import TermsOfUseEn from '@/views/TermsOfUse/TermsOfUseEn'

export default {
  name: 'TermsOfVue.vue',
  components: { TermsOfUseEn }
}
</script>

<style scoped>

</style>
