import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function stake (APIName, from, commit, stakeParameter) {
  const API = Utils._getAPI(APIName)
  const suggestedParams = await Utils.getParams()
  const to = 'XXDWS6VMYOCTM26D5IM7337GU5X7X6XUD47ESNSC65IAVP2CJF4ZLWIUDI'
  let note = { com: commit }
  if (stakeParameter.isLedger) {
    note.ledger = stakeParameter.address
  }
  note = new TextEncoder('utf-8').encode(`algogems/gov1:j${JSON.stringify(note)}`)
  const txn = algosdk.makePaymentTxnWithSuggestedParams(from, to, 0, undefined, note, suggestedParams)
  const d = await API.sign(txn)
  const message = {
    number_gems: commit,
    signed_tx: d,
    ledger: (stakeParameter.isLedger) ? stakeParameter.address : null
  }
  return Utils._callServer('/api/governance/commit', message)
}

export default stake
