<template>
  <span class="image-upload">
      <template v-if="state != 'loading'">
        <label :for="`upload${api}`">
          <slot></slot>
        </label>
        <input type="file" :id="`upload${api}`" accept="image/png, image/jpeg" @change="(e) => uploadImage(e)">
      </template>
      <DotLinearLoader v-else/>
    </span>
</template>

<script>
import DotLinearLoader from '../Common/Spinner/DotLinearLoader'

export default {
  name: 'ImageUpload',
  components: { DotLinearLoader },
  props: {
    api: String,
    parameters: Object,
    _gifAccepted: Boolean
  },
  data () {
    return {
      state: 'done'
    }
  },
  computed: {
    gifAccepted () {
      return (this._gifAccepted === undefined) ? true : this._gifAccepted
    }
  },
  methods: {
    uploadImage (e) {
      const image = e.target.files[0]
      if (image) {
        this.state = 'loading'
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = e => {
          const formData = new FormData()
          formData.append('file', this.dataURLtoFile(e.target.result, 'picture.jpeg'))
          if (this.parameters) {
            for (const [key, value] of Object.entries(this.parameters)) {
              formData.append(key, value)
            }
          }
          this.$http
            .post(this.api, formData, { headers: { 'Content-Type': `multipart/form-data; boundary=${formData._boundary}` } })
            .then(() => {
              this.state = 'done'
              this.$emit('done')
            })
            .catch((err) => {
              this.state = 'error'
              this.$emit('done', { err: err })
            })
        }
      }
    },
    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    }
  }
}
</script>

<style scoped>

.image-upload {
  display: flex;
}

.image-upload label{
  text-align: center;
  box-sizing: border-box;
  padding: 20px;
  cursor: pointer;
  opacity: 0.7;
  color: var(--blue);
  transition: opacity 0.2s ease;
  z-index: 2;
}

.image-upload:hover label{
  opacity: 1;
}

.image-upload input{
  opacity: 0;
  width: .1px;
  height: .1px;
  position: absolute;
}
</style>
