<template>
  <select v-model="selected" @change="emit">
    <option v-for="option in options" :key="option.name" :value="option.value">
      {{option.name}}
    </option>
  </select>
</template>

<script>
export default {
  name: 'Select',
  props: ['options', 'defaultValue'],
  data () {
    return {
      selected: (this.defaultValue) ? this.defaultValue : this.options[0].value
    }
  },
  methods: {
    emit () {
      this.$emit('selected', this.selected)
    }
  }
}
</script>

<style scoped>
select {
  display: block;
  background: var(--background);
  border: none;
  box-shadow: var(--shadow);
  width: 100%;
  margin: 5px 0;
  padding: 8px 0 8px 5px;
  border-radius: 5px;
  color: var(--text);
}

select:focus {
  outline: none;
}
</style>
