import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function buy (APIName, from, tokenId, price, buySupply, saleId) {
  try {
    const API = Utils._getAPI(APIName)
    const message = {
      token_id: tokenId,
      price: price,
      number: buySupply,
      app_id: saleId
    }

    const parsed = await Utils._callServer('/api/buy/check', message)
    console.log('Buy - check', parsed)

    if (parsed.version === 1) {
      return buyV1(API, from, tokenId, parsed)
    }

    const suggestedParams = await Utils.getParams()
    console.log('Buy - Params', suggestedParams)

    const appAddr = algosdk.getApplicationAddress(parsed.app_id)
    const txns = []

    if (!parsed.check) {
      txns.push(algosdk.makeAssetTransferTxnWithSuggestedParams(from, from, undefined, undefined, 0, undefined, tokenId, suggestedParams))
    }

    if (parsed.asa_id === 1) {
      txns.push(algosdk.makePaymentTxnWithSuggestedParams(from, appAddr, buySupply * parsed.micro_amount, undefined, undefined, suggestedParams))
    } else {
      txns.push(algosdk.makeAssetTransferTxnWithSuggestedParams(from, appAddr, undefined, undefined, buySupply * parsed.micro_amount, undefined, parsed.asa_id, suggestedParams))
    }

    const accounts = [
      parsed.seller, parsed.gems_fees, parsed.royalties, from
    ]
    const appArgs = []
    appArgs.push(new TextEncoder().encode('buy'))
    const foreignAssets = [tokenId]
    if (parsed.asa_id !== 1) {
      foreignAssets.push(parsed.asa_id)
    }
    txns.push(algosdk.makeApplicationNoOpTxn(
      from, suggestedParams, parsed.app_id, appArgs, accounts, undefined, foreignAssets, undefined, undefined, undefined))
    console.log('Buy - SignGroup', txns)
    const signedTxs = await API.signGroup(txns, txns.map(x => true))
    console.log('Buy - Signed', signedTxs)

    const message2 = {
      token_id: tokenId,
      app_id: parsed.app_id,
      signed_tx: signedTxs,
      number: buySupply
    }
    return Utils._callServer('/api/transaction/buy/validation/v2', message2)
  } catch (e) {
    console.log(e)
    throw e
  }
}

async function buyV1 (API, from, tokenId, parsed) {
  try {
    const suggestedParams = await Utils.getParams()
    console.log('Buy - Params', suggestedParams)

    const txns = []
    const isSigning = []
    if (!parsed.check) {
      txns.push(
        algosdk.makeAssetTransferTxnWithSuggestedParams(from, from, undefined, undefined, 0, undefined, tokenId, suggestedParams)
      )
      isSigning.push(true)
    }

    const txNft = algosdk.makeAssetTransferTxnWithSuggestedParams(
      parsed.from_nft, parsed.to_nft,
      undefined, undefined,
      1, undefined,
      parsed.nft_id, suggestedParams)
    txns.push(txNft)
    isSigning.push(false)

    txns.push(
      algosdk.makePaymentTxnWithSuggestedParams(
        parsed.from, parsed.to_owner,
        parsed.price_owner,
        undefined, undefined,
        suggestedParams)
    )
    isSigning.push(true)

    txns.push(
      algosdk.makePaymentTxnWithSuggestedParams(
        parsed.from, parsed.to_us,
        parsed.price_us,
        undefined, undefined,
        suggestedParams)
    )
    isSigning.push(true)

    if (parsed.to_creator != null) {
      txns.push(
        algosdk.makePaymentTxnWithSuggestedParams(
          parsed.from, parsed.to_creator,
          parsed.price_creator,
          undefined, undefined,
          suggestedParams)
      )
      isSigning.push(true)
    }

    console.log('Buy - SignGroup', txns, isSigning)
    const signed = await API.signGroup(txns, isSigning)
    console.log('Buy - request server sign', txNft, signed)
    return Utils._callServer('/api/transaction/buy/validation', { txNft: txNft, txns: signed })
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default buy
