<template>
  <div id="app" :class="[(dark) ? 'dark' : '', customClass ? customClass : '']">
    <Nav/>
    <router-view :key="reloadView" />
    <router-view name="footer"/>
  </div>
</template>

<script>

import { mapMutations, mapState } from 'vuex'
import Nav from '@/components/Common/Nav'

export default {
  name: 'App',
  components: { Nav },
  data () {
    return {
      reloadView: 0
    }
  },
  computed: {
    ...mapState(['dark', 'customClass'])
  },
  methods: {
    ...mapMutations([
      'login',
      'setWallet',
      'setAdult',
      'setDark'
    ])
  },
  mounted () {
    this.$nextTick(() => {
      this.$http.get('/api/auth/check').then(response => {
        this.login(response.data)
        this.setWallet(response.data)
      }).catch(() => {
      })

      this.$http.get('/api/get/nsfw').then(response => {
        this.setAdult({ isAdult: response.data.nsfw })
      })

      this.$http.get('/api/account/mode').then(response => {
        this.setDark({ dark: response.data.mode === 'dark' })
      })

      const tld = window.location.origin.split('.').pop()
      switch (tld) {
        case 'fr':
          this.$i18n.locale = 'fr'
          break
        case 'pt':
          this.$i18n.locale = 'pt'
          break
      }
    })
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&family=Varela+Round&Roboto+Condensed:wght@700&display=swap');
body {
  margin: 0;
}

#app {
  --blue: #008ef7;
  --red: #ca1b52;
  --error: var(--red);
  background: var(--background);
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  transition: background .5s ease;
  --invert: 0;
  width: 100%;
  overflow-x: hidden;
}

#app:not(.dark) {
  --background: #fafafa;
  --foreground: #fff;
  --foreground-transparent: rgba(255,255,255,.9);
  --foreground-blur: rgba(255,255,255,.6);
  --nav-background: #fafafa;
  --fake-blur: #e6e8eeeb;
  --headings: #d7d7d7;
  --text: #2c3e50;
  --textlight: rgba(44, 62, 80, 0.51);
  --accent: var(--blue);
  --complement: #fff;
  --light-shadow: rgba(0, 0, 0, .05) 0px 0px 20px;
  --shadow: rgba(0, 0, 0, .05) 0px 0px 5px, rgba(0, 0, 0, .1) 0px 0px 20px;
  --strong-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  --accent-shadow: 0 0 .5vw rgba(0,0,0,.1), 0 0 10vw rgba(0, 142, 247, .1);
  --gradient: linear-gradient(45deg, var(--accent), #4d02b1);
  --lightgradient: linear-gradient(45deg, #e1f2ff,#f6ebfb);
  --background-alt: #e8e8e8;
  --foreground-alt: var(--foreground);
  --invert: 0;
  --pop-up-background: #fffffff5;
}

#app.dark {
  --background: #0e1117;
  --foreground: #1d222d;
  --foreground-transparent: rgba(29,34,45,.5);
  --foreground-blur: rgba(29,34,45,.5);
  --nav-background: #0e1117;
  --fake-blur: #1c1d20eb;
  --headings: #202231;
  --text: #dae2ff;
  --textlight: rgba(218, 226, 255, 0.6);
  --accent: var(--blue);
  --complement: #fff;
  --blur-overlay: rgba(14, 17, 23, .5);
  --light-shadow: rgba(0, 0, 0, .1) 0px 0px 20px;
  --shadow: rgba(0, 0, 0, .1) 0px 0px 2px, rgba(0, 0, 0, .3) 0px 0px 20px;
  --strong-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  --accent-shadow: 0 0 .5vw rgba(0,0,0,.1), 0 0 10vw rgba(202, 49, 112, 0.3);
  --gradient: linear-gradient(45deg, var(--accent),#4d02b1);
  --lightgradient: linear-gradient(45deg, #103252, #581e70);
  --background-alt: #1d222d;
  --foreground-alt: #0e1117;
  --invert: 1;
  --pop-up-background: rgba(14, 17, 23, 0.9);
}

/*Global*/
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.full-height{
  min-height: calc(100vh - 81px);
}

.stepper {
  padding-bottom: 50px;
}
.stepper p{
  text-align: center;
}

.stepper button{
  margin: 0 20px;
}

.mobile-only {
  display: none;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .desktop-only{
    display: none;
  }

  .mobile-only{
    display: initial;
  }
}

.hooper-list{
  overflow: visible!important;
}

.hooper-navigation button{
  background: var(--foreground);
  border-radius: 15px;
  box-shadow: var(--light-shadow);
  transition: transform .2s ease;
  margin: 0 5px;
  fill: var(--accent);
}

.hooper-pagination{
  bottom: -20px!important;
}

.hooper-indicator {
  background: var(--headings);
  box-shadow: var(--shadow);
  height: 12px!important;
  width: 12px!important;
  border-radius: 50%!important;
  margin: 5px!important;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .hooper-navigation button{
    display: none;
  }
}

/*Elements styles*/
* {
  color: var(--text);
}

form{
  position: relative;
}
input, textarea {
  display: block;
  width: 100%;
  max-width: 450px;
  margin: 20px auto;
  padding: 10px 20px;
  background: var(--foreground);
  box-shadow: var(--light-shadow);
  border: 1px solid transparent;
  border-radius: 20px;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: var(--text);
  box-sizing: border-box;
}

.input{
  width: 100%;
  max-width: 450px;
  margin: 20px auto;
  padding: 10px 20px;
  background: var(--foreground);
  box-shadow: var(--light-shadow);
  border: 1px solid transparent;
  border-radius: 20px;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: var(--text);
  height: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.input input {
  border: none;
  background: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  font-size: 1em;
  border-radius: 0;
}

textarea{
  height: 200px;
  resize: none;
}

input:focus, textarea:focus{
  outline: none;
}

input.invalid, textarea.invalid, .input.invalid{
  border: 1px solid var(--error);
}

.input-container{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: var(--text);
}

.button-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  color: var(--text);
}

.error{
  width: 100%;
  position: absolute;
  bottom: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--error);
  fill: var(--error);
  font-weight: 400;
  animation: slide-bottom 0.2s ease;
}
.error .unicon{
  margin: 0 5px;
}

.unicon{
  display: inline-flex!important;
  justify-content: center;
  align-items: center;
}
/*Text style*/
h1{
  text-transform: lowercase;
  text-align: center;
  color: var(--headings);
  font-family: 'Varela Round', sans-serif;
}

h1.section-title{
  text-transform: capitalize;
  font-family: 'Varela Round', sans-serif;
  font-size: 1.5em;
  color: var(--text);
  text-align: center;
  opacity: .2;
}

h2, h3, h4, h5 {
  padding: 0;
  margin: 0;
}

h2 {
  font-family: 'Varela Round', sans-serif;
}

h5 {
  font-weight: 400;
  color: var(--textlight);
}

p{
  color: var(--textlight);
  font-size: 0.9em;
  font-weight: 400;
  text-align: justify;
}

a{
  color: var(--text);
}

.unicon {
  fill: var(--text);
}

.big{
  font-size: 5em;
}

.medium{
  font-size: 3em;
}

.light{
  font-weight: 400;
}
/*Animations*/
.next-enter-active, .next-leave-active, .previous-enter-active, .previous-leave-active {
  transition: all .2s ease;
}

.next-enter {
  transform: translatex(20px);
  opacity: 0;
}

.next-enter-active .button, .next-leave-active  .button, .previous-enter-active  .button, .previous-leave-active .button {
  opacity: 0;
  transform: translateY(20px);
}

.next-leave-to {
  transform: translatex(-20px);
  opacity: 0;
}

.previous-enter {
  transform: translatex(-20px);
  opacity: 0;
}

.previous-leave-to {
  transform: translatex(20px);
  opacity: 0;
}

@keyframes fade {
  0%{opacity: 0}
  100%{opacity: 1}
}

@keyframes spin {
  0%{transform: rotate(0deg)}
  100%{transform: rotate(360deg)}
}

@keyframes card-appear{
  0%{opacity: 0; transform: translateY(20px)}
  100%{opacity: 1; transform: translateY(0px)}
}

@keyframes slide-bottom{
  0%{opacity: 0; transform: translateY(5px)}
  100%{opacity: 1; transform: translateY(0px)}
}

@keyframes slide-in{
  0%{opacity: 0; transform: translateY(50px)}
  50%{opacity: 1}
  100%{transform: translateY(0px)}
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  textarea {
    width: 325px;
  }
}

</style>
