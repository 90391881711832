<template>
  <form>
    <h1 class="medium">{{$t('createPicture.header.title')}}</h1>
    <p>Choose your NFT format</p>
    <div class="nft-kind-selector">
      <label for="picture-selector">
        <input type="radio" id="picture-selector" value="picture" v-model="nftKind">
        <unicon name="image"/>
        <span>Image or GIF</span>
      </label>
      <label for="instagram-selector" v-if="hasInstaAccess">
        <input type="radio" id="instagram-selector" value="instagram" v-model="nftKind">
        <unicon name="instagram"/>
        <span>Instagram</span>
      </label>
      <label for="video-selector">
        <input type="radio" id="video-selector" value="video" v-model="nftKind">
        <unicon name="video"/>
        <span>Video</span>
      </label>
      <label for="music-selector">
        <input type="radio" id="music-selector" value="music" v-model="nftKind">
        <unicon name="music"/>
        <span>Audio</span>
      </label>
    </div>

    <template v-if="nftKind !== 'instagram'">
      <template v-if="nftKind === 'video'">
        <h4>Select and upload a video <span class="small-text">(.mp4, .webm & .mov only)</span></h4>
        <label for="video-file-upload" class="nft-file-label">
          <unicon name="video"/>
          {{nftFile ? nftFile.name : 'No file selected'}}
          <input type="file" id="video-file-upload" accept="video/mp4, video/quicktime, video/webm" @change=uploadFile>
        </label>
      </template>
      <template v-if="nftKind === 'music'">
        <h4>Select and upload audio <span class="small-text">(.mp3 only)</span></h4>
        <label for="music-file-upload" class="nft-file-label">
          <unicon name="music"/>
          {{nftFile ? nftFile.name : 'No file selected'}}
          <input type="file" id="music-file-upload" accept="audio/mpeg" @change=uploadFile>
        </label>
      </template>

      <h4>
        <template v-if="nftKind === 'picture'">
          Select and upload a file <span class="small-text">(.png, .jpeg & .gif only)</span>
        </template>
        <template v-else>
          Choose a thumbnail <span class="small-text">(.png, .jpeg & .gif only)</span>
        </template>
      </h4>
      <NFTUpload :class="{'invalid':!valid() && error}" @file="(file) => {this.mimeType = file.type; this.pictureFile = file}" @picture="(picture) => {this.picture = picture}"></NFTUpload>

    </template>
    <h5 v-if="error" class="error">
      <unicon height="24px" name="exclamation-octagon"/>
      {{ error }}
    </h5>
    <div class="button-container">
      <template v-if="isUploading">
        <LinearSpinner>
          {{$t('createPicture.ipfsUpload')}}
        </LinearSpinner>
      </template>
      <Button v-else-if="nftKind!=='instagram'" class="blue" @click.native.prevent="next()">{{$t('createPicture.button.next')}}</Button>
    </div>
    <template v-if="nftKind === 'instagram'">
      <InstagramImages @picture="(p) => this.picture = p" @file="uploadInstagram"/>
    </template>
  </form>
</template>

<script>
import Button from '@/components/Common/Button/Button'
import NFTUpload from '@/components/Create/NFTUpload'
import LinearSpinner from '@/components/Common/Spinner/LinearSpinner'
import InstagramImages from '../../../components/Create/InstagramImages'
import { mapState } from 'vuex'

export default {
  name: 'CreatePicture',
  components: {
    InstagramImages,
    LinearSpinner,
    NFTUpload,
    Button
  },
  data () {
    return ({
      name: '',
      error: false,
      picture: null,
      pictureFile: null,
      mimeType: null,
      isUploading: false,
      nftKind: 'picture',
      nftFile: null
    })
  },
  computed: {
    ...mapState(['address']),
    hasInstaAccess () {
      return [
        'TST2PF3APFSTOQS57TTILKU3IV5O4RCU7XK6SXBS4VFDRG2APTD3QOOF7I',
        '3ZR2JP7X377SDOBOG5XTWTCVBY6HQUH7LZFPYMVJXRAYONXJ6FZXGAQK7Y',
        'IL2CNQOSJRI3XJNKQW3BJ2JICZ6RGI752NSJEQ52U6GKWBX5ZZYZ7MI7LQ'
      ].includes(this.address)
    }
  },
  methods: {
    uploadFile (e) {
      const file = e.target.files[0]
      if (file) {
        this.nftFile = file
      }
    },
    valid () {
      return (this.nftKind === 'instagram' && !!this.pictureFile) || (!!this.picture && (this.nftKind === 'picture' || !!this.nftFile))
    },
    toggleError () {
      this.error = false
      setTimeout(() => {
        if (!this.valid()) {
          this.error = 'Please upload a picture'
        }
      }, 100)
    },
    uploadInstagram (m) {
      this.pictureFile = m.file
      this.mimeType = m.file.type
      this.next()
    },
    next (data = {}) {
      if (this.valid()) {
        const done = (p) => {
          this.isUploading = false
          this.$emit('next', { ...data, mimeType: this.mimeType, picture: this.picture, ipfs_url: p.data.ipfs_url, ipfs_hash: p.data.ipfs_hash, canPayMint: p.data.create_user, crescendo: p.data.crescendo })
        }

        const error = (e) => {
          this.error = e
          this.isUploading = false
        }

        this.isUploading = true

        const formData = new FormData()
        if (this.nftKind === 'video') {
          formData.append('thumbnail', this.pictureFile)
          formData.append('file', this.nftFile)
          this.$http.post('/api/create/upload/video', formData,
            { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(done)
            .catch(error)
        } else if (this.nftKind === 'music') {
          formData.append('thumbnail', this.pictureFile)
          formData.append('file', this.nftFile)
          this.$http.post('/api/create/upload/music', formData,
            { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(done)
            .catch(error)
        } else {
          formData.append('file', this.pictureFile)
          this.$http.post('/api/create/upload/image', formData,
            { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(done)
            .catch(error)
        }
      } else {
        this.toggleError()
      }
    }
  }
}
</script>

<style scoped>

.nft-kind-selector {
  display: flex;
  width: 600px;
  justify-content: space-around;
  margin: 30px auto;
}

.nft-kind-selector input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  color: var(--complement);
}

.nft-kind-selector label {
  display: flex;
  align-content: center;
}

.nft-kind-selector label span {
  margin: auto;
}

.nft-kind-selector label .unicon {
  margin-right: 5px;
  fill: var(--textlight);
}

label input:checked ~ span {
  color: var(--accent);
}

label input:checked ~ .unicon {
  fill: var(--accent);
}

.nft-file-label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--textlight);
  margin-bottom: 50px;
}

.nft-file-label .unicon {
  fill: var(--textlight);
  margin-right: 8px;
}

.nft-file-label:hover {
  color: var(--text);
}

.nft-file-label:hover .unicon {
  fill: var(--text);
}

.nft-file-label input {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

h4 {
  text-align: center;
  font-weight: normal;
  margin: 10px;
}

h4 .small-text {
  display: block;
  color: var(--textlight);
  font-size: smaller;
}
</style>
