<template>
    <div>
      <unicon @click.native="share" name="copy" v-if="!copied"/>
      <unicon @click.native="share" name="check" v-else/>
      <span v-if="displayText" class="copied-text">Copied!</span>
    </div>
</template>

<script>
export default {
  name: 'CopyButton',
  props: {
    link: String
  },
  data () {
    return {
      displayText: false,
      copied: false
    }
  },
  methods: {
    share () {
      this.copied = true
      const dummy = document.createElement('input')
      document.body.appendChild(dummy)
      dummy.setAttribute('id', 'dummy_id')
      document.getElementById('dummy_id').value = this.$props.link
      dummy.select()
      document.execCommand('copy')
      this.displayText = true
      setTimeout(() => {
        this.displayText = false
      }, 400)
      setTimeout(() => {
        this.copied = false
      }, 1200)
      document.body.removeChild(dummy)
    }
  }
}
</script>

<style scoped>
div {
  position: relative;
  display: inline-block;
}

.unicon {
  margin-left: 9px;
  fill: var(--text);
  cursor: pointer;
}

.copied-text {
  position: absolute;
  top: -1em;
  left: 1em;
  font-size: xx-small;
  font-weight: lighter;
  white-space: nowrap;
  font-family: 'Roboto', sans-serif;
  text-transform: capitalize;
  font-weight: initial;
}

.smaller .unicon {
  fill: var(--textlight);
  position: relative;
  top: 6px;
}
</style>
