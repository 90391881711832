<template>
  <div class="collection-grid">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CollectionGrid'
}
</script>

<style scoped>
.collection-grid {
  display: grid;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  margin: 25px 0;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 50px;
  grid-auto-rows: 275px;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .collection-grid {
    padding: 0;
    box-sizing: border-box;
    grid-template-columns: repeat(auto-fill, minmax(90vw, 1fr));
  }
}
</style>
