import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import VueRouter from 'vue-router'
import MarketNew from '../views/Market/Market'
import Auctions from '../views/Market/Auctions'
import Sales from '../views/Market/Sales'
import NFT from '../views/NFT'
import NFTEdit from '../views/NFTEdit'
import Create from '@/views/Create/Create'
import Gallery from '@/views/Gallery/Gallery'
import Authenticate from '@/views/User/Authenticate/Authenticate'
import TermsOfUse from '@/views/TermsOfUse/TermsOfUse'
import Footer from '@/components/Common/Footer'
import Settings from '@/views/User/Settings'
import CreateCollection from '@/views/Create/CreateCollection'
import Collection from '@/views/Collection'
import CollectionEdit from '@/views/CollectionEdit'
import Collections from '@/views/Market/Collections'
import Governance from '@/views/Governance/Governance'
import Stake from '@/views/Governance/Stake'
import GalleryBody from '@/views/Gallery/GalleryBody'
import GalleryAuctions from '@/components/User/InfiniteLists/Auctions'
import GalleryBids from '@/components/User/InfiniteLists/Bids'
import GalleryCollections from '@/components/User/InfiniteLists/Collections'
import GalleryCreations from '@/components/User/InfiniteLists/Creations'
import GalleryFollower from '@/components/User/InfiniteLists/Follower'
import GalleryFollowing from '@/components/User/InfiniteLists/Following'
import GalleryOwnedNft from '@/components/User/InfiniteLists/OwnedNft'
import GalleryPacks from '@/components/User/InfiniteLists/Packs'
import GallerySales from '@/components/User/InfiniteLists/Sales'
import GalleryStaking from '@/components/User/InfiniteLists/Stakings'
import GalleryShuffles from '@/components/User/InfiniteLists/Shuffles'
import GalleryFusions from '@/components/User/InfiniteLists/Fusions'
import Stats from '@/components/User/Stats'
import User from '@/views/User/User'
import AlgoGlyph from '../views/User/AlgoGlyph'
import VIPGallery from '@/views/Market/VIPGallery'
import FollowedNFT from '../components/User/InfiniteLists/FollowedNFT'
import CreateShuffle from '../views/Create/CreateShuffle'
import Shuffle from '../views/Shuffle'
import Shuffles from '@/views/Market/Shuffles'
import HDLSubmarket from '@/views/Market/Submarkets/HDLSubmarket'
import HDLBody from '@/components/Submarket/HDLBody'
import TokenSale from '../components/Submarket/TokenSale'
import Offers from '../components/User/InfiniteLists/Offers'
import MyOffers from '../components/User/InfiniteLists/MyOffers'
import HelpJoe from '@/views/HelpJoe'
import BkartelSubmarket from '../views/Market/Submarkets/BkartelSubmarket'
import BkartelBody from '../components/Submarket/BkartelBody'
import CrescendoSubmarket from '../views/Market/Submarkets/CrescendoSubmarket'
import CrescendoBody from '../components/Submarket/CrescendoBody'
import CrescendoServiceForm from '../components/Submarket/CrescendoServiceForm'
import CrescendoShippingForm from '../components/Submarket/CrescendoShippingForm'
import Maintenance from '../views/Maintenance'
import Listings from '../components/User/Listings'
import Pending from '../components/User/Pending'
import CosmicChampBody from '../components/Submarket/CosmicChampBody'
import CosmicChampSubmarket from '../views/Market/Submarkets/CosmicChampSubmarket'
import DistributeToken from '../views/DistributeToken'
import CreatePack from '../views/Create/CreatePack'
import CreateFusion from '../views/Create/CreateFusion'
import Fusion from '../views/Fusion'
import CreateStaking from '../views/Create/CreateStaking'
import AlgoLeaguesBody from '../components/Submarket/AlgoLeaguesBody'
import AlgoLeaguesSubmarket from '../views/Market/Submarkets/AlgoLeaguesSubmarket'
import Pack from '../views/Pack'
import Community from '../components/User/Community'
import Notifications from '../components/User/InfiniteLists/Notifications'
import FusionSetting from '../views/FusionSetting'
import Desk from '../views/User/Desk'
import Instagram from '../components/User/Instagram'
import InstagramRedirect from '../views/InstagramRedirect'
import CreateInstagram from '../views/Create/CreateInstagram/CreateInstagram'
// import HondaSubmarket from '@/views/Market/Submarkets/HondaSubmarket'
import Mobile from '@/views/mobile.vue'
import AuctionClose from '../views/AuctionClose.vue'

Vue.use(VueRouter)

const MAINTENANCE = false

const routes = [
  {
    path: '/',
    name: 'Market',
    components: {
      default: MarketNew,
      footer: Footer
    }
  },
  {
    path: '/maintenance',
    component: Maintenance
  },
  {
    path: '/auctions',
    name: 'Auctions',
    component: Auctions
  },
  {
    path: '/collections',
    name: 'Collections',
    component: Collections
  },
  {
    path: '/sales',
    name: 'sales',
    component: Sales
  },
  {
    path: '/vip',
    name: 'VIP',
    component: VIPGallery
  },
  {
    path: '/nft/:id',
    components: {
      default: NFT,
      footer: Footer
    },
    children: [
      {
        path: 'auction/:auctionId',
        components: {
          default: NFT,
          footer: Footer
        },
        props: {
          default: true
        }
      },
      {
        path: 'sale/:saleId',
        components: {
          default: NFT,
          footer: Footer
        },
        props: {
          default: true
        }
      },
      {
        path: 'offer/:offerId',
        components: {
          default: NFT,
          footer: Footer
        },
        props: {
          default: true
        }
      }
    ],
    props: {
      default: true
    }
  },
  {
    path: '/nft_edit/:id',
    components: {
      default: NFTEdit,
      footer: Footer
    },
    props: {
      default: true
    }
  },
  {
    path: '/create',
    components: {
      default: Create,
      footer: Footer
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/instagram/create',
    components: {
      default: CreateInstagram,
      footer: Footer
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/gallery/:id',
    components: { default: Gallery },
    children: [
      {
        path: '',
        components: {
          default: GalleryBody,
          footer: Footer
        }
      },
      {
        path: 'auctions',
        component: GalleryAuctions
      },
      {
        path: 'collections',
        component: GalleryCollections
      },
      {
        path: 'creations',
        component: GalleryCreations
      },
      {
        path: 'follower',
        component: GalleryFollower
      },
      {
        path: 'following',
        component: GalleryFollowing
      },
      {
        path: 'ownedNft',
        component: GalleryOwnedNft
      },
      {
        path: 'sales',
        component: GallerySales
      },
      {
        path: 'shuffles',
        component: GalleryShuffles
      },
      {
        path: 'stats',
        component: Stats
      },
      {
        path: 'fusions',
        component: GalleryFusions
      },
      {
        path: 'packs',
        component: GalleryPacks
      },
      {
        path: 'instagram',
        component: Instagram
      },
      {
        path: 'community',
        component: Community,
        props: {
          kind: 'account'
        }
      }
    ],
    props: {
      default: true
    }
  },
  {
    path: '/user',
    component: User,
    children: [
      {
        path: '',
        components: {
          default: Stats,
          footer: Footer
        }
      },
      {
        path: 'listings',
        component: Listings,
        children: [
          {
            path: '',
            component: GalleryAuctions
          },
          {
            path: 'sales',
            component: GallerySales
          },
          {
            path: 'shuffles',
            component: GalleryShuffles
          },
          {
            path: 'my_offers',
            component: MyOffers
          },
          {
            path: 'staking',
            component: GalleryStaking
          },
          {
            path: 'fusions',
            component: GalleryFusions
          },
          {
            path: 'packs',
            component: GalleryPacks
          }
        ]
      },
      {
        path: 'pending',
        component: Pending,
        children: [
          {
            path: '',
            component: Offers
          },
          {
            path: 'bids',
            component: GalleryBids
          }
        ]
      },
      {
        path: 'instagram',
        component: Instagram
      },
      {
        path: 'collections',
        component: GalleryCollections
      },
      {
        path: 'creations',
        component: GalleryCreations
      },
      {
        path: 'follower',
        component: GalleryFollower
      },
      {
        path: 'following',
        component: GalleryFollowing
      },
      {
        path: 'ownedNft',
        component: GalleryOwnedNft
      },
      {
        path: 'favouriteNft',
        component: FollowedNFT
      },
      {
        path: 'community',
        component: Community,
        props: {
          kind: 'account'
        }
      },
      {
        path: 'notifications',
        component: Notifications
      },
      {
        path: 'desk',
        component: Desk
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    components: {
      default: Settings,
      footer: Footer
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/authenticate',
    name: 'authenticate',
    components: {
      default: Authenticate,
      footer: Footer
    },
    beforeEnter: (to, from, next) => {
      store.commit('setRouteBeforeAuthenticate', { routeBeforeAuthenticate: from.path })
      next()
    }
  },
  {
    path: '/token_sale',
    component: MarketNew,
    beforeEnter (to, from, next) {
      window.location.href = 'https://algogemsnft.github.io/#faq '
    }
  },
  {
    path: '/faq',
    components: {
      default: MarketNew,
      footer: Footer
    },
    beforeEnter (to, from, next) {
      window.location.href = 'https://algogemsnft.github.io/#faq '
    }
  },
  {
    path: '/terms_of_use',
    components: {
      default: TermsOfUse,
      footer: Footer
    }
  },
  {
    path: '/create_collection',
    component: CreateCollection,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/collection/:id',
    component: Collection,
    props: {
      default: true
    }
  },
  {
    path: '/edit_collection/:id',
    component: CollectionEdit,
    props: {
      default: true
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/governance',
    component: Governance,
    children: [
      /* {
        path: '',
        component: Home,
        alias: 'governance',
        children: [
          {
            path: '',
            component: Vote,
            alias: 'vote',
            children: [
              {
                path: 'spotlight',
                component: Spotlight,
                name: 'governance-spotlight'
              },
              {
                path: '',
                alias: 'verify',
                component: Verify,
                name: 'governance-verify'
              },
              {
                path: 'ban',
                component: Ban,
                name: 'governance-ban'
              }
            ]
          }
        ]
      }, */
      {
        path: '',
        alias: 'stake',
        component: Stake
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/algoglyph',
    component: AlgoGlyph,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/shuffles',
    component: Shuffles
  },
  {
    path: '/shuffle/create',
    component: CreateShuffle,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/shuffle/:id',
    component: Shuffle,
    props: {
      default: true
    }
  },
  {
    path: '/fusion/create',
    component: CreateFusion,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/fusion/settings',
    component: FusionSetting,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/fusion/:id',
    component: Fusion,
    props: {
      default: true
    }
  },
  {
    path: '/pack/create',
    component: CreatePack,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pack/:id',
    component: Pack,
    props: {
      default: true
    }
  },
  {
    path: '/shops/headline',
    alias: '/hdl',
    component: HDLSubmarket,
    children: [
      {
        path: '',
        name: 'hdl-home',
        components: {
          default: HDLBody,
          footer: Footer
        }
      },
      {
        path: 'sales',
        component: GallerySales,
        props: {
          id: 'HEADLINE'
        },
        name: 'hdl-sales'
      },
      {
        path: 'auctions',
        component: GalleryAuctions,
        props: {
          id: 'HEADLINE'
        },
        name: 'hdl-auctions'
      },
      {
        path: 'token',
        component: TokenSale,
        props: {
          _asaId: 137594422
        },
        name: 'hdl-shuffles'
      }
    ]
  },
  {
    path: '/shops/Bkartel',
    alias: '/bkrt',
    component: BkartelSubmarket,
    children: [
      {
        path: '',
        name: 'bkrt-home',
        components: {
          default: BkartelBody,
          footer: Footer
        }
      },
      {
        path: 'sales',
        component: GallerySales,
        props: {
          id: 'FOEUI7GSVBOKPBFPNPKO7NQ64Z4HDI2EVZWHANXHPLJJCS4Q35S3JRTZHA'
        },
        name: 'bkrt-sales'
      },
      {
        path: 'auctions',
        component: GalleryAuctions,
        props: {
          id: 'FOEUI7GSVBOKPBFPNPKO7NQ64Z4HDI2EVZWHANXHPLJJCS4Q35S3JRTZHA'
        },
        name: 'bkrt-auctions'
      },
      {
        path: 'shuffles',
        component: GalleryShuffles,
        props: {
          id: 'FOEUI7GSVBOKPBFPNPKO7NQ64Z4HDI2EVZWHANXHPLJJCS4Q35S3JRTZHA'
        },
        name: 'bkrt-shuffles'
      },
      {
        path: 'packs',
        component: GalleryPacks,
        props: {
          id: 'FOEUI7GSVBOKPBFPNPKO7NQ64Z4HDI2EVZWHANXHPLJJCS4Q35S3JRTZHA'
        },
        name: 'bkrt-packs'
      },
      {
        path: 'brands',
        component: GalleryCollections,
        props: {
          id: 'FOEUI7GSVBOKPBFPNPKO7NQ64Z4HDI2EVZWHANXHPLJJCS4Q35S3JRTZHA'
        },
        name: 'bkrt-brands'
      },
      {
        path: 'token',
        component: TokenSale,
        props: {
          _asaId: 230946361
        },
        name: 'bkrt-token'
      },
      {
        path: 'service-form/:id',
        component: CrescendoServiceForm,
        name: 'crsd-service-form',
        props: {
          default: true
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'shipping-form/:id',
        component: CrescendoShippingForm,
        name: 'crsd-shipping-form',
        props: {
          default: true
        },
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/shops/crescendo',
    alias: '/crsd',
    component: CrescendoSubmarket,
    children: [
      {
        path: '',
        name: 'crsd-home',
        components: {
          default: CrescendoBody,
          footer: Footer
        }
      },
      {
        path: 'sales',
        component: GallerySales,
        props: {
          id: 'WXLHOLGBGWIF4WXCSART3QRIINPM5R3OSULYQ3TWWHSPAI2YHIJMVM4XRI'
        },
        name: 'crsd-sales'
      },
      {
        path: 'auctions',
        component: GalleryAuctions,
        props: {
          id: 'WXLHOLGBGWIF4WXCSART3QRIINPM5R3OSULYQ3TWWHSPAI2YHIJMVM4XRI'
        },
        name: 'crsd-auctions'
      },
      {
        path: 'shuffles',
        component: GalleryShuffles,
        props: {
          id: 'WXLHOLGBGWIF4WXCSART3QRIINPM5R3OSULYQ3TWWHSPAI2YHIJMVM4XRI'
        },
        name: 'crsd-shuffles'
      },
      {
        path: 'artists',
        component: GalleryCollections,
        props: {
          id: 'WXLHOLGBGWIF4WXCSART3QRIINPM5R3OSULYQ3TWWHSPAI2YHIJMVM4XRI'
        },
        name: 'crsd-artists'
      },
      {
        path: 'token',
        component: TokenSale,
        props: {
          _asaId: 435335235
        },
        name: 'crsd-token'
      },
      {
        path: 'service-form/:id',
        component: CrescendoServiceForm,
        name: 'crsd-service-form',
        props: {
          default: true
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'shipping-form/:id',
        component: CrescendoShippingForm,
        name: 'crsd-shipping-form',
        props: {
          default: true
        },
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/shops/cosmicChamps',
    alias: '/cosg',
    component: CosmicChampSubmarket,
    children: [
      {
        path: '',
        name: 'cosg-home',
        components: {
          default: CosmicChampBody,
          footer: Footer
        }
      },
      {
        path: 'sales',
        component: GallerySales,
        props: {
          id: 'CCNFTNFTSXVYGCGP5EU7AMUTQLUZI6TXRS4XMW2LP4XARZH3LB6RZPUOQM'
        },
        name: 'cosg-sales'
      },
      {
        path: 'auctions',
        component: GalleryAuctions,
        props: {
          id: 'CCNFTNFTSXVYGCGP5EU7AMUTQLUZI6TXRS4XMW2LP4XARZH3LB6RZPUOQM'
        },
        name: 'cosg-auctions'
      },
      {
        path: 'shuffles',
        component: GalleryShuffles,
        props: {
          id: 'CCNFTNFTSXVYGCGP5EU7AMUTQLUZI6TXRS4XMW2LP4XARZH3LB6RZPUOQM'
        },
        name: 'cosg-shuffles'
      },
      {
        path: 'seasons',
        component: GalleryCollections,
        props: {
          id: 'CCNFTNFTSXVYGCGP5EU7AMUTQLUZI6TXRS4XMW2LP4XARZH3LB6RZPUOQM'
        },
        name: 'cosg-seasons'
      },
      {
        path: 'token',
        component: TokenSale,
        props: {
          _asaId: 1065092715
        },
        name: 'cosg-token'
      }
    ]
  },
  {
    path: '/shops/algoLeagues',
    component: AlgoLeaguesSubmarket,
    children: [
      {
        path: '',
        name: 'al-home',
        components: {
          default: AlgoLeaguesBody,
          footer: Footer
        }
      },
      {
        path: 'sales',
        component: GallerySales,
        props: {
          id: 'AlgoLeaguesOfficial'
        }
      },
      {
        path: 'auctions',
        component: GalleryAuctions,
        props: {
          id: 'AlgoLeaguesOfficial'
        }
      },
      {
        path: 'shuffles',
        component: GalleryShuffles,
        props: {
          id: 'PZNGYF4Y25GGO674BW4CRDHFKOKHMHZXSFXIKMYPEJCQAUTDH52WV24XTY'
        },
        name: 'alc-shuffles'
      },
      {
        path: 'sets',
        component: GalleryCollections,
        props: {
          id: 'PZNGYF4Y25GGO674BW4CRDHFKOKHMHZXSFXIKMYPEJCQAUTDH52WV24XTY'
        },
        name: 'alc-sets'
      },
      {
        path: 'fusions',
        component: GalleryFusions,
        props: {
          id: 'AlgoLeaguesOfficial'
        }
      },
      {
        path: 'packs',
        component: GalleryPacks,
        props: {
          id: 'AlgoLeaguesOfficial'
        }
      },
      {
        path: 'token',
        component: TokenSale,
        props: {
          _asaId: 445905873
        }
      }
    ]
  },
  {
    path: '/shops/honda',
    alias: '/honda',
    component: () => import('@/views/Market/Submarkets/HondaSubmarket')
  },
  {
    path: '/distributeToken',
    component: DistributeToken
  },
  {
    path: '/staking/create',
    component: CreateStaking
  },
  {
    path: '/helpJoe',
    component: HelpJoe
  },
  {
    path: '/instagramMint',
    component: InstagramRedirect,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mobile',
    component: Mobile
  },
  {
    path: '/nfd/:id',
    beforeEnter (to, from, next) {
      const nfd = to.params.id
      axios.get('/api/account/nfd', { params: { nfd: nfd } })
        .then((d) => {
          next(`/gallery/${d.data.username}`)
        })
        .catch(next('/'))
    }
  },
  {
    path: '/close_auction',
    component: AuctionClose,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'is-active'
})

router.beforeEach((to, from, next) => {
  if (MAINTENANCE && to.path !== '/maintenance') {
    console.log('a')
    next('/maintenance')
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.userId == null) {
      axios.get('/api/auth/check').then(response => {
        store.commit('login', response.data)
        store.commit('setWallet', response.data)
        next()
      }).catch(() => {
        next({
          name: 'authenticate',
          params: { next: to.fullPath }
        })
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
