<template>
  <div class="centered">
    <div class="spinner"></div>
    <p><slot></slot></p>
  </div>
</template>

<script>
export default {
  name: 'LinearSpinner'
}
</script>

<style scoped>
.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  width: 200px;
  margin-top: 2rem;
  overflow: hidden;
  position: relative;
  height: 0.5rem;
  background: var(--lightgradient);
  border-radius: 20px;
}

.spinner::before {
  content: "";
  position: absolute;
  left: -130%;
  width: 100%;
  height: 100%;
  background: var(--lightgradient);
  animation: progress 2s linear infinite;
}

@keyframes progress {
  0% {
    left: -130%;
    background: var(--gradient);
  }
  50% {
    left: 130%;
    background: var(--gradient);
  }
  51% {
    background: var(--accent);
  }
  100% {
    background: var(--accent);
  }
}
</style>
