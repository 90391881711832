import Vue from 'vue'
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'

import {
  uni18Plus,
  uniAdjust,
  uniAngleDown,
  uniAngleLeft,
  uniAngleRight,
  uniAngleUp,
  uniArrowDown,
  uniArrowGrowth,
  uniArrowRandom,
  uniArrowsV,
  uniArrowToBottom,
  uniArrowUp,
  uniArrowUpRight,
  uniAt,
  uniBan,
  uniBox,
  uniBrightnessEmpty,
  uniChartLine,
  uniChat,
  uniCheck,
  uniCheckCircle,
  uniCircle,
  uniClockTen,
  uniCopy,
  uniDashboard,
  uniDiscord,
  uniDownloadAlt,
  uniEdit,
  uniEditAlt,
  uniEnvelope,
  uniEstate,
  uniExchange,
  uniExclamationOctagon,
  uniExclamationTriangle,
  uniExternalLinkAlt,
  uniEyeSlash,
  uniFacebookF,
  uniFavorite,
  uniFavoriteMonochrome,
  uniFileEditAlt,
  uniFire,
  uniFocusAdd,
  uniFolder,
  uniImage,
  uniImageBlock,
  uniImageCheck,
  uniImageEdit,
  uniImagePlus,
  uniImageShield,
  uniImageTimes,
  uniImport,
  uniInfoCircle,
  uniInstagram,
  uniJackhammer,
  uniKeyholeCircle,
  uniLayers,
  uniLinkH,
  uniListUl,
  uniLock,
  uniMediumM,
  uniMinusCircle,
  uniMoneyBillSlash,
  uniMoneyInsert,
  uniMoneyStack,
  uniMoneyWithdraw,
  uniMoneyWithdrawal,
  uniMoon,
  uniMusic,
  uniPathfinderUnite,
  uniPause,
  uniPercentage,
  uniPlay,
  uniPlus,
  uniPlusCircle,
  uniQuestionCircle,
  uniRedditAlienAlt,
  uniRedo,
  uniRocket,
  uniSearch,
  uniSetting,
  uniShare,
  uniShuffle,
  uniSignout,
  uniSpinnerAlt,
  uniStopwatch,
  uniSun,
  uniSync,
  uniTagAlt,
  uniTelegram,
  uniThumbsDown,
  uniThumbsUp,
  uniTimes,
  uniTimesCircle,
  uniTimesCircleMonochrome,
  uniTopArrowFromTop,
  uniTrashAlt,
  uniTrophy,
  uniTwitter,
  uniUsdCircle,
  uniUser,
  uniUsersAlt,
  uniVideo,
  uniWallet,
  uniApps
} from 'vue-unicons/dist/icons'

Unicon.add([
  uniAngleLeft,
  uniImagePlus,
  uniTimes,
  uniKeyholeCircle,
  uniExclamationOctagon,
  uniExclamationTriangle,
  uniUser,
  uniFire,
  uniMoneyBillSlash,
  uniLock,
  uniEdit,
  uniCheck,
  uniFocusAdd,
  uniFacebookF,
  uniTwitter,
  uniRedditAlienAlt,
  uniInstagram,
  uniSearch,
  uniExchange,
  uniUsdCircle,
  uniArrowGrowth,
  uniStopwatch,
  uniBrightnessEmpty,
  uniClockTen,
  uniArrowUp,
  uniArrowDown,
  uniArrowsV,
  uniAdjust,
  uniEyeSlash,
  uniImage,
  uniImageShield,
  uniImageBlock,
  uniArrowUpRight,
  uniSetting,
  uniSignout,
  uniInfoCircle,
  uniFavoriteMonochrome,
  uniFavorite,
  uniTimesCircleMonochrome,
  uniPlusCircle,
  uniMinusCircle,
  uniEditAlt,
  uniTimesCircle,
  uniTrophy,
  uniDownloadAlt,
  uniTopArrowFromTop,
  uniAngleRight,
  uniEnvelope,
  uniTelegram,
  uniAt,
  uniPlus,
  uniShare,
  uniThumbsUp,
  uniMoneyWithdraw,
  uniEstate,
  uniWallet,
  uniChartLine,
  uniImageCheck,
  uniImageEdit,
  uniFolder,
  uniLinkH,
  uniArrowRandom,
  uniDashboard,
  uniImageTimes,
  uniSync,
  uniTagAlt,
  uniThumbsDown,
  uniCheckCircle,
  uniBan,
  uniRocket,
  uniSpinnerAlt,
  uniRedo,
  uniCircle,
  uni18Plus,
  uniMoon,
  uniSun,
  uniPlay,
  uniVideo,
  uniMusic,
  uniImport,
  uniPause,
  uniMediumM,
  uniArrowToBottom,
  uniShuffle,
  uniAngleUp,
  uniAngleDown,
  uniExternalLinkAlt,
  uniMoneyWithdrawal,
  uniMoneyInsert,
  uniDiscord,
  uniChat,
  uniPercentage,
  uniFileEditAlt,
  uniBox,
  uniMusic,
  uniQuestionCircle,
  uniListUl,
  uniCopy,
  uniMoneyStack,
  uniJackhammer,
  uniPathfinderUnite,
  uniLayers,
  uniUsersAlt,
  uniTrashAlt,
  uniChat,
  uniApps
])

Vue.use(Unicon)
