<template>
  <AppView id="sales">
    <h1 v-if="!_asaId">{{$t('sales.header')}}</h1>
    <Section>
      <template v-slot:header>
        <SortButtonsSales :_initialSort="sortOption" @selected="sort"/>
        <span class="filters">
          <textual-button @click.native="toggleDisplayUnverifiedArtist">
            <VerifiedLogo :class="(displayUnverifiedArtist) ? 'unverified' : ''"/>
            {{$t('common.verified-artist')}}
          </textual-button>
          <ToggleNSFW v-model="nsfw"/>
        </span>
      </template>
      <CardGrid>
        <Card v-for="(sale, index) in sales" :key="`${index}-sale`" :data="sale" :style="getStyle(index)"/>
        <Card v-for="(auction, index) in auctions" :key="`${index}-auction`" :data="auction" :style="getStyle(index)"/>
      </CardGrid>
      <infinite-loading spinner="waveDots" @infinite="infiniteHandler" :identifier="infiniteId" :forceUseInfiniteWrapper="true"><span slot="no-more"></span><span slot="no-results"></span></infinite-loading>
    </Section>
  </AppView>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import Card from '@/components/Cards/Card'
import AppView from '@/components/Layout/AppView'
import Section from '@/components/Layout/Section'
import CardGrid from '@/components/Cards/CardGrid'
import VerifiedLogo from '@/components/Assets/VerifiedLogo'
import TextualButton from '@/components/Common/Button/TextualButton'
import SortButtonsSales from '@/components/Common/Button/SortButtonsSales'
import ToggleNSFW from '@/components/Common/Button/ToggleNSFW'

export default {
  name: 'TokenSale',
  components: {
    SortButtonsSales,
    TextualButton,
    VerifiedLogo,
    CardGrid,
    Section,
    AppView,
    Card,
    InfiniteLoading,
    ToggleNSFW
  },
  props: ['_asaId'],
  data () {
    return {
      start: 0,
      end: 16,
      sales: [],
      auctions: [],
      shuffles: [],
      sortOption: 'random',
      displayUnverifiedArtist: false,
      infiniteId: 0,
      nsfw: false,
      asaId: this._asaId ? this._asaId : 1,
      loadingKind: 'sale'
    }
  },
  watch: {
    nsfw () {
      this.reload()
    }
  },
  computed: {
    saleUrl () {
      return '/api/sales'
    },
    auctionUrl () {
      return '/api/auctions'
    },
    shuffleUrl () {
      return '/api/shuffle'
    }
  },
  methods: {
    infiniteHandler ($state) {
      const params = {
        start: this.start,
        end: this.end,
        sort: this.sortOption,
        'verified-only': !this.displayUnverifiedArtist,
        nsfw: this.nsfw
      }
      if (this.$route.query.tag) {
        params.tag = this.$route.query.tag
      }
      if (this.asaId !== 1) {
        params.asa_id = this.asaId
      }
      if (this.loadingKind === 'sale') {
        this.$http.get(this.saleUrl, {
          params: params
        }).then(({ data }) => {
          if (data?.sales.length) {
            this.start += data.sales.length
            this.end += data.sales.length
            this.sales.push(...data.sales)
            $state.loaded()
          } else {
            this.start = 0
            this.end = 16
            this.loadingKind = 'auction'
            this.infiniteHandler($state)
          }
        })
      } else if (this.loadingKind === 'auction') {
        this.$http.get(this.auctionUrl, {
          params: params
        }).then(({ data }) => {
          if (data?.auctions.length) {
            this.start += data.auctions.length
            this.end += data.auctions.length
            this.sales.push(...data.auctions)
            $state.loaded()
          } else {
            $state.complete()
          }
        })
      }
    },
    reload () {
      this.start = 0
      this.end = 16
      this.sales = []
      this.auctions = []
      this.shuffles = []
      this.loadingKind = 'sale'
      this.infiniteId += 1
    },
    toggleDisplayUnverifiedArtist () {
      this.displayUnverifiedArtist = !this.displayUnverifiedArtist
      this.reload()
    },
    sort (sortOption) {
      this.sortOption = sortOption
      this.reload()
    },
    getStyle (index) {
      return `animation-delay: ${100 + ((index % 4) * 50)}ms`
    }
  }
}
</script>

<style scoped>
/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  #sales h1{
    text-align: center;
  }

  #sales .sort-buttons-wrapper{
    width: 100%;
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.filters .toggle-nsfw {
  margin-left: 10px;
}
</style>
