<template>
  <Section id="sales">
    <template v-slot:header>
      <span></span>
      <ToggleNSFW v-model="nsfw" v-if="hasNsfw"/>
    </template>

    <CardGrid v-if="offers.length">
      <ListCard
        v-for="(offer, index) in offers"
        :key="offer.token_id"
        :data="offer"
        :style="`animation-delay: ${100 + ((index % 4) * 50)}ms`"
        @startContract="(data) => newContract(data)"
      />
    </CardGrid>
    <div v-else>
        <h4>You don't have any offers yet</h4>
    </div>
    <infinite-loading spinner="waveDots" @infinite="infiteListHandler" :identifier="`primary-${infiniteId}`" :forceUseInfiniteWrapper="true">
      <span slot="no-results"></span>
      <span slot="no-more">
      </span>
    </infinite-loading>

    <ContractPopUp v-if='displayContractPopUp' :_kind="contractKind" :_price='contractPrice' :_is-algo=false :_tokenId='contractTokenId' @done='() => {}' @close="displayContractPopUp = false" :_isAlgo="isContractAlgo"></ContractPopUp>
  </Section>
</template>

<script>
import { mapState } from 'vuex'
import Section from '@/components/Layout/Section'
import InfiniteLoading from 'vue-infinite-loading'
import ListCard from '@/components/Cards/ListCard'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import CardGrid from '@/components/Cards/CardGrid'
import ToggleNSFW from '@/components/Common/Button/ToggleNSFW'

export default {
  name: 'Offers',
  components: { ToggleNSFW, CardGrid, ListCard, Section, InfiniteLoading, ContractPopUp },
  props: ['id'],
  data: () => {
    return {
      offers: [],
      infiniteId: 0,
      increment: 16,
      contractKind: '',
      contractPrice: 1,
      contractTokenId: 0,
      isContractAlgo: true,
      displayContractPopUp: false,
      nsfw: false,
      hasNsfw: false
    }
  },
  computed: {
    ...mapState([
      'userId',
      'wallet'
    ]),
    galleryId () {
      return this.id || this.userId
    },
    offerURL () {
      return '/api/offers/other'
    }
  },
  watch: {
    nsfw () {
      this.reload()
    }
  },
  methods: {
    reload () {
      this.offers = []
      this.infiniteId++
    },
    newContract (data) {
      this.contractKind = data.kind
      this.contractPrice = data.price
      this.contractTokenId = data.tokenId
      this.isContractAlgo = true
      this.displayContractPopUp = true
    },
    infiteListHandler ($state) {
      this.$http.get(this.offerURL, {
        params: {
          start: this.offers.length,
          end: this.offers.length + this.increment,
          nsfw: this.nsfw
        }
      }).then(({ data }) => {
        if (data?.metadata.length) {
          this.offers.push(...data.metadata)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  },
  mounted () {
    this.$http.get(this.offerURL, {
      params: {
        start: 0,
        end: 1,
        nsfw: true
      }
    }).then(({ data }) => {
      if (data?.metadata.length) this.hasNsfw = true
    })
  }
}
</script>

<style scoped>
.card-grid {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-auto-rows: 85px;
}

.margin-top {
  margin-top: 180px;
}

h4 {
  text-align: center;
}
</style>
