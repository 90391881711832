<template>
  <form @keyup.enter="$emit('previous')">
    <h1 class="big light flex-center">
      <unicon height="50px" name="exclamation-octagon" width="50px"/>
      {{ $t('errorCreate.header') }}
    </h1>
    <p>{{ errorMessage }}</p>
    <div class="button-container">
      <Button class="error-btn" @click.native.prevent="$emit('previous')">{{t('errorCreate.button.goBack')}}</Button>
    </div>
  </form>
</template>

<script>
import Button from '@/components/Common/Button/Button'

export default {
  name: 'ErrorCreate',
  components: { Button },
  props: ['errorMsg'],
  computed: {
    errorMessage () {
      if (this.errorMsg && this.errorMsg.data && this.errorMsg.data.error) {
        return this.errorMsg.data.error
      }
      return 'Unexpected error'
    }
  }
}
</script>

<style scoped>
h1 {
  color: var(--error);
  margin: 10px;
}

.unicon {
  fill: var(--error);
}
</style>
