<template>
  <div :class="computedClass" :style="computedStyle">
    <jazzicon class="user-picture default" :address="address.hexEncode()" :diameter="diameter" v-if="showDefault"/>
  </div>
</template>

<script>
export default {
  name: 'UserPicture',
  props: {
    address: {
      type: String,
      required: true
    },
    accountId: {
      type: Number
    },
    version: {
      type: Number
    },
    diameter: {
      type: Number,
      default: 100
    }
  },
  data () {
    return {
      image: null,
      showDefault: false
    }
  },
  watch: {
    accountId (newValue) {
      if (newValue) {
        this.showDefault = false
        this.loadImage()
      }
    },
    version (newValue) {
      if (newValue) {
        this.showDefault = false
        this.loadImage()
      }
    }
  },
  computed: {
    imageUrl () {
      const imageId = (!this.version ? this.accountId : `${this.accountId}-${this.version}`)
      const imageRequest = JSON.stringify({
        bucket: 'algogems',
        key: `profile/${imageId}`,
        edits: {
          resize: {
            width: this.diameter,
            height: this.diameter
          }
        }
      })
      return `/image/${btoa(imageRequest)}`
    },
    computedStyle () {
      let s = `width: ${this.diameter}px; height: ${this.diameter}px;`
      if (this.image) {
        s += `background-image: url(${this.image}); background-size: cover; background-position: center`
      }
      return s
    },
    computedClass () {
      let c = 'user-picture '
      if (this.diameter <= 100) {
        c += 'small '
      }
      if (!this.image) {
        c += 'loading'
      } else {
        c += 'custom'
      }
      return c
    }
  },
  methods: {
    loadImage () {
      const img = new Image()
      img.onload = () => {
        img.decode().then(() => {
          this.image = img.src
        }).catch()
      }
      img.onerror = () => {
        this.showDefault = true
      }
      img.src = this.imageUrl
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.accountId) {
        this.loadImage()
      } else {
        this.showDefault = true
      }
    })
  }
}
</script>

<style scoped>
.user-picture{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  overflow: hidden;
  background-repeat: no-repeat;
  box-shadow: 0 0 .5vw rgba(0,0,0,.1), 0 0 10vw rgba(45, 89, 250, 0.1);
  border: 1px solid var(--foreground);
  z-index: 10;
}
.user-picture.loading{
  box-shadow: none;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f3f3f3;
  background: linear-gradient(to right,#f3f3f3 5%, #dcdcdc 20%,#f3f3f3 35%);
  background-size: 1000px 640px;
}

.user-picture.small{
  box-shadow: none;
}
</style>
