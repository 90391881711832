import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function buyFusion (APIName, from, fusion) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()

    const message = {
      app_id: fusion.id
    }
    const parsed = await Utils._callServer('/api/fusion/buy', message, 'GET')
    /*
     buyer
     app_address
     seller
     amount
     gems_address
     */

    const txns = []

    txns.push(algosdk.makeAssetTransferTxnWithSuggestedParams(from, from, undefined, undefined, 0, undefined, fusion.output.token_id, suggestedParams))

    txns.push(
      algosdk.makePaymentTxnWithSuggestedParams(
        parsed.buyer, parsed.app_address,
        fusion.price,
        undefined, undefined,
        suggestedParams,
        undefined
      )
    )

    const ids = ['input_1', 'input_2', 'input_3', 'input_4']
    const nftIds = []

    for (const id of ids) {
      if (fusion[id].token_id) {
        txns.push(
          algosdk.makeAssetTransferTxnWithSuggestedParams(
            parsed.buyer, parsed.app_address,
            undefined, undefined,
            1,
            new TextEncoder().encode(id),
            fusion[id].token_id,
            suggestedParams))
        nftIds.push(fusion[id].token_id)
      }
    }

    nftIds.push(fusion.output.token_id)

    const appArgs = []
    appArgs.push(new TextEncoder().encode('buy'))
    const accounts = []
    accounts.push(parsed.seller)
    accounts.push(parsed.gems_address)
    accounts.push(parsed.buyer)

    txns.push(
      algosdk.makeApplicationNoOpTxn(
        parsed.buyer,
        suggestedParams,
        fusion.id, appArgs,
        accounts,
        undefined, nftIds,
        undefined, undefined, undefined)
    )

    const signedTxs = await API.signGroup(txns, txns.map(x => true))

    const message2 = {
      signed_tx: signedTxs,
      app_id: fusion.id
    }
    return Utils._callServer('/api/fusion/buy', message2)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default buyFusion
