<template>
  <AppView v-if="incorrectPack" >
    <Error>
      <template v-if="isNaN(id)">
        There is no pack with id <i>{{id}}</i>.
      </template>
      <template v-else>This pack has been closed</template>
    </Error>
  </AppView>
  <AppView id="pack" :key="reloadIndex" v-else-if="!wonNFTs">
    <div class="owner-info">
      <UserPicture :account-id="accountId" :version="ppVersion" :address="ownerAddress"/>
      <span class="address">
        <router-link :to="ownerUrl">
          @{{ ownerUsername }}
        </router-link>
      </span>
    </div>
    <Section>
      <h1>{{ title }}</h1>
      <p class="description" v-if="description.length">
        {{ description }}<br><br>
        {{ composition }}
      </p>
      <p class="price">
        <span>
          {{price}}<ASALogo :asaId="1"/>
        </span> <br>
        {{remainingNft}} Packs remaining
      </p>

      <p v-if="startTime" class="timer">
        Pack starts in: <template v-if="timeBeforeStart.days">{{timeBeforeStart.days}}d </template>{{timeBeforeStart.hours}}h {{timeBeforeStart.minutes}}m {{timeBeforeStart.seconds}}s
      </p>
      <div class="button-container">
        <Button @click.native="cancel" class="error-btn" v-if="['cancel_shuffle', 'close_shuffle', 'cancel_pack'].includes(action)">Cancel</Button>
        <Button @click.native="buy" v-if="action === 'buy_shuffle'">Buy</Button>
        <Button @click.native="buy" v-if="action === 'claim_shuffle'">Claim Key</Button>
        <Button @click.native="buy" v-if="action === 'buy_pack'">Claim Pack</Button>
        <Button @click.native="optIn" v-if="whitelistId">
          Whitelist Token Opt-in
        </Button>
      </div>
      <div>
        <CardGrid :key="infiniteId">
          <Card v-for="(nft, index) in nfts" :key="nft.token_id" :data="nft" :style="getStyle(index)"/>
        </CardGrid>
        <infinite-loading spinner="waveDots" @infinite="infiniteHandler" :forceUseInfiniteWrapper="true" :identifier="infiniteId"><span slot="no-more"></span><span
          slot="no-results"></span></infinite-loading>
      </div>
    </Section>
    <ContractPopUp v-if="displayContractPopUp"
                   :_state="contractPopUpState"
                   :_message="contractMessage"
                   @close="() => { displayContractPopUp = false; reload() }"
    />
  </AppView>
  <AppView v-else>
    <div class="pack-won-nft-body">
      <ul>
        <Card
          :data="nft" v-for="[i, nft] of wonNFTs.entries()"
          :key="i"
        />
      </ul>
    </div>
    <Button @click.native="wonNFTs = null; reload()">Close</Button>
  </AppView>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import Card from '@/components/Cards/Card'
import { mapMutations, mapState } from 'vuex'
import Button from '@/components/Common/Button/Button'
import AppView from '@/components/Layout/AppView'
import Section from '@/components/Layout/Section'
import CardGrid from '@/components/Cards/CardGrid'
import UserPicture from '@/components/User/UserPicture'
import ContractPopUp from '../components/Common/PopUp/ContractPopUp'
import Error from '../components/Common/Error'
import ASALogo from '../components/Assets/ASALogo'
import Contract from '../web3/Contract'

export default {
  name: 'Pack',
  components: { ASALogo, Error, ContractPopUp, UserPicture, CardGrid, Section, Button, Card, InfiniteLoading, AppView },
  data () {
    return {
      start: 0,
      end: 16,
      asaId: 1,
      title: '',
      description: '',
      ownerAddress: '',
      ownerUsername: '',
      price: 0,
      accountId: 0,
      ppVersion: 0,
      nfts: [],
      infiniteId: 0,
      id: this.$route.params.id,
      displayContractPopUp: false,
      contractPopUpState: 'loading',
      contractMessage: '',
      action: null,
      remainingNft: undefined,
      wonNFTIds: undefined,
      wonNFTs: false,
      unblurNft: [],
      incorrectPack: false,
      whitelistId: null,
      startTime: 0,
      now: Date.now() / 1000,
      reloadIndex: 0,
      composition: '',
      shuffleId: undefined
    }
  },
  computed: {
    ...mapState([
      'wallet',
      'address',
      'userId',
      'routeBeforeAuthenticate'
    ]),
    timeBeforeStart () {
      let delta = this.startTime - this.now
      if (delta > 0) {
        const days = Math.floor(delta / 86400)
        delta -= days * 86400
        const hours = Math.floor(delta / 3600) % 24
        delta -= hours * 3600
        const minutes = Math.floor(delta / 60) % 60
        delta -= minutes * 60
        const seconds = Math.floor(delta % 60)
        return {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds
        }
      } else {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        }
      }
    },
    isMine () {
      return (this.userId === this.ownerUsername) || (this.address === this.ownerAddress)
    },
    ownerUrl () {
      return `/gallery/${this.ownerUsername}`
    },
    url () {
      return `/api/pack/info/${this.id}`
    },
    NFTUrl () {
      return `/api/pack/nft/${this.id}`
    }
  },
  methods: {
    ...mapMutations(['setRouteBeforeAuthenticate']),
    reloadNfts () {
      this.start = 0
      this.end = 16
      this.nfts = []
      this.infiniteId++
    },
    reload () {
      this.fetchData(() => {
        this.reloadIndex++
        this.reloadNfts()
      })
    },
    infiniteHandler ($state) {
      const params = {
        start: this.start,
        end: this.end
      }
      this.$http.get(this.NFTUrl, {
        params: params
      }).then(({ data }) => {
        if (data?.metadata.length) {
          this.start += data.metadata.length
          this.end += data.metadata.length
          this.nfts.push(...data.metadata)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    fetchData (callback = () => {}) {
      this.start = 0
      this.end = 16
      this.nfts = []
      this.$http.get(this.url).then(({ data }) => {
        if (data) {
          this.title = data.metadata.title
          this.description = data.metadata.description
          this.ownerAddress = data.metadata.address
          this.ownerUsername = (data.metadata.username) ? data.metadata.username : data.metadata.address
          this.accountId = data.metadata.account_id
          this.ppVersion = data.metadata.v_pp
          this.action = data.metadata.action
          this.remainingNft = data.metadata.total
          this.price = data.metadata.price
          this.asaId = data.metadata.asa_id
          this.whitelistId = data.metadata.whitelist
          this.composition = data.metadata.composition
          this.shuffleId = data.metadata.shuffle_id
          this.startTime = data.metadata.start

          if (this.routeBeforeAuthenticate === this.$route.path) {
            this.setRouteBeforeAuthenticate({ routeBeforeAuthenticate: '/' })
            if (this.action.includes('buy')) {
              this.buy()
            }
          }
          setInterval(() => {
            this.now = Date.now() / 1000
          }, 1000)
          callback()
        }
      }).catch(err => {
        console.log(err)
        this.incorrectPack = true
      })
    },
    getStyle (index) {
      return `animation-delay: ${100 + ((index % 4) * 50)}ms`
    },
    buy () {
      if (this.address) {
        this.contractPopUpState = 'loading'
        this.displayContractPopUp = true
        Contract.buyPack(this.wallet, this.address, this.id, this.shuffleId, this.action)
          .then((m) => {
            this.contractPopUpState = 'done'
            this.remainingNft--
            this.doneBuy(m.nfts)
          })
          .catch((e) => {
            this.contractPopUpState = 'error'
            this.contractMessage = e.toString()
            this.fetchData()
          })
      } else {
        this.$router.push('/authenticate')
      }
    },
    async doneBuy (nfts) {
      const temp = []
      for (const nftId of nfts) {
        const nft = await this.$http.get(`/api/nft/${nftId}`)
        if (nft.data && nft.data.nft) {
          temp.push(nft.data.nft)
        }
      }
      this.displayContractPopUp = false
      this.wonNFTs = temp.slice()
    },
    cancel () {
      this.contractPopUpState = 'loading'
      this.displayContractPopUp = true
      Contract.cancelPack(this.wallet, this.address, this.id, this.shuffleId, this.action)
        .then((m) => {
          this.contractPopUpState = 'done'
        })
        .catch((e) => {
          this.contractPopUpState = 'error'
          this.contractMessage = e.toString()
        })
    },
    optIn () {
      this.contractPopUpState = 'loading'
      this.displayContractPopUp = true
      Contract.signIn(this.wallet, this.address, this.whitelistId)
        .then((m) => {
          this.contractPopUpState = 'done'
          this.reload()
        })
        .catch((e) => {
          this.contractPopUpState = 'error'
          this.contractMessage = e.toString()
        })
    }
  },
  beforeMount () {
    if (!this.id || isNaN(this.id)) {
      this.incorrectPack = true
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.incorrectPack) {
        this.fetchData()
      }
    })
  }
}
</script>

<style scoped>

.dark * {
  color: var(--complement);
}

.dark .address a {
  color: var(--complement);
}

h1{
  color: var(--text);
}

p{
  padding: 25px;
}

a {
  text-decoration: none;
}

.owner-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -60px;
}

.address{
  margin-top: 10px;
}

.address a {
  color: var(--text);
  font-size: 1em;
  margin: 15px;
}

.checkbox {
  height: 10px;
  width: 10px;
  border: 1px solid var(--text);
  border-radius: 5px;
}

.textual-button .unicon {
  background: var(--background);
  fill: var(--text);
  margin-right: 7px;
}

.textual-button:hover .unicon {
  transform: none;
}

.pack-won-nft-body ul {
  position: relative;
  display: flex;
  max-width: 900px;
  justify-content: space-around;
  margin: auto;
}

@media only screen
and (max-device-width: 1200px) {
  .won-nft-body {
    grid-template-columns: auto;
  }
}

.description {
  text-align: center;
}

.price {
  text-align: center;
  margin: 0;
  padding: 0;
}

.price span {
  color: var(--text);
  font-size: xx-large;
}

.button-container {
  max-width: 500px;
  margin: auto;
}

.timer {
  text-align: center;
  font-size: larger;
}

@keyframes won-nft-card-appears {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  40% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
</style>

<style>
  .pack-won-nft-body .card.blur a {
    filter: blur(50px);
  }
</style>
