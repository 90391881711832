<template>
<div id="stake">
  <ul>
    <li style="animation-delay: 50ms">
      <unicon name="keyhole-circle" width="40px" height="40px"/>
      <h4>Stake</h4>
      Stake your GEMS to take part in the governance and be eligible to vote.
    </li>
    <li style="animation-delay: 100ms">
      <unicon name="thumbs-up" width="40px" height="40px"/>
      <h4>Vote</h4>
      Vote to verify, ban and give spotlight to creators. Take part in the marketplace decision process!
    </li>
    <li style="animation-delay: 150ms">
      <unicon name="money-withdraw" width="40px" height="40px"/>
      <h4>Get rewarded</h4>
      Get rewarded for staking your GEMS and participating in the governance!
    </li>
  </ul>
  <div class="button-container" v-if="true">
    <Button @click.native="stake">Stake your <ASALogo :asaName="'gems'"/>GEMS</Button>
    <Button class="secondary" @click.native="buyGems">Buy <ASALogo :asaName="'gems'"/>GEMS</Button>
  </div>
  <h5 v-else>Staking is currently disabled. Please wait until the next staking period opens.</h5>
  <h5>You have staked: {{governanceStats.staked}} <ASALogo :asaName="'gems'"/></h5>
  <h5>{{ governanceStats.pool_total }} <ASALogo :asaName="'gems'"/>GEMS in pool.</h5>
  <ContractPopUp
    v-if='displayContractPopUp'
    :_kind="'stake'"
    @done="stakeHandler()"
    @close="displayContractPopUp = false"/>
</div>
</template>

<script>
import ASALogo from '@/components/Assets/ASALogo'
import Button from '@/components/Common/Button/Button'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import { mapState } from 'vuex'

export default {
  name: 'Stake',
  components: { ContractPopUp, Button, ASALogo },
  data () {
    return {
      displayContractPopUp: false,
      contractKind: 'stake'
    }
  },
  computed: {
    ...mapState(['governanceStats'])
  },
  methods: {
    stake () {
      this.contractKind = 'stake'
      this.displayContractPopUp = true
    },
    buyGems () {
      window.open('https://vestige.fi/swap?asset_in=0&asset_out=230946361', '_blank').focus()
    },
    stakeHandler () {
      this.$http.get('/api/governance/stats').then(({ data }) => {
        this.updateGovernanceStats(data)
        this.$router.push('/governance/vote')
      }).catch(err => {
        console.error(err)
      })
    }
  }
}
</script>

<style scoped>
#stake h1, #stake h5, #stake h2{
  text-align: center;
}

#stake h5{
  font-size: 1.2em;
  margin-bottom: 50px;
}

#stake ul{
  list-style: none;
  padding: 0;
  margin: 75px 0;
  display: flex;
  justify-content: space-evenly;
  flex-flow: wrap;
}

#stake ul li{
  text-align: center;
  width: 200px;
  height: 200px;
  background: var(--foreground);
  box-shadow: var(--strong-shadow);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: card-appear .5s ease;
  margin: 30px 0;
}

#stake li h4{
  font-size: 1.5em;
  text-transform: uppercase;
  margin: 10px 0;
  color:  var(--accent);
}

#stake li .unicon{
  fill: var(--accent);
}

#stake button{
  margin: 10px 10px 50px 10px;
}
</style>
