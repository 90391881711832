<template>
  <div>
    <h1 class="medium">{{$t('createSale.title')}}</h1>
    <p>{{$t('createSale.body')}}</p>
    <div class="button-container">
      <Button class="blue" @click.native.prevent="next()">{{$t('createSale.button.next')}}</Button>
      <Button class="blue">
        <router-link :to="`/nft/${data.token_id}`">
          {{$t('createSale.button.seeNft')}}
        </router-link>
      </Button>

    </div>
  </div>
</template>

<script>
import Button from '@/components/Common/Button/Button'

export default {
  name: 'CreateSale',
  props: {
    data: Object
  },
  components: { Button },
  methods: {
    next () {
      this.$emit('next')
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: var(--complement);
}
</style>
