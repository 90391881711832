<template>
  <AppView id="hdl-submarket">
    <template v-slot:header>
    <div class="market-header">
      <img src="@/assets/marketHeader/headline-header.png" alt="headline nft" class="hdl-nft"/>
      <div class="title">
        <h2><a href="https://www.headline-inc.com/" target="_blank">HEADLINE</a></h2>
        <h3>HDL MARKET</h3>
      </div>
      <div class="sales-buttons">
        <router-link :to="`/hdl`">
          <unicon name="estate"/>
          Home
        </router-link>
        <router-link :to="`/hdl/sales`">
          <unicon name="usd-circle"/>
          Sales
        </router-link>
        <router-link :to="`/hdl/auctions`">
          <unicon name="arrow-growth"/>
          Auctions
        </router-link>
        <router-link :to="`/hdl/shuffles`" v-if="false">
          <unicon name="shuffle"/>
          Shuffles
        </router-link>
        <router-link :to="`/hdl/token`">
          <ASALogo class="no-margin" asa-id="137594422"/>
          HDL
        </router-link>
      </div>
    </div>
    </template>
    <router-view/>
  </AppView>
</template>

<script>
import 'hooper/dist/hooper.css'
import AppView from '@/components/Layout/AppView'
import { mapMutations } from 'vuex'
import ASALogo from '../../../components/Assets/ASALogo'

export default {
  name: 'HDLSubmarket',
  components: { ASALogo, AppView },
  methods: {
    ...mapMutations(['setCustomClass'])
  },
  beforeDestroy () {
    this.setCustomClass({ customClass: '' })
  },
  mounted () {
    this.setCustomClass({ customClass: 'hdl' })
  }
}
</script>

<style scoped>
.market-header{
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.market-header .title{
  z-index: 1;
}

.market-header .title h2{
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 8em;
  line-height: .95em;
  text-transform: uppercase;
  text-align: center;
  color: var(--text);
}

.market-header .title h2 a {
  text-decoration: none;
}

.market-header .title h3{
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 3em;
  font-weight: lighter;
  line-height: 1em;
  text-transform: uppercase;
  text-align: center;
  color: var(--textlight);
  margin: 0;
}

.market-body {
  margin-top: 0px;
}

.sales-buttons {
  display: flex;
}

.sales-buttons a {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  margin: 0px 13px;
}

.sales-buttons a .unicon, .sales-buttons a .asa-logo {
  margin-bottom: 5px;
  padding: 12px;

  background-color: #000;
  border-radius: 15px;
}

.sales-buttons a .asa-logo {
  width: 24px;
  height: 24px;
  font-size: x-large;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sales-buttons a:hover {
  color: var(--blue);
}

.sales-buttons a:hover .unicon {
  fill: var(--blue);
}

.sales-buttons a.router-link-exact-active {
  color: var(--blue);
}

.sales-buttons a.router-link-exact-active .unicon {
  fill: var(--blue);
}

.market-header .hdl-nft{
  max-width: 730px;
  z-index: 1;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {

  .market-header {
    max-height: 510px;
  }

  .market-header .hdl-nft{
  max-width: 100vw;
  max-height: 600px;
  min-height: 300px;
  }

.market-header .title h2{
    font-size: 3.5em;
  }

  .market-header .title h3{
    font-size: 1.5em;
  }

}
</style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100;400;700&family=Mr+Dafoe&display=swap');

#app.hdl {
  --blue: #008ef7;
  --red: #ca1b52;
  --error: var(--red);
  background: var(--background);
  min-height: 100vh;
  font-family: 'Libre Franklin', sans-serif;
  transition: background .5s ease;
  --invert: 0;
  width: 100%;
  overflow-x: hidden;
}

#app.hdl.dark, #app.hdl {
  --background: #161616;
  --foreground: #1d222d;
  --foreground-transparent: rgba(29,34,45,.5);
  --foreground-blur: rgba(29,34,45,.5);
  --nav-background: #161616;
  --fake-blur: #1c1d20eb;
  --headings: #202231;
  --text: #dae2ff;
  --textlight: rgba(218, 226, 255, 0.6);
  --accent: var(--blue);
  --complement: #fff;
  --blur-overlay: rgba(14, 17, 23, .5);
  --light-shadow: rgba(0, 0, 0, .1) 0px 0px 20px;
  --shadow: rgba(0, 0, 0, .1) 0px 0px 2px, rgba(0, 0, 0, .3) 0px 0px 20px;
  --strong-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  --accent-shadow: 0 0 .5vw rgba(0,0,0,.1), 0 0 10vw rgba(202, 49, 112, 0.3);
  --gradient: linear-gradient(45deg, var(--accent),#4d02b1);
  --lightgradient: linear-gradient(45deg, #103252, #581e70);
  --background-alt: #1d222d;
  --foreground-alt: #0e1117;
  --invert: 1;
  --pop-up-background: rgba(14, 17, 23, 0.9);
}

#hdl-submarket .view-body .view-header {/* Prevents ugly header on currency tab */
  display: none;
}
</style>
