<template>
  <Section class="crescendo-form" v-if="state === 'init'">
    <h1>Shipping Form</h1>
    <form @submit.stop.prevent>
      <span>Email:</span>
      <input v-model="email" :class="{'invalid':!emailValid && error}" placeholder="Email" type="text">

      <span>Address:</span>
      <div class="address-form">
        <input v-model="street" :class="{'invalid':!addressValid && error}" placeholder="Street Name" type="text">
        <input v-model="city" :class="{'invalid':!addressValid && error}" placeholder="City" type="text">
        <input v-model="zipcode" :class="{'invalid':!addressValid && error}" placeholder="Zip Code" type="text">
        <input v-model="addressState" :class="{'invalid':!addressValid && error}" placeholder="State" type="text">
        <input v-model="country" :class="{'invalid':!addressValid && error}" placeholder="Country" type="text">
      </div>

      <span>Name or Username:</span>
      <input v-model="username" :class="{'invalid':!nameValid && error}" placeholder="Name or Username" type="text">

      <span>Shirt Size:</span>
      <select v-model="size" :class="{'invalid':!sizeValid && error}">
        <option>xs</option>
        <option>s</option>
        <option>m</option>
        <option>l</option>
        <option>xl</option>
      </select>

      <h5 v-if="error"><unicon name="exclamation-octagon"/> {{error}}</h5>
      <Button @click.native="submit">Submit</Button>
    </form>
  </Section>
  <Section v-else-if="state === 'loading'">
    <LinearSpinner/>
  </Section>
  <Section v-else-if="state === 'done'">
    <SuccessCheckmark/>
    <Button @click.native="state = 'init'">Back</Button>
  </Section>
  <Section v-else class="error-section">
    <h1><unicon name="exclamation-octagon"/> Error</h1>
    <p>An error occured, please retry. If the error persist contact us.</p>
    <p>{{error}}</p>
    <Button @click.native="state = 'init'">Back</Button>
  </Section>
</template>

<script>
import Section from '../Layout/Section'
import Button from '../Common/Button/Button'
import { mapState } from 'vuex'
import LinearSpinner from '../Common/Spinner/LinearSpinner'
import SuccessCheckmark from '../Assets/SuccessCheckmark'

export default {
  name: 'CRSDShippingForm',
  components: { SuccessCheckmark, LinearSpinner, Button, Section },
  data () {
    return {
      id: this.$route.params.id,
      email: '',
      street: '',
      city: '',
      zipcode: '',
      addressState: '',
      country: '',
      username: '',
      size: '',
      transactionId: '',
      error: undefined,
      state: 'loading'
    }
  },
  computed: {
    ...mapState(['address']),
    emailValid () {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email)
    },
    addressValid () {
      return this.street.length && this.city.length && this.zipcode.length && this.addressState.length && this.country.length
    },
    usernameValid () {
      return this.username.length > 0
    },
    sizeValid () {
      return true
    },
    nameValid () {
      return true
    },
    dataUrl () {
      return `/api/nft/${this.id}`
    }
  },
  methods: {
    toggleError () {
      if (!this.emailValid) {
        this.error = 'Invalid email'
      } else if (!this.addressValid) {
        if (this.street.length === 0) {
          this.error = 'Please add a street name'
        } else if (this.city.length === 0) {
          this.error = 'Please add a city name'
        } else if (this.zipcode.length === 0) {
          this.error = 'Please add a zipcode name'
        } else if (this.addressState.length === 0) {
          this.error = 'Please add a state name'
        } else if (this.country.length === 0) {
          this.error = 'Please add a country name'
        } else {
          this.error = 'Invalid address'
        }
      } else if (!this.usernameValid) {
        this.error = 'Please add your Name or a Username'
      } else if (!this.sizeValid) {
        this.error = 'Select a shirt size'
      }
    },
    submit () {
      this.state = 'loading'
      this.error = undefined
      if (!this.emailValid || !this.addressValid || !this.usernameValid || !this.sizeValid) {
        this.toggleError()
        this.state = 'init'
        return
      }

      const json = {
        email: this.email,
        street: this.street,
        city: this.city,
        zipcode: this.zipcode,
        state: this.addressState,
        country: this.country,
        username: this.username,
        size: this.size,
        nftid: this.id,
        algo_address: this.address
      }
      this.$http.post('/api/crescendo/form/shipping', json)
        .then(() => {
          this.state = 'done'
        })
        .catch(error => {
          this.state = 'error'
          this.error = error.message
          console.error('Error!', error.message)
        })
    },
    loadNftData () {
      this.$http.get(this.dataUrl).then(response => {
        if (response.data && response.data.nft) {
          if (response.data.nft.crescendo !== 'physical') {
            this.$router.push(`/nft/${this.id}`)
          }
          if (response.data.nft.number === 0) {
            // this.$router.push(`/nft/${this.id}`)
          }
          this.state = 'init'
        }
      })
    }
  },
  beforeMount () {
    this.loadNftData()
  }
}
</script>

<style scoped>
.crescendo-form {
  max-width: 600px;
  margin: auto;
}

form {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  min-width: 500px;
  grid-column-gap: 10px;
  padding-right: 75px;
}

form > *:nth-child(odd):not(h5):not(button) {
  align-self: center;
  justify-self: right;
  max-width: 150px;
}

form > *:nth-child(even):not(button) {
  align-self: center;
  justify-self: left;
  width: 100%;
}

form button, form h5 {
  grid-column: span 2;
}

h5 {
  color: var(--error);
  display: flex;
  align-items: center;
  justify-content: center;
}

h5 .unicon {
  fill: var(--error);
  margin-right: 5px;
}

.error-section {
  max-width: 500px;
  margin: auto;
}

.error-section h1 {
  color: var(--error);
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-section h1 .unicon {
  fill: var(--error);
  margin-right: 5px;
}
.address-form {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0 10px;
}

.address-form > *:first-child {
  grid-column: span 2;
}

select {
  display: block;
  width: 100%;
  max-width: 450px;
  margin: 20px auto;
  padding: 10px 20px;
  background: var(--foreground);
  box-shadow: var(--light-shadow);
  border: 1px solid transparent;
  border-radius: 20px;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: var(--text);
  box-sizing: border-box;
}
</style>
