<template>
  <AppView id="market" v-if="state === 'loaded'">
    <template v-slot:header>
    <div class="market-header">
        <div class="actions-wrapper">
          <Search class='mobile-only' show-suggestions="true"></Search>
        </div>
    </div>
    </template>
      <h2>{{$t('market.spotlight')}}</h2>
      <Section>
        <template v-slot:header>
          <h3>{{$t('market.staffPick')}}</h3>
        </template>
        <hooper :settings="spotlightSettings" style="height: 450px">
          <slide v-for="banner in banners" :key="banner.url">
            <a :href="banner.target" target="_blank" class="spotlight-image" :style="getBannerStyle(banner.img)"></a>
          </slide>
          <slide v-for="banner in randBanners" :key="banner.url">
            <a :href="banner.target" target="_blank" :class="{'spotlight-image': true, sponsored: banner.sponsored}" :style="getBannerStyle(banner.img)"></a>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </Section>
      <h2>{{$t('market.trending')}}</h2>
    <Section>
      <template v-slot:header>
        <h3>Hot auctions</h3>
        <TextualButton :to="auctionsPath">{{$t('market.exploreAuctions')}}</TextualButton>
      </template>
      <CardGrid>
        <Card v-for="(auction, index) in bids" :key="auction.token_id" :data="getCardInfo(auction)" :style="`animation-delay: ${100 + (index * 50)}ms`" :spotlight="index === 0"/>
      </CardGrid>
    </Section>
    <Section>
      <template v-slot:header>
        <h3>Governor spotlight</h3>
        <TextualButton :to="auctionsPath">{{$t('market.exploreAuctions')}}</TextualButton>
      </template>
      <CardGrid>
        <Card v-for="(auction, index) in auctions" :key="auction.token_id" :data="auction" :style="`animation-delay: ${100 + (index * 50)}ms`"/>
      </CardGrid>
    </Section>
      <Section>
        <template v-slot:header>
          <h3>{{$t('market.collections')}}</h3>
          <TextualButton :to="collectionsPath" >{{$t('market.moreCollections')}}</TextualButton>
        </template>
        <hooper :settings="collectionSettings" style="height: 280px; margin-top: 25px;">
          <slide v-for="collection in collections"
                 :key="collection.collection_id"
                 :index="collection.collection_id"
          >
            <CollectionCard :collection="collection" />
          </slide>
          <hooper-navigation slot="hooper-addons" class="collection-navigation"></hooper-navigation>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </Section>
    <Section>
      <template v-slot:header>
        <h3>Shuffles</h3>
        <TextualButton to="/shuffles">I'm feeling lucky</TextualButton>
      </template>
      <ShuffleGrid>
        <ShuffleCard v-for="shuffle in shuffles" :key="shuffle.id" :shuffle="shuffle"/>
      </ShuffleGrid>
    </Section>
      <Section>
        <template v-slot:header>
          <h3>{{$t('market.categories')}}</h3>
        </template>
        <ul class="categories">
          <router-link tag="li" to="/sales?tag=art" class="cat-art">{{$t('categories.art')}}</router-link>
          <router-link tag="li" to="/sales?tag=algorand" class="cat-algorand">Algorand</router-link>
          <router-link tag="li" to="/sales?tag=pixel" class="cat-pixel">{{$t('categories.pixel')}}</router-link>
          <router-link tag="li" to="/sales?tag=cgi" class="cat-3d">{{$t('categories.cgi')}}</router-link>
          <router-link tag="li" to="/sales?tag=drawing" class="cat-drawing">{{$t('categories.drawing')}}</router-link>
        </ul>
      </Section>
      <Section>
        <template v-slot:header>
          <h3>{{$t('market.sales')}}</h3>
          <TextualButton :to="salesPath">{{$t('market.moreSales')}}</TextualButton>
        </template>
        <CardGrid>
          <Card v-for="(sale, index) in sales" :key="sale.token_id" :data="sale" :style="`animation-delay: ${100 + (index * 50)}ms`"/>
        </CardGrid>
      </Section>
      <!--
      <h2>Algogems marketplace</h2>
      <Section>
        <template v-slot:title>
          <h3>Your gate to Algorand's NFT world</h3>
        </template>
      </Section>
      -->
  </AppView>
  <Loader v-else/>
</template>

<script>
import { Hooper, Navigation as HooperNavigation, Pagination as HooperPagination, Slide } from 'hooper'
import 'hooper/dist/hooper.css'
import Card from '@/components/Cards/Card'
import Loader from '@/components/Common/Spinner/Loader'
import TextualButton from '@/components/Common/Button/TextualButton'
import Section from '@/components/Layout/Section'
import AppView from '@/components/Layout/AppView'
import Search from '@/components/Common/Search'
import CollectionCard from '@/components/Collection/CollectionCard'
import CardGrid from '@/components/Cards/CardGrid'
import ShuffleCard from '@/components/Shuffle/ShuffleCard'
import ShuffleGrid from '@/components/Shuffle/ShuffleGrid'

export default {
  name: 'Market',
  components: { ShuffleGrid, ShuffleCard, CardGrid, CollectionCard, Search, AppView, Section, TextualButton, Loader, Card, HooperPagination, Hooper, Slide, HooperNavigation },
  data () {
    return {
      state: 'loading',
      events: [],
      collections: [],
      auctions: [],
      bids: [],
      sales: [],
      shuffles: [],
      banners: [
        /* {
          img: require('@/assets/MyAlgoExploit.png'),
          target: 'https://twitter.com/myalgo_/status/1632862464244162560?s=20'
        },
        {
          img: require('@/assets/Migration.png'),
          target: 'https://discord.com/channels/929455984655626281/929477554451136562/1072145551430262975'
        }, */
        {
          img: require('@/assets/cosmicchamps.png'),
          target: 'https://algogems.io/shops/cosmicChamps/sales'
        },
        /* {
          img: require('@/assets/NoFees.gif'),
          target: 'https://algogems.io/vip'
        }, */
        {
          img: require('@/assets/AlgoLeagues.jpg'),
          target: 'https://algogems.io/shops/algoLeagues/sales'
        }
        /* ,
        {
          img: require('@/assets/Littles.png'),
          target: 'https://algogems.io/gallery/LittleMonsters/shuffles'
        } */
      ],
      randBanners: [
        {
          img: require('@/assets/GemsBanner.png'),
          target: 'https://twitter.com/AlgogemsNFT'
        },
        {
          img: require('@/assets/Prsms.png'),
          target: 'https://www.algogems.io/shuffle/836'
        }
        /* {
          img: require('@/assets/AtomicBanner.png'),
          target: 'https://algogems.io/shuffle/696'
        },
        {
          img: require('@/assets/CryptoTrees.png'),
          target: 'https://algogems.io/gallery/CryptoTrees/sales'
        },
        {
          img: require('@/assets/Bkartel.png'),
          target: 'https://www.algogems.io/bkrt'
        },
        {
          img: require('@/assets/MoistGrannies.gif'),
          target: 'https://algogems.io/shuffle/693'
        } */
      ],
      spotlightSettings: {
        infiniteScroll: true,
        autoPlay: true,
        playSpeed: 5000,
        wheelControl: false
      },
      collectionSettings: {
        itemsToShow: 1,
        centerMode: true,
        infiniteScroll: true,
        autoPlay: false,
        wheelControl: false,
        breakpoints: {
          1200: {
            itemsToShow: 3
          },
          950: {
            itemsToShow: 2.5
          },
          750: {
            itemsToShow: 2
          }
        }
      }
    }
  },
  computed: {
    bidPath () {
      return '/home/activity/bid'
    },
    auctionsPath () {
      return '/auctions'
    },
    collectionsPath () {
      return '/collections'
    },
    salesPath () {
      return '/sales'
    },
    eventsPath () {
      return '/events'
    },
    shufflesPath () {
      return '/shuffle'
    }
  },
  methods: {
    getCardInfo (bid) {
      bid.username = bid.creator_username
      bid.address = bid.creator_address
      return bid
    },
    getBannerStyle (img) {
      return `background-image: url(${img}); background-size: cover; background-position: center;`
    },
    getData () {
      this.state = 'loading'
      let eventsLoaded = false
      let bidsLoaded = false
      let collectionsLoaded = false
      let auctionsLoaded = false
      let salesLoaded = false
      let shufflesLoaded = false
      this.collections = []
      const collectionsParams = {
        start: 0,
        end: 9,
        sort: 'spotlight',
        'verified-only': true
      }
      this.$http(`/api${this.collectionsPath}`, { params: collectionsParams }).then((response) => {
        if (response.data && response.data.metadata) {
          this.collections = response.data.metadata
          collectionsLoaded = true
          if (bidsLoaded && eventsLoaded && collectionsLoaded && auctionsLoaded && salesLoaded && shufflesLoaded) {
            this.state = 'loaded'
          }
        }
      }).catch(err => {
        console.log(err)
      })

      this.auctions = []
      const auctionsParams = {
        start: 0,
        end: 4,
        sort: 'popular',
        'verified-only': true
      }
      this.$http(`/api${this.salesPath}`, { params: auctionsParams }).then((response) => {
        if (response.data && response.data.sales) {
          this.auctions = response.data.sales
          auctionsLoaded = true
          if (bidsLoaded && eventsLoaded && collectionsLoaded && auctionsLoaded && salesLoaded && shufflesLoaded) {
            this.state = 'loaded'
          }
        }
      }).catch(err => {
        console.log(err)
      })

      this.sales = []
      const salesParams = {
        start: 0,
        end: 8,
        sort: 'spotlight',
        'verified-only': true
      }
      this.$http(`/api${this.salesPath}`, { params: salesParams }).then((response) => {
        if (response.data && response.data.sales) {
          this.sales = response.data.sales
          salesLoaded = true
          if (bidsLoaded && eventsLoaded && collectionsLoaded && auctionsLoaded && salesLoaded && shufflesLoaded) {
            this.state = 'loaded'
          }
        }
      }).catch(err => {
        console.log(err)
      })

      this.events = []
      this.$http(`/api${this.eventsPath}`).then((response) => {
        if (response.data && response.data.events) {
          this.events = response.data.events
          eventsLoaded = true
          if (bidsLoaded && eventsLoaded && collectionsLoaded && auctionsLoaded && salesLoaded && shufflesLoaded) {
            this.state = 'loaded'
          }
        }
      }).catch(err => {
        console.log(err)
      })

      const shuffleParams = {
        start: 0,
        end: 4,
        sort: 'spotlight',
        'verified-only': true
      }
      this.shuffles = []
      this.$http(`/api${this.shufflesPath}`, { params: shuffleParams }).then((response) => {
        if (response.data && response.data.metadata) {
          this.shuffles = response.data.metadata
          shufflesLoaded = true
          if (eventsLoaded && collectionsLoaded && auctionsLoaded && salesLoaded && shufflesLoaded) {
            this.state = 'loaded'
          }
        }
      }).catch(err => {
        console.log(err)
      })
      this.bids = []
      this.$http(`/api${this.bidPath}`).then((response) => {
        if (response.data && response.data.metadata) {
          this.bids = response.data.metadata.slice(0, 5)
          bidsLoaded = true
          if (bidsLoaded && eventsLoaded && collectionsLoaded && auctionsLoaded && salesLoaded) {
            this.state = 'loaded'
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getData()
      this.shuffleArray(this.randBanners)
    })
  }
}
</script>

<style>
.market-header{
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.market-header .title{
  position: relative;
  z-index: 1;
}

.market-header .title h2{
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 4em;
  line-height: .95em;
  text-transform: uppercase;
  text-align: left;
  color: var(--text);
}

.market-header .title h3{
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.8em;
  line-height: 1em;
  text-transform: uppercase;
  text-align: left;
  color: var(--text);
  margin: 25px 0;
}

.market-header .actions-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
}

.market-header #search .search-bar input {
  width: 250px;
}

.market-header button{
  margin: 0 10px;
}

.market-header .algo-nft{
  width: 350px;
  margin-left: 100px;
  position: relative;
  z-index: 1;
}

.market-header .confetti{
  position: absolute;
  width: 900px;
  z-index: 0;
  bottom: 100px;
}

.market-header .buy-gems{
  display: flex;
  align-items: center;
}

.market-header .buy-gems img{
  margin-right: 5px;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .market-header{
    flex-direction: column-reverse;
    width: 100%;
    overflow: hidden;
    height: 120px;
    top: -5px;
    z-index: 13;
  }

  .market-header .title h2{
    font-size: 2.5em;
    text-align: center;
  }

  .market-header .title h3{
    font-size: 1.2em;
    line-height: 1em;
    text-align: center;
    margin: 15px 0;
  }

  .market-header .algo-nft{
    margin-top: -10vh;
    width: 200px;
    margin-left: 0;
  }

  .market-header .actions-wrapper{
    margin-top: 50px;
    flex-direction: column;
  }

  .market-header button{
    display: none;
  }

  .market-header .buy-gems{
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 8px;
  }

}

.spotlight-image {
  max-width: 1150px;
  height: 400px;
  display: block;
  margin: 25px;
  border-radius: 50px;
  box-shadow: var(--strong-shadow);
}

.spotlight-image.sponsored {
  border: 4px solid var(--accent);
  position: relative;
}

.spotlight-image.sponsored:after{
  content: "AD";
  color: var(--foreground);
  display: block;
  bottom: 20px;
  right: 20px;
  position: absolute;
  background: var(--accent);
  padding: 3px 7px;
  border-radius: 5px;
  font-weight: 900;
}

.collection-navigation {
  transform: translateY(-200px);
}

.collection-wrapper {
  padding: 25px;
  cursor: pointer;
  text-align: center;
  display: block;
  text-decoration: none;
}

.categories{
  display: grid;
  grid-template-columns: repeat(5, 210px);
  grid-auto-rows: 210px;
  gap: 37px;
  padding: 25px 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
}

.categories li{
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: lighter;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  color: #ffffff;
  box-shadow: var(--strong-shadow);
  cursor: pointer;
  transition: transform .2s ease;
  background-size: cover;
  background-position: center;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .categories{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: 150px;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    padding: 25px;
    gap: 25px;
  }

}

.categories li:hover{
  transform: scale(1.05);
}

.categories .cat-art{
  background-image: url("../../assets/cat_art.png");
}

.categories .cat-algorand{
  background-image:  url("../../assets/cat_algorand.png");
}

.categories .cat-pixel{
  background-image: url("../../assets/cat_pixel.png");
}

.categories .cat-3d{
  background-image: url("../../assets/cat_3d.png");
}

.categories .cat-drawing{
  background-image: url("../../assets/cat_drawing.png");
}
</style>
