
  <template>
    <ul class="list-card-grid">
      <slot></slot>
    </ul>
  </template>

<script>
export default {
  name: 'ListCardGrid'
}
</script>

<style scoped>
  .list-card-grid {
    display: grid;
    justify-items: flex-start;
    align-items: center;
    padding: 0;
    margin: 25px 0;
    list-style: none;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 35px;
    grid-auto-rows: 100px;
  }

  /* Portrait */
  @media only screen
  and (max-device-width: 1200px) {
    .list-card-grid {
      padding: 15px;
      box-sizing: border-box;
    }
  }
</style>
