import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function challenge (APIName, from) {
  try {
    const API = Utils._getAPI(APIName)
    let message = {
      address: from
    }

    const parsed = await Utils._callServer('/api/challenge/check', message)

    const note = (typeof parsed.note === 'string') ? new TextEncoder('utf-8').encode(parsed.note) : parsed.note
    const amount = parsed.amount
    const suggestedParams = await Utils.getParams()
    const txn = algosdk.makePaymentTxnWithSuggestedParams(from, from, amount, undefined, note, suggestedParams)

    const signedTxn = await API.sign(txn)
    message = {
      price: amount,
      address: from,
      signed_tx: signedTxn.blob,
      wallet: API.name
    }
    return await Utils._callServer('/api/challenge/validate', message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default challenge
