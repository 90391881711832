import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function sendToken (APIName, from, tokenId, addresses, amounts) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()
    console.log('SendToken - suggested params', suggestedParams)

    const txns = []
    for (const [i, to] of addresses.entries()) {
      txns.push(algosdk.makeAssetTransferTxnWithSuggestedParams(
        from, to,
        undefined, undefined,
        amounts[i], undefined,
        tokenId, suggestedParams,
        undefined
      ))
    }

    console.log('SendToken - Sign', txns)
    const signedTxs = await API.signGroup(txns, txns.map(x => true), false)
    console.log('SendToken - signed', signedTxs)

    const message = {
      signed_tx: signedTxs
    }
    return Utils._callServer('/api/sdk/distribution', message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default sendToken
