import connect from './Contracts/connect'
import makeOffer from './Contracts/makeOffer'
import acceptOffer from './Contracts/acceptOffer'
import closeOffer from './Contracts/closeOffer'
import bigVote from './Contracts/bigVote'
import joeStart from './Contracts/joeStart'
import logout from './Contracts/logout'
import address from './Contracts/address'
import bid from './Contracts/bid'
import buy from './Contracts/buy'
import sell from './Contracts/sell'
import claimRoyaltyFees from './Contracts/claimRoyaltyFees'
import createShuffle from './Contracts/createShuffle'
import claimShuffle from './Contracts/claimShuffle'
import closeShuffle from './Contracts/closeShuffle'
import cancelShuffle from './Contracts/cancelShuffle'
import buyShuffle from './Contracts/buyShuffle'
import signIn from './Contracts/signIn'
import vote from './Contracts/vote'
import updateSale from './Contracts/updateSale'
import auction from './Contracts/auction'
import cancel from './Contracts/cancel'
import challenge from './Contracts/challenge'
import tip from './Contracts/tip'
import create from './Contracts/create'
import stake from './Contracts/stake'
import deleteASAs from './Contracts/deleteASAs'
import editASA from './Contracts/editASA'
import algoglyphMint from './Contracts/algoGlyphMint'
import getVerified from './Contracts/getVerified'
import sendToken from './Contracts/sendToken'
import createStaking from './Contracts/createStaking'
import createFusion from './Contracts/createFusion'
import buyFusion from './Contracts/buyFusion'
import cancelFusion from './Contracts/cancelFusion'
import createPack from './Contracts/createPack'
import buyPack from './Contracts/buyPack'
import cancelPack from './Contracts/cancelPack'
import startShuffle from './Contracts/startShuffle'
import auctionClose from './Contracts/auctionClose'

export default {
  connect,
  logout,
  address,
  bid,
  buy,
  sell,
  updateSale,
  auction,
  cancel,
  challenge,
  tip,
  create,
  stake,
  deleteASAs,
  editASA,
  algoglyphMint,
  vote,
  bigVote,
  signIn,
  close,
  createShuffle,
  buyShuffle,
  claimShuffle,
  closeShuffle,
  cancelShuffle,
  claimRoyaltyFees,
  makeOffer,
  acceptOffer,
  closeOffer,
  joeStart,
  getVerified,
  sendToken,
  createStaking,
  createFusion,
  buyFusion,
  cancelFusion,
  createPack,
  buyPack,
  cancelPack,
  startShuffle,
  auctionClose
}
