<template>
  <Section id="sales">
    <template v-slot:header>
      <span></span>
      <ToggleNSFW v-model="nsfw" v-if="hasNsfw"/>
    </template>

    <h3>Primary Market</h3>
    <CardGrid>
      <ListCard
        v-for="(sale, index) in primaryNFTs"
        :key="`primary-${sale.token_id}`"
        :data="sale"
        :style="`animation-delay: ${100 + ((index % 4) * 50)}ms`"
        @startContract="(data) => newContract(data)"
      />
    </CardGrid>
    <infinite-loading spinner="waveDots" @infinite="infiteListHandler" :identifier="`primary-${infiniteId}`" :forceUseInfiniteWrapper="true"><span slot="no-more"></span>
      <span slot="no-results">
        <template v-if="id">
            <h4>This user doesn't have any active sales</h4>
          </template>
          <template v-else>
            <h4>You don't have any active sales. List owned NFTs <router-link to="/user/ownedNft">here</router-link></h4>
          </template>
      </span>
    </infinite-loading>

    <template v-if="primaryMarketLoaded">
      <h3 class="margin-top">Secondary Market</h3>
      <CardGrid>
        <ListCard
          v-for="(sale, index) in secondaryNFTs"
          :key="`secondary-${sale.token_id}`"
          :data="sale"
          :style="`animation-delay: ${100 + ((index % 4) * 50)}ms`"
          @startContract="(data) => newContract(data)"
        />
      </CardGrid>
      <infinite-loading spinner="waveDots" @infinite="secondInfiteListHandler" :identifier="`secondary-${infiniteId}`" :forceUseInfiniteWrapper="true">
        <span slot="no-more"></span><span slot="no-results">
          <template v-if="id">
              <h4>There are no resales of this user's NFTs</h4>
            </template>
            <template v-else>
              <h4>There are no resales of your NFTs</h4>
            </template>
        </span>
      </infinite-loading>
    </template>

    <ContractPopUp v-if='displayContractPopUp' :_kind="contractKind" :_price='contractPrice' :_is-algo=false :_tokenId='contractTokenId' @done='() => {}' @close="displayContractPopUp = false" :_isAlgo="isContractAlgo"></ContractPopUp>
  </Section>
</template>

<script>
import { mapState } from 'vuex'
import Section from '@/components/Layout/Section'
import InfiniteLoading from 'vue-infinite-loading'
import ListCard from '@/components/Cards/ListCard'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import CardGrid from '@/components/Cards/CardGrid'
import ToggleNSFW from '@/components/Common/Button/ToggleNSFW'

export default {
  name: 'UserSales',
  components: { ToggleNSFW, CardGrid, ListCard, Section, InfiniteLoading, ContractPopUp },
  props: ['id'],
  data: () => {
    return {
      primaryNFTs: [],
      secondaryNFTs: [],
      infiniteId: 0,
      increment: 16,
      contractKind: '',
      contractPrice: 1,
      contractTokenId: 0,
      isContractAlgo: true,
      displayContractPopUp: false,
      primaryMarketLoaded: false,
      nsfw: false,
      hasNsfw: false
    }
  },
  computed: {
    ...mapState([
      'userId',
      'wallet'
    ]),
    galleryId () {
      return this.id || this.userId
    },
    salesUrl () {
      return `/api/sales/${this.galleryId}`
    }
  },
  watch: {
    nsfw () {
      this.reload()
    }
  },
  methods: {
    reload () {
      this.primaryNFTs = []
      this.secondaryNFTs = []
      this.primaryMarketLoaded = false
      this.infiniteId++
    },
    newContract (data) {
      this.contractKind = data.kind
      this.contractPrice = data.price
      this.contractTokenId = data.tokenId
      this.isContractAlgo = true
      this.displayContractPopUp = true
    },
    infiteListHandler ($state) {
      this.$http.get(this.salesUrl, {
        params: {
          start: this.primaryNFTs.length,
          end: this.primaryNFTs.length + this.increment,
          nsfw: this.nsfw,
          primary: true
        }
      }).then(({ data }) => {
        if (data?.sales.length) {
          this.primaryNFTs.push(...data.sales)
          $state.loaded()
        } else {
          $state.complete()
          this.primaryMarketLoaded = true
        }
      })
    },
    secondInfiteListHandler ($state) {
      this.$http.get(this.salesUrl, {
        params: {
          start: this.secondaryNFTs.length,
          end: this.secondaryNFTs.length + this.increment,
          nsfw: this.nsfw,
          primary: false
        }
      }).then(({ data }) => {
        if (data?.sales.length) {
          this.secondaryNFTs.push(...data.sales)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  },
  mounted () {
    this.$http.get(this.salesUrl, {
      params: {
        start: 0,
        end: 1,
        nsfw: true,
        primary: true
      }
    }).then(({ data }) => {
      if (data?.sales.length) this.hasNsfw = true
    })
    this.$http.get(this.salesUrl, {
      params: {
        start: 0,
        end: 1,
        nsfw: true,
        primary: false
      }
    }).then(({ data }) => {
      if (data?.sales.length) this.hasNsfw = true
    })
  }
}
</script>

<style scoped>
.card-grid { /* For the sales gallery and shop view spacing */
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-auto-rows: 100px;
}

.margin-top {
  margin-top: 40px;
}
</style>
