<template>
  <AppView id="governance" v-if="loaded">
    <h1 style="margin-bottom: 20px">Governance</h1>
    <router-view/>
  </AppView>
  <Loader v-else/>
</template>

<script>
import { mapMutations } from 'vuex'
import AppView from '@/components/Layout/AppView'
import Loader from '@/components/Common/Spinner/Loader'

export default {
  name: 'Governance',
  components: { Loader, AppView },
  data () {
    return {
      loaded: false
    }
  },
  methods: {
    ...mapMutations(['updateGovernanceStats'])
  },
  mounted () {
    this.$nextTick(() => {
      this.$http.get('/api/governance/stats').then(({ data }) => {
        this.updateGovernanceStats(data)
        this.loaded = true
        if (!data.staked) {
          this.$router.push('/governance/stake')
        }
      }).catch(err => {
        console.error(err)
      })
    })
  }
}
</script>

<style scoped>
@media only screen
and (max-device-width: 1200px) {
#governance .view-body > h1 {
  text-align: center;
}
}
</style>
