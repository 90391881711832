import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function acceptOffer (APIName, from, appId, tokenId) {
  try {
    const API = Utils._getAPI(APIName)
    const parsed = await Utils._callServer('/api/offers/accept', { app_id: appId }, 'GET')

    const suggestedParams = await Utils.getParams()

    const accounts = []
    accounts.push(parsed.buyer)
    accounts.push(parsed.gems_fees)
    accounts.push(parsed.royalties)
    accounts.push(parsed.seller)
    console.log(accounts)
    const appAddr = algosdk.getApplicationAddress(appId)

    const fundTxn = algosdk.makeAssetTransferTxnWithSuggestedParams(
      parsed.seller, appAddr, undefined, undefined, 1, undefined, tokenId, suggestedParams, undefined)

    const appArgs = []
    appArgs.push(new TextEncoder().encode('accept_offer'))

    const appCallTxn = algosdk.makeApplicationNoOpTxn(
      parsed.seller, suggestedParams, appId, appArgs, accounts, undefined, [tokenId], undefined, undefined
    )

    /* const accounts2 = []
    accounts2.push(parsed.buyer)
    accounts2.push(parsed.seller)
    const appDeleteTxn = algosdk.makeApplicationDeleteTxn(
      parsed.seller, suggestedParams,
      appId, undefined,
      accounts2,
      undefined, [tokenId]) */

    const d = await API.signGroup([fundTxn, appCallTxn], [true, true])
    const message = {
      signed_tx: d,
      token_id: tokenId,
      app_id: appId
    }
    return Utils._callServer('/api/offers/accept', message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default acceptOffer
