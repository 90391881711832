import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function closeOffer (APIName, from, appId, tokenId) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()

    const cancelInfo = await Utils._callServer('/api/offers/cancel', { app_id: appId }, 'GET')

    const accounts = []
    accounts.push(cancelInfo.buyer)

    const txn =
      algosdk.makeApplicationDeleteTxn(
        from, suggestedParams,
        appId, undefined,
        accounts,
        undefined, [tokenId])

    const signedTx = await API.sign(txn)
    const message = {
      signed_tx: [signedTx],
      token_id: tokenId,
      app_id: appId
    }
    return Utils._callServer('/api/offers/cancel', message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default closeOffer
