import { render, staticRenderFns } from "./HelpJoe.vue?vue&type=template&id=02436a70&scoped=true&"
import script from "./HelpJoe.vue?vue&type=script&lang=js&"
export * from "./HelpJoe.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02436a70",
  null
  
)

export default component.exports