<template>
<AppView>
  <h2>New Collection</h2>
  <NFTUpload :class="{'invalid':!valid && error}" @file="(file) => {this.file = file}"/>
  <p class="image-size-text">
    <span>Recommended:</span> <span> 800x400px</span>
    <span>Preview card:</span> <span> 350x175px</span>
    <span>Header:</span> <span> scaling width x400px</span>
  </p>
  <input v-model="title" type="text" :placeholder="$t('createCollection.placeholder.title')" :class="{'invalid':!titleValid && error}" maxlength="50">
  <textarea v-model="description" :class="{'invalid':!descriptionValid && error}"
            :placeholder="$t('createCollection.placeholder.description')" maxlength="500"></textarea>
  <span class="flex-center">{{$t('menu.nsfw')}}
    <Toggle :default-sate="isNsfw" @toggle="(state) => this.isNsfw = state"/>
  </span>
  <h5 v-if="error" class="error">
    <unicon height="24px" name="exclamation-octagon"/>
    {{ error }}
  </h5>
  <Button @click.native="create">{{$t('common.button.next')}}</Button>
</AppView>
</template>

<script>
import NFTUpload from '@/components/Create/NFTUpload'
import Button from '@/components/Common/Button/Button'
import AppView from '../../components/Layout/AppView'
import Toggle from '../../components/Common/Input/Toggle'

export default {
  name: 'CreateCollection.vue',
  components: { Toggle, AppView, Button, NFTUpload },
  props: {
    id: String
  },
  data () {
    return {
      start: 0,
      end: 16,
      file: null,
      title: '',
      description: '',
      error: null,
      isNsfw: false
    }
  },
  computed: {
    url () {
      return '/api/collections/create'
    },
    editCollectionURL () {
      return '/collection'
    },
    valid () {
      return !!this.file && this.titleValid && this.descriptionValid
    },
    titleValid () {
      return /^.{1,50}$/.test(this.title)
    },
    descriptionValid () {
      return true // /^.{0,500}$/.test(this.description)
    }
  },
  methods: {

    create () {
      console.log(this.valid)
      if (!this.valid) {
        this.toggleError()
        return
      }
      const formData = new FormData()
      formData.append('title', this.title)
      formData.append('description', this.description)
      formData.append('file', this.file)
      formData.append('nsfw', this.isNsfw)
      this.$http.post(this.url, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((m) => {
          console.log(m)
          this.$router.push(this.editCollectionURL + `/${m.data.collection_id}`)
        })
        .catch((err) => { this.errorr = err })
    },
    toggleError () {
      this.error = false
      if (!this.titleValid) {
        if (!this.title.length) {
          this.error = 'Title is required'
        } else {
          this.error = 'Title is invalid'
        }
      } else if (!this.descriptionValid) {
        this.error = 'Description is invalid'
      }
    }
  }
}
</script>

<style scoped>
h2 {
  text-align: center;
}
.error {
  position: initial;
}
.checkbox {
  height: 10px;
  width: 10px;
  border: 1px solid var(--text);
  border-radius: 5px;
}
.image-size-text {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 2px 5px;
  margin: auto;
  width: 200px;
}

.image-size-text > *:nth-child(odd) {
  align-self: center;
  justify-self: right;
}

.image-size-text > *:nth-child(even) {
  align-self: center;
  justify-self: left;
  min-width: 300px;
}
</style>
