import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function closeShuffle (APIName, from, shuffleId) {
  try {
    const API = Utils._getAPI(APIName)
    let message = {
      shuffle_id: shuffleId
    }
    const parsed = await Utils._callServer('/api/shuffle/close', message, 'GET')

    const suggestedParams = await Utils.getParams()

    const foreignAssets = []
    if (parsed.whitelist_id) {
      foreignAssets.push(parsed.whitelist_id)
    }
    if (parsed.asa_id !== 1) {
      foreignAssets.push(parsed.asa_id)
    }
    const signedTx = await API.sign(
      algosdk.makeApplicationDeleteTxn(
        from, suggestedParams, parsed.app_id, undefined, undefined, undefined, foreignAssets
      )
    )

    message = {
      shuffle_id: shuffleId,
      signed_tx: signedTx
    }
    return Utils._callServer('/api/shuffle/close', message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default closeShuffle
