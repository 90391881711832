<template>
  <Section>
    <template v-slot:header>
      <h3>Hot on the {{asaName}} market</h3>
      <span class="navigate-buttons">
        <unicon
          name="angle-up"
          :class="rowIndex === 0 ? '' : 'activated'"
          @click.native="scrollUp"
        />
        <unicon
          name="angle-down"
          :class="rowIndex === 2 ? '' : 'activated'"
          @click.native="scrollDown"
        />
      </span>
    </template>
    <ListCardGrid v-if="auctions.length || sales.length" ref="grid">
      <template v-if="auctions.length">
        <ListCard v-for="auction of auctions" :key="auction.token_id" :data="auction">Auction</ListCard>
      </template>
      <template v-if="sales.length">
        <ListCard v-for="sale of sales" :key="sale.token_id" :data="sale">Sale</ListCard>
      </template>
    </ListCardGrid>
      <ShuffleGrid v-if="shuffles.length">
        <ShuffleCard v-for="shuffle in shuffles" :key="shuffle.id" :shuffle="shuffle"/>
      </ShuffleGrid>
  </Section>
</template>

<script>
import Section from '../Layout/Section'
import ListCard from '../Cards/ListCard'
import ListCardGrid from '../Cards/ListCardGrid'
import ShuffleCard from '../Shuffle/ShuffleCard'
import ShuffleGrid from '../Shuffle/ShuffleGrid'

export default {
  name: 'HotSales',
  props: ['asaName', 'asaId'],
  components: { ListCardGrid, ListCard, Section, ShuffleCard, ShuffleGrid },
  data () {
    return {
      auctions: [],
      sales: [],
      shuffles: [],
      rowIndex: 0
    }
  },
  methods: {
    getData () {
      this.auctions = []
      this.sales = []
      this.shuffles = []
      const salesParams = {
        start: 0,
        end: 17,
        sort: 'new',
        asa_id: this.asaId
      }
      this.$http('/api/auctions', { params: salesParams }).then((response) => {
        if (response.data && response.data.auctions) {
          this.auctions = response.data.auctions
        }
      }).catch(err => {
        console.log(err)
      })
      this.$http('/api/sales', { params: salesParams }).then((response) => {
        if (response.data && response.data.sales) {
          this.sales = response.data.sales
        }
      }).catch(err => {
        console.log(err)
      })
      const shuffleParams = {
        start: 0,
        end: 4,
        asa_id: this.asaId,
        'verified-only': true
      }
      this.$http('/api/shuffle', { params: shuffleParams }).then((response) => {
        if (response.data && response.data.metadata) {
          this.shuffles = response.data.metadata
        }
      }).catch(err => {
        console.log(err)
      })
    },
    scrollDown () {
      this.rowIndex++
      this.rowIndex = Math.min(2, this.rowIndex)
      this.$refs.grid.$el.scrollTop = 420 * this.rowIndex
    },
    scrollUp () {
      this.rowIndex--
      this.rowIndex = Math.max(0, this.rowIndex)
      this.$refs.grid.$el.scrollTop = 420 * this.rowIndex
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style scoped>
.navigate-buttons {
  display: grid;
  grid-template-columns: auto;
}

.navigate-buttons .unicon {
  fill: var(--textlight)
}

.navigate-buttons .unicon.activated:hover {
  fill: var(--text)
}

.list-card-grid {
  overflow: hidden;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-auto-rows: 105px; /*makes the scroll buttons do nothing if their are no other results. */
  max-height: 401px;
}

</style>
