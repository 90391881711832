<template>
  <div :style="bannerStyle" :class="{ banner: true, loading: !bannerUrl}" ref="banner"></div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    id: Number,
    version: {
      type: Number
    },
    isCollectionBanner: Boolean
  },
  data () {
    return {
      bannerUrl: null
    }
  },
  computed: {
    bannerStyle () {
      if (this.bannerUrl) {
        return `background-image: url(${this.bannerUrl})`
      }
      return ''
    }
  },
  watch: {
    id (newValue) {
      if (newValue) {
        this.loadBanner()
      }
    },
    version (newValue) {
      if (newValue) {
        this.loadBanner()
      }
    }
  },
  methods: {
    loadBanner () {
      let bgUrl
      const path = this.isCollectionBanner ? 'collection' : 'cover'
      const defaultImage = JSON.stringify({
        bucket: 'algogems',
        key: `${path}/default`,
        edits: {
          resize: {
            width: this.$refs.banner.getBoundingClientRect().width,
            height: this.$refs.banner.getBoundingClientRect().height
          }
        }
      })
      if (this.id) {
        const bannerId = (!this.version ? this.id : `${this.id}-${this.version}`)
        const imageRequest = JSON.stringify({
          bucket: 'algogems',
          key: `${path}/${bannerId}`,
          edits: {
            resize: {
              width: this.$refs.banner.getBoundingClientRect().width,
              height: this.$refs.banner.getBoundingClientRect().height
            }
          }
        })
        bgUrl = `/image/${btoa(imageRequest)}`
      } else {
        bgUrl = `/image/${btoa(defaultImage)}`
      }
      const bgImg = new Image()
      bgImg.onload = () => {
        this.bannerUrl = bgUrl
      }
      bgImg.onerror = () => {
        this.bannerUrl = `/image/${btoa(defaultImage)}`
      }
      bgImg.src = bgUrl
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadBanner()
    })
  }
}
</script>

<style scoped>
.banner {
  width: 98%;
  height: 400px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  border-radius: 20px;
  margin: 100px auto 0 auto;
}

.banner.loading {
  box-shadow: none;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f3f3f3;
  background: linear-gradient(to right,#f3f3f3 5%, #e5e5e5 20%,#f3f3f3 60%);
  background-size: 1000px 640px;
}
</style>
