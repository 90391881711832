<template>
  <div class="sort-buttons-wrapper">
    <template v-if="_usePopular">
      <input type="radio" id="popular" name="sortButtons" value="popular" v-model="selected">
      <label for="popular" :class="(selected == 'popular') ? 'selected' : ''">
        <unicon class="social-icons" name="favorite" height="30px" width="30px"/> {{$t('sortButtons.popular')}}
      </label>
    </template>
    <input type="radio" id="random" name="sortButtons" value="random" v-model="selected">
    <label for="random" :class="(selected == 'random') ? 'selected' : ''" @click="reloadRandom">
      <unicon class="social-icons" name="arrow-random" height="25px" width="25px"/> {{$t('sortButtons.random')}}
    </label>
    <input type="radio" id="new" name="sortButtons" value="new" v-model="selected">
    <label for="new" :class="(selected == 'new') ? 'selected' : ''">
      <unicon class="social-icons" name="brightness-empty" height="30px" width="30px"/> {{$t('sortButtons.new')}}
    </label>

    <template v-if="selected == 'high'">
      <input type="radio" id="priceAsc" name="sortButtons" value="low" v-model="selected">
      <label @click="switchPriceSort" for="priceAsc" :class="(selected == 'high') ? 'selected' : ''">
        <unicon class="social-icons" name="arrow-up" height="30px" width="30px"/>{{$t('sortButtons.price')}}
      </label>
    </template>
    <template v-if="selected == 'low'">
      <input type="radio" id="priceDsc" name="sortButtons" value="high" v-model="selected">
      <label @click="switchPriceSort" for="priceDsc" :class="(selected == 'low') ? 'selected' : ''">
        <unicon class="social-icons" name="arrow-down" height="30px" width="30px"/>{{$t('sortButtons.price')}}
      </label>
    </template >
    <template v-if="selected!='high' && selected!='low'">
      <input type="radio" id="price" name="sortButtons" value="low" v-model="selected">
      <label for="price">
        <unicon class="social-icons" name="arrows-v" height="30px" width="30px"/>{{$t('sortButtons.price')}}
      </label>
    </template>

    <input type="radio" id="ending" name="sortButtons" value="ending" v-model="selected">
    <label for="ending" :class="(selected == 'ending') ? 'selected' : ''">
      <unicon class="social-icons" name="stopwatch" height="30px" width="30px"/> {{$t('sortButtons.ending')}}
    </label>
  </div>
</template>

<script>
export default {
  name: 'SortButtonsAuction',
  props: {
    _initialSort: String,
    _usePopular: Boolean
  },
  data () {
    return {
      selected: (this._initialSort) ? this._initialSort : 'random'
    }
  },
  watch: {
    _initialSort () {
      this.selected = (this._initialSort) ? this._initialSort : 'random'
    },
    selected (sortOption) {
      this.$emit('selected', sortOption)
    }
  },
  methods: {
    switchPriceSort () {
      this.selected = (this.selected === 'low') ? 'high' : 'low'
      this.$emit('selected', this.selected)
    },
    reloadRandom () {
      if (this.selected === 'random') {
        window.location.reload()
      }
    }
  }
}
</script>

<style scoped>
input {
  display: inline;
  margin: 0;
  width: 0;
  height: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  padding: 0;
}

label {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  margin: 2px;
  color: var(--textlight);
  cursor: pointer;
}

.unicon {
  margin-right: 5px;
  fill: var(--textlight);
}

label.selected {
  background: var(--foreground);
  border-radius: 10px;
  color: var(--accent);
  box-shadow: var(--light-shadow);
}

label.selected .unicon {
  fill: var(--accent);
}

.sort-buttons-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

@media only screen
and (max-device-width: 1200px) {
  .sort-buttons-wrapper {
    flex-wrap: wrap;
    width: 100vw;
  }
}
</style>
