<template>
  <div class="dark-theme-switch" @click="toggle">
    <unicon name="moon" v-if="dark"/>
    <unicon name="sun" v-else/>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'DarkThemeSwitch',
  computed: {
    ...mapState(['dark'])
  },
  methods: {
    ...mapMutations(['toggleDark']),
    toggle () {
      this.$http.post('/api/account/mode', { mode: (this.dark) ? 'light' : 'dark' })
        .then(() => {
          this.toggleDark()
        })
    }
  }
}
</script>

<style scoped>
div {
  display: inline-block;
  cursor: pointer;
}
</style>
