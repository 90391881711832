import Utils from '../utils'
import algosdk from 'algosdk-latest'
import buyShuffle from './buyShuffle'
import claimShuffle from './claimShuffle'

async function buyPack (APIName, from, packId, shuffleId, step = 'buy_shuffle') {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()

    if (step === 'buy_shuffle') {
      await buyShuffle(APIName, from, shuffleId)
    } else if (step === 'claim_shuffle') {
      await claimShuffle(APIName, from, shuffleId)
    }

    const message = {
      pack_id: packId
    }
    const parsed = await Utils._callServer('/api/pack/buy', message, 'GET')
    // "seller"
    // "app_address"
    // "app_id"
    // "gems_fees_wallet"
    // "gems_fees_percent"
    //  "bidder"
    //  "amount"
    //  "whitelist"
    //  "nfts"

    const txns = []

    for (const x of new Set(parsed.nfts)) {
      txns.push(
        algosdk.makeAssetTransferTxnWithSuggestedParams(
          from, from, undefined, undefined, 0, undefined, x, suggestedParams
        )
      )
    }

    txns.push(
      algosdk.makeAssetTransferTxnWithSuggestedParams(
        from, parsed.app_address,
        undefined, undefined,
        1,
        undefined,
        parsed.whitelist,
        suggestedParams))

    txns.push(
      algosdk.makePaymentTxnWithSuggestedParams(
        from, parsed.app_address,
        parsed.amount,
        undefined, undefined,
        suggestedParams,
        undefined
      )
    )

    const appArgs = []
    appArgs.push(new TextEncoder().encode('buy'))

    const accounts = []
    accounts.push(parsed.seller)
    accounts.push(parsed.bidder)
    accounts.push(parsed.gems_fees_wallet)

    const foreignAssets = parsed.nfts.slice()
    foreignAssets.push(parsed.whitelist)

    txns.push(
      algosdk.makeApplicationNoOpTxn(
        from,
        suggestedParams,
        parsed.app_id, appArgs,
        accounts,
        undefined, foreignAssets,
        undefined, undefined, undefined)
    )

    const signedTxs = await API.signGroup(txns, txns.map(x => true))

    const message2 = {
      signed_tx: signedTxs,
      app_id: parsed.app_id
    }
    return await Utils._callServer('/api/pack/buy', message2)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default buyPack
