<template>
  <span class="asa-logo">
    <img class="algo-logo" src="@/assets/asaLogo/1.svg" alt="A" v-if="asaName === 'algo' || asaId === 1">
    <img class="gems-logo" src="@/assets/asaLogo/230946361.svg" alt="$Gems" v-else-if="asaName === 'gems' || asaId === 230946361">
    <img :class="`${asaId}-logo`" :src="require(`@/assets/asaLogo/${asaId}.png`)" v-else-if="[386192725,386195940,435335235,465865291,417708610,571576867,445905873,753890862,796425061,1065092715,400593267].includes(asaId)">
    <img :class="`${asaId}-logo`" :src="require(`@/assets/asaLogo/${asaId}.svg`)" :alt="asaId" v-else>
  </span>
</template>

<script>
export default {
  name: 'ASALogo',
  props: ['asaName', 'asaId']
}
</script>

<style scoped>
img {
  margin-left: 2px;
  height: 0.9em;
}

img:not(.algo-logo) {
  position: relative;
  top: 0.1em;
}

.no-margin img {
  margin-left: 0;
  top: 0;
}

.algo-logo {
  height: 0.7em;
  filter: invert(var(--invert));
  opacity: .8;
}

.button .gems-logo {
  width: 30px;
  height: 30px;
}
</style>
