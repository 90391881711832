import { render, staticRenderFns } from "./CrescendoSubmarket.vue?vue&type=template&id=86de82e0&scoped=true&"
import script from "./CrescendoSubmarket.vue?vue&type=script&lang=js&"
export * from "./CrescendoSubmarket.vue?vue&type=script&lang=js&"
import style0 from "./CrescendoSubmarket.vue?vue&type=style&index=0&id=86de82e0&prod&scoped=true&lang=css&"
import style1 from "./CrescendoSubmarket.vue?vue&type=style&index=1&id=86de82e0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86de82e0",
  null
  
)

export default component.exports