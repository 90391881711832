<template>
  <li class="shuffle-card">
    <ul class="overview">
      <Card v-for="nft in shuffle.token_info" :key="nft.token_id" :data="{token_id: nft.token_id, extension: nft.extension, content_type: nft.content_type, to: `/shuffle/${shuffle.id}`, ...shuffle}"/>
    </ul>
  </li>
</template>

<script>
import Card from '@/components/Cards/Card'
export default {
  name: 'ShuffleCard',
  components: { Card },
  props: {
    shuffle: Object
  }
}
</script>

<style scoped>
.overview {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 250px;
  height: 350px;
  position: relative;
}

.overview li{
  width: 250px;
  height: 350px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.overview li.loading{
  position: absolute;
}

.overview li:nth-child(2){
  top: -10px;
  left: 10px;
  z-index: 2;
  animation-delay: .1s;
}

.overview li:nth-child(3){
  top: -20px;
  left: 20px;
  z-index: 1;
  animation-delay: .2s;
}
</style>
