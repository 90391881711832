<template>
  <div class="dot-spinner"></div>
</template>

<script>
export default {
  name: 'DotLinearLoader'
}
</script>

<style scoped>
.dot-spinner {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--blue);
  color: var(--blue);
  box-shadow: 9999px 0 0 -5px var(--blue);
  animation: dotSpinner 1.5s infinite linear;
  animation-delay: .25s;
}

.dot-spinner::before, .dot-spinner::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--blue);
  color: var(--blue);
}

.dot-spinner::before {
  box-shadow: 9984px 0 0 -5px var(--blue);
  animation: dotSpinnerBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-spinner::after {
  box-shadow: 10014px 0 0 -5px var(--blue);
  animation: dotSpinnerAfter 1.5s infinite linear;
  animation-delay: .5s;
}

@keyframes dotSpinnerBefore {
  0% {
    box-shadow: 9984px 0 0 -5px var(--blue);
  }
  30% {
    box-shadow: 9984px 0 0 2px var(--blue);
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px var(--blue);
  }
}

@keyframes dotSpinner {
  0% {
    box-shadow: 9999px 0 0 -5px var(--blue);
  }
  30% {
    box-shadow: 9999px 0 0 2px var(--blue);
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px var(--blue);
  }
}

@keyframes dotSpinnerAfter {
  0% {
    box-shadow: 10014px 0 0 -5px var(--blue);
  }
  30% {
    box-shadow: 10014px 0 0 2px var(--blue);
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px var(--blue);
  }
}
</style>
