import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function getVerified (APIName, from) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParams(from, from, undefined, undefined, 0, new TextEncoder('utf-8').encode(`Sign In: ${534930853}`), 534930853, suggestedParams)

    const signed = await API.sign(txn)
    return Utils._callServer('api/account/vgems', { signed_tx: signed })
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default getVerified
