<template>
<div id="footer">
  <ul class="socials">
    <li><a href="https://t.me/AlgogemsLounge" target="_blank"><unicon name="telegram" /></a></li>
    <li><a href="https://discord.com/invite/xtZnB7tZw8" target="_blank"><unicon name="discord" /></a></li>
    <li><a href="https://www.reddit.com/r/Algogems/" target="_blank"><unicon name="reddit-alien-alt" /></a></li>
    <li><a href="https://twitter.com/AlgogemsNFT" target="_blank"><unicon name="twitter" /></a></li>
    <li><a href="https://www.facebook.com/algogemsplatform/" target="_blank"><unicon name="facebook-f" /></a></li>
    <li><a href="https://instagram.com/algogems.io" target="_blank"><unicon name="instagram" /></a></li>
    <li><a href="https://algogems.medium.com" target="_blank"><unicon name="medium-m" /></a></li>
    <li><a href="mailto:contact@algogems.io"><unicon name="at" /></a></li>
  </ul>
  <ul class="refs">
    <li><router-link to="/terms_of_use">{{$t('common.termsOfUse')}}</router-link></li>
    <li><a href="https://algogemsnft.github.io" target="_blank">About</a></li>
    <li><a href="https://algogemsnft.github.io#faq" target="_blank">FAQ</a></li>
  </ul>
  <h5 class="title">Algogems Bots</h5>
  <ul class="socials bots">
    <li>
      <a href="https://discord.com/api/oauth2/authorize?client_id=986343597668454461&permissions=274877963264&scope=bot" target="_blank"><unicon name="discord" /></a>
    </li>
    <li>
      <a href="https://twitter.com/AlgogemsSales"><unicon name="twitter" /></a>
    </li>
  </ul>
  <LangSelector></LangSelector>
  <h5>
    Made with ❤️ in 🇫🇷<br>
    Copyright © 2021 NFX Labs
  </h5>
</div>
</template>

<script>
import LangSelector from './Button/LangSelector'

export default {
  name: 'Footer',
  components: { LangSelector }
}
</script>

<style scoped>
#footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
  background: linear-gradient(var(--background), rgba(255,255,255,0) 80%, rgba(255,255,255,0) 100%), var(--lightgradient);
  text-align: center;
  padding-bottom: 50px;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.socials {
  margin-top: 25px;
  fill: var(--accent);
}

.socials li {
  margin: 10px;
  background: var(--background);
  box-shadow: var(--light-shadow);
  padding: 10px;
  border-radius: 10px;
}

.refs{
  margin-top: 10px;
  font-size: .9em;
}

.refs li {
  margin: 0 5px;
}

.refs li a {
  color: var(--textlight);
}

#footer h5{
  color: var(--textlight);
  margin-top: 25px;
}

#footer .socials.bots {
  margin-top: 2px;
}
</style>
