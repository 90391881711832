<template>
  <Section class="stats-section">
    <p class="unverified-disclaimer" v-if="gallery && !gallery.is_verify">
      <unicon name="exclamation-triangle"/>
      <template v-if="id !== userId">This creator is not yet verified. Learn more&nbsp;<a href="https://algogemsnft.github.io/#public" target="_blank">here</a>.</template>
      <template v-else>You are not verified. Learn more&nbsp;<a href="https://algogemsnft.github.io/#public" target="_blank">here</a>.</template>
    </p>
    <div class="external-links" v-if="gallery">
      <p>
        <a :href="`https://www.nftexplorer.app/collection?creator=${gallery.addresses[0]}`" target="_blank">
          <img src="@/assets/nftxplorer_logo.svg">
        </a>
      </p>
      <p>
        <a :href="`https://allo.info/account/${gallery.addresses[0]}`" target="_blank">
          <img class="allo-logo" src="@/assets/allo-logo-grape.svg">
        </a>
      </p>
    </div>
    <div class="stats-section-body" v-if="gallery">
      <div class="sales-overview">
        <NumberCard :number="gallery.sales_price" :text="$t('user.earned')" :is-algo="true" />
        <NumberCard :number="gallery.sales_number" :text="$t('user.nftSold')" :is-algo="false" />
        <NumberCard :number="gallery.sales_price_mean" :text="$t('user.nftMeanPrice')" :is-algo="true" />
      </div>
      <div class="nft-examples" v-if="gallery.nft_best">
        <div>
          <Card :data="gallery.nft_best"/>
          <span class="caption">{{$t('stats.best')}}</span>
        </div>
        <div>
          <Card :data="gallery.nft_last"/>
          <span class="caption">{{$t('stats.last')}}</span>
        </div>
      </div>
    </div>
  </Section>
</template>

<script>
import NumberCard from '../Cards/NumberCard'
import { mapState } from 'vuex'
import Card from '@/components/Cards/Card'

export default {
  name: 'StatsCard',
  components: { Card, NumberCard },
  props: ['id'],
  data () {
    return {
      gallery: null
    }
  },
  computed: {
    ...mapState([
      'userId'
    ]),
    galleryId () {
      return this.id || this.userId
    },
    apiUrl () {
      return `/api/account/info/${this.galleryId}`
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$http.get(this.apiUrl).then(response => {
        if (response.data) {
          this.gallery = response.data
          this.isFollowing = response.data.follow
        }
      }).catch(err => {
        console.log(err)
      })
    })
  }
}
</script>

<style scoped>
.header {
  text-align: center;
  color: var(--textlight)
}

.stats-section-body {
  max-width: 1200px;
  padding: 20px;
  border-radius: 20px;
  margin: 10px auto;
  font-weight: bold;
  color: var(--textlight);
}

.nft-examples {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin: 20px;
}

.nft-examples .card {
  width: 250px;
  height: 350px;
  list-style: none;
  margin-bottom: 20px;
}

.sales-overview {
  display: flex;
  justify-content: space-around;
  margin: 20px;
  margin-bottom: 40px;
  color: var(--text);
  font-size: larger;
}

.sales-overview span {
  display: flex;
  align-items: center;
}

.sales-overview .unicon {
  margin-right: 5px;
}

.caption {
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen
and (max-device-width: 1200px) {
  .sales-overview {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .number-card {
    margin: 15px;
  }
}

.external-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  max-width: 700px;
  margin: auto;
}

.external-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: var(--foreground);
  padding: 10px 20px;
  border-radius: 15px;
}

.external-links img {
  height: 25px;
}

.external-links a:hover {
  box-shadow: var(--shadow);
  transform: scale(1.1);
}

.dark .allo-logo {
  filter: invert(0.3);
}

.unverified-disclaimer {
  color: var(--error);
  display: flex;
  align-items: center;
  justify-content: center;
}

.unverified-disclaimer .unicon {
  fill: var(--error);
  margin-right: 10px;
}
</style>
