<template>
  <div class="toggle-nsfw">
    <unicon :class="(state) ? 'activated' : ''" name="18-plus" @click.native="toggle"/>
    <NSFWVerificationPopUp @yes="toggle" @no="displayAgePopUp = false" v-if="displayAgePopUp"/>
  </div>
</template>

<script>
import NSFWVerificationPopUp from '../PopUp/NSFWVerificationPopUp'
import { mapState } from 'vuex'

export default {
  name: 'ToggleNSFW',
  components: { NSFWVerificationPopUp },
  props: ['value'],
  data () {
    return {
      state: this.isAdult && this.value,
      displayAgePopUp: false
    }
  },
  computed: {
    ...mapState(['isAdult'])
  },
  methods: {
    toggle () {
      this.displayAgePopUp = false
      if (this.isAdult) {
        this.state = !this.state
        this.$emit('input', this.state)
      } else {
        this.displayAgePopUp = true
      }
    }
  }
}
</script>

<style scoped>
.toggle-nsfw {
  margin: 5px;
  display: inline-block;
  height: 24px;
  cursor: pointer;
}

.unicon {
  fill: var(--textlight);
}

.unicon.activated {
  fill: #ca3170;
}
</style>
