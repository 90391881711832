import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function deleteASAs (APIName, from, params) {
  const API = Utils._getAPI(APIName)
  const suggestedParams = await Utils.getParams()

  const txns = []

  console.log(params.nfts)
  for (const id of params.nfts) {
    txns.push(
      algosdk.makeAssetDestroyTxnWithSuggestedParams(
        from,
        undefined,
        id,
        suggestedParams)
    )
  }

  const signedTxs = await API.signGroup(txns, txns.map(x => true), false)
  return Utils._callServer('/api/sdk/send_all', { signed_tx: signedTxs })
}

// eslint-disable-next-line no-unused-vars
async function deleteASAv1 (APIName, tokenId) {
  const API = Utils._getAPI(APIName)
  const message = {
    token_id: tokenId
  }
  const parsed = await Utils._callServer('/api/transaction/delete/check', message)
  console.log(parsed)
  if (!parsed) {
    throw new Error()
  }
  if (['send', 'delete'].includes(parsed.transaction)) {
    const suggestedParams = await Utils.getParams()
    const note = new TextEncoder('utf-8').encode(parsed.note)
    let txn
    if (parsed.transaction === 'send') {
      txn = algosdk.makeAssetTransferTxnWithSuggestedParams(parsed.from, parsed.to, undefined, undefined, 1, note, tokenId, suggestedParams)
    } else if (parsed.transaction === 'delete') {
      txn = algosdk.makeAssetDestroyTxnWithSuggestedParams(parsed.from, note, tokenId, suggestedParams)
    }
    const d = await API.sign(txn)
    console.log(d)
    if (!d) {
      throw new Error()
    }
    const message = {
      token_id: tokenId,
      signed_tx: d
    }
    return Utils._callServer('/api/transaction/delete/validate', message)
  } else {
    return new Promise((resolve, reject) => {
      resolve()
    })
  }
}

export default deleteASAs
