<template>
  <div id='nsfw-age-verification'>
    <div id='nsfw-age-verification-body'>
      <h1>{{$t('NSFWAgeVerification.header')}}</h1>
      <p class='centered'>
        {{$t('NSFWAgeVerification.body')}}
      </p>
      <p class='centered'>
        {{$t('NSFWAgeVerification.buttonHeader')}}
      </p>
      <div class="flex-line">
        <Button @click.native='goBack' class="secondary small-margin">{{$t('NSFWAgeVerification.button.goBack')}}</Button>
        <Button @click.native='enter' class="small-margin">{{$t('NSFWAgeVerification.button.enter')}}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Common/Button/Button'
import { mapMutations } from 'vuex'

export default {
  name: 'NSFWVerificationPopUp',
  components: { Button },
  methods: {
    ...mapMutations([
      'setAdult'
    ]),
    enter () {
      this.$http.get('/api/set/nsfw').then(
        () => {
          this.setAdult({ isAdult: true })
          this.$emit('yes')
        }
      )
    },
    goBack () {
      this.$emit('no')
    }
  }
}
</script>

<style scoped>
h1 {
  color: var(--text);
}

#nsfw-age-verification {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background: var(--pop-up-background);

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.centered {
  text-align: center;
}

.flex-line {
  display: flex;
  justify-content: center;
}

.small-margin {
  margin: 10px;
}
</style>
