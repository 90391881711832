<template>
  <form>
    <h1 class="medium">Instagram Mint</h1>
    <template v-if="!displayImages">
      <p>Enter your public instagram account name: </p>
      <input type="text" v-model="instagramAccount" />
      <Button @click.native="displayImages = true" >Load Pictures</Button>
    </template>
    <InstagramImages v-else :account="instagramAccount" @picture="(p) => this.picture = p" @file="uploadInstagram"/>
  </form>
</template>

<script>
import InstagramImages from '../../../components/Create/InstagramImages'
import { mapState } from 'vuex'
import Button from '../../../components/Common/Button/Button'

export default {
  name: 'CreatePicture',
  components: {
    Button,
    InstagramImages
  },
  data () {
    return ({
      name: '',
      error: false,
      picture: null,
      pictureFile: null,
      mimeType: null,
      isUploading: false,
      nftKind: 'picture',
      nftFile: null,
      instagramAccount: '',
      displayImages: false
    })
  },
  computed: {
    ...mapState(['address'])
  },
  methods: {
    valid () {
      return (this.nftKind === 'instagram' && !!this.pictureFile) || (!!this.pictureFile && (this.nftKind === 'picture' || !!this.nftFile))
    },
    toggleError () {
      this.error = false
      setTimeout(() => {
        if (!this.valid()) {
          this.error = 'Please upload a picture'
        }
      }, 100)
    },
    uploadInstagram (m) {
      /* this.pictureFile = m.file
      this.mimeType = m.file.type */
      this.pictureFile = m
      this.next()
    },
    next (data = {}) {
      if (this.valid()) {
        const done = (p) => {
          this.isUploading = false
          this.picture = 'instagram'
          this.$emit('next', { ...data, mimeType: this.mimeType, picture: this.picture, ipfs_url: p.data.ipfs_url, ipfs_hash: p.data.ipfs_hash, canPayMint: p.data.create_user, crescendo: p.data.crescendo })
        }

        const error = (e) => {
          this.error = e
          this.isUploading = false
        }

        this.isUploading = true
        const formData = new FormData()
        formData.append('file', this.pictureFile)
        this.$http.post('/api/instagram/create/upload', formData,
          { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(done)
          .catch(error)
      } else {
        this.toggleError()
      }
    }
  }
}
</script>

<style scoped>

.nft-kind-selector {
  display: flex;
  width: 600px;
  justify-content: space-around;
  margin: 30px auto;
}

.nft-kind-selector input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  color: var(--complement);
}

.nft-kind-selector label {
  display: flex;
  align-content: center;
}

.nft-kind-selector label span {
  margin: auto;
}

.nft-kind-selector label .unicon {
  margin-right: 5px;
  fill: var(--textlight);
}

label input:checked ~ span {
  color: var(--accent);
}

label input:checked ~ .unicon {
  fill: var(--accent);
}

.nft-file-label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--textlight);
  margin-bottom: 50px;
}

.nft-file-label .unicon {
  fill: var(--textlight);
  margin-right: 8px;
}

.nft-file-label:hover {
  color: var(--text);
}

.nft-file-label:hover .unicon {
  fill: var(--text);
}

.nft-file-label input {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

h4 {
  text-align: center;
  font-weight: normal;
  margin: 10px;
}

h4 .small-text {
  display: block;
  color: var(--textlight);
  font-size: smaller;
}
</style>
