import Utils from '../utils'
import * as algosdk from 'algosdk-latest'

async function algoglyphMint (APIName, from, asset) {
  const API = Utils._getAPI(APIName)
  const suggestedParams = await Utils.getParams()

  const txn2 = algosdk.makeAssetCreateTxnWithSuggestedParams(from, asset.note, 1, 0, false, undefined, undefined, undefined, undefined, asset.unit, asset.title, asset.url, undefined, suggestedParams)
  const d = await API.sign(txn2)
  const message = {
    signed_tx: d
  }
  return Utils._callServer('/api/glyph/create', message)
}

export default algoglyphMint
