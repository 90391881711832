import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function createStacking (APIName, from, stacking) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()
    const contractParams = await Utils._callServer('/api/stack/create', stacking, 'GET')

    let txn
    if (stacking.asa_id === 1) {
      txn =
        algosdk.makePaymentTxnWithSuggestedParams(
          from, contractParams.to, contractParams.amount,
          undefined, undefined,
          suggestedParams, undefined
        )
    } else {
      txn =
        algosdk.makeAssetTransferTxnWithSuggestedParams(
          from, contractParams.to,
          undefined, undefined,
          contractParams.amount, undefined, stacking.asa_id,
          suggestedParams, undefined)
    }

    const signedTx = await API.sign(txn)
    const message = {
      ...stacking,
      signed_tx: [signedTx]
    }
    return Utils._callServer('/api/stack/create', message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default createStacking
