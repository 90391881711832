<template>
  <div class="wrapper">
    <img src="@/assets/mobile-mock-up.png" alt="mobile mock up" style="height: 700px;"/>
    <div>
      <h2>Download Algogems mobile</h2>
      <p>Explore and buy NFTs from the palm of your hand.</p>
      <Button><a href="/app.apk" target="_blank" style="color: #fff;">Download</a></Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Common/Button/Button.vue'

export default {
  name: 'mobile',
  components: { Button }
}
</script>

<style scoped>
.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-wrap: wrap;
  padding: 100px 0;
}

h2 {
  font-family: 'Roboto', sans-serif;
}

.wrapper > img {
  margin-left: 70px;
}

.wrapper > div {
  text-align: center;
}
</style>
