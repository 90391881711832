<template>
<router-link :to="(n) ? '/user/notifications' : '/user'" class="notifications-tracker">
  <div class="red-div" v-if="number">
    {{number}}
  </div>
  <unicon name="user"/>
</router-link>
</template>

<script>
export default {
  name: 'UserNotificationsTracker',
  data () {
    return {
      n: 0
    }
  },
  computed: {
    number () {
      if (this.n < 100) {
        return this.n
      } else {
        return '99+'
      }
    }
  },
  methods: {
    updateNumber () {
      this.$http.get('/api/notification/number').then(({ data }) => {
        this.n = data.number
      }).catch(err => {
        console.error(err)
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.updateNumber()
      setInterval(this.updateNumber, 350000)
    })
  }
}
</script>

<style scoped>
.notifications-tracker {
  position: relative;
}

.red-div {
  font-size: xx-small;
  background: var(--error);
  border-radius: 100%;
  text-align: center;
  padding: 5px;
  position: absolute;
  top: -19px;
  left: 9px;
  min-width: 10px;
  height: 10px;
  color: white;
}
</style>
