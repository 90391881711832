<template>
  <Section id="collections">
    <template v-slot:header>
      <span></span>
      <ToggleNSFW v-model="nsfw" v-if="hasNsfw"/>
    </template>
    <CollectionGrid>
      <div class="plus" v-if="me">
        <div class="cover">
          <router-link :to="createCollectionURL" v-if="me">
              <unicon name="plus" height="50" width="50"/>
          </router-link>
        </div>
      </div>
      <CollectionCard v-for="(collection, index) in nftList" :key="collection.token_id" :collection="collection" :style="`animation-delay: ${100 + ((index % 4) * 50)}ms`"/>
    </CollectionGrid>
    <template v-if="!nftList.length && id">
      <h4>This user has not created any collections</h4>
    </template>
    <template v-else-if="!nftList.length">
      <h4>You don't have any collections. Create one <router-link to="/create_collection">here</router-link></h4>
    </template>
    <infinite-loading spinner="waveDots" @infinite="infiteListHandler" :identifier="infiniteId" :forceUseInfiniteWrapper="true"><span slot="no-more"></span>
      <span slot="no-results">
      </span>
    </infinite-loading>
  </Section>
</template>

<script>
import { mapState } from 'vuex'
import Section from '@/components/Layout/Section'
import InfiniteLoading from 'vue-infinite-loading'
import CollectionGrid from '@/components/Collection/CollectionGrid'
import CollectionCard from '@/components/Collection/CollectionCard'
import ToggleNSFW from '@/components/Common/Button/ToggleNSFW'

export default {
  name: 'UserCollection',
  components: { ToggleNSFW, CollectionCard, CollectionGrid, Section, InfiniteLoading },
  props: ['id'],
  data: () => {
    return {
      nftList: [],
      infiniteId: 0,
      increment: 9,
      nsfw: false,
      hasNsfw: false
    }
  },
  computed: {
    ...mapState([
      'userId',
      'address',
      'wallet'
    ]),
    me () {
      return this.id === this.userId || this.id === this.address
    },
    galleryId () {
      return this.id || this.userId
    },
    collectionsUrl () {
      return `/api/collections/${this.galleryId}`
    },
    createCollectionURL () {
      return '/create_collection'
    }
  },
  watch: {
    nsfw () {
      this.reload()
    }
  },
  methods: {
    reload () {
      this.nftList = []
      this.infiniteId++
    },
    infiteListHandler ($state) {
      const url = this.collectionsUrl
      this.$http.get(url, {
        params: {
          start: this.nftList.length,
          end: this.nftList.length + this.increment,
          nsfw: this.nsfw
        }
      }).then(({ data }) => {
        if (data?.metadata.length) {
          this.nftList.push(...data.metadata)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  },
  mounted () {
    this.$http.get(this.collectionsUrl, {
      params: {
        start: 0,
        end: 1,
        nsfw: true
      }
    }).then(({ data }) => {
      if (data?.metadata.length) this.hasNsfw = true
    })
  }
}
</script>

<style scoped>
.card-grid {
  grid-auto-rows: 285px;
}

.plus {
  width: 350px;
  height: 275px;
  margin: auto;
}

.plus .cover{
  width: 100%;
  height: 175px;
  border-radius: 20px;
  background-color: var(--pop-up-background);
  box-shadow: var(--light-shadow);
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plus:hover .cover{
  transform: scale(1.05);
}

.plus .cover .unicon {
  fill: var(--accent);
}

h4 {
  text-align: center;
}
</style>
