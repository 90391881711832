<template>
  <div class="market-body">
    <HotSales :asa-id="435335235" asa-name="CRSD"/>
    <Section>
      <template v-slot:header>
        <h3>Explore our artists</h3>
        <TextualButton to="/crsd/artists">See More</TextualButton>
      </template>
      <CollectionGrid>
        <CollectionCard v-for="(artist, index) in crescendoArtists" :key="artist.token_id" :collection="artist" :style="`animation-delay: ${100 + (index * 50)}ms`"/>
      </CollectionGrid>
    </Section>
  </div>
</template>

<script>
import HotSales from './HotSales'
import Section from '../Layout/Section'
import CollectionCard from '../Collection/CollectionCard'
import CollectionGrid from '../Collection/CollectionGrid'
import TextualButton from '../Common/Button/TextualButton'

export default {
  name: 'HDLBody',
  components: { TextualButton, CollectionGrid, CollectionCard, HotSales, Section },
  data () {
    return {
      crescendoArtists: []
    }
  },
  methods: {
    getData () {
      this.crescendoArtists = []
      const salesParams = {
        start: 0,
        end: 9,
        nsfw: false
      }
      this.$http('/api/collections/WXLHOLGBGWIF4WXCSART3QRIINPM5R3OSULYQ3TWWHSPAI2YHIJMVM4XRI', { params: salesParams }).then((response) => {
        if (response.data && response.data.metadata) {
          this.crescendoArtists = response.data.metadata
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style scoped>

</style>
