<template>
  <AppView id="cosg-submarket">
    <template v-slot:header>
    <div class="market-header">
      <a href="https://www.cosmicchamps.com/" target="_blank">
        <img src="@/assets/marketHeader/cosmic-logo.png" alt="cosg logo" class="cosg-logo"/>
      </a>
      <img src="@/assets/marketHeader/cosmic-background.png" alt="cosg background" class="cosg-background"/>

      <div class="title">
        <h3>COSG MARKET</h3>
      </div>
      <div class="sales-buttons" v-if="hasAccess">
        <router-link :to="`/shops/cosmicChamps`">
          <unicon name="estate"/>
          Home
        </router-link>
        <router-link :to="`/shops/cosmicChamps/sales`">
          <unicon name="usd-circle"/>
          Sales
        </router-link>
        <router-link :to="`/shops/cosmicChamps/auctions`">
          <unicon name="arrow-growth"/>
          Auctions
        </router-link>
        <router-link :to="`/shops/cosmicChamps/shuffles`">
          <unicon name="shuffle"/>
          Shuffles
        </router-link>
        <router-link :to="`/shops/cosmicChamps/seasons`">
          <unicon name="apps"/>
          Seasons
        </router-link>
        <router-link :to="`/shops/cosmicChamps/token`">
          <ASALogo class="no-margin" :asa-id="1065092715"/>
          COSG
        </router-link>
      </div>
    </div>
    </template>
    <router-view v-if="hasAccess"/>
  </AppView>
</template>

<script>
import 'hooper/dist/hooper.css'
import AppView from '@/components/Layout/AppView'
import { mapMutations, mapState } from 'vuex'
import ASALogo from '@/components/Assets/ASALogo'

export default {
  name: 'cosgSubmarket',
  components: { ASALogo, AppView },
  computed: {
    ...mapState(['address']),
    hasAccess () {
      // return this.address === 'TST2PF3APFSTOQS57TTILKU3IV5O4RCU7XK6SXBS4VFDRG2APTD3QOOF7I' || this.address === 'CCNFTNFTSXVYGCGP5EU7AMUTQLUZI6TXRS4XMW2LP4XARZH3LB6RZPUOQM'
      return true
    }
  },
  methods: {
    ...mapMutations(['setCustomClass'])
  },
  beforeDestroy () {
    this.setCustomClass({ customClass: '' })
  },
  mounted () {
    this.setCustomClass({ customClass: 'cosg' })
  }
}
</script>

<style scoped>
.market-header{
  height: 615px;
  display: flex;
  margin-top: 80px;
  flex-direction: column;
  text-align: center;
  margin-bottom: 0px;
}

.market-header .title{
  position: relative;
  z-index: 1;
}

.market-header .title h2{
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 8em;
  line-height: .95em;
  text-transform: uppercase;
  text-align: center;
  color: var(--text);
}

.market-header .title h2 a {
  text-decoration: none;
}

.market-header .title h3{
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 3em;
  font-weight: lighter;
  line-height: 1em;
  text-transform: uppercase;
  text-align: center;
  color: var(--textlight);
  margin: 0;
}

.market-header .cosg-logo{
  max-width: 730px;
  width: 90%;
  z-index: 1;
  position: relative;
}

.market-header .cosg-background{
  position: absolute;
  width: 100%;
  max-width: 1300px;
  min-width: 900px;
  z-index: 0;
  mix-blend-mode: lighten;
  opacity: .5;
}

.market-body {
  margin-top: 10px;
}

.sales-buttons {
  display: flex;
  position: relative;
  z-index: 5;
  margin-bottom: 20px;
}

.sales-buttons a {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  margin: 0px 15px;
}

.sales-buttons a .unicon, .sales-buttons a .asa-logo {
  margin-bottom: 5px;
  padding: 12px;
  background-color: #000;
  border-radius: 15px;
}

.sales-buttons a .asa-logo {
  width: 24px;
  height: 24px;
  font-size: x-large;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sales-buttons a:hover {
  color: var(--blue);
}

.sales-buttons a:hover .unicon {
  fill: var(--blue);
}

.sales-buttons a.router-link-exact-active {
  color: var(--blue);
}

.sales-buttons a.router-link-exact-active .unicon {
  fill: var(--blue);
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .market-header{
  height: 580px;
  margin-top: 40px;
}

.market-header img{
  margin-top: 10px;
}

.market-body {
  margin-top: 5px;
}

}
</style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100;400;700&family=Mr+Dafoe&display=swap');

#app.cosg {
  --blue: #499AAF;
  --red: #ca1b52;
  --error: var(--red);
  background: linear-gradient(180deg,#0a1423,#14285f 50%,#14a0b4);
  min-height: 100vh;
  font-family: 'Libre Franklin', sans-serif;
  transition: background .5s ease;
  --invert: 0;
  width: 100%;
  overflow-x: hidden;
}

#app.cosg.dark, #app.cosg {
  --background: #0a1423;
  --foreground: #11223b;
  --foreground-transparent: rgba(29,34,45,.5);
  --foreground-blur: rgba(29,34,45,.5);
  --nav-background: #0a1423;
  --fake-blur: #1c1d20eb;
  --headings: #202231;
  --text: #ffffff;
  --textlight: rgba(218, 228, 255, 0.6);
  --accent: var(--blue);
  --complement: #fff;
  --blur-overlay: rgba(14, 17, 23, .5);
  --light-shadow: rgba(0, 0, 0, .1) 0px 0px 20px;
  --shadow: rgba(0, 0, 0, .1) 0px 0px 2px, rgba(0, 0, 0, .3) 0px 0px 20px;
  --strong-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  --accent-shadow: 0 0 .5vw rgba(0,0,0,.1), 0 0 10vw rgba(202, 49, 112, 0.3);
  --gradient: linear-gradient(180deg,#96d2cd,#5ac3d7 51.04%,#14a0b4);
  --lightgradient: linear-gradient(180deg,#96d2cd,#5ac3d7 51.04%,#14a0b4);
  --background-alt: #1d222d;
  --foreground-alt: #0e1117;
  --invert: 1;
  --pop-up-background: rgba(14, 17, 23, 0.9);
}

#cosg-submarket .view-body .view-header {
  display: none;
}

#app.cosg .view-header{
  background: transparent;
}
</style>
