<template>
  <Section id="sales">
    <template v-slot:header>
    </template>
    <CardGrid>
      <ListCard
        v-for="(staking, index) in nfts"
        :key="`primary-${staking.token_id}`"
        :data="staking"
        :style="`animation-delay: ${100 + ((index % 4) * 50)}ms`"
        @startContract="(data) => newContract(data)"
      >
        <p>{{staking.pool}}<ASALogo :asa-id="staking.asa_id" /> left inside the pool</p>
      </ListCard>
    </CardGrid>
    <infinite-loading spinner="waveDots" @infinite="infiteListHandler" :identifier="`primary-${infiniteId}`" :forceUseInfiniteWrapper="true"><span slot="no-more"></span>
      <span slot="no-results">
        <template v-if="id">
            <h4>This user doesn't have any active staking</h4>
          </template>
          <template v-else>
            <h4>You don't have any active staking</h4>
          </template>
      </span>
    </infinite-loading>

    <ContractPopUp v-if='displayContractPopUp' :_kind="contractKind" :_price='contractPrice' :_is-algo=false :_tokenId='contractTokenId' @done='() => {}' @close="displayContractPopUp = false" :_isAlgo="isContractAlgo"></ContractPopUp>
  </Section>
</template>

<script>
import { mapState } from 'vuex'
import Section from '@/components/Layout/Section'
import InfiniteLoading from 'vue-infinite-loading'
import ListCard from '@/components/Cards/ListCard'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import CardGrid from '@/components/Cards/CardGrid'
import ASALogo from '../../Assets/ASALogo'

export default {
  name: 'UserStaking',
  components: { ASALogo, CardGrid, ListCard, Section, InfiniteLoading, ContractPopUp },
  props: ['id'],
  data: () => {
    return {
      nfts: [],
      infiniteId: 0,
      increment: 16,
      contractKind: '',
      contractPrice: 1,
      contractTokenId: 0,
      isContractAlgo: true,
      displayContractPopUp: false
    }
  },
  computed: {
    ...mapState([
      'userId',
      'address',
      'wallet'
    ]),
    galleryId () {
      return this.id || this.userId
    },
    stackingUrl () {
      return '/api/stack'
    }
  },
  methods: {
    reload () {
      this.nfts = []
      this.infiniteId++
    },
    newContract (data) {
      this.contractKind = data.kind
      this.contractPrice = data.price
      this.contractTokenId = data.tokenId
      this.isContractAlgo = true
      this.displayContractPopUp = true
    },
    infiteListHandler ($state) {
      this.$http.get(this.stackingUrl, {
        params: {
          start: this.nfts.length,
          end: this.nfts.length + this.increment
        }
      }).then(({ data }) => {
        console.log(data)
        if (data?.metadata.length) {
          this.nfts.push(...data.metadata)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  },
  beforeMount () {
    if (!['PZNGYF4Y25GGO674BW4CRDHFKOKHMHZXSFXIKMYPEJCQAUTDH52WV24XTY', 'TST2PF3APFSTOQS57TTILKU3IV5O4RCU7XK6SXBS4VFDRG2APTD3QOOF7I', 'IL2CNQOSJRI3XJNKQW3BJ2JICZ6RGI752NSJEQ52U6GKWBX5ZZYZ7MI7LQ', 'VXNVFUF5BTXJXM6GFRJ4J57KKAXNNT5AQ6IKI4RTYBFJGHOCVWYLJSU36Y'].includes(this.address)) {
      this.$router.push('/user/listings')
    }
  }
}
</script>

<style scoped>
.card-grid {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-auto-rows: 85px;
}

.margin-top {
  margin-top: 180px;
}
</style>
