import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function auctionClose (APIName, from, appId) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()
    const parsed = await Utils._callServer(`/api/auctiondebug/${appId}`, {}, 'GET')

    const accounts = []
    accounts.push(from)

    if (parsed.bid_address) {
      accounts.push(parsed.bid_address)
    }

    accounts.push(parsed.gems_address)
    accounts.push(parsed.royalties_address)

    const txn = algosdk.makeApplicationDeleteTxn(
      from, suggestedParams, appId, undefined, accounts, undefined, [parsed.nft_id], undefined)

    const signedTx = await API.sign(txn)
    const message = {
      signed_tx: [signedTx]
    }
    return Utils._callServer('/api/sdk/send_all', message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default auctionClose
