<template>
  <div>
    <Section v-if="collections.length > 0">
      <template v-slot:header>
        <h5>{{$t('gallery.collection')}}</h5>
        <router-link :to="`/gallery/${galleryId}/collections`" v-if=" collections.length > 5" class="see-more-button">
          {{$t('gallery.button.seeMore')}}
          <unicon name="angle-right" />
        </router-link>
      </template>
      <CollectionGrid v-if="collections.length && collections.length <= 3">
        <CollectionCard v-for="(collection, index) in collections" :key="collection.collection_id" :collection="collection" :style="`animation-delay: ${100 + ((index % 4) * 50)}ms`"/>
      </CollectionGrid>
      <hooper :settings="collectionSettings" style="height: 300px;"  v-else-if="collections.length > 3">
        <slide v-for="collection in collections"
               :key="collection.collection_id"
               :index="collection.collection_id"
        >
          <CollectionCard :collection="collection" />
        </slide>
        <hooper-navigation slot="hooper-addons" class="collection-navigation"></hooper-navigation>
        <hooper-pagination slot="hooper-addons"></hooper-pagination>
      </hooper>
    </Section>
    <Section v-if="creations.length > 0">
      <template v-slot:header>
        <h5>{{$t('gallery.creations')}}</h5>
        <router-link :to="`/gallery/${galleryId}/creations`" v-if=" creations.length > 4" class="see-more-button">
          {{$t('gallery.button.seeMore')}}
          <unicon name="angle-right" />
        </router-link>
      </template>
      <CardGrid>
        <Card v-for="(creation, index) in creations.slice(0, 4)" :key="creation.token_id" :data="creation" :style="`animation-delay: ${100 + (index * 50)}ms`"/>
      </CardGrid>
    </Section>
       <Section v-if="auctions.length">
         <template v-slot:header>
           <h5>New secondary auctions</h5> <!--<h5>{{$t('gallery.auctions')}}</h5>-->
           <router-link :to="`/gallery/${galleryId}/auctions`" class="see-more-button" v-if="auctions.length > 6">
             {{$t('gallery.button.seeMore')}}
             <unicon name="angle-right" />
           </router-link>
         </template>
         <ListCardGrid>
           <ListCard v-for="(auction, index) in auctions.slice(0, 6)" :key="auction.token_id" :data="auction" :style="`animation-delay: ${100 + (index * 50)}ms`" @startContract="(data) => newContract(data)"/>
         </ListCardGrid>
       </Section>
       <Section v-if="sales.length">
         <template v-slot:header>
           <h5>New secondary sales</h5> <!--<h5>{{$t('gallery.sales')}}</h5>-->
           <router-link :to="`/gallery/${galleryId}/sales`" class="see-more-button" v-if="sales.length > 6" >
             {{$t('gallery.button.seeMore')}}
             <unicon name="angle-right" />
           </router-link>
         </template>
         <ListCardGrid>
           <ListCard v-for="(sale, index) in sales.slice(0, 6)" :key="sale.token_id" :data="sale" :style="`animation-delay: ${100 + (index * 50)}ms`" @startContract="(data) => newContract(data)"/>
         </ListCardGrid>
       </Section>
    <Section :key="`ownedNFT${reload}`" v-if="ownedNFT.length">
      <template v-slot:header>
        <h5>{{$t('gallery.owned')}}</h5>
        <router-link :to="`/gallery/${galleryId}/ownedNft`" class="see-more-button" v-if="ownedNFT.length > 6" >
          {{$t('gallery.button.seeMore')}}
          <unicon name="angle-right" />
        </router-link>
      </template>
      <CardGrid>
        <Card v-for="(nft, index) in ownedNFT.filter((n)=>n).slice(0, 6)" :key="nft.token_id" :data="nft" :style="`animation-delay: ${100 + (index * 50)}ms`" @startContract="(data) => newContract(data)"/>
      </CardGrid>
    </Section>
    <!--<Section v-if="gallery && gallery.description">
      <template v-slot:header>
        <h5>{{$t('gallery.about')}} {{galleryId}}</h5>
      </template>
      <p>{{gallery.description}}</p>
    </Section>-->
    <Section v-if="gallery && gallery.email && false" class="about-container">
      <p>
        <unicon name="envelope" height="20px" width="20px"/>
        {{$t('gallery.email')}}:
        <template v-if="gallery.email.slice(0,5) === 'https'">
          <a :href="gallery.email" target="_blank">{{gallery.email}}</a>
        </template>
        <template v-else>
          <a :href="'mailto:' + gallery.email">{{gallery.email}}</a>
        </template>
      </p>
   </Section>
    <ContractPopUp v-if='displayContractPopUp' :_kind="contractKind" :_price='contractPrice' :_tokenId='contractTokenId' @done='forceReload' @close="displayContractPopUp = false"></ContractPopUp>
  </div>
</template>

<script>
import { Hooper, Navigation as HooperNavigation, Pagination as HooperPagination, Slide } from 'hooper'
import 'hooper/dist/hooper.css'
import { mapState } from 'vuex'
import Section from '@/components/Layout/Section'
import ListCard from '@/components/Cards/ListCard'
import CardGrid from '@/components/Cards/CardGrid'
import Card from '@/components/Cards/Card'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import ListCardGrid from '@/components/Cards/ListCardGrid'
import CollectionCard from '@/components/Collection/CollectionCard'
import CollectionGrid from '@/components/Collection/CollectionGrid'

export default {
  name: 'GalleryBody',
  components: { CollectionGrid, CollectionCard, ListCardGrid, ContractPopUp, Section, Hooper, Slide, HooperPagination, HooperNavigation, ListCard, CardGrid, Card },
  props: {
    id: String
  },
  data () {
    return {
      increment: 7,
      gallery: null,
      hasCover: false,
      auctions: [],
      sales: [],
      collections: [],
      bids: [],
      ownedNFT: [],
      creations: [],
      username: '',
      reload: 0,
      displayContractPopUp: false,
      contractState: 'needPrice',
      contractMessage: '',
      contractKind: 'tip',
      contractPrice: 1,
      contractTokenId: 0,
      collectionSettings: {
        itemsToShow: 1,
        centerMode: true,
        infiniteScroll: true,
        autoPlay: true,
        wheelControl: false,
        breakpoints: {
          1200: {
            itemsToShow: 3
          },
          950: {
            itemsToShow: 2.5
          },
          750: {
            itemsToShow: 2
          }
        }
      }
    }
  },
  watch: {
    galleryId () {
      this.gallery = null
      this.hasCover = false
      this.username = ''
      this.$http.get(this.apiUrl).then(response => {
        if (response.data) {
          this.gallery = response.data
        }
      }).catch(err => {
        console.log(err)
      })
      this.fetchNFTData()
    }
  },
  computed: {
    ...mapState([
      'userId',
      'wallet'
    ]),
    galleryId () {
      return this.id
    },
    isAddress () {
      return this.galleryId.length === 58
    },
    apiUrl () {
      return `/api/account/info/${this.galleryId}`
    },
    auctionsUrl () {
      return `/api/auctions/${this.galleryId}`
    },
    salesUrl () {
      return `/api/sales/${this.galleryId}`
    },
    collectionsUrl () {
      return `/api/collections/${this.galleryId}`
    },
    bidsUrl () {
      return '/api/auctions/bid'
    },
    ownedNFTUrl () {
      return `/api/gallery/${this.galleryId}`
    },
    creationUrl () {
      return `/api/creation/${this.galleryId}`
    },
    authUrl () {
      return '/authenticate'
    },
    createCollectionURL () {
      return '/create_collection'
    }
  },
  methods: {
    forceReload () {
      this.fetchNFTData()
      this.reload++
    },
    newContract (data) {
      this.contractKind = data.kind
      this.contractPrice = data.price
      this.contractTokenId = data.tokenId
      this.displayContractPopUp = true
    },
    fetchNFTData () {
      this.auctions = []
      this.sales = []
      this.collections = []
      this.bids = []
      this.ownedNFT = []
      this.creations = []
      this.$http.get(this.auctionsUrl, {
        params: {
          start: 0,
          end: this.increment,
          sort: 'new'
        }
      }).then(({ data }) => {
        this.auctions.push(...data.auctions)
      })

      this.$http.get(this.salesUrl, {
        params: {
          start: 0,
          end: this.increment,
          sort: 'new'
        }
      }).then(({ data }) => {
        this.sales.push(...data.sales)
      })

      this.$http.get(this.collectionsUrl, {
        params: {
          start: 0,
          end: this.increment,
          sort: 'new'
        }
      }).then(({ data }) => {
        this.collections.push(...data.metadata)
      })
      this.$http.get(this.bidsUrl, {
        params: {
          start: 0,
          end: this.increment,
          sort: 'new'
        }
      }).then(({ data }) => {
        this.bids.push(...data.auctions)
      })
      this.$http.get(this.ownedNFTUrl, {
        params: {
          start: 0,
          end: this.increment,
          sort: 'new'
        }
      }).then(({ data }) => {
        this.ownedNFT.push(...data.metadata.filter(x => x !== null))
      })
      this.$http.get(this.creationUrl, {
        params: {
          start: 0,
          end: this.increment,
          sort: 'new'
        }
      }).then(({ data }) => {
        this.creations.push(...data.metadata)
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.galleryId) {
        this.$router.push(this.authUrl)
      }
      this.$http.get(this.apiUrl).then(response => {
        if (response.data) {
          this.gallery = response.data
        }
      }).catch(err => {
        console.log(err)
      })
      this.fetchNFTData()
    })
  }
}
</script>

<style scoped>
h1 {
  color: var(--text);
}

h5 {
  font-size: medium;
  margin: 0;
}

a {
  margin-left: 10px;
  text-decoration: none;
  color: var(--textlight)
}

a:hover {
  color: var(--text)
}

button {
  z-index: 2;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .auction-sale-container {
    padding: 25px;
    box-sizing: border-box;
    width: 100%;
  }

  .about-container p{
    justify-content: center;
  }
}

.about-container p {
  display: flex;
  align-items: center;
}

.about-container p .unicon {
  fill: var(--textlight);
  position: relative;
  top: -2px;
  margin-right: 5px;
}

.head.light .unicon {
  fill: var(--text);
  opacity: 0.8;
}

.head.light .unicon:hover {
  opacity: 1;
}

.full-width {
  flex-grow: 1;
}

.see-more-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: var(--text);
  font-weight: bold;
}

.see-more-button .unicon{
  padding: 3px;
  border-radius: 50%;
  background: var(--accent);
  fill: var(--complement);
  margin-left: 5px;
  transition: transform .2s ease;
}

.see-more-button:hover .unicon{
  transform: translateX(5px);
}
</style>
