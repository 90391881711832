<template>
<AppView id="settings" v-if="gallery">
  <h1>Settings</h1>
  <div class="head">
    <Banner :id="gallery.account_id" :version="gallery.v_banner"/>
    <UserPicture :address="gallery.addresses[0]" :account-id="gallery.account_id" :version="gallery.v_pp"/>
  </div>
  <div class="button-container" v-if="!isAddress">
    <ImageUpload :api="'/api/account/update/pp'" @done="gallery.v_pp += 1" :_gifAccepted="false">
      {{$t('settings.changePP')}}
      <span class="smaller-text">16:9</span>
    </ImageUpload>
    <ImageUpload :api="'/api/account/update/cover'" @done="gallery.v_banner += 1" :_gifAccepted="false">
      {{$t('settings.changeBanner')}}
      <span class="smaller-text">1150x400px</span>
      <span class="smaller-text">(cropped to 340x400px for mobile)</span>
    </ImageUpload>
  </div>
  <p v-else>{{$t('settings.ppExplain')}}</p>

  <h3>General Settings</h3>
  <div class="two-columns">
    <span>{{$t('settings.username')}}:</span>
    <span>
      <input v-model="username" :class="{'invalid':!usernameValid && error}" :placeholder="galleryId" type="text" maxlength="25"
             @change="() => { this.usernameChanged = true}">
    </span>
    <template v-if="!isAddress">
      <span>{{$t('settings.about')}}:</span>
      <span>
        <textarea maxlength="500" v-model="about" :class="{'invalid':!aboutValid && error}"
                :placeholder="$t('settings.placeholder.about')"  @input="() => { this.aboutChanged = true }"></textarea>
      </span>
      <span>Royalties: <br> <span class="smaller-text">0 - 10%</span></span>
      <span>
        <IntInput
          :default-value="royalties"
          :min="0" :max="10"
          :step="1" class="small"
          @val="(val) => { this.royalties= val; this.royaltiesChanged = true }"/>
      </span>
    </template>
  </div>

  <h3>Social Medias</h3>
  <div class="two-columns" v-if="!isAddress">
    <span><unicon name="instagram" height="20px" width="20px"/></span>
    <span>
        <input maxlength="100" v-model="socialMedia.instagram"
               :class="{'invalid':socialMedia.instagram.length && !/https:\/\//.test(socialMedia.instagram) && error}"
               placeholder="Instagram" type="text"  @input="() => { this.socialMediaChanged = true }" >
    </span>

    <span><unicon name="twitter" height="20px" width="20px"/></span>
    <span>
        <input maxlength="100" v-model="socialMedia.twitter"
               :class="{'invalid':socialMedia.twitter.length && !/https:\/\//.test(socialMedia.twitter) && error}"
               placeholder="Twitter" type="text"  @input="() => { this.socialMediaChanged = true }">
    </span>

    <span><unicon name="telegram" height="20px" width="20px"/></span>
    <span>
        <input maxlength="100" v-model="socialMedia.telegram"
               :class="{'invalid':socialMedia.telegram.length && !/https:\/\//.test(socialMedia.telegram) && error}"
               placeholder="Telegram" type="text"  @input="() => { this.socialMediaChanged = true }">
    </span>

    <span><unicon name="reddit-alien-alt" height="20px" width="20px"/></span>
    <span>
        <input maxlength="100" v-model="socialMedia.reddit"
               :class="{'invalid':socialMedia.reddit.length && !/https:\/\//.test(socialMedia.reddit) && error}"
               placeholder="Reddit" type="text"  @input="() => { this.socialMediaChanged = true }">
    </span>

    <span><unicon name="facebook-f" height="20px" width="20px"/></span>
    <span>
        <input maxlength="100" v-model="socialMedia.facebook"
               :class="{'invalid':socialMedia.facebook.length && !/https:\/\//.test(socialMedia.facebook) && error}"
               placeholder="Facebook" type="text"  @input="() => { this.socialMediaChanged = true }">
    </span>

    <span><unicon name="discord" height="20px" width="20px"/></span>
    <span>
        <input maxlength="100" v-model="socialMedia.discord"
               :class="{'invalid':socialMedia.discord.length && !/https:\/\//.test(socialMedia.discord) && error}"
               placeholder="Discord" type="text"  @input="() => { this.socialMediaChanged = true }">
    </span>

    <span><unicon name="link-h" height="20px" width="20px"/></span>
    <span>
        <input maxlength="100" v-model="socialMedia.website"
               :class="{'invalid':socialMedia.website.length && !/https:\/\//.test(socialMedia.website) && error}"
               placeholder="Website" type="text"  @input="() => { this.socialMediaChanged = true }">
    </span>

    <span><unicon name="envelope" height="20px" width="20px"/></span>
    <span>
      <input maxlength="100" v-model="socialMedia.email"
             placeholder="E-mail" type="email"  @input="() => { this.socialMediaChanged = true }">
    </span>
  </div>

  <h3>Other Settings</h3>
  <div class="two-columns" v-if="!isAddress">
    <span>AlgoGlyph</span>
    <span>
      <input maxlength="100" v-model="algoGlyph" :class="{'invalid':!algoGlyphValid && error}"
             :placeholder="$t('settings.placeholder.algoglyph')" type="email"  @input="() => { this.algoGlyphChanged = true }">
    </span>
    <p></p>
    <p class="underline" v-html="$t('algoglyph.dontHaveOne')"></p>
    <div class="glyph-container" v-if="algoGlyph">
      <AlgoGlyph :tx-id="algoGlyph" :color="dark ? '#ffffff' : '#000000'"/>
    </div>
  </div>
  <div class="button-container">
    <h5 v-if="error" class="error">
      <unicon height="24px" name="exclamation-octagon"/>
      {{ error }}
    </h5>
    <Button @click.native="update" v-if="!isLoading">{{$t('settings.button.update')}}</Button>
    <DotLinearLoader v-else/>
    <router-link to="/user">
      <Button class="secondary">{{$t('common.button.back')}}</Button>
    </router-link>
    <Button class="error-btn" v-if="false">{{$t('settings.button.delete')}}</Button>
  </div>
</AppView>
  <Loader v-else/>
</template>

<script>
import Button from '@/components/Common/Button/Button'
import Banner from '@/components/User/Banner'
import { mapMutations, mapState } from 'vuex'
import ImageUpload from '@/components/Create/ImageUpload'
import DotLinearLoader from '@/components/Common/Spinner/DotLinearLoader'
import AlgoGlyph from '@/components/Assets/AlgoGlyph'
import UserPicture from '@/components/User/UserPicture'
import AppView from '@/components/Layout/AppView'
import Loader from '@/components/Common/Spinner/Loader'
import IntInput from '../../components/Common/Input/IntInput'

export default {
  name: 'Settings',
  components: { IntInput, Loader, AppView, UserPicture, AlgoGlyph, DotLinearLoader, ImageUpload, Banner, Button },
  props: {
    id: String
  },
  data () {
    return {
      error: false,
      username: '',
      usernameChanged: false,
      about: '',
      aboutChanged: false,
      socialMedia: {
        instagram: '',
        twitter: '',
        telegram: '',
        reddit: '',
        facebook: '',
        website: '',
        email: '',
        discord: ''
      },
      socialMediaChanged: false,
      algoGlyph: '',
      algoGlyphChanged: false,
      royalties: 0,
      royaltiesChanged: false,
      reloadPP: 0,
      reloadCover: 0,
      isLoading: false,
      gallery: null
    }
  },
  computed: {
    ...mapState([
      'userId',
      'dark'
    ]),
    galleryId () {
      return this.id || this.userId
    },
    isAddress () {
      return this.galleryId.length === 58
    },
    apiUrl () {
      return `/api/account/info/${this.galleryId}`
    },
    authUrl () {
      return '/authenticate'
    },
    usernameValid () {
      return /^.{0,25}$/.test(this.username) && !this.username.includes(' ') && !this.username.includes('.')
    },
    aboutValid () {
      return /^.{0,500}$/.test(this.description)
    },
    algoGlyphValid () {
      return /^.{0,100}$/.test(this.algoGlyph)
    },
    socialMediaValid () {
      for (const [k, v] of Object.entries(this.socialMedia)) {
        if (k !== 'email' && v.length && !/https:\/\/.*/.test(v)) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    ...mapMutations(['login']),
    displayError () {
      if (!/^.{0,25}$/.test(this.username)) {
        this.error = 'Username cannot be longer than 25 characters'
      } else if (this.username.includes(' ')) {
        this.error = 'Username cannot includes spaces'
      } else if (this.username.includes('.')) {
        this.error = 'Username cannot includes dots'
      } else if (!this.socialMediaValid) {
        this.error = 'Links should start with https://'
      }
    },
    update () {
      if (!this.usernameValid || !this.socialMediaValid) {
        this.displayError()
        return
      }

      let N = 0
      if (this.usernameChanged) N++
      if (this.aboutChanged) N++
      if (this.socialMediaChanged) N++
      if (this.algoGlyphChanged) N++
      if (this.royaltiesChanged) N++

      let n = 0

      if (this.usernameChanged) {
        this.isLoading = true
        this.$http
          .post('/api/account/update/username', { username: this.username })
          .then((response) => {
            this.login({ username: this.username })
            this.usernameChanged = false
            n++
            if (n === N) this.$router.push('/user')
          })
          .catch(err => {
            this.isLoading = false
            this.error = this.$t('error.' + err.response.data.code, (err.response.data.parameters) ? err.response.data.parameters : {})
            console.log(err)
          })
      }
      if (this.aboutChanged) {
        this.isLoading = true
        this.$http
          .post('/api/account/update/description', { description: this.about })
          .then(() => {
            this.aboutChanged = false
            n++
            if (n === N) this.$router.push('/user')
          })
          .catch((err) => {
            this.isLoading = false
            this.error = this.$t('error.' + err.response.data.code)
            console.log(err)
          })
      }
      if (this.socialMediaChanged) {
        this.isLoading = true
        this.$http
          .post('/api/account/update/email', { email: JSON.stringify(this.socialMedia) })
          .then(() => {
            this.socialMediaChanged = false
            n++
            console.log('allo')
            if (n === N) this.$router.push('/user')
          })
          .catch((err) => {
            this.isLoading = false
            this.error = JSON.stringify(err)
            console.log(err)
          })
      }
      if (this.algoGlyphChanged) {
        this.isLoading = true
        this.$http
          .post('/api/glyph/update', { glyph: this.algoGlyph })
          .then(() => {
            this.algoGlyphChanged = false
            n++
            if (n === N) this.$router.push('/user')
          })
          .catch((err) => {
            this.isLoading = false
            this.error = JSON.stringify(err)
            console.log(err)
          })
      }
      if (this.royaltiesChanged) {
        this.isLoading = true
        this.$http
          .post('/api/account/update/royalties', { royalties: this.royalties })
          .then((response) => {
            this.royaltiesChanged = false
            n++
            if (n === N) this.$router.push('/user')
          })
          .catch(err => {
            this.isLoading = false
            this.error = this.$t('error.' + err.response.data.code, (err.response.data.parameters) ? err.response.data.parameters : {})
            console.log(err)
          })
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.galleryId) {
        this.$router.push(this.authUrl)
      }
      this.$http.get(this.apiUrl).then(({ data }) => {
        if (data) {
          this.gallery = data
          this.about = data.description
          if (data.email) {
            const temp = JSON.parse(data.email)
            for (const key of Object.keys(temp)) {
              this.$set(this.socialMedia, key, temp[key])
            }
          }
          this.royalties = (data.royalties) ? data.royalties : 0
          this.algoGlyph = data.glyph_id
        }
      }).catch(err => {
        console.log(err)
      })
    })
  }
}
</script>

<style scoped>
#settings h1{
  text-align: center;
}

a {
  text-decoration: none;
}

h3 {
  max-width: 500px;
  margin: auto;
  margin-top: 50px;
  color: var(--textlight);
}

button {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0;
}

.head {
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: -50px;
}

.head .user-picture {
  top: -50px;
}

.head .banner{
  margin: 0;
}

.two-columns {
  display: grid;
  min-width: 500px;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  padding-right: 75px;
}

.two-columns > *:nth-child(odd) {
  align-self: center;
  justify-self: right;
}

.two-columns > *:nth-child(even) {
  align-self: center;
  justify-self: left;
  min-width: 300px;
}

.glyph-container {
  position: relative;
  height: 200px;
  width: 100%;
  grid-column: 2;
}

.underline {
  position: relative;
  top: -25px;
  text-align: center;
  font-size: small;
}

.smaller-text {
  text-align: center;
  font-size: smaller;
  width: 100%;
  display: block;
  color: var(--textlight);
}

.button-container {
  position: static;
  margin: 0;
  padding: 0;
}

@media only screen
and (max-device-width: 1200px) {
  .head {
    width: 90%;
    margin: auto;
  }

  .two-columns {
    margin: auto;
    margin-top: 20px;
    display: flex;
    min-width: 0;
    max-width: 300px;
    flex-direction: column;
    padding: 25px;
  }

  .two-columns > *:nth-child(odd) {
    align-self: flex-start;
    margin-top: 20px;
  }

  .two-columns > *:nth-child(even) {
    min-width: 300px;
  }
  .underline {
    top: -50px;
  }

  .button-container {
    margin: 0 0 50px 0;
  }
}
</style>
