import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: null,
    isAdult: false,
    wallet: null,
    address: null,
    pendingVotes: 0,
    governanceStats: {},
    dark: false,
    customClass: null,
    nsfw: false,
    routeBeforeAuthenticate: '/',
    sortOptions: {}
  },
  mutations: {
    login (state, payload) {
      if (payload.username) {
        state.userId = payload.username
      } else if (payload.address) {
        state.userId = payload.address
      }
      state.address = payload.address
    },
    logout (state, payload) {
      state.userId = null
      state.wallet = null
      state.address = null
    },
    setWallet (state, payload) {
      state.wallet = payload.wallet
    },
    setAdult (state, payload) {
      state.isAdult = payload.isAdult
    },
    updatePendingVotes (state, payload) {
      Vue.set(state.governanceStats, 'uncommited_votes', payload)
    },
    updateGovernanceStats (state, payload) {
      state.governanceStats = payload
    },
    toggleNsfw (state, payload) {
      state.nsfw = !state.nsfw
    },
    toggleDark (state, payload) {
      state.dark = !state.dark
    },
    setDark (state, payload) {
      state.dark = payload.dark
    },
    setCustomClass (state, payload) {
      state.customClass = payload.customClass
    },
    setRouteBeforeAuthenticate (state, payload) {
      state.routeBeforeAuthenticate = payload.routeBeforeAuthenticate
    },
    setSortOptions (state, payload) {
      state.sortOptions = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
