import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function sell (APIName, from, tokenId, price, supply, asaId, isPrivate) {
  try {
    const API = Utils._getAPI(APIName)
    let message = {
      token_id: tokenId,
      asa_id: asaId,
      amount: price
    }
    const parsed = await Utils._callServer('/api/sale/contract/info', message, 'GET')
    const suggestedParams = await Utils.getParams()

    const appArgs = []
    appArgs.push(algosdk.decodeAddress(from).publicKey)
    appArgs.push(Utils.longToByteArray(tokenId))
    appArgs.push(Utils.longToByteArray(parsed.start))
    appArgs.push(Utils.longToByteArray(parsed.micro_amount))
    appArgs.push(algosdk.decodeAddress(parsed.gems_address).publicKey)
    appArgs.push(algosdk.decodeAddress(parsed.creator).publicKey)
    appArgs.push(Utils.longToByteArray(parsed.fees))
    appArgs.push(Utils.longToByteArray(parsed.royalties))
    if (asaId !== 1) {
      appArgs.push(Utils.longToByteArray(asaId))
    }

    const application =
      algosdk.makeApplicationCreateTxn(
        from,
        suggestedParams,
        algosdk.OnApplicationComplete.NoOpOC,
        new Uint8Array(Buffer.from(parsed.approval_program, 'base64')),
        new Uint8Array(Buffer.from(parsed.clear_state_program, 'base64')),
        // Utils._base64ToArrayBuffer(parsed.approval_program),
        // Utils._base64ToArrayBuffer(parsed.clear_state_program),
        0, 0,
        7, 7,
        appArgs
      )

    const signedApp = await API.sign(application)

    message = {
      number: supply,
      signed_tx: signedApp
    }

    const parsed2 = await Utils._callServer('/api/sale/app/create', message)
    // {"app_id", "sender", "amt"}
    console.log('Sell - check', parsed2)

    const appAddr = algosdk.getApplicationAddress(parsed2.app_id)
    console.log(from, appAddr)
    const txns = []

    txns.push(
      algosdk.makePaymentTxnWithSuggestedParams(
        from, appAddr, parsed2.amt, undefined, undefined, suggestedParams)
    )

    const foreignAssets = [tokenId]
    if (asaId !== 1) {
      foreignAssets.push(asaId)
    }
    txns.push(
      algosdk.makeApplicationNoOpTxn(from, suggestedParams, parsed2.app_id, [new TextEncoder().encode('setup')], undefined, undefined, foreignAssets, undefined, undefined, undefined)
    )

    txns.push(
      algosdk.makeAssetTransferTxnWithSuggestedParams(
        from, appAddr,
        undefined, undefined,
        supply, undefined,
        tokenId, suggestedParams)
    )

    const signedTxs = await API.signGroup(txns, txns.map(x => true))
    message = {
      token_id: tokenId,
      app_id: parsed2.app_id,
      asa_id: asaId,
      number: supply,
      price: price,
      signed_tx: signedTxs,
      private: isPrivate
    }
    console.log('Sell - Validate', signedTxs)
    return Utils._callServer('/api/transaction/sale/create/validate', message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default sell
