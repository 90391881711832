<template>
  <AppView v-if="!isAdult && nft.nsfw">
    <NSFWVerificationPopUp @no="$router.push('/')"/>
  </AppView>
  <AppView id="nft" v-else-if="nft.title && !isDeleted" :key="reload">
    <div class="nft-grid">
      <div class="details">
        <button class="favorite-button" @click="toggleFollow" v-if="address !== userId">
          <unicon name="favorite" width="30px" height="30px" v-if="!nft.is_favorite"/>
          <unicon name="favorite" width="30px" height="30px" icon-style="monochrome" v-else/>
        </button>
        <ul class="auction-timeout" v-if="displayBidHistory">
          <template v-if="isExpired">
            <li>
              {{$t('nft.footnote.ended')}}
            </li>
          </template>
          <template v-else>
            <li v-if="timeRemaining.days">{{ timeRemaining.days }}d</li>
            <li>{{ timeRemaining.hours | zeroPad }}</li>
            <li>{{ timeRemaining.minutes | zeroPad }}</li>
            <li>{{ timeRemaining.seconds | zeroPad }}</li>
          </template>
        </ul>
        <div class="nft-image-container" @click="toggleFocus">
          <video v-if="nft.content_type === 'video'" ref="video" :src="nft.url" width="100%" height="100%" :class="{loading: !videoLoaded}" autoplay loop>
            Your browser does not support the video tag.
          </video>
          <audio v-if="nft.content_type === 'audio'" ref="audio" :src="nft.url" width="100%" height="100%" :class="{loading: !audioLoaded}" loop>
            Your browser does not support the audio tag.
          </audio>
          <div class="spinner-wrapper" v-if="(nft.content_type === 'video'  && !videoLoaded) || (nft.content_type === 'audio' && !audioLoaded) || (nft.content_type === 'model/gltf-binary' && !modelLoaded)">
            <Spinner/>
          </div>
          <div class="spinner-wrapper" v-if="!focused && (videoLoaded || audioLoaded)">
            <unicon name="play" width="30px" height="30px" v-if="videoLoaded"/>
            <unicon name="music" width="30px" height="30px" v-else/>
          </div>
          <div class="spinner-wrapper pause" v-if="focused && ( videoLoaded || audioLoaded)">
            <unicon name="pause" width="30px" height="30px"/>
          </div>
          <div class="model-wrapper" v-if="nft.content_type === 'model/gltf-binary' && modelLoaded">
            <model-gltf
              :rotation="rotation"
              :src="modelLoaded"
              :lights="[
                                    {
            type: 'HemisphereLight',
            position: { x: 0, y: 1, z: 0 },
            skyColor: 0xffffff,
            groundColor: 0xfafafa,
            intensity: .8,
          },
          {
            type: 'DirectionalLight',
            position: { x: 1, y: 1, z: 1 },
            color: 0xffffff,
            intensity: 0.8,
          }]"
              :outputEncoding="sRGBEncoding"
            />
          </div>
          <img :src="thumbnailUrl" :alt="id" class="nft-image" v-if="(nft.content_type !== 'video' && nft.content_type !== 'model/gltf-binary') || (nft.content_type === 'video'  && !videoLoaded) || (nft.content_type === 'model/gltf-binary' && !modelLoaded)"/>
          <img v-if="fullsizeImage" :alt="`${id} FullSize`" :src="fullsizeImage" class="full-size"/>
          <AlgoGlyph :color="nft.glyph_color" :txId="nft.glyph_id" v-if="nft.glyph_id && displayGlyph"/>
        </div>
        <div class="header">
          <div v-if="nft.glyph_id"
               class="display-glyph-button"
               :class="(displayGlyph) ? 'selected': '' "
               @click="() => {displayGlyph = !displayGlyph}">
            <unicon name="tag-alt"/>
          </div>

          <div class="nft-info">
            <div class="nft-title">
              <h3>{{ nft.title }}</h3>
              <h5>#{{ id }}</h5>
            </div>
            <div class="nft-links">
              <h3 class="nft-author">by
                <router-link :to="galleryUrl">
                  <span v-if="nft.username">@{{nft.username}}</span>
                  <Address v-else :address="nft.address"/>
                </router-link>
              </h3>
              <h5>
                <a :href="nft.url" target="_blank" class="external-link" v-if="nft.url">
                  <unicon class="social-icons" name="arrow-up-right" height="11px" width="11px" fill="rgba(44, 62, 80, 0.51)"/>
                  Original
                </a>
                <a :href="nft.ipfs_link" target="_blank" class="external-link" v-else>
                  <unicon class="social-icons" name="arrow-up-right" height="11px" width="11px" fill="rgba(44, 62, 80, 0.51)"/>
                  IPFS
                </a>
              </h5>
              <h5>
                <a :href="`https://www.allo.info/asset/${nft.token_id}`"  target="_blank" class="external-link">
                  <unicon class="social-icons" name="arrow-up-right" height="11px" width="11px" fill="rgba(44, 62, 80, 0.51)"/>
                  Allo
                </a>
              </h5>
              <h5>
                <a :href="`https://www.nftexplorer.app/asset/${nft.token_id}`" target="_blank" class="external-link">
                  <unicon class="social-icons" name="arrow-up-right" height="11px" width="11px" fill="rgba(44, 62, 80, 0.51)"/>
                  NFTx
                </a>
              </h5>
              <h5 v-if="nft.glyph_id">
                <a :href="`https://allo.info/tx/${nft.glyph_id}`" target="_blank" class="external-link">
                  <unicon class="social-icons" name="arrow-up-right" height="11px" width="11px" fill="rgba(44, 62, 80, 0.51)"/>
                  AlgoGlyph
                </a>
              </h5>
            </div>
          </div>

          <div class="price-cta">
            <div class="labels">
              <Tooltip>
                <template>
                  <unicon v-if="isStackable" name="jackhammer"/>
                </template>
                <template v-slot:tooltipText>
                  NFT staking available.
                </template>
              </Tooltip>
            </div>
            <h3 class="nft-price" v-if="nft.price">{{ nft.price }}<ASALogo :asaId="nft.asa_id"/></h3>
            <template v-if="this.nft.action">
              <Button @click.native="() => contractHandler('bid')" v-if="this.nft.action.includes('bid')">{{$t('nft.button.bid')}}</Button>
              <Button @click.native="() => contractHandler('claim')" v-if="this.nft.action.includes('claim')">{{$t('nft.button.claim')}}</Button>
              <Button @click.native="() => contractHandler('auctionToSales')" class="error-btn" v-if="this.nft.action.includes('sell_back')">{{$t('nft.button.sell')}}</Button>
              <Button @click.native="() => contractHandler('buy')" v-if="this.nft.action.includes('buy')">{{$t('nft.button.buy')}}</Button>
              <Button @click.native="() => contractHandler('close')" v-if="this.nft.action.includes('close')">{{$t('nft.button.close')}}</Button>
              <Button @click.native="() => contractHandler('sell')" class="error-btn" v-if="this.nft.action.includes('sell')">{{$t('nft.button.sell')}}</Button>
              <Button @click.native="() => contractHandler('cancel')" class="error-btn" v-if="this.nft.action.includes('cancel')">{{$t('nft.button.cancel')}}</Button>
              <Button @click.native="() => contractHandler('update')" v-if="this.nft.action.includes('update')">{{$t('nft.button.update')}}</Button>
              <Button @click.native="() => contractHandler('swap')" v-if="this.nft.action.includes('swap')">{{$t('nft.button.swap')}}</Button>
              <Button @click.native="() => contractHandler('makeOffer')" v-if="this.nft.action.includes('offer')">Offer</Button>
              <Button @click.native="() => contractHandler('acceptOffer')" v-if="this.nft.action.includes('accept')">Accept</Button>
              <Button @click.native="() => contractHandler('closeOffer')" class="error-btn" v-if="this.nft.action.includes('cancel_offer')">Cancel</Button>
            </template>
          </div>
        </div>
        <p>❤️{{ $t('nft.footnote.royalties', { royalties: this.nft.royalties}) }}</p>
        <p v-if='nft.description' class="nft-description">
          <span v-for="[i, paragraph] of nft.description.split('\\n').entries()" :key="i">
            {{ paragraph }}<br>
          </span>
        </p>

        <p v-if="nft.crescendo > 0 && nft.number > 0">
          <router-link class="crescendo-button" :to="`/crsd/shipping-form/${id}`" v-if="nft.crescendo === 'physical'">
            <unicon name="box"/><span>Order your physical item</span>
          </router-link>
          <router-link class="crescendo-button" :to="`/crsd/service-form/${id}`" v-if="nft.crescendo === 'custom_service' || nft.crescendo === 'service'">
            <unicon name="file-edit-alt"/><span>Request a service</span>
          </router-link>
        </p>

        <ul v-if="attributes > 0" class="arc69-attributes">
          <li v-for="attribute in attributes" :key="attribute.key">
            <span v-if="attribute.key && attribute.key.length">{{attribute.key}}:</span>
            <span>{{attribute.value}}</span>
            <div>{{attribute.rarity}}%</div>
          </li>
        </ul>
        <ul class="nft-tags" v-if="nft.tags && nft.tags.length">
          <li v-for="tag in nft.tags" :key="tag">{{ tag }}</li>
        </ul>

        <p class="supply-informations">
          <span>
            <b>Total supply:</b> {{nft.max_supply}}
          </span>
          <span v-if="nft.buy_supply">
            <b>Up for sale:</b> {{nft.buy_supply}}
          </span>
          <span>
            <b>Owned amount:</b> {{nft.number}}
          </span>
        </p>

        <div class="danger-zone">
          <router-link :to="`/nft_edit/${id}`" v-if="canEdit">
            <Button class="report-btn blue">
              <unicon class="social-icons" name="image-edit" height="20px" width="20px"/>
              <span class="lifted-text">{{$t('nft.button.editMetadata')}}</span>
            </Button>
          </router-link>
          <Button @click.native="refreshMetada" class="report-btn blue">
            <unicon class="social-icons" name="sync" height="20px" width="20px"/>
            <span class="lifted-text">{{$t('nft.button.refreshMetadata')}}</span>
          </Button>
          <Button @click.native="deleteASA" class="report-btn" v-if="this.nft.action.includes('delete')">
            <unicon class="social-icons" name="image-times" height="20px" width="20px"/>
            <span class="lifted-text">{{$t('nft.button.delete')}}</span>
          </Button>
          <Button v-if="nft.report" @click.native="displayReportPopUp=true" class="report-btn">
            <unicon class="social-icons" name="image-block" height="20px" width="20px"/>
            <span class="lifted-text">{{$t('nft.button.report')}}</span>
          </Button>
          <span v-if="nft.report_check" class="already-reported">
            <unicon class="social-icons" name="image-block" height="20px" width="20px"/>
            <span class="lifted-text">{{$t('nft.report_already_submitted')}}</span>
          </span>
        </div>
      </div>

      <div>
        <div class="history">
          <template v-if="nft.mine_auction.length || nft.mine_sale.length">
            <h4 class="section-title">Your sales</h4>
            <table>
              <tr v-for="auction in nft.mine_auction" :key="auction.id" class="history-card ">
                <td class="address" style="width: 230.45px">
                  <timeago :auto-update="60" :datetime="auction.end_date * 1000"></timeago>
                  <a :href="`/nft/${id}/auction/${auction.id}`" target="_blank" style="text-decoration: none">
                    <div class="cell-card">
                      <unicon name="arrow-growth"/>
                      Auction
                      <h5> #{{auction.id}}</h5>
                    </div>
                  </a>
                </td>
                <td class="bid">
                  <div class="cell-card">{{ auction.price }}<ASALogo :asaId="auction.asa_id"/></div>
                </td>
              </tr>
              <tr v-for="sale in nft.mine_sale" :key="sale.id" class="history-card ">
                <td class="address" style="width: 230.45px">
                  <a :href="`/nft/${id}/sale/${sale.id}${sale.key ? '?key=' + sale.key : ''}`" target="_blank" style="text-decoration: none">
                    <div class="cell-card">
                      <unicon name="usd-circle"/>
                      Sale <h5> #{{sale.id}}</h5>
                    </div>
                  </a>
                </td>
                <td class="bid">
                  <div class="cell-card">{{ sale.price }}<ASALogo :asaId="sale.asa_id"/></div>
                </td>
              </tr>
            </table>
          </template>
          <template v-if="nft.auctions.length || nft.sales.length">
            <h4 class="section-title">Current sales</h4>
            <table>
              <tr v-for="auction in nft.auctions" :key="auction.id" class="history-card ">
                <td class="address" style="width: 230.45px">
                  <timeago :auto-update="60" :datetime="auction.end_date * 1000"></timeago>
                  <a :href="`/nft/${id}/auction/${auction.id}`" target="_blank" style="text-decoration: none">
                    <div class="cell-card">
                      <unicon name="arrow-growth"/>
                      Auction <h5> #{{auction.id}}</h5>
                    </div>
                  </a>
                </td>
                <td class="bid">
                  <div class="cell-card">{{ auction.price }}<ASALogo :asaId="auction.asa_id"/></div>
                </td>
              </tr>
              <tr v-for="sale in nft.sales" :key="sale.id" class="history-card ">
                <td class="address" style="width: 230.45px">
                  <a :href="`/nft/${id}/sale/${sale.id}`" target="_blank" style="text-decoration: none">
                    <div class="cell-card">
                      <unicon name="usd-circle"/>
                      Sale <h5> #{{sale.id}}</h5>
                    </div>
                  </a>
                </td>
                <td class="bid">
                  <div class="cell-card">{{ sale.price }}<ASALogo :asaId="sale.asa_id"/></div>
                </td>
              </tr>
            </table>
          </template>
        </div>
        <div class="history">
          <template v-if="nft.mine_offer && nft.mine_offer.length">
            <h4 class="section-title">My offers</h4>
            <table>
              <tr v-for="offer in nft.mine_offer" :key="offer.id" class="history-card ">
                <td class="address" style="width: 230.45px">
                  <a :href="`/nft/${id}/offer/${offer.id}`" target="_blank" style="text-decoration: none">
                    <div class="cell-card">
                      <unicon name="usd-circle"/>
                      Offer <h5> #{{offer.id}}</h5>
                    </div>
                  </a>
                </td>
                <td class="bid">
                  <div class="cell-card">{{ offer.price }}<ASALogo :asaId="offer.asa_id"/></div>
                </td>
              </tr>
            </table>
          </template>
          <template v-if="nft.offers && nft.offers.length">
            <h4 class="section-title">Offers</h4>
            <table>
              <tr v-for="offer in nft.offers.slice().reverse()" :key="offer.id" class="history-card ">
                <td class="address" style="width: 230.45px">
                  <a :href="`/nft/${id}/offer/${offer.id}`" target="_blank" style="text-decoration: none">
                    <div class="cell-card">
                      <unicon name="usd-circle"/>
                      Offer <h5> #{{offer.id}}</h5>
                    </div>
                  </a>
                </td>
                <td class="bid">
                  <div class="cell-card">{{ offer.price }}<ASALogo :asaId="offer.asa_id"/></div>
                </td>
              </tr>
            </table>
          </template>
        </div>

        <div class="history" v-if="displayBidHistory || displayBuyHistory">
          <template v-if="displayBidHistory">
            <h4 class="section-title">{{$t('nft.bidhistory')}}</h4>
            <table v-if="bidHistory.length">
              <tr v-for="bid in bidHistory" :key="bid.date" class="history-card">
                <td class="address">
                  <timeago :auto-update="60" :datetime="bid.date * 1000"></timeago>
                  <a :href="`/gallery/${bid.address}`" target="_blank" style="text-decoration: none">
                    <div class="cell-card">
                      <jazzicon :address="bid.address.hexEncode()" :diameter="40" class="icon"/>
                      <Address :address="bid.address"/>
                    </div>
                  </a>
                </td>
                <td class="bid">
                  <div class="cell-card">{{ bid.price }}<ASALogo :asaId="nft.asa_id"/></div>
                </td>
              </tr>
            </table>
            <h3 v-else>
              <unicon name="money-bill-slash" class="icon" width="50px" height="40px"/>
              {{$t('nft.noBid')}}
            </h3>
          </template>
          <template v-if="displayBuyHistory">
            <h4 class="section-title">{{$t('nft.buyhistory')}}</h4>
            <table v-if="buyHistory.length">
              <tr v-for="buy in buyHistory" :key="buy.date" class="history-card">
                <td class="address">
                  <timeago :auto-update="60" :datetime="buy.date * 1000"></timeago>
                  <a :href="`/gallery/${buy.address}`" target="_blank" style="text-decoration: none">
                    <div class="cell-card">
                      <jazzicon :address="buy.address.hexEncode()" :diameter="40" class="icon"/>
                      <Address :address="buy.address"/>
                    </div>
                  </a>
                </td>
                <td class="bid">
                  <div class="cell-card">{{ buy.price }}<ASALogo :asaId="buy.asa_id"/></div>
                </td>
              </tr>
            </table>
            <h3 v-else>
              <unicon name="money-bill-slash" class="icon" width="50px" height="40px"/>
              {{$t('nft.noBuy')}}
            </h3>
          </template>
        </div>
      </div>
      <ContractPopUp v-if='displayContractPopUp'
                     :_kind="contractKind"
                     :_price='nft.price'
                     :_tokenId='parseInt(id)'
                     @close="closePopUp"
                     :_asa="nft.asa_id"
                     :_contractParams="{
                       nftSupply: this.nft.number,
                       buySupply: this.nft.buy_supply,
                       royalties: this.nft.royalties,
                       saleId: this.saleId,
                       offerId: this.offerId
                     }"/>
      <ReportPopUp :_nftID="this.id" v-if='displayReportPopUp' @close="displayReportPopUp = false; forceReload()"></ReportPopUp>
    </div>
    <Community :id="id" :kind="'nft'"/>
    <div v-if="reports.length">
      <h3>Reports</h3>
      <ReportCard v-for="[i, data] of reports.entries()" :key="i" :data="data"/>
    </div>
  </AppView>
  <AppView v-else-if="nft.title && isDeleted">
    {{$t('nft.hasBeenDeleted')}}
  </AppView>
  <Loader v-else/>
</template>

<script>
import { sRGBEncoding } from 'three'
import { ModelGltf } from 'vue-3d-model'
import Address from '@/components/User/Address'
import Button from '@/components/Common/Button/Button'
import ASALogo from '@/components/Assets/ASALogo'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import Loader from '@/components/Common/Spinner/Loader'
import { mapMutations, mapState } from 'vuex'
import ReportPopUp from '@/components/Common/PopUp/ReportPopUp'
import AppView from '@/components/Layout/AppView'
import ReportCard from '@/components/Cards/ReportCard'
import AlgoGlyph from '@/components/Assets/AlgoGlyph'
import Spinner from '@/components/Common/Spinner/Spinner'
import NSFWVerificationPopUp from '@/components/Common/PopUp/NSFWVerificationPopUp'
import Tooltip from '../components/Common/Tooltip'
import Community from '../components/User/Community'

export default {
  name: 'NFT',
  components: { Community, Tooltip, NSFWVerificationPopUp, Spinner, AlgoGlyph, ReportCard, AppView, ReportPopUp, Loader, ContractPopUp, ASALogo, Button, Address, ModelGltf },
  props: ['id', 'auctionId', 'saleId', 'offerId'],
  data () {
    return {
      nft: {},
      attributes: [],
      bidHistory: [],
      buyHistory: [],
      reports: [],
      isDeleted: false,
      canEdit: false,
      displayGlyph: false,
      now: Date.now() / 1000,
      displayReportPopUp: false,
      displayContractPopUp: false,
      contractState: 'needPrice',
      contractKind: '',
      reload: 0,
      focused: false,
      videoLoaded: false,
      audioLoaded: false,
      modelLoaded: false,
      fullsizeImage: null,
      sRGBEncoding,
      isStackable: false,
      rotation: {
        x: -Math.PI / 2,
        y: 0,
        z: 0
      }
    }
  },
  watch: {
    auctionId () {
      this.forceReload()
    },
    saleId () {
      this.forceReload()
    },
    offerId () {
      this.forceReload()
    }
  },
  computed: {
    ...mapState([
      'wallet',
      'userId',
      'address',
      'isAdult',
      'routeBeforeAuthenticate'
    ]),
    dataUrl () {
      return `/api/nft/${this.id}`
    },
    galleryUrl () {
      const userId = this.nft.username || this.nft.address
      return `/gallery/${userId}`
    },
    refreshUrl () {
      return `/api/nft/refresh/${this.id}`
    },
    favoriteUrl () {
      return '/api/nft/fav'
    },
    timeRemaining () {
      let delta = this.nft.end_date - this.now
      if (delta > 0) {
        const days = Math.floor(delta / 86400)
        delta -= days * 86400
        const hours = Math.floor(delta / 3600) % 24
        delta -= hours * 3600
        const minutes = Math.floor(delta / 60) % 60
        delta -= minutes * 60
        const seconds = Math.floor(delta % 60)
        return {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds
        }
      } else {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        }
      }
    },
    isExpired () {
      return this.now >= this.nft.end_date
    },
    displayBidHistory () {
      return this.nft.action && this.nft.action.includes('bid')
    },
    displayBuyHistory () {
      return this.nft.action && (!this.nft.action.includes('bid') || this.buyHistory.length)
    },
    thumbnailUrl () {
      const imageKey = this.nft.extension ? `images/${this.id}.${this.nft.extension}` : `images/${this.id}`
      const imageRequest = JSON.stringify({
        bucket: 'algogems',
        key: imageKey,
        edits: {
          resize: {
            width: 850,
            height: 600,
            fit: 'inside'
          }
        }
      })
      return `/image/${btoa(imageRequest)}`
    }
  },
  methods: {
    ...mapMutations(['setRouteBeforeAuthenticate']),
    toggleFocus () {
      if (this.$refs.video) {
        if (this.focused) {
          this.focused = false
          this.$refs.video.pause()
        } else {
          this.focused = true
          this.$refs.video.play()
        }
      }
      if (this.$refs.audio) {
        if (this.focused) {
          this.focused = false
          this.$refs.audio.pause()
        } else {
          this.focused = true
          this.$refs.audio.play()
        }
      }
    },
    toggleFollow () {
      this.$http.post(this.favoriteUrl, {
        token_id: this.id,
        action: (this.nft.is_favorite) ? 'remove' : 'add'
      }).then(
        () => {
          this.nft.is_favorite = !this.nft.is_favorite
        }
      )
    },
    loadThumbnail () {
      const img = new Image()
      img.onload = () => {
        this.thumbnailImage = img.src
        if (this.nft.content_type === 'video' || this.nft.content_type === 'audio' || this.nft.extension === 'gif' || this.nft.content_type === 'model/gltf-binary') {
          this.$nextTick(() => {
            this.loadFullSize()
          })
        }
      }
      img.onerror = () => {
        if (!this.failed) {
          this.failed = true
          this.$http.get(`/api/images/import/${this.id}`)
            .then(() => {
              this.loadThumbnail()
            }).catch(err => {
              console.error(err)
            })
        }
      }
      img.src = this.thumbnailUrl
    },
    loadFullSize () {
      if (this.nft.content_type === 'video') {
        const videoElement = this.$refs.video
        videoElement.addEventListener('loadeddata', (e) => {
          if (videoElement.readyState >= 3) {
            this.videoLoaded = true
            this.focused = true
          }
        })
        if (videoElement.readyState >= 3) {
          this.videoLoaded = true
          this.focused = true
        }
      }
      if (this.nft.content_type === 'audio') {
        const audioElement = this.$refs.audio
        audioElement.addEventListener('canplay', (e) => {
          this.audioLoaded = true
        })
        if (audioElement.readyState >= 3) {
          this.audioLoaded = true
        }
      }
      if (this.nft.extension === 'gif') {
        const img = new Image()
        img.onload = () => {
          this.fullsizeImage = img.src
        }
        img.onerror = (err) => {
          console.error(`Error while loading fullsize: ${err}`)
        }
        img.src = `/fullsize/images/${this.id}.gif`
      }
      if (this.nft.content_type === 'model/gltf-binary') {
        this.$http.get(this.nft.ipfs_link, { responseType: 'blob' }).then(({ data }) => {
          const reader = new FileReader()
          reader.onload = (e) => {
            this.modelLoaded = `data:model/gltf-binary;base64,${btoa(e.target.result)}`
            this.rotate()
          }
          reader.onerror = (err) => {
            console.log('Error : ' + err.type)
          }
          reader.readAsBinaryString(data)
        }).catch(err => {
          console.error(`Error while loading fullsize: ${err}`)
        })
      }
    },
    fetchData () {
      let url = this.dataUrl
      if (this.auctionId) {
        url += `/auction/${this.auctionId}`
      }
      if (this.saleId) {
        url += `/sale/${this.saleId}`
      }
      if (this.offerId) {
        url += `/offer/${this.offerId}`
      }

      this.$http.get(url, { params: { key: this.$route.query.key } }).then(response => {
        if (response.data && response.data.nft && response.data.bids) {
          for (const key of Object.keys(response.data.nft)) {
            this.$set(this.nft, key, response.data.nft[key])
          }
          this.isStackable = response.data.is_stake
          this.isDeleted = response.data.delete
          this.canEdit = response.data.edit
          this.attributes = []
          this.bidHistory = []
          this.buyHistory = []
          if (response.data.attribute) {
            this.attributes.push(...response.data.attribute)
          }
          if (response.data.bids) {
            this.bidHistory.push(...response.data.bids)
          }
          if (response.data.buys) {
            this.buyHistory.push(...response.data.buys)
          }
          if (response.data.report) {
            this.reports.push(...response.data.report)
          }

          if (!this.nft.content_type && !this.failed) {
            this.failed = true
            this.$http.get(`/api/images/import/${this.id}`)
              .then(() => {
                this.fetchData()
              }).catch(err => {
                console.error(err)
              })
          } else {
            this.loadThumbnail()
          }

          if (this.routeBeforeAuthenticate === this.$route.path) {
            this.setRouteBeforeAuthenticate({ routeBeforeAuthenticate: '/' })
            if (this.nft.action.includes('bid')) {
              this.contractHandler('bid')
            }
            if (this.nft.action.includes('buy')) {
              this.contractHandler('buy')
            }
          }
        }
        setInterval(() => {
          this.now = Date.now() / 1000
        }, 1000)
      }).catch(err => {
        if (this.auctionId || this.saleId || this.offerId) {
          this.$router.push(`/nft/${this.id}`)
        } else {
          console.log(err)
        }
      })
    },
    deleteASA () {
      this.contractHandler('delete')
    },
    refreshMetada () {
      this.$http.get(this.refreshUrl).then(response => {
        this.forceReload()
      }).catch(err => {
        console.log(err)
      })
    },
    contractHandler (contractKind) {
      if (this.address) {
        this.contractKind = contractKind
        this.displayContractPopUp = true
      } else {
        this.$router.push('/authenticate')
      }
    },
    closePopUp () {
      this.displayContractPopUp = false
      this.forceReload()
    },
    forceReload () {
      this.reload += 1
      this.fetchData()
    },
    onModelLoad () {
      console.log('loaded')
      this.rotate()
    },
    rotate () {
      if (!this.previousRotateTimestamp) {
        this.previousRotateTimestamp = Date.now()
      }
      if (Date.now() - this.previousRotateTimestamp > 1 / 60 * 1000) {
        this.rotation.x = 0
        this.rotation.z = 0
        this.rotation.y += 0.01
        this.previousRotateTimestamp = Date.now()
      }
      requestAnimationFrame(this.rotate)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.fetchData()
    })
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}

.nft-grid {
  display: grid;
  grid-template-columns: 1fr 350px;
  padding: 0;
}

.nft-image-container .spinner-wrapper {
  padding: 20px;
  background: var(--foreground);
  border-radius: 20px;
  width: 30px;
  height: 30px;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  position: absolute;
}

.nft-image-container .spinner-wrapper.pause {
  opacity: 0;
}

.nft-image-container:hover .spinner-wrapper.pause {
  opacity: 1;
}

.nft-image-container video.loading {
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
}

.nft-image, .nft-image-container video {
  display: block;
  width: auto;
  height: auto;
  border-radius: 20px;
  box-shadow: var(--light-shadow);
  margin: 0px auto 0px auto;
  transition: all .5s ease;
  max-width: 850px;
  max-height: 600px;
}

.nft-image-container .model-wrapper {
  display: block;
  width: 60vw;
  height: 87vh;
  border-radius: 20px;
  max-width: 850px;
  max-height: 600px;
  overflow: hidden;
}

.nft-image-container  .full-size{
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 20px;
}

.header {
  margin-top: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text);
  background: var(--lightgradient);
  border-radius: 20px;
  flex-wrap: wrap;
  position: relative;
}

.nft-title {
  display: flex;
  align-items: center;
}

.nft-title h3{
  font-size: 1.6em;
}

.nft-title h5{
  margin-left: 5px;
}

.nft-links {
  display: flex;
  align-items: center;
}

.nft-links h5{
  margin-left: 5px;
}

.nft-links a.external-link {
  text-decoration: none;
  color: var(--textlight);
}

.nft-info .nft-author {
  font-size: 1em;
  font-weight: 400;
}

.price-cta {
  display: flex;
  align-items: center;
  text-align: center;
}

.price-cta .nft-price {
  font-size: 2em;
  margin-right: 10px;
}

.price-cta button {
  margin: 0;
  width: 150px;
  border-radius: 15px;
}

.nft-description {
  color: var(--textlight);
  padding: 5px 10px;
  text-align: justify;
  margin: 0;
}

.nft-tags, .arc69-attributes {
  list-style: none;
  margin: 5px 0 0 0;
  padding: 0 10px;
}

.nft-tags li {
  background: var(--foreground);
  display: inline-block;
  padding: 7px 10px;
  border-radius: 10px;
  box-shadow: var(--shadow);
  font-weight: 400;
  font-size: .8em;
  margin-right: 5px;
  color: var(--textlight);
}

.arc69-attributes li {
  box-sizing: border-box;
  display: inline-block;
  padding: 7px 10px;
  margin-right: 10px;
  margin-bottom: 15px;
  border-radius: 10px;
  font-weight: 400;
  font-size: .8em;
  color: var(--textlight);
}

.arc69-attributes li span, .arc69-attributes li div {
  text-align: center;
  margin: 5px;
}

.arc69-attributes li span:first-child {
  font-weight: bolder;
  color: var(--accent);
}

.arc69-attributes li div {
  color: var(--textlight);
}

.history {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 300px;
  overflow-y: auto;
  margin-left: 5px;
  margin-top: 5px;
}

.history h1 {
  width: 100%;
  text-align: center;
}

.history table {
  border-collapse: collapse;
  color: var(--text);
  width: 100%;
}

.history table td:last-child {
  text-align: right;
}

.history-card .cell-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: var(--foreground);
  box-shadow: var(--shadow);
  height: 60px;
  padding: 5px 20px;
  margin: 15px 0;
}

.history-card .address{
  position: relative;
}

.history-card .address time{
  font-size: .6em;
  color: var(--textlight);
  position: absolute;
}

.history-card .address .cell-card {
  border-radius: 20px 0 0 20px;
}

.history-card .bid .cell-card {
  border-radius: 0 20px 20px 0;
  position: relative;
}

.history-card .bid .cell-card:before {
  display: block;
  content: '';
  background: var(--foreground);
  width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -20px;
}

.history-card .icon {
  margin-right: 10px;
}

.history-card .unicon {
  margin-right: 10px;
}

.cell-card .unicon {
  fill: var(--accent);
}

.history-card h5 {
  margin-left: 5px;
}

.history h3{
  text-align: center;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--headings);
  fill: var(--headings);
  padding: 30px 0;
  border: 2px dashed var(--headings);
  border-radius: 20px;
  box-sizing: border-box;
}

.footnotes {
  font-size: .8em;
  color: var(--textlight);
  font-weight: 400;
  width: 100%;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 15px;
}

.already-reported {
  margin: 25px 0px;
  color: var(--error);
  display: flex;
  font-size: x-small;
}

.lifted-text {
  margin-left: 5px;
  margin-top: 4px;
}

.danger-zone {
  display: flex;
  align-items: center;
  height: 35px;
}

.report-btn.blue {
  color: var(--accent);
}

.report-btn.blue .unicon {
  fill: var(--accent);
}

.details {
  display: flex;
  flex-direction: column;
  position: relative;
}

.nft-image-container {
  position: relative;
  display: inline-block;
  margin: auto;
}

#algo-glyph {
  bottom: 0px;
  right: 0px;
}

.display-glyph-button {
  position: absolute;
  right: 0;
  top: -75px;
  z-index: 10;
  border-radius: 100%;
  border: 2px var(--textlight) solid;
  width: 34px;
  height: 34px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.display-glyph-button.selected {
  border: 0px;
  background: var(--gradient);
}

.display-glyph-button .unicon {
  fill: var(--textlight);
}

.display-glyph-button.selected .unicon {
  fill: white;
}

.favorite-button {
  background: none;
  border: none;
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 10;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .details {
    max-width: calc(100vw - 50px);
    height: auto;
  }
  .nft-grid {
    grid-template-columns: 1fr;
    padding: 25px;
    padding-top: 0;
    box-sizing: border-box;
    width: 100%;
  }

  .nft-tags li {
  margin: 4px;
  }

  .nft-image-container .model-wrapper {
  display: flex;
  width: 100%;
  height: 55vh;
  border-radius: 20px;
  overflow: hidden;
}

  .nft-image-container {
    width: 100%;
    height: auto;
  }

  .nft-image, .nft-image-container video {
    width: 100%;
  }

  .price-cta{
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }

  .history {
    margin-left: 0;
  }

  .history-card .time time {
    position: absolute;
    top: -10px;
    left: 0;
    width: 100px;
    text-align: left;
  }

.nft-tags, .arc69-attributes {
  list-style: none;
  margin: 0;
  padding: 5px;
}

}

.supply-informations span {
  margin-right: 8px;
}

.auction-timeout {
  position: absolute;
  top: -10px;
  right: 6px;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  z-index: 2;
}

.auction-timeout li {
  background: rgba(0, 0, 0, .8);
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  margin: 1px;
}

.section-title {
  font-size: x-large;
}

.labels {
  margin-right: 5px;
}

.crescendo-button {
  display: flex;
  align-items: center;
  transition: all .2s ease;
  opacity: 0.8;
}

.crescendo-button:hover {
  opacity: 1;
}

.crescendo-button .unicon {
  fill: var(--accent);
  margin-right: 6px;
}
</style>
