<template>
  <div class="market-body">
    <Section>
      <template v-slot:header>
        <h3>Explore our brands</h3>
        <TextualButton to="/bkrt/brands">See More</TextualButton>
      </template>
      <CollectionGrid>
        <CollectionCard v-for="(artist, index) in bkartelArtists" :key="artist.token_id" :collection="artist" :style="`animation-delay: ${100 + (index * 50)}ms`"/>
      </CollectionGrid>
    </Section>
    <HotSales :asa-id="230946361" asa-name="GEMS"/>
  </div>
</template>

<script>
import HotSales from './HotSales'
import Section from '../Layout/Section'
import CollectionCard from '../Collection/CollectionCard'
import CollectionGrid from '../Collection/CollectionGrid'
import TextualButton from '../Common/Button/TextualButton'

export default {
  name: 'BKRTBody',
  components: { TextualButton, CollectionGrid, CollectionCard, HotSales, Section },
  data () {
    return {
      bkartelArtists: []
    }
  },
  methods: {
    getData () {
      this.bkartelArtists = []
      const salesParams = {
        start: 0,
        end: 9,
        nsfw: false
      }
      this.$http('/api/collections/FOEUI7GSVBOKPBFPNPKO7NQ64Z4HDI2EVZWHANXHPLJJCS4Q35S3JRTZHA', { params: salesParams }).then((response) => {
        if (response.data && response.data.metadata) {
          this.bkartelArtists = response.data.metadata
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style scoped>

</style>
