<template>
<AppView v-if="status !== undefined">
  <h1>Fusion settings</h1>
  <p>The fusions are <template v-if="status">activated</template><template v-else>deactivated</template></p>
  <Button @click.native="toggleStatus"><template v-if="!status">Activate</template><template v-else>Deactivate</template></Button>
</AppView>
<AppView v-else>
  <LinearSpinner/>
</AppView>
</template>

<script>
import AppView from '../components/Layout/AppView'
import LinearSpinner from '../components/Common/Spinner/LinearSpinner'
import { mapState } from 'vuex'
import Button from '../components/Common/Button/Button'

export default {
  name: 'FusionSetting',
  components: { Button, LinearSpinner, AppView },
  data () {
    return {
      status: undefined
    }
  },
  computed: {
    ...mapState(['address'])
  },
  methods: {
    getStatus () {
      this.$http.get('/api/fusion/algoleague/status')
        .then((d) => {
          console.log(d)
          this.status = d.data.status
        })
        .catch((e) => {
          console.log(e)
        })
    },
    toggleStatus () {
      const status = this.status
      this.status = undefined
      this.$http.post('/api/fusion/algoleague/status', { status: status })
        .then(() => {
          this.getStatus()
        })
        .catch(() => {
          this.getStatus()
        })
    }
  },
  mounted () {
    if (!['XKALZ5S4VLJHNUO2IOMF6MKG63FCLI3BQYMBIVV5AJKJQURXVE4UWNMMUE', 'PZNGYF4Y25GGO674BW4CRDHFKOKHMHZXSFXIKMYPEJCQAUTDH52WV24XTY'].includes(this.address)) {
      this.$router.push('/')
    }
    this.getStatus()
  }
}
</script>

<style scoped>
h1, p {
  text-align: center;
}

.view-body h1 {
  text-align: center;
}
</style>
