<template>
  <Section>
    <div class="flex-center">
      <img src="@/assets/gems_error_logo.png">
      <span>
        <h2>The page you tried to load does not exist.</h2> <br>
        <slot></slot>
      </span>
    </div>
  </Section>
</template>

<script>
import Section from '../Layout/Section'

export default {
  name: 'Error',
  components: { Section }
}
</script>

<style scoped>
img {
  height: 150px;
  width: auto;
}

@media only screen
and (max-device-width: 1200px) {
  .flex-center {
    flex-direction: column;
  }
}
</style>
