<template>
  <AppView id="algoLeagues-submarket">
    <template v-slot:header>
    <div class="market-header">
      <div class="banner">
        <a href="https://discord.gg/Qxaywxajh4" target="_blank">
          <img src="@/assets/marketHeader/algoleague-header.png" alt="algo leagues nft">
        </a>
      </div>
      <div class="sales-buttons">
        <router-link :to="`/shops/algoLeagues`">
          <unicon name="estate"/>
          Home
        </router-link>
        <router-link :to="`/shops/algoLeagues/sales`">
          <unicon name="usd-circle"/>
          Sales
        </router-link>
        <router-link :to="`/shops/algoLeagues/auctions`">
          <unicon name="arrow-growth"/>
          Auctions
        </router-link>
        <router-link :to="`/shops/algoLeagues/shuffles`">
          <unicon name="shuffle"/>
          Shuffles
        </router-link>
        <router-link :to="`/shops/algoLeagues/fusions`">
          <unicon name="pathfinder-unite"/>
          Fusions
        </router-link>
        <router-link :to="`/shops/algoLeagues/packs`">
          <unicon name="layers"/>
          Packs
        </router-link>
        <router-link :to="`/shops/algoLeagues/sets`">
          <unicon name="apps"/>
          Sets
        </router-link>
        <router-link :to="`/shops/algoLeagues/token`">
          <ASALogo class="no-margin" :asa-id="445905873"/>
          ALC
        </router-link>
      </div>
    </div>
    </template>
    <router-view/>
  </AppView>
</template>

<script>
import 'hooper/dist/hooper.css'
import AppView from '@/components/Layout/AppView'
import { mapMutations } from 'vuex'
import ASALogo from '@/components/Assets/ASALogo'

export default {
  name: 'AlgoLeaguesSubmarket',
  components: { ASALogo, AppView },
  methods: {
    ...mapMutations(['setCustomClass'])
  },
  beforeDestroy () {
    this.setCustomClass({ customClass: '' })
  },
  mounted () {
    this.setCustomClass({ customClass: 'algoLeagues' })
  }
}
</script>

<style scoped>
.market-header{
  height: 670px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  background: #010213;
}

.market-header .title{
  position: relative;
  z-index: 1;
}

.market-header .title h2{
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 8em;
  line-height: .95em;
  text-transform: uppercase;
  text-align: center;
  color: var(--text);
}

.market-header .title h2 a {
  text-decoration: none;
}

.market-header .title h3{
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 3em;
  font-weight: lighter;
  line-height: 1em;
  text-transform: uppercase;
  text-align: center;
  color: var(--textlight);
  margin: 0;
}

.market-header .banner{
  height: 580px;
}

.market-header .banner img{
  position: absolute;
  bottom: -50px;
  height: 800px;
  left: calc(50% - 650px);
  mix-blend-mode: lighten;
}

.market-body {
  margin-top: 10px;
}

.sales-buttons {
  display: flex;
  position: relative;
  z-index: 5;
}

.sales-buttons a {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  margin: 0px 25px;
}

.sales-buttons a .unicon, .sales-buttons a .asa-logo {
  margin-bottom: 5px;
  padding: 12px;
  background-color: #000;
  border-radius: 15px;
}

.sales-buttons a .asa-logo {
  width: 24px;
  height: 24px;
  font-size: x-large;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sales-buttons a:hover {
  color: var(--accent);
}

.sales-buttons a:hover .unicon {
  fill: var(--accent);
}

.sales-buttons a.router-link-exact-active {
  color: var(--accent);
}

.sales-buttons a.router-link-exact-active .unicon {
  fill: var(--accent);
}

.market-header .algoLeagues-nft{
  width: 100vw;
  z-index: 1;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
}
</style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100;400;700&family=Mr+Dafoe&display=swap');

#app.algoLeagues {
  --blue: #008ef7;
  --red: #ca1b52;
  --error: var(--red);
  background: var(--background);
  min-height: 100vh;
  font-family: 'Libre Franklin', sans-serif;
  transition: background .5s ease;
  --invert: 0;
  width: 100%;
  overflow-x: hidden;
}

#app.algoLeagues.dark, #app.algoLeagues {
  --background: #000318;
  --foreground: #10142f;
  --foreground-transparent: rgba(29,34,45,.5);
  --foreground-blur: rgba(29,34,45,.5);
  --nav-background: #161616;
  --fake-blur: #1c1d20eb;
  --headings: #202231;
  --text: #dae2ff;
  --textlight: rgba(218, 226, 255, 0.6);
  --accent: #f88d2e;
  --complement: #fff;
  --blur-overlay: rgba(14, 17, 23, .5);
  --light-shadow: rgba(0, 0, 0, .1) 0px 0px 20px;
  --shadow: rgba(0, 0, 0, .1) 0px 0px 2px, rgba(0, 0, 0, .3) 0px 0px 20px;
  --strong-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  --accent-shadow: 0 0 .5vw rgba(0,0,0,.1), 0 0 10vw rgba(202, 49, 112, 0.3);
  --gradient: linear-gradient(45deg, var(--accent), #EB5628);
  --lightgradient: linear-gradient(45deg, #050922, #010626);
  --background-alt: #1d222d;
  --foreground-alt: #0e1117;
  --invert: 1;
  --pop-up-background: rgba(14, 17, 23, 0.9);
}

#algoLeagues-submarket .view-body .view-header {
  display: none;
}

.sales-buttons {
  min-width: 80vw;
  flex-wrap: wrap;
  justify-content: center;
}

.sales-buttons a .unicon, .sales-buttons a .asa-logo {
  margin: 5px;
}
</style>
