<template>
  <div id="search">
    <div class="search-bar">
      <input type="text" id="search-input" autocomplete="off" v-model="searchInput" @input="search">
      <label for="search-input">
        <Spinner v-if="loading"/>
        <template v-else>
          <unicon name="times" v-if="searchInput" @click="reset"/>
          <unicon name="search" v-else/>
        </template>
      </label>
    </div>
    <ul class="search-suggestions" v-if="showSuggestions && searchInput.length > 2 && displaySuggestions && !loading">
      <li v-for="suggestion in suggestions" :key="suggestion.value">
        <router-link :to="`/gallery/${suggestion.value}`" v-if="suggestion.type === 'Username'">{{suggestion.value}} <span class="result-type">{{suggestion.type}}</span></router-link>
        <router-link :to="`/collection/${suggestion.value.collection_id}`" v-if="suggestion.type === 'Collection'">{{suggestion.value.title}} <span class="result-type">{{suggestion.type}}</span></router-link>
        <router-link :to="`/nft/${suggestion.value.token_id}`" v-if="suggestion.type === 'NFT'">{{suggestion.value.title}} <span class="result-type">{{suggestion.type}}</span></router-link>
      </li>
      <li v-for="suggestion in suggestions" :key="suggestion.value">
        <router-link :to="`/auctions?tag=${suggestion.value}`" v-if="suggestion.type === 'Tag'">{{suggestion.value}} <span class="result-type">{{'Auctions'}}</span></router-link>
      </li>
      <li v-for="suggestion in suggestions" :key="suggestion.value">
        <router-link :to="`/sales?tag=${suggestion.value}`" v-if="suggestion.type === 'Tag'">{{suggestion.value}} <span class="result-type">{{'Sales'}}</span></router-link>
      </li>
      <li class="no-results" v-if="searchInput.length > 7 && !suggestions.length"><!--Makes sure the params are long enough-->
        No results
        <router-link v-if="searchInput.length > 57" :to="`/gallery/${searchInput}`">{{searchInput.slice(0,5)+'.'+searchInput.slice(53,58)}} <span class="result-type">{{'Address'}}</span></router-link>
        <router-link v-if="searchInput.length < 13 && searchInput/2" :to="`/nft/${searchInput}`">{{searchInput.slice(0,12)}} <span class="result-type">{{'Asset'}}</span></router-link> <!--controls nft asa id length-->
      </li>
    </ul>
  </div>
</template>

<script>
import Spinner from './Spinner/Spinner'

export default {
  name: 'Search',
  components: {
    Spinner
  },
  props: ['showSuggestions'],
  data () {
    return {
      searchInput: '',
      suggestions: [],
      loading: false,
      displaySuggestions: true,
      timeout: 0
    }
  },
  methods: {
    search () {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      if (this.searchInput.length > 2) {
        this.loading = true
        const q = this.searchInput
        this.timeout = setTimeout(() => {
          this.$http.get(`/api/search?value=${encodeURIComponent(q)}`).then(response => {
            this.suggestions = response.data.metadata
            this.loading = false
            this.displaySuggestions = true
            if (this.searchInput.length > 2) {
              this.$emit('results', {
                q,
                r: this.suggestions
              })
            }
          }).catch(err => {
            console.log(err)
          })
        }, 1000)
      } else {
        this.loading = false
        this.$emit('results', false)
      }
    },
    reset () {
      this.searchInput = ''
      this.suggestions = []
      this.loading = false
      this.$emit('results', false)
    },
    routeTo (suggestion) {
      switch (suggestion.type) {
        case 'Username':
          this.$router.push('gallery/' + suggestion.value)
          break
        case 'Tag':
          this.searchInput = suggestion.value
          this.$router.push({ path: '', query: { tag: suggestion.value } })
          break
        default :
          console.error('Search: Unknown Type', suggestion.type)
      }
    },
    hideSuggestions () {
      this.displaySuggestions = false
    }
  },
  mounted () {
    window.addEventListener('click', this.hideSuggestions)
  },
  unmounted () {
    window.removeEventListener('click', this.hideSuggestions)
  }
}
</script>

<style scoped>
#search{
  text-align: center;
  position: relative;
  top: 75px;
  z-index: 14;
}
.search-bar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: var(--foreground);
  border-radius: 20px;
  box-shadow: var(--light-shadow);
  overflow: hidden;
  z-index: 14;
}

.search-bar .unicon{
  padding: 0 10px;
  fill: var(--textlight);
}

.search-bar .spinner{
  margin-right: 10px;
}

.search-bar label{
  cursor: pointer;
}

#search-input{
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  font-size: 1.3em;
  height: 55px;
  z-index: 14;
}

.search-suggestions {
  padding: 10px 20px 10px 10px;
  list-style: none;
  width: 400px;
  margin: 20px 0;
  background: var(--foreground);
  border-radius: 20px;
  box-shadow: var(--light-shadow);
  color: var(--text);
  position: absolute;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  top: 55px;
  left: 35px;
  z-index: 15;
  transform: translate3d(0, 0, 200px)
}

/* width */
.search-suggestions::-webkit-scrollbar {
  width: 10px;
  border-radius: 0 5px 5px 0;
}

/* Track */
.search-suggestions::-webkit-scrollbar-track {
  background: var(--background);
}

/* Handle */
.search-suggestions::-webkit-scrollbar-thumb {
  background: var(--textlight);
  border-radius: 5px;
}

/* Handle on hover */
.search-suggestions::-webkit-scrollbar-thumb:hover {
  background: var(--text);
}

.search-suggestions li a{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.search-suggestions li .result-type{
  font-weight: lighter;
  color: var(--textlight);
}

.search-suggestions li:hover{
  background: var(--background);
  color: var(--accent);
}

li.no-results:hover, li.loading:hover{
  background: none;
  color: var(--text);
}

@media only screen
and (max-device-width: 1200px) {
  #search{
    position: unset;
  }

  .search-suggestions {
    width: 85vw;
    margin: 0px;
    padding: 5px;
    height: 50vh;
    top: 125px;
    left: 7.5vw;
    position: fixed;
    z-index: 15;
    box-sizing: border-box;
    overflow-y: visible;
    border-radius: 10px;
    box-shadow: var(--strong-shadow);
    transform: translate3d(0, 0, 200px)
  }

  @supports (backdrop-filter: blur(20px)) {
    .search-suggestions {
      background-color: var(--foreground-transparent);
      backdrop-filter: blur(20px);
    }
  }

  .search-suggestions li{
    margin: 5px 0;
    border-radius: 5px;
  }

  .search-suggestions li a{
    padding: 15px 10px;
  }
}
</style>
