<template>
  <div class="arc69-filters">
    <h2>Traits</h2>
    <div v-for="[property, options] of Object.entries(properties)"
         :key="property"
         class="filter">
      {{property}}
      <Select
        :options="options"
        @selected="(val) => filter(property, val)"/>
    </div>
    <div v-if="Object.entries(properties) < 1"><h5>No ARC traits</h5></div>
  </div>
</template>

<script>
import Select from '../Common/Input/Select'

export default {
  name: 'ARC69Filters',
  components: { Select },
  props: ['arc69'],
  computed: {
    properties () {
      const temp = {}
      for (const [property, values] of Object.entries(this.arc69)) {
        temp[property] = [{ name: 'No Filter', value: null }]
        for (const value of values) {
          temp[property].push({ name: value, value: value })
        }
      }
      return temp
    }
  },
  methods: {
    filter (property, val) {
      this.$emit('filter', { property: property, value: val })
    }
  }
}
</script>

<style scoped>
.arc69-filters {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0;
}

.filter {
  margin: 8px 0;
  font-size: small;
}
</style>
