<template>
  <div class="instagram-images" v-if="!hasToConnect">
    <p>Select an instagram post to use</p>
    <div class="button-container">
      <TextualButton class="reverse" @click.native="previousPage" v-if="this.before">
        <unicon name="angle-left" />previous
      </TextualButton>
      <span v-else></span>
      <TextualButton @click.native="nextPage" v-if="this.after">
        next<unicon name="angle-right" />
      </TextualButton>
      <span v-else></span>
    </div>
    <ul v-if="media.length">
      <li v-for="m of media" :key="m.id" class="media" @click="select(m)">
        <template v-if="m.media_type === 'IMAGE'">
          <img :src="`/api/instagram/dl_image?url=${b64(m.media_url)}`">
        </template>
      </li>
    </ul>
    <LinearSpinner v-else />
  </div>
  <div v-else class="connection-div">
    <p>You are not connected to Instagram.</p>
    <a :href="connectionURL" class="instagram-connection-button"><unicon name="instagram"/>Connect to Instagram</a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TextualButton from '../Common/Button/TextualButton'
import LinearSpinner from '../Common/Spinner/LinearSpinner'

export default {
  name: 'InstagramImages',
  components: { LinearSpinner, TextualButton },
  props: ['account'],
  data () {
    return {
      media: [],
      before: undefined,
      after: undefined,
      start: 0,
      increment: 3,
      hasToConnect: false,
      connectionURL: ''
    }
  },
  computed: {
    ...mapState(['userId'])
  },
  methods: {
    b64 (s) {
      return btoa(s)
    },
    previousPage () {
      this.start -= this.increment
      this.fetchData({ before: this.before })
    },
    nextPage () {
      this.start += this.increment
      this.fetchData({ after: this.after })
    },
    select (m) {
      this.$emit('file', btoa(m.media_url))
      /* this.toDataURL(m.media_url)
        .then(dataUrl => {
          const fileData = this.dataURLtoFile(dataUrl, 'imageName.jpg')
          m.file = fileData
          this.$emit('picture', dataUrl)
          this.$emit('file', m)
        }) */
    },
    async toDataURL (url) {
      const response = await fetch(url)
      const blob = await response.blob()
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
    },
    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(','); var mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1]); var n = bstr.length; var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    fetchData (params = {}) {
      params = {
        ...params,
        username: this.account, // this.userId,
        start: this.start,
        end: this.start + this.increment
      }
      this.before = undefined
      this.after = undefined
      this.media = []
      // '/api/instagram/media'
      this.$http.get('/api/instagram/get_post_final', { params: params })
        .then((d) => {
          for (const m of d.data.metadata) {
            m.media_type = 'IMAGE'
            m.media_url = m.url
            this.media.push(m)
          }
          /*
          for (const m of d.data.media) {
            if (m.media_type === 'IMAGE') { this.media.push(m) }
            if (m.media_type === 'CAROUSEL_ALBUM') {
              for (const c of m.children.data) {
                if (m.caption) c.caption = m.caption
                this.media.push(c)
              }
            }
          }
          if (d.data.paging.next) {
            this.after = d.data.paging.cursors.after
          }
          if (d.data.paging.previous) {
            this.before = d.data.paging.cursors.before
          } */
          this.after = d.data.after
          this.before = d.data.before
        })
        .catch((e) => {
          console.log(e)
          if (e.response.data.url) {
            this.hasToConnect = true
            this.connectionURL = e.response.data.url
          }
        })
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style scoped>
ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
}

.media {
  border-radius: 20px;
  width: 300px;
  height: 350px;
  overflow: hidden;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media:hover {
  box-shadow: var(--shadow);
}

.media img {
  width: 350px;
  height: 350px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  margin: auto
}

.instagram-images {
  max-width: 1000px;
}

.instagram-connection-button {
  background: linear-gradient(-148deg,#B900B4, #F50001);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 200px;
  height: 40px;
  cursor: pointer;
}

.instagram-connection-button .unicon {
  margin-right: 5px;
}
</style>
