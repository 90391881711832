<template>
  <div id="loader-wrapper">
    <ul class="loader">
      <li class="center">
        <img v-if="false" class="logo" src="@/assets/logo_nsfw.png" alt="logo">
        <img v-else class="logo" src="@/assets/logo.svg" alt="logo">
      </li>
      <li class="item item-1"></li>
      <li class="item item-2"></li>
      <li class="item item-3"></li>
      <li class="item item-4"></li>
      <li class="item item-5"></li>
      <li class="item item-6"></li>
      <li class="item item-7"></li>
      <li class="item item-8"></li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Loader',
  computed: {
    ...mapState(['dark'])
  }
}
</script>

<style scoped>
#loader-wrapper{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--background);
  animation: fade .5s ease forwards;
}

.loader {
  width: 40px;
  height: 40px;
  padding: 0;
  list-style: none;
  background: none;
}

.loader img{
  width: 100%;
}

.loader .center {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 99;
  /* animation: center 3.2s ease-in-out infinite; */
}
.loader .item {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 15px;
  left: 0;
  right: 0;
  margin: auto;
  background: var(--accent);
  border-radius: 50%;
}

.item-1 {
  animation: anim-1 3.2s ease-in-out infinite 0.2s;
  animation-fill-mode: backwards;
}

@keyframes anim-1 {
  0%, 60%, 100% {
    transform: rotate(45deg) translateX(40px) scale(1);
  }
  10%, 50% {
    transform: rotate(45deg) translateX(0) scale(1.5);
  }
}
.item-2 {
  animation: anim-2 3.2s ease-in-out infinite 0.4s;
  animation-fill-mode: backwards;
}

@keyframes anim-2 {
  0%, 60%, 100% {
    transform: rotate(90deg) translateX(40px) scale(1);
  }
  10%, 50% {
    transform: rotate(90deg) translateX(0) scale(1.5);
  }
}
.item-3 {
  animation: anim-3 3.2s ease-in-out infinite 0.6s;
  animation-fill-mode: backwards;
}

@keyframes anim-3 {
  0%, 60%, 100% {
    transform: rotate(135deg) translateX(40px) scale(1);
  }
  10%, 50% {
    transform: rotate(135deg) translateX(0) scale(1.5);
  }
}
.item-4 {
  animation: anim-4 3.2s ease-in-out infinite 0.8s;
  animation-fill-mode: backwards;
}

@keyframes anim-4 {
  0%, 60%, 100% {
    transform: rotate(180deg) translateX(40px) scale(1);
  }
  10%, 50% {
    transform: rotate(180deg) translateX(0) scale(1.5);
  }
}
.item-5 {
  animation: anim-5 3.2s ease-in-out infinite 1s;
  animation-fill-mode: backwards;
}

@keyframes anim-5 {
  0%, 60%, 100% {
    transform: rotate(225deg) translateX(40px) scale(1);
  }
  10%, 50% {
    transform: rotate(225deg) translateX(0) scale(1.5);
  }
}
.item-6 {
  animation: anim-6 3.2s ease-in-out infinite 1.2s;
  animation-fill-mode: backwards;
}

@keyframes anim-6 {
  0%, 60%, 100% {
    transform: rotate(270deg) translateX(40px) scale(1);
  }
  10%, 50% {
    transform: rotate(270deg) translateX(0) scale(1.5);
  }
}
.item-7 {
  animation: anim-7 3.2s ease-in-out infinite 1.4s;
  animation-fill-mode: backwards;
}

@keyframes anim-7 {
  0%, 60%, 100% {
    transform: rotate(315deg) translateX(40px) scale(1);
  }
  10%, 50% {
    transform: rotate(315deg) translateX(0) scale(1.5);
  }
}
.item-8 {
  animation: anim-8 3.2s ease-in-out infinite 1.6s;
  animation-fill-mode: backwards;
}

@keyframes anim-8 {
  0%, 60%, 100% {
    transform: rotate(360deg) translateX(40px) scale(1);
  }
  10%, 50% {
    transform: rotate(360deg) translateX(0) scale(1.5);
  }
}
@keyframes center {
  0%, 10%, 90%, 100% {
    transform: scale(0.7);
  }
  45%, 55% {
    transform: scale(1);
  }
}
</style>
