<template>
<Section>
  <div v-if="address" style="position: relative">
    <textarea
      type="text"
      v-model="newMessage"
      placeholder="Add a comment..."
      maxlength="1000"
    />
    <div v-if="newMessage.length">
      <Button @click.native="sendNewMessage" v-if="!isSendingMessage">Submit</Button>
      <LinearSpinner v-else/>
    </div>
    <h5 v-if="error" class="error">
      <unicon height="24px" name="exclamation-octagon"/>
      {{ error }}
    </h5>
  </div>
  <ul>
    <Comment
      v-for="[i, msg] of messages.entries()"
      :key="`msg${i}`"
      :data="msg"
    />
  </ul>
  <infinite-loading spinner="waveDots" @infinite="infiteListHandler" :identifier="`secondary-${infiniteId}`" :forceUseInfiniteWrapper="true">
    <span slot="no-results">
    </span>
    <span slot="no-more"></span>
  </infinite-loading>
</Section>
</template>

<script>
import Section from '../Layout/Section'
import { mapState } from 'vuex'
import Button from '../Common/Button/Button'
import InfiniteLoading from 'vue-infinite-loading'
import Comment from '../Common/Comment'
import LinearSpinner from '../Common/Spinner/LinearSpinner'

export default {
  name: 'Community',
  components: { LinearSpinner, Comment, Button, Section, InfiniteLoading },
  props: ['id', 'kind'],
  data () {
    return {
      increment: 30,
      messages: [],
      infiniteId: 0,
      newMessage: '',
      isSendingMessage: false,
      error: undefined
    }
  },
  computed: {
    ...mapState([
      'userId',
      'address'
    ]),
    galleryId () {
      return this.id || this.userId
    }
  },
  methods: {
    sendNewMessage () {
      this.error = undefined
      if (!this.newMessage.length) {
        this.error = 'Cannot leave an empty comment'
        return
      }
      this.isSendingMessage = true
      this.$http.post(`/api/${this.kind}/comments`, {
        user: this.galleryId,
        token_id: this.galleryId,
        comment: this.newMessage
      })
        .then(() => {
          this.isSendingMessage = false
          this.newMessage = ''
          this.infiniteId++
        })
        .catch((e) => {
          this.isSendingMessage = false
        })
    },
    infiteListHandler ($state) {
      this.$http.get(`/api/${this.kind}/comments`, {
        params: {
          start: this.messages.length,
          end: this.messages.length + this.increment,
          token_id: this.galleryId,
          user: this.galleryId
        }
      }).then(({ data }) => {
        if (data?.metadata.length) {
          this.messages.push(...data.metadata)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  }
}
</script>

<style scoped>
ul {
  list-style-type: none;
  max-width: 600px;
  margin: auto;
}

.error {
  bottom: -30px;
}

textarea {
  height: 80px;
}
</style>
