<template>
  <ul>
    <li>
      <span :class="['knob', this.value === this.min?'hide':'']" @click="decrement()">-</span>
    </li>
    <li class="value">
      <span class="input-wrap">
        <span class="width-machine">{{value}}</span>
        <input
        type="number"
        :min="min"
        :max="max"
        v-model="value"
        @change="onInput"> <!-- (decimal) ? decimal : 1 -->
      </span>
      <slot></slot>
    </li>
    <li>
      <span :class="['knob', this.value === this.max?'hide':'']" @click="increment()">+</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'IntInput',
  props: {
    defaultValue: Number,
    max: Number,
    min: Number,
    step: Number,
    symbol: String,
    delay: Number,
    decimals: {
      type: Number,
      default: 0
    }
  },
  data () {
    return ({
      value: 0
    })
  },
  methods: {
    increment () {
      if (this.value + this.step <= this.max) {
        this.value += this.step
        this.$emit('val', this.value)
      } else if (this.value < this.max) {
        this.value = this.max
        this.$emit('val', this.value)
      }
    },
    decrement () {
      if (this.value - this.step >= this.min) {
        this.value -= this.step
        this.$emit('val', this.value)
      } else if (this.value > this.min) {
        this.value = this.min
        this.$emit('val', this.value)
      }
    },
    onInput (e) {
      if (isNaN(this.value)) {
        this.value = this.min
      } else if (this.value > this.max) {
        this.value = this.max
      } else if (this.value < this.min) {
        this.value = this.min
      }
      this.value = parseFloat(parseFloat(this.value).toFixed(this.decimals))
      this.$emit('val', this.value)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.value = this.defaultValue
    })
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.value{
  background: var(--foreground);
  border-radius: 10px;
  box-shadow: var(--light-shadow);
  user-select: none;
  color: var(--text);
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  padding-right: 20px;
}

.value input[type=number]  {
  margin: 0;
  padding: 0 0 0 20px;
  background: none;
  border: none;
  box-shadow: none;
  border-radius: 0;
  font-weight: 800;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.input-wrap {
  position: relative;
}

.width-machine {
  padding: 10px 0 10px 20px;
  font-weight: 800;
  color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.invalid .value{
  border: 1px solid var(--error);
}

.small .value{
  font-size: 1em;
  padding-top: 10px;
  padding-bottom: 10px;
}

.knob{
  background: var(--foreground);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--accent);
  cursor: pointer;
  margin: 20px;
  font-size: 1.2em;
  transition: all 0.2s ease;
  border: none;
  font-weight: 600;
  user-select: none;
  box-shadow: var(--light-shadow);
}

.small .knob{
  font-size: .8em;
  height: 30px;
  width: 30px;
  margin: 10px;
}

.knob:hover{
  background: var(--accent);
  color: var(--complement);
  box-shadow: var(--accent-shadow);
}

.knob:focus{
  outline: none;
}

.knob.hide{
  opacity: 0;
  pointer-events: none;
}
</style>
