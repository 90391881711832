<template>
  <AppView id="nft-edit" v-if="nft.title && canEdit && mime_type" :key="reload">
    <form @submit.prevent="() => {}">
      <h2>{{$t('nftEdit.title', {token_id: id})}}</h2>
      <p>{{$t('nftEdit.description')}}</p>
      <textarea @keyup.enter.prevent.stop v-model="description" :class="{'invalid':!descriptionValid && error}"
                 maxlength="500"></textarea>
      <ARC69Input :attributes="attributes" class="space-above" :error="error.length && !this.attributesValid"/>
      <p class="space-above">{{$t('nftEdit.settings')}}</p>
      <span class="flex-center">{{$t('menu.nsfw')}}
        <Toggle :default-sate="isNsfw" @toggle="toggleNsfw"/>
      </span>
      <h5 v-if="error" class="error">
        <unicon height="24px" name="exclamation-octagon"/>
        {{ error }}
      </h5>
      <div class="button-container">
        <Button class="secondary">
          <router-link :to="nftUrl">
            {{$t('nftEdit.button.back')}}
          </router-link>
        </Button>
        <Button class="blue" @click.native.prevent="edit()">{{$t('nftEdit.button.validate')}}</Button>
      </div>
      <ContractPopUp v-if='displayContractPopUp' :_state="contractState" :_message='contractMessage' @close="closePopUp"></ContractPopUp>
    </form>
  </AppView>
  <Loader v-else/>
</template>

<script>
import Loader from '@/components/Common/Spinner/Loader'
import { mapState } from 'vuex'
import AppView from '@/components/Layout/AppView'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import ARC69Input from '@/components/Create/ARC69Input'
import Toggle from '@/components/Common/Input/Toggle'
import Contract from '@/web3/Contract'
import Button from '@/components/Common/Button/Button'

export default {
  name: 'NFT',
  components: { Button, Toggle, ARC69Input, ContractPopUp, AppView, Loader },
  props: ['id'],
  data () {
    return {
      nft: {},
      description: '',
      attributes: [],
      canEdit: false,
      mime_type: null,
      isNsfw: false,
      algogemsManager: false,
      displayReportPopUp: false,
      displayContractPopUp: false,
      contractState: 'loading',
      contractMessage: '',
      reload: 0,
      error: false
    }
  },
  computed: {
    ...mapState([
      'wallet',
      'address'
    ]),
    descriptionValid () {
      return true
    },
    nftUrl () {
      return `/nft/${this.id}`
    },
    dataUrl () {
      return `/api/nft/${this.id}`
    },
    attributesValid () {
      for (const x of this.attributes) {
        if (!x.key.length) return false
      }
      const keys = this.attributes.map(x => x.key)
      if (new Set(keys).size !== keys.length) return false
      return true
    },
    noteSizeValid () {
      return this.getNote().length < 1024
    }
  },
  methods: {
    getNote () {
      const properties = {}
      for (const x of this.attributes) {
        properties[x.key] = (x.value.length) ? x.value : 'none'
      }
      const note = {
        creator: this.address,
        description: this.description,
        external_link: this.external_link,
        mime_type: this.mime_type,
        properties: properties,
        sensitive_content: this.isNsfw,
        standard: 'arc69'
      }
      if (this.nft.glyph_id) {
        note.glyph = this.nft.glyph_id
      }
      return new TextEncoder('utf-8').encode(JSON.stringify(note))
    },
    toggleError () {
      this.error = false
      setTimeout(() => {
        if (!this.descriptionValid) {
          this.error = 'Description is invalid'
        } else if (!this.attributesValid) {
          this.error = 'Attributes key cannot be empty nor duplicated'
        } else if (!this.noteSizeValid) {
          this.error = 'Too much informations for your NFT. Please reduce the description or the number of ARC69 properties'
        }
      }, 100)
    },
    edit () {
      if (this.descriptionValid && this.attributesValid && this.noteSizeValid) {
        this.displayContractPopUp = true
        const properties = {}
        for (const x of this.attributes) {
          properties[x.key] = (x.value.length) ? x.value : 'none'
        }
        const asset = {
          mime_type: this.mime_type,
          properties: properties,
          description: this.description,
          nsfw: this.isNsfw,
          external_link: this.external_link,
          token_id: parseInt(this.id),
          note: this.getNote()
        }
        if (this.algogemsManager) {
          this.$http.post(`/api/nft/edit/${this.id}`, asset)
            .then((m) => {
              console.log('done')
              this.contractState = 'done'
            })
            .catch((e) => {
              this.contractState = 'error'
              this.contractMessage = JSON.stringify(e)
            })
        } else {
          Contract.editASA(this.wallet, this.address, asset)
            .then((m) => {
              this.contractState = 'done'
            })
            .catch((e) => {
              this.contractState = 'error'
              this.contractMessage = JSON.stringify(e)
            })
        }
      } else {
        this.toggleError()
      }
    },
    toggleNsfw (state) {
      this.isNsfw = state
    },
    fetchMimeType () {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', this.external_link, true)
      xhr.responseType = 'blob'
      xhr.onload = (e) => {
        this.mime_type = xhr.response.type
      }
      xhr.send()
    },
    fetchData () {
      this.$http.get(this.dataUrl).then(response => {
        if (response.data && response.data.nft) {
          for (const key of Object.keys(response.data.nft)) {
            this.$set(this.nft, key, response.data.nft[key])
          }
          console.log(this.nft)
          this.canEdit = response.data.edit
          if (!this.canEdit) {
            this.$router.push(this.nftUrl)
            return
          }
          this.algogemsManager = response.data.algogems_manager
          this.description = response.data.nft.description
          this.attributes = []
          if (response.data.attribute) {
            this.attributes.push(...response.data.attribute)
          }
          this.external_link = response.data.nft.ipfs_link

          this.isNsfw = response.data.nft.nsfw

          this.fetchMimeType()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    closePopUp () {
      this.displayContractPopUp = false
      if (this.contractState === 'done') {
        this.$router.push(`/nft/${this.id}`)
        this.forceReload()
      }
    },
    forceReload () {
      this.reload += 1
      this.fetchData()
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}

form {
  position: relative;
  max-width: 452px;
  margin: auto;
}

.error {
  bottom: 121px;
}

.lifted-text {
  margin-left: 5px;
  margin-top: 4px;
}
</style>
