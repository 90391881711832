<template>
  <Section class="crescendo-form" v-if="state === 'init'">
    <h1>Service Form</h1>
    <form @submit.prevent.stop>
      <span>Email:</span>
      <input v-model="email" :class="{'invalid':!emailValid && error}" placeholder="Email" type="text">

      <template v-if="custom">
        <span>Description of the service you need:</span>
        <textarea v-model="description" :class="{'invalid':!descriptionValid && error}" placeholder="Description" max="500"/>

        <span>Ideal budget (in $):</span>
        <input v-model="budget" :class="{'invalid':!budgetValid && error}" type="number" min="1">
      </template>

      <span>Ideal deliver date:</span>
      <input v-model="date" :class="{'invalid':!dateValid && error}" type="date">

      <h5 v-if="error"><unicon name="exclamation-octagon"/> {{error}}</h5>
      <Button  @click.native="submit">Submit</Button>
    </form>
  </Section>
  <Section v-else-if="state === 'loading'">
    <LinearSpinner/>
  </Section>
  <Section v-else-if="state === 'done'">
    <SuccessCheckmark/>
    <Button @click.native="state = 'init'">Back</Button>
  </Section>
  <Section v-else class="error-section">
    <h1><unicon name="exclamation-octagon"/> Error</h1>
    <p>An error occured, please retry. If the error persist contact us.</p>
    <p>{{error}}</p>
    <Button @click.native="state = 'init'">Back</Button>
  </Section>
</template>

<script>
import Section from '../Layout/Section'
import Button from '../Common/Button/Button'
import LinearSpinner from '../Common/Spinner/LinearSpinner'
import SuccessCheckmark from '../Assets/SuccessCheckmark'
import { mapState } from 'vuex'

export default {
  name: 'CRSDServiceForm',
  components: { SuccessCheckmark, LinearSpinner, Button, Section },
  data () {
    return {
      custom: false,
      id: this.$route.params.id,
      email: '',
      description: '',
      budget: 0,
      date: '2022-05-16',
      error: undefined,
      state: 'loading'
    }
  },
  computed: {
    ...mapState(['address']),
    emailValid () {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email)
    },
    descriptionValid () {
      return this.description.length > 0
    },
    budgetValid () {
      return this.budget > 0
    },
    dateValid () {
      return true
    },
    dataUrl () {
      return `/api/nft/${this.id}`
    }
  },
  methods: {
    toggleError () {
      if (!this.emailValid) {
        this.error = 'Invalid email'
      } else if (!this.descriptionValid) {
        if (this.description.length === 0) {
          this.error = 'Please add a description'
        } else {
          this.error = 'Invalid description'
        }
      } else if (!this.budgetValid) {
        this.error = 'Budget must be over 0$'
      } else if (!this.dateValid) {
        this.error = 'Select a future date'
      }
    },
    submit () {
      this.state = 'loading'
      this.error = undefined
      if (!this.emailValid || !this.descriptionValid || !this.budgetValid || !this.dateValid) {
        this.toggleError()
        this.state = 'init'
        return
      }

      const json = {
        email: this.email,
        description: this.description,
        budget: this.budget,
        date: this.date,
        nftid: this.id,
        algo_address: this.address
      }
      this.$http.post('/api/crescendo/form/service', json)
        .then(() => {
          this.state = 'done'
        })
        .catch(error => {
          this.state = 'error'
          this.error = error.message
          console.error('Error!', error.message)
        })
    },
    loadNftData () {
      this.$http.get(this.dataUrl).then(response => {
        if (response.data && response.data.nft) {
          if (response.data.nft.crescendo !== 'custom_service' && response.data.nft.crescendo !== 'service') {
            this.$router.push(`/nft/${this.id}`)
          }

          if (response.data.nft.number === 0) {
            // this.$router.push(`/nft/${this.id}`)
          }

          if (response.data.nft.crescendo === 'custom_service') {
            this.custom = true
          } else {
            this.description = response.data.nft.title
          }
        }
        this.state = 'init'
      }).catch((e) => { console.log(e) })
    }
  },
  beforeMount () {
    this.loadNftData()
  }
}
</script>

<style scoped>
.crescendo-form {
  max-width: 600px;
  margin: auto;
}

form {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  min-width: 500px;
  grid-column-gap: 10px;
  padding-right: 75px;
}

form > *:nth-child(odd):not(h5):not(button) {
  align-self: center;
  justify-self: right;
  max-width: 150px;
}

form > *:nth-child(even):not(button) {
  align-self: center;
  justify-self: left;
  width: 100%;
}

form button, form h5 {
  grid-column: span 2;
}

h5 {
  color: var(--error);
  display: flex;
  align-items: center;
  justify-content: center;
}

h5 .unicon {
  fill: var(--error);
  margin-right: 5px;
}

.error-section {
  max-width: 500px;
  margin: auto;
}

.error-section h1 {
  color: var(--error);
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-section h1 .unicon {
  fill: var(--error);
  margin-right: 5px;
}
</style>
