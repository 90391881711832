<template>
  <div id="auth_wallet">
    <unicon height="100px" name="keyhole-circle" width="100px"/>
    <p>
      <b>{{$t('authentificateUnlock.header')}}</b>
    </p>
    <h5 v-html="$t('authentificateUnlock.body')"></h5>
  </div>
</template>

<script>
import Contract from '@/web3/Contract'
import { mapState } from 'vuex'

export default {
  name: 'AuthenticateUnlock',
  data () {
    return {
      unlockTimeout: null
    }
  },
  computed: {
    ...mapState([
      'wallet'
    ])
  },
  methods: {
    unlockWallet () {
      if (this.wallet !== null) {
        Contract.address(this.wallet).then(addresses => {
          if (addresses.length) {
            this.$emit('next', { addresses })
          } else {
            console.log('Error address length is null', addresses)
          }
        }).catch((e) => {
          console.log('Error address', e)
          this.unlockTimeout = setTimeout(() => {
            this.unlockWallet()
          }, 1000)
        })
      } else {
        this.$emit('previous')
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.unlockWallet()
    })
  },
  beforeDestroy () {
    if (this.unlockTimeout) {
      clearTimeout(this.unlockTimeout)
    }
  }
}
</script>

<style scoped>
#auth_wallet{
  text-align: center;
  background: var(--foreground);
  border-radius: 20px;
  box-shadow: var(--light-shadow);
  padding: 50px;
}
.unicon {
  fill: var(--text);
}
</style>
