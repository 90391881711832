import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function startShuffle (APIName, from, shuffle) {
  try {
    console.log(shuffle)
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()

    const appArgs3 = []
    appArgs3.push(new TextEncoder().encode('start'))

    const foreignAssets = []
    if (shuffle.whitelist) {
      foreignAssets.push(shuffle.token_id)
    }
    if (shuffle.asaId !== 1) {
      foreignAssets.push(shuffle.asaId)
    }
    const txn =
      algosdk.makeApplicationNoOpTxn(
        from,
        suggestedParams,
        shuffle.app_id, appArgs3,
        [],
        undefined, foreignAssets,
        undefined, undefined, undefined)

    const d = await API.sign(txn)
    const message = {
      signed_tx: [d]
    }
    return Utils._callServer('/api/sdk/send_all', message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default startShuffle
