import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function updateSale (APIName, from, tokenId, price) {
  const API = Utils._getAPI(APIName)

  const parsed = await Utils._callServer('/api/sale/update/check', { token_id: tokenId })

  if (parsed.version === 1) {
    return updateSaleV1(
      tokenId, price
    )
  }

  const suggestedParams = await Utils.getParams()

  const appArgs = []
  appArgs.push(new TextEncoder().encode('update_price'))
  appArgs.push(Utils.longToByteArray(price * 1_000_000))
  const txn = algosdk.makeApplicationNoOpTxn(
    from, suggestedParams, parsed.app_id, appArgs, undefined, undefined, undefined, undefined, undefined)
  const signedTx = await API.sign(txn)
  const message = {
    token_id: tokenId,
    price: price,
    signed_tx: signedTx
  }
  return Utils._callServer('/api/sale/update/validate', message)
}

async function updateSaleV1 (tokenId, price) {
  const message = {
    token_id: tokenId,
    price: price
  }
  return Utils._callServer('/api/sell/update', message)
}

export default updateSale
