<template>
  <form @keyup.enter="next()">
    <h1 class="medium">#{{$t('createAuctionSettings.settings')}}</h1>
    <p>{{$t('createAuctionSettings.auctionStartPrice')}}</p>
    <div class="spotlight">
      <IntInput :default-value="price" :max="1000000" :min="5" :step="10" @val="(val) => { this.price = val }" :class="{'invalid':!priceValid && error}"><ASALogo :asaName="'algo'"/></IntInput>
    </div>
    <div class="vertical-flex-center" v-if="data.isMintOnUserSide">
      <span class="flex-center">{{$t('createAuctionSettings.label.auction')}}
        <Toggle :default-sate="isAuction" @toggle="(state)=>{isAuction = state}"/>
      </span>
      <span v-if="isAuction">
        <p>{{$t('createAuctionSettings.durations')}}</p>
        <IntInput :default-value="duration" :max="200" :min="0" :step="24" class="small"
                  @val="(val) => { this.duration = val }" :class="{'invalid':!durationValid && error}">h</IntInput>
      </span>
    </div>
    <h5 v-if="error" class="error">
      <unicon height="24px" name="exclamation-octagon"/>
      {{ error }}
    </h5>
    <div class="flex-center">
      <Button v-if="isAuction" class="blue" @click.native.prevent="next()">{{$t('createAuctionSettings.button.auction')}}</Button>
      <Button v-else class="blue" @click.native.prevent="next()">{{$t('createAuctionSettings.button.sell')}}</Button>
    </div>
  </form>
</template>

<script>
import Button from '@/components/Common/Button/Button'
import IntInput from '@/components/Common/Input/IntInput'
import Toggle from '@/components/Common/Input/Toggle'
import Contract from '@/web3/Contract'
import { mapState } from 'vuex'
import ASALogo from '../../../components/Assets/ASALogo'

export default {
  name: 'CreateAuctionSettings',
  props: {
    data: Object
  },
  components: {
    ASALogo,
    Toggle,
    IntInput,
    Button
  },
  data () {
    return ({
      error: false,
      price: 5,
      duration: 24,
      isAuction: this.data.isMintOnUserSide
    })
  },
  computed: {
    ...mapState([
      'wallet'
    ]),
    priceValid () {
      return Number.isInteger(this.price) && this.price >= 5 && this.price <= 1000000
    },
    durationValid () {
      return Number.isInteger(this.duration) && this.duration >= 0 && this.duration <= 200
    }
  },
  methods: {
    toggleError () {
      this.error = false
      setTimeout(() => {
        if (!this.priceValid) {
          this.error = 'Price is invalid'
        }
        if (!this.durationValid) {
          this.error = 'Duration is invalid'
        }
      }, 100)
    },
    next () {
      if (this.priceValid && (!this.isAuction || this.durationValid)) {
        this.$emit('loading')
        if (this.data.isMintOnUserSide) {
          if (this.isAuction) {
            Contract.auction(this.wallet, this.data.token_id, this.price, this.duration)
              .then(this.end)
              .catch((err) => {
                this.$emit('error', err.response)
              })
          } else {
            Contract.sell(this.wallet, this.data.token_id, this.price)
              .then(this.end)
              .catch((err) => {
                this.$emit('error', err.response)
              })
          }
        } else {
          const message = {
            price: this.price,
            token_id: this.data.token_id
          }
          let api = '/api/create/sell'
          if (this.isAuction) {
            message.duration = this.duration
            api = '/api/create/auction'
          }

          this.$http.post(api, message).then(response => {
            this.end()
          }).catch(err => {
            this.$emit('error', err.response)
          })
        }
      } else {
        this.toggleError()
      }
    },
    end () {
      localStorage.removeItem('nft_title')
      localStorage.removeItem('nft_description')
      localStorage.removeItem('nft_tags')
      localStorage.removeItem('nft_private')
      this.$emit('confirm', '/nft/' + this.data.token_id)
    },
    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    }
  },
  mounted () {
    /* if (!this.data?.picture) {
      this.$emit('previous')
    } */
  }
}
</script>

<style scoped>
.spotlight{
  background: var(--lightgradient);
  border-radius: 20px;
  padding: 5px;
  margin-bottom: 20px;
}

.vertical-flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
