<template>
  <AppView id="gallery" v-if="gallery">
    <template v-slot:header>
      <Banner :id="gallery.account_id" :version="gallery.v_banner"/>
      <div class="head">
          <button class="top-left-button" @click="toggleFollow" v-if="!isAddress">
            <unicon name="favorite" width="30px" height="30px" v-if="!isFollowing"/>
            <unicon name="favorite" width="30px" height="30px" icon-style="monochrome" v-else/>
          </button>
        <UserPicture :address="gallery.addresses[0]" :account-id="gallery.account_id" :version="gallery.v_pp"/>
        <h1 class="username" :class="(hasCover) ? 'light' : ''">
          <span v-if="gallery.username" >@{{gallery.username}}</span>
          <Address v-else :address="gallery.addresses[0]"/>
          <CopyButton :link="shareLink" />

          <VerifiedLogo v-if="gallery.is_verify"/>
          <template v-if="gallery && gallery.email">
            <ContactButton :_socialMedia="gallery.email"/>
          </template>
        </h1>
        <p v-if="gallery && gallery.description">{{gallery.description}}</p>
        <ul class="addresses">
          <li v-for="address in gallery.addresses" :key="address">
            {{ address }}<CopyButton :link="address" class="smaller" />
          </li>
          <li class="nfd-address" v-if="gallery.nfd">
            <img src="@/assets/nfd_logo.png"> {{gallery.nfd}}
          </li>
        </ul>
        <p v-if="gallery.gems_optin">
            <TextualButton @click.native="tip">{{$t('gallery.tip')}}<ASALogo :asaName="'gems'"/></TextualButton>
        </p>
        <div class="followers-container">
          <router-link :to="`${baseUrl}/follower`">{{gallery.follower}} follower<template v-if="gallery.follower >1">s</template></router-link>
          <span class="separator"></span>
          <router-link :to="`${baseUrl}/following`">{{gallery.following}} following</router-link>
        </div>
      </div>
    </template>

    <nav>
      <router-link :to="`${baseUrl}`">
        <unicon name="estate"/>
        {{$t('userGalleryNav.home')}}
      </router-link>
      <router-link :to="`${baseUrl}/sales`" v-if="hasSales">
        <unicon name="usd-circle"/>
        {{$t('userGalleryNav.sales')}}
      </router-link>
      <router-link :to="`${baseUrl}/auctions`" v-if="hasAuctions">
        <unicon name="arrow-growth"/>
        {{$t('userGalleryNav.auctions')}}
      </router-link>
      <router-link :to="`${baseUrl}/shuffles`"  v-if="hasShuffles">
        <unicon name="shuffle"/>
        Shuffles
      </router-link>
      <router-link :to="`${baseUrl}/creations`" v-if="hasCreations">
        <unicon name="image-edit"/>
        {{$t('userGalleryNav.creations')}}
      </router-link>
      <router-link :to="`${baseUrl}/collections`" v-if="hasCollections">
        <unicon name="folder"/>
        {{$t('userGalleryNav.collections')}}
      </router-link>
      <router-link :to="`${baseUrl}/ownedNft`" v-if="hasOwnedNFT">
        <unicon name="image-check"/>
        {{$t('userGalleryNav.owned')}}
      </router-link>
      <router-link :to="`${baseUrl}/fusions`" v-if="hasFusions">
        <unicon name="pathfinder-unite"/>
        Fusions
      </router-link>
      <router-link :to="`${baseUrl}/packs`" v-if="hasPacks">
        <unicon name="layers"/>
        Packs
      </router-link>
      <router-link :to="`${baseUrl}/instagram`" v-if="gallery.instagram">
        <unicon name="instagram"/>
        Instagram
      </router-link>
      <router-link :to="`${baseUrl}/community`">
        <unicon name="users-alt"/>
        Community
      </router-link>
      <router-link :to="`${baseUrl}/stats`">
        <unicon name="dashboard"/>
        {{$t('userGalleryNav.stats')}}
      </router-link>
    </nav>

    <ContractPopUp v-if='displayContractPopUp' :_kind="contractKind" :_price='contractPrice' :_toAddress='contractToAddress' @done='forceReload' @close="displayContractPopUp = false"/>
    <router-view :id="galleryId"></router-view>
  </AppView>
  <Loader v-else/>
</template>

<script>
import Address from '@/components/User/Address'
import Loader from '@/components/Common/Spinner/Loader'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import Banner from '@/components/User/Banner'
import CopyButton from '@/components/Common/Button/CopyButton'
import AppView from '@/components/Layout/AppView'
import ContactButton from '@/components/Common/Button/ContactButton'
import TextualButton from '@/components/Common/Button/TextualButton'
import VerifiedLogo from '@/components/Assets/VerifiedLogo'
import UserPicture from '@/components/User/UserPicture'
import { mapMutations } from 'vuex'
import ASALogo from '@/components/Assets/ASALogo'

export default {
  name: 'Gallery',
  components: { ASALogo, UserPicture, VerifiedLogo, TextualButton, ContactButton, AppView, CopyButton, Banner, Loader, Address, ContractPopUp },
  props: {
    id: String
  },
  data () {
    return {
      gallery: null,
      hasCover: false,
      increment: 1,
      description: '',
      hasAuctions: false,
      hasSales: false,
      hasCollections: false,
      hasOwnedNFT: false,
      hasCreations: false,
      hasShuffles: false,
      hasFusions: false,
      hasPacks: false,
      username: '',
      pictureProfileFile: undefined,
      displayContractPopUp: false,
      contractState: 'needPrice',
      contractMessage: '',
      contractKind: 'tip',
      contractPrice: 1,
      contractToAddress: '',
      contractTokenId: 0,
      isContractAlgo: true,
      reload: 0,
      isFollowing: false,
      infiniteId: 0,
      collectionSettings: {
        itemsToShow: 1,
        centerMode: true,
        infiniteScroll: true,
        autoPlay: true,
        wheelControl: false,
        breakpoints: {
          1200: {
            itemsToShow: 3
          },
          950: {
            itemsToShow: 2.5
          },
          750: {
            itemsToShow: 2
          }
        }
      }
    }
  },
  watch: {
    galleryId () {
      this.gallery = null
      this.auctions = []
      this.sales = []
      this.collections = []
      this.ownedNFT = []
      this.username = ''
      this.$http.get(this.apiUrl).then(response => {
        if (response.data) {
          this.gallery = response.data
        }
      }).catch(err => {
        console.log(err)
      })
      this.fetchNFTData()
    }
  },
  computed: {
    galleryId () {
      return this.id
    },
    shareLink () {
      return window.location.origin + this.$route.path
    },
    isAddress () {
      return this.galleryId.length === 58
    },
    gemsNumber () {
      if (this.gallery.gems_number < 1000) return Math.round(this.gallery.gems_number)
      if (this.gallery.gems_number < 1000000) {
        return Math.round(this.gallery.gems_number / 1000).toString() + 'k'
      } else {
        return Math.round(this.gallery.gems_number / 1000000).toString() + 'm'
      }
    },
    baseUrl () {
      return `/gallery/${this.galleryId}`
    },
    apiUrl () {
      return `/api/account/info/${this.galleryId}`
    },
    auctionsUrl () {
      return `/api/auctions/${this.galleryId}`
    },
    salesUrl () {
      return `/api/sales/${this.galleryId}`
    },
    collectionsUrl () {
      return `/api/collections/${this.galleryId}`
    },
    ownedNFTUrl () {
      return `/api/gallery/${this.galleryId}`
    },
    creationUrl () {
      return `/api/creation/${this.galleryId}`
    },
    createCollectionURL () {
      return '/create_collection'
    },
    shuffleUrl () {
      return '/api/shuffle'
    },
    fusionUrl () {
      return `/api/fusion/user/${this.galleryId}`
    },
    packUrl () {
      return `/api/pack/user/${this.galleryId}`
    }
  },
  methods: {
    ...mapMutations(['login', 'logout']),
    tip () {
      this.contractKind = 'tip'
      this.contractPrice = 5
      this.contractToAddress = this.gallery.addresses[0]
      this.isContractAlgo = false
      this.displayContractPopUp = true
    },
    toggleFollow () {
      const message = { username: this.galleryId }
      if (this.isFollowing) {
        this.$http.post('/api/account/unfollow', message)
          .then(() => {
            this.isFollowing = false
          })
      } else {
        this.$http.post('/api/account/follow', message)
          .then(() => {
            this.isFollowing = true
          })
      }
    },
    forceReload () {
      // this.fetchNFTData()
      this.reload++
    },
    fetchNFTData () {
      const params1 = {
        start: 0,
        end: 1,
        sort: 'new'
      }
      const params2 = {
        start: 0,
        end: 1,
        sort: 'new',
        nsfw: true
      }

      const params3 = {
        start: 0,
        end: 1,
        sort: 'new',
        primary: true
      }
      const params4 = {
        start: 0,
        end: 1,
        sort: 'new',
        nsfw: true,
        primary: true
      }

      this.$http.get(this.auctionsUrl, {
        params: params1
      }).then(({ data }) => {
        if (data.auctions.length > 0) this.hasAuctions = true
      })
      this.$http.get(this.auctionsUrl, {
        params: params2
      }).then(({ data }) => {
        if (data.auctions.length > 0) this.hasAuctions = true
      })
      this.$http.get(this.auctionsUrl, {
        params: params3
      }).then(({ data }) => {
        if (data.auctions.length > 0) this.hasAuctions = true
      })
      this.$http.get(this.auctionsUrl, {
        params: params4
      }).then(({ data }) => {
        if (data.auctions.length > 0) this.hasAuctions = true
      })

      this.$http.get(this.salesUrl, {
        params: params1
      }).then(({ data }) => {
        console.log(data)
        if (data.sales.length > 0) this.hasSales = true
      })
      this.$http.get(this.salesUrl, {
        params: params2
      }).then(({ data }) => {
        if (data.sales.length > 0) this.hasSales = true
      })
      this.$http.get(this.salesUrl, {
        params: params3
      }).then(({ data }) => {
        console.log(data)
        if (data.sales.length > 0) this.hasSales = true
      })
      this.$http.get(this.salesUrl, {
        params: params4
      }).then(({ data }) => {
        if (data.sales.length > 0) this.hasSales = true
      })

      this.$http.get(this.collectionsUrl, {
        params: params1
      }).then(({ data }) => {
        if (data.metadata.length > 0) this.hasCollections = true
      })
      this.$http.get(this.collectionsUrl, {
        params: params2
      }).then(({ data }) => {
        if (data.metadata.length > 0) this.hasCollections = true
      })

      this.$http.get(this.ownedNFTUrl, {
        params: {
          start: 0,
          end: 9,
          sort: 'new'
        }
      }).then(({ data }) => {
        if (data.metadata.filter(x => x !== null).length > 0) this.hasOwnedNFT = true
      })
      this.$http.get(this.ownedNFTUrl, {
        params: {
          start: 0,
          end: 9,
          sort: 'new',
          nsfw: true
        }
      }).then(({ data }) => {
        if (data.metadata.filter(x => x !== null).length > 0) this.hasOwnedNFT = true
      })

      this.$http.get(this.creationUrl, {
        params: params1
      }).then(({ data }) => {
        if (data.metadata.length > 0) this.hasCreations = true
      })
      this.$http.get(this.creationUrl, {
        params: params2
      }).then(({ data }) => {
        if (data.metadata.length > 0) this.hasCreations = true
      })

      params1.user = this.galleryId
      this.$http.get(this.shuffleUrl, {
        params: params1
      }).then(({ data }) => {
        if (data.metadata.length > 0) this.hasShuffles = true
      })
      params2.user = this.galleryId
      this.$http.get(this.shuffleUrl, {
        params: params2
      }).then(({ data }) => {
        if (data.metadata.length > 0) this.hasShuffles = true
      })

      this.$http.get(this.fusionUrl, { params: { start: 0, end: 9 } }).then(
        ({ data }) => {
          if (data.metadata.length > 0) this.hasFusions = true
        }
      )

      this.$http.get(this.packUrl, { params: { start: 0, end: 9 } }).then(
        ({ data }) => {
          if (data.metadata.length > 0) this.hasPacks = true
        }
      )
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$http.get(this.apiUrl).then(response => {
        if (response.data) {
          this.gallery = response.data
          this.isFollowing = response.data.follow
        }
      }).catch(err => {
        console.log(err)
      })
      this.fetchNFTData()
    })
  }
}
</script>

<style scoped>
h1 {
  color: var(--text);
}

h5 {
  font-size: medium;
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--textlight)
}

a:hover {
  color: var(--text)
 }

button {
  z-index: 2;
}

.head{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin: -50px auto 20px auto;

}

.username {
  text-transform: initial;
  margin-bottom: 0px;
  z-index: 2;
  color: var(--text);
}

.username a{
  margin-left: 10px;
}

.addresses{
  padding: 0;
  margin: 5px;
  list-style: none;
  text-transform: uppercase;
  z-index: 2;
  word-break: break-all;
}

.addresses li {
  padding: 5px 10px;
  color: var(--textlight);
  border-radius: 10px;
  margin: 5px;
  text-align: center;
}

.followers-container {
  margin-top: 20px;
  padding: 7px 16px;
  border-radius: 15px;
  color: var(--text);
  background: var(--foreground);
  box-shadow: var(--shadow);
  z-index: 2;
}

.followers-container a {
  margin: 10px;
}

.followers-container .separator {
  height: 80%;
  border-left: solid 1px var(--textlight);
  margin: 0 5px;
}

.top-left-button {
  position: absolute;
  top: 60px;
  left: 10px;
  background: none;
  border: none;
}

.top-left-button .unicon {
  fill: var(--text);
  opacity: 0.75;
}

.top-left-button .unicon:hover {
  opacity: 1;
}

.top-right-button {
  position: absolute;
  top: 60px;
  right: 10px;
  border: none;
  background: none;
}

.top-right-button .unicon {
  fill: var(--text);
  opacity: 0.75;
}

.top-right-button .unicon:hover {
  opacity: 1;
}

.top-right-button:focus {
  outline: none;
}

.head.light .unicon {
  fill: var(--text);
  opacity: 0.8;
}

.head.light .unicon:hover {
  opacity: 1;
}

nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 90vw;
  margin-bottom: 30px;
}

nav a {
  color: var(--textlight);
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

nav a .unicon {
  margin-right: 7px;
  fill: var(--textlight);
}

nav a:hover {
  box-shadow: var(--accent-shadow);
  background: var(--foreground);
  color: var(--text);
}

nav a:hover .unicon {
  fill: var(--text);
}

nav a.router-link-exact-active {
  color: var(--accent);
  box-shadow: var(--accent-shadow);
  background: var(--foreground)
}

nav a.router-link-exact-active .unicon {
  fill: var(--accent);
}

.textual-button {
  margin: auto;
  margin-top: 5px;
}

.textual-button .gems-logo {
  top: 1px;
}

.verified-logo {
  margin-left: 10px;
}

.nfd-address img {
  height: 0.8em;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .auction-sale-container {
    padding: 25px;
    box-sizing: border-box;
    width: 100%;
  }

  nav {
    margin: auto;
  }
}
</style>
