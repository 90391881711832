<template>
  <div class="section">
    <div class="section-header">
      <slot name="header"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Section'
}
</script>

<style scoped>
.section{
  margin-bottom: 50px;
}

.section-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30px; /* matches the other heights for See more */
}

.section-header h3{
  color: var(--textlight);
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .section-header {
    padding: 0 25px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>
