<template>
<div>
  <nav>
    <router-link :to="`${baseUrl}/pending`">
      <unicon name="money-withdrawal"/>
      Offers
    </router-link>
    <router-link :to="`${baseUrl}/pending/bids`">
      <unicon name="chart-line"/>
      {{ $t('userGalleryNav.bids') }}
    </router-link>
    <router-link :to="`${baseUrl}/pending/staked`" v-if="false">
      <unicon name="jackhammer"/>
      Staked
    </router-link>
    <button v-if="gallery.claim" @click="claimRoyalties">
      <unicon name="arrow-to-bottom"/>Claim All
    </button>
  </nav>
  <router-view></router-view>
  <ContractPopUp _kind="claimRoyalties" v-if="displayContractPopUp"
                 @close="displayContractPopUp = false"/>
</div>
</template>

<script>
import ContractPopUp from '../Common/PopUp/ContractPopUp'

export default {
  name: 'Pending',
  components: { ContractPopUp },
  props: ['gallery'],
  data () {
    return {
      displayContractPopUp: false
    }
  },
  computed: {
    baseUrl () {
      return '/user'
    }
  },
  methods: {
    claimRoyalties () {
      this.displayContractPopUp = true
    }
  }
}
</script>

<style scoped>
button {
  background: none;
  border: none;
  cursor: pointer;
}

nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 90vw;
  margin-bottom: 30px;
}

nav a, nav button {
  color: var(--textlight);
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

nav a .unicon, nav button .unicon {
  margin-right: 7px;
  fill: var(--textlight);
}

nav a:hover, nav button:hover {
  box-shadow: var(--light-shadow);
  color: var(--text);
}

nav a:hover .unicon, nav button:hover .unicon {
  fill: var(--text);
}

nav a.router-link-exact-active {
  color: var(--accent);
  box-shadow: var(--accent-shadow);
  background: var(--foreground)
}

nav a.router-link-exact-active .unicon {
  fill: var(--accent);
}
</style>
