/* eslint-disable no-undef */
const algosdk = require('algosdk-latest')
const encoder = require('uint8-to-base64')

module.exports = class ExodusAPI {
  static name = 'exodus'
  static async connect () {
    if (typeof window.exodus === 'undefined') {
      alert('Exodus is not installed. Please install it.')
      return
    }
    return await window.exodus.algorand.connect()
  }

  static logout () {
    if (ExodusAPI.wallet) {
      return new Promise((resolve, reject) => {
        ExodusAPI.wallet.disconnect()
          .then(() => {
            ExodusAPI.wallet = null
            localStorage.clear()
            resolve()
          })
          .catch(reject)
      })
    } else {
      return new Promise((resolve, reject) => { resolve() })
    }
  }

  static async account () {
    await ExodusAPI.connect()
    return [{ address: window.exodus.algorand.address }]
  }

  static async sign (txn, getUint8 = false) {
    if (!window.exodus.algorand.isConnected) await ExodusAPI.connect()
    const binaryTx = txn.toByte()
    const d = await window.exodus.algorand.signTransaction([binaryTx])
    const stx = { blob: d[0] }
    if (!getUint8) {
      stx.blob = encoder.encode(stx.blob)
    }
    return stx
  }

  static async signGroup (txns, isSigning, isGrouped = true) {
    if (!window.exodus.algorand.isConnected) await ExodusAPI.connect()
    let toSign = []
    if (isGrouped) {
      const txngroup = algosdk.assignGroupID(txns)
      for (let i = 0; i < txns.length; i++) {
        txns[i].group = txngroup[i].group
        if (isSigning[i]) {
          toSign.push(txns[i].toByte())
        }
      }
    } else {
      toSign = txns.map(x => x.toByte())
    }
    let d = await window.exodus.algorand.signTransaction(toSign)
    d = d.map(x => { return { blob: x } })
    for (const stx of d) {
      stx.blob = encoder.encode(stx.blob)
    }
    return d
  }
}
