<template>

  <div id="auth_wallet">
    <h1>{{$t('authentificateWallet.header')}}</h1>
    <ul>
      <!--<li @click="selectMyAlgo">
        <img src="@/assets/myalgo.png" alt="myalgo"/>
      </li>
      <li @click="selectAlgoSigner" style="animation-delay: 150ms">
        <img class="algosigner" :class="(dark)? 'dark' : ''" src="@/assets/algosigner.png" alt="algosigner"/>
      </li>-->
      <li @click="selectAlgoWallet" style="animation-delay: 150ms">
        <img class="pera" :class="(dark)? 'dark' : ''" src="@/assets/pera-wallet.svg" alt="pera wallet"/>
      </li>
      <li @click="selectExodusWallet" style="animation-delay: 150ms">
        <img src="@/assets/Exodus_logo_white.png" alt="exodus wallet" v-if="dark"/>
        <img src="@/assets/Exodus_logo_dark.png" alt="exodus wallet" v-else/>
      </li>
      <li @click="selectDeflyWallet" style="animation-delay: 150ms">
        <img src="@/assets/deflywallet.svg" style="filter: invert(1)" alt="defly wallet" v-if="dark"/>
        <img src="@/assets/deflywallet.svg" alt="delfy wallet" v-else/>
      </li>
    </ul>
    <a href="https://algogemsnft.github.io/#new-to-blockchain" target="_blank" class="smaller-text"><unicon name="question-circle"/>New to the Algorand Blockchain? We got you covered.</a>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'AuthenticateWallet',
  computed: {
    ...mapState(['dark']),
    isAlgoSignerInstalled () {
      try {
        // eslint-disable-next-line no-undef
        return typeof AlgoSigner !== 'undefined'
      } catch {
        return false
      }
    }
  },
  methods: {
    ...mapMutations([
      'setWallet'
    ]),
    /* selectAlgoSigner () {
      if (!this.isAlgoSignerInstalled) {
        window.open('https://chrome.google.com/webstore/detail/algosigner/kmmolakhbgdlpkjkcjkebenjheonagdm', '_blank').focus()
      } else {
        this.setWallet({ wallet: 'algosigner' })
        this.next()
      }
    },
    selectMyAlgo () {
      this.setWallet({ wallet: 'myalgo' })
      this.next()
    }, */
    selectAlgoWallet () {
      this.setWallet({ wallet: 'algowallet' })
      this.next()
    },
    selectExodusWallet () {
      this.setWallet({ wallet: 'exodus' })
      this.next()
    },
    selectDeflyWallet () {
      this.setWallet({ wallet: 'defly' })
      this.next()
    },
    next () {
      this.$emit('next')
    }
  }
}
</script>

<style scoped>

#auth_wallet ul{
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

#auth_wallet li{
  text-align: center;
  background: var(--foreground);
  border-radius: 20px;
  box-shadow: var(--light-shadow);
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  cursor: pointer;
  transition: all .2s ease;
  animation: card-appear .5s ease backwards;
}

#auth_wallet li:hover{
  transform: scale(1.05);
}

img {
  max-height: 50px;
  max-width: 100px;
}

img.pera.dark {
  filter: invert(1);
}

img.algosigner.dark {
  filter: brightness(40);
}

a {
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  opacity: 0.7;
}

a:hover {
  opacity: 1;
}

a .unicon {
  fill: var(--accent);
  margin-right: 5px;
}

a.smaller-text {
  font-size: smaller;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {

  #auth_wallet {
    position: relative;
    justify-content: center;
    top: 30px;
  }

  a.smaller-text {
    margin-top: 10px;
    margin-bottom: 75px;
  }

  #auth_wallet ul{
    flex-direction: column;
  }

  #auth_wallet li{
    height: 60px;
    width: 250px;
    margin: 10px 0;
  }
}
</style>
