<template>
  <AppView>
    <div class="head">
      <h1>Create Fusion</h1>
      <CardGrid style="min-width: 555px; max-width: 700px">
        <Card :data="first" v-if="first">
          <div class="remove-button" @click.stop.prevent="first=undefined">
            <unicon height="30" width="30" name="times-circle"/>
          </div>
        </Card>
        <li class="empty-card" v-else>
          Select an NFT below or<br>
          enter an NFT id here
          <input type="number" min="0" v-model="ids.first">
          <Button @click.native="add('first')">Add</Button>
        </li>
        <Card :data="second" v-if="second">
          <div class="remove-button" @click.stop.prevent="second=undefined">
            <unicon height="30" width="30" name="times-circle"/>
          </div>
        </Card>
        <li class="empty-card" v-else>
          Select an NFT below or<br>
          enter an NFT id here
          <input type="number" min="0" v-model="ids.second">
          <Button @click.native="add('second')">Add</Button>
        </li>
        <Card :data="third" v-if="third">
          <div class="remove-button" @click.stop.prevent="third=undefined">
            <unicon height="30" width="30" name="times-circle"/>
          </div>
        </Card>
        <li class="empty-card" v-else>
          Select an NFT below or<br>
          enter an NFT id here
          <input type="number" min="0" v-model="ids.third">
          <Button @click.native="add('third')">Add</Button>
        </li>
        <Card :data="fourth" v-if="fourth">
          <div class="remove-button" @click.stop.prevent="fourth=undefined">
            <unicon height="30" width="30" name="times-circle"/>
          </div>
        </Card>
        <li class="empty-card" v-else>
          Select an NFT below or<br>
          enter an NFT id here
          <input type="number" min="0" v-model="ids.fourth">
          <Button @click.native="add('fourth')">Add</Button>
        </li>
      </CardGrid>
      <unicon name="angle-down" height="80" width="80"/>
      <CardGrid>
        <Card :data="result" v-if="result">
          <div class="remove-button" @click.stop.prevent="result=undefined">
            <unicon height="30" width="30" name="times-circle"/>
          </div>
        </Card>
        <li class="empty-card" v-else>
          Select an NFT below or<br>
          enter an NFT id here
          <input type="number" min="0" v-model="ids.result">
          <Button @click.native="add('result')">Add</Button>
        </li>
      </CardGrid>
    </div>
    <div class="flex-center">
      <p>Price:</p>
      <IntInput
        :min="0"
        :max="10000000000000000000"
        :step="1"
        :default-value="price"
        @val="(val) => { this.price = val }"
      />
      <ASALogo style="font-size: xx-large" :asa-id="1"/>
    </div>
    <div class="flex-center">
      <p>Number of Fusion to create:</p>
      <IntInput
        :min="1"
        :max="100000000000000000"
        :step="1"
        :default-value="nbFusion"
        @val="(val) => { this.nbFusion = val }"
      />
    </div>
    <div style="position: relative">
      <Button @click.native="startContract">
        {{$t('createShuffle.button.create')}}
      </Button>
      <h5 v-if="error" class="error">
        <unicon height="24px" name="exclamation-octagon"/>
        {{ error }}
      </h5>
    </div>

    <div class="nft-list">
      <div class="nft-list-head">
        <TextualButton class="reverse" @click.native="previousPage" v-if="this.start>0">
          <unicon name="angle-left" />{{$t('common.button.back')}}
        </TextualButton>
        <span v-else></span>
        <span>{{start + 1}}-{{start + increment + 1}}</span>
        <TextualButton @click.native="nextPage" v-if="this.morePage">
          {{$t('common.button.next')}}<unicon name="angle-right" />
        </TextualButton>
        <span v-else></span>
      </div>
      <ListCardGrid v-if="!this.isLoadingPage">
        <ListCard v-for="(nft, index) in nfts" :key="nft.token_id" :data="nft" :style="`animation-delay: ${100 + (index * 50)}ms`">
          <div class="button-container">
            <Button @click.native.stop.prevent="() => {
                if (!first) first = nft
                else if (!second) second = nft
                else if (!third) third = nft
                else fourth = nft
              }
             ">
              Add as Component
            </Button>
            <Button @click.native.stop.prevent="result=nft">
              Add as Result
            </Button>
          </div>
        </ListCard>
      </ListCardGrid>
      <LinearSpinner v-else/>
    </div>
    <ContractPopUp v-if='displayContractPopUp' :_contractParams="contractParams" @done='done' @close="displayContractPopUp = false" :_kind="'createFusion'"/>
  </AppView>
</template>

<script>
import ListCard from '@/components/Cards/ListCard'
import { mapState } from 'vuex'
import Button from '@/components/Common/Button/Button'
import TextualButton from '@/components/Common/Button/TextualButton'
import LinearSpinner from '@/components/Common/Spinner/LinearSpinner'
import AppView from '@/components/Layout/AppView'
import ListCardGrid from '@/components/Cards/ListCardGrid'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import Card from '@/components/Cards/Card'
import CardGrid from '@/components/Cards/CardGrid'
import IntInput from '@/components/Common/Input/IntInput'
import ASALogo from '@/components/Assets/ASALogo'

export default {
  name: 'CreateFusion',
  components: { ASALogo, IntInput, CardGrid, Card, ContractPopUp, ListCardGrid, AppView, LinearSpinner, TextualButton, Button, ListCard },
  data () {
    return {
      start: 0,
      end: 16,
      increment: 9,
      morePage: false,
      isLoadingPage: false,
      first: undefined,
      second: undefined,
      third: undefined,
      fourth: undefined,
      ids: { first: 0, second: 0, third: 0, fourth: 0, result: 0 },
      result: undefined,
      price: 0,
      nfts: [],
      displayContractPopUp: false,
      contractParams: {},
      id: null,
      error: null,
      nbFusion: 1
    }
  },
  computed: {
    ...mapState([
      'wallet',
      'userId',
      'address'
    ]),
    allNftsUrl () {
      return `/api/creation/${this.userId}`
    },
    doneUrl () {
      return `/fusion/${this.id}`
    },
    userUrl () {
      return '/user'
    }
  },
  methods: {
    add (x) {
      this.$http.get(`/api/nft/${this.ids[x]}`)
        .then((d) => {
          delete d.data.nft.action
          switch (x) {
            case 'first':
              this.first = d.data.nft
              break
            case 'second':
              this.second = d.data.nft
              break
            case 'third':
              this.third = d.data.nft
              break
            case 'fourth':
              this.fourth = d.data.nft
              break
            case 'result':
              this.result = d.data.nft
          }
        })
    },
    startContract () {
      if (!this.first || !this.second) {
        this.error = 'Select more NFTs as components'
      } else if (!this.result) {
        this.error = 'Please select a result NFT'
      } else {
        this.contractParams = {
          components: [this.first.token_id, this.second.token_id],
          result: this.result.token_id,
          price: this.price,
          nbFusion: this.nbFusion
        }
        if (this.third) {
          this.contractParams.components.push(this.third.token_id)
        }
        if (this.fourth) {
          this.contractParams.components.push(this.fourth.token_id)
        }
        this.displayContractPopUp = true
      }
    },
    done (m) {
      this.id = m.id
      this.$router.push(this.doneUrl)
    },
    previousPage () {
      if (this.isLoadingPage) return
      this.start -= this.increment
      this.start = (this.start < 0) ? 0 : this.start
      this.refreshPage()
    },
    nextPage () {
      if (this.isLoadingPage) return
      this.start += this.increment
      this.refreshPage()
    },
    refreshPage () {
      const params = {
        start: this.start,
        end: this.start + this.increment
      }
      this.nfts = []
      this.isLoadingPage = true
      this.$http.get(this.allNftsUrl, {
        params: params
      }).then(({ data }) => {
        this.isLoadingPage = false
        if (data?.metadata.length) {
          this.nfts = data.metadata.filter(x => x)
          this.morePage = !data.bool_end
        }
      }).catch(() => {
        this.isLoadingPage = false
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.refreshPage()
    })
  }
}
</script>

<style scoped>

.head {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.head > div {
  margin: 0px 60px;
}

.head button {
  margin-bottom: 31px;
}

.head input {
  width: 300px;
}

.list-card-grid {
  grid-template-columns: 1fr, 1fr;
}

.nft-list-head {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 50px;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 25px;
  width: 400px;
  text-align: center;
}

.button-container button {
  margin: 5px;
}

.list-card {
  position: absolute;
}

.list-card .overview{
  width: 100%;
}

.list-card button {
  margin: 0;
  width: 110px;
  height: 55px;
  fill: var(--foreground);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.selected-item-menu {
  display: flex;
  align-items: center;
}

.selected-item-menu button {
  height: 54px;
}

.selected-item-menu ul {
  margin: 0;
}

.selected-item-menu.raise {
  position: relative;
  top: -13px;
}

.error {
  bottom: -50px;
}

.empty-card {
  width: 250px;
  height: 350px;
  background: var(--foreground-transparent);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.empty-card input {
  max-width: 150px;
}

.remove-button {
  position: absolute;
  top: 15px;
  right: 15px;
}

.remove-button .unicon {
  fill: var(--error);
}
.button-container {
  display: grid;
  position: relative;
  margin: 0px;
  padding: 0px;
  left: 10px;
  top: -35px;
}

.button-container button {
  margin-right: 10px;
}

@media only screen
and (max-device-width: 1200px) {

}
</style>
