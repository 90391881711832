<template>
  <span class="tooltip">
    <slot></slot>
    <span class="tooltip-text">
      <slot name="tooltipText"></slot>
    </span>
  </span>
</template>

<script>
export default {
  name: 'Tooltip'
}
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 60px;
  background-color: var(--pop-up-background);
  color: var(--text);
  font-size: x-small;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -30px;
  z-index: 1000;

  opacity: 0;
  transition: opacity 1s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltip-text::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--pop-up-background) transparent;
}
</style>
