<template>
  <router-link v-if="to" :to="to" class="textual-button">
    <slot></slot>
    <unicon name="angle-right"/>
  </router-link>
  <button class="textual-button" v-else><slot></slot></button>
</template>

<script>
export default {
  name: 'TextualButton',
  props: ['to']
}
</script>

<style scoped>
button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin: 0;
}

button:focus {
  outline: none;
}

.textual-button{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: var(--text);
  font-weight: bold;
}

.textual-button:hover .unicon{
  transform: translateX(5px);
}

.unicon{
  padding: 3px;
  border-radius: 50%;
  background: var(--accent);
  fill: var(--complement);
  margin-left: 5px;
  transition: transform .2s ease;
}

.textual-button.reverse:hover .unicon{
  transform: translateX(-5px);
}

.textual-button.reverse .unicon{
  margin-left: 0px;
  margin-right: 5px;
}

.error-btn .unicon {
  background: var(--error);
}
</style>
