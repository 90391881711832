<template>
<div class="report-pop-up">
  <div class="pop-up-content">
    <button @click="close" class="close-button">X</button>
    <template v-if="state === 'selectKind'">
      <h2>{{$t('reportPopUp.whyAreYouReporting')}}</h2>
      <ul>
        <li>
          <input type="radio" id="spam" name="sortButtons" value="spam" v-model="selected">
          <label for="spam">
            <unicon class="social-icons" name="adjust" height="80px" width="80px" transform="rotate(45)"/>
            {{$t('reportPopUp.spam')}}
          </label>
        </li>

        <li>
          <input type="radio" id="offensive" name="sortButtons" value="offensive" v-model="selected">
          <label for="offensive">
            <unicon class="social-icons" name="eye-slash" height="80px" width="80px"/>
            {{$t('reportPopUp.offensive')}}
          </label>
        </li>

        <li>
          <input type="radio" id="stolen" name="sortButtons" value="stolen" v-model="selected">
          <label for="stolen">
            <unicon class="social-icons" name="image-shield" height="80px" width="80px"/>
            {{$t('reportPopUp.stolen')}}
          </label>
        </li>
      </ul>
    </template>
    <template v-else-if="state === 'addInfos'">
      <h2>{{$t('reportPopUp.additional')}}:</h2>
      <textarea v-model="additional" maxlength="250"></textarea>
      <div class="buttons-holder">
        <Button @click.native="state = 'selectKind'" class="no-top-bottom-margin secondary">{{$t('reportPopUp.back')}}</Button>
        <Button @click.native="submit" class="no-top-bottom-margin">{{$t('reportPopUp.submit')}}</Button>
      </div>
    </template>
    <template v-else-if="state === 'loading'">
      <LinearSpinner/>
      <h5>{{$t('reportPopUp.loading')}}</h5>
    </template>
    <template v-else-if="state === 'done'">
      <SuccessCheckmark/>
      <h5>{{$t('reportPopUp.done')}}</h5>
    </template>
  </div>
</div>
</template>

<script>
import Button from '../Button/Button'
import LinearSpinner from '../Spinner/LinearSpinner'
import SuccessCheckmark from '../../Assets/SuccessCheckmark'

export default {
  name: 'ReportPopUp',
  components: { SuccessCheckmark, LinearSpinner, Button },
  props: {
    _nftID: String
  },
  data () {
    return {
      selected: '',
      additional: '',
      state: 'selectKind'
    }
  },
  watch: {
    selected (selectedOption) {
      if (selectedOption === 'stolen') {
        this.state = 'addInfos'
      } else {
        this.submit()
      }
    }
  },
  methods: {
    submit () {
      this.state = 'loading'
      const params = {
        nftID: this.$props._nftID,
        kind: this.selected,
        infos: this.additional
      }
      this.$http.post('/api/nft/report', params)
        .then(() => {
          this.state = 'done'
        })
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
*{
  color: var(--text);
}

.report-pop-up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  margin: 10px;
}

h2 {
  margin: 10px 5px;
  text-align: center;
}

h5 {
  text-align: center;
}

.pop-up-content {
  position: relative;
  min-height: 100px;
  background: white;
  box-shadow: var(--accent-shadow);
  border-radius: 20px;
  padding: 15px 10px;
}

.buttons-holder {
  display: flex;
}

input[type="radio"] {
  width: 0px;
  height: 0px;
}

ul {
  padding: 0;
  margin-top: 30px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  text-align: center;
  background: var(--foreground);
  border-radius: 20px;
  box-shadow: var(--light-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  cursor: pointer;
  transition: all .2s ease;
  animation: card-appear .5s ease backwards;
}

li > label {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 140px;
  width: 140px;
  padding: 15px;
  color: var(--textlight);
}

li > label:hover {
  color: var(--error);
}

.close-button {
  position: absolute;
  font-size: x-large;
  border: none;
  border-radius: 100%;
  background: white;
  box-shadow: var(--accent-shadow);
  top: -30px;
  right: -30px;
  color: var(--textlight);
}

.close-button:focus {
  outline: none;
}

.close-button:hover {
  color: var(--text);
}

.no-top-bottom-margin {
  margin-top: 2px;
  margin-bottom: 2px;
}

.unicon {
  fill: var(--textlight);
}

.unicon:hover {
  fill: var(--error);
}
</style>
