import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function create (APIName, from, asset) {
  try {
    if (Array.isArray(asset)) {
      return await createMultiple(APIName, from, asset)
    }
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()
    const txn = algosdk.makeAssetCreateTxnWithSuggestedParams(from, asset.note, asset.number, 0, undefined, from, from, undefined, undefined, asset.unit, asset.title, asset.ipfs_url, undefined, suggestedParams)
    const d = await API.sign(txn)

    const message = {
      ...asset,
      signed_tx: d
    }
    return Utils._callServer('/api/create/validate', message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

async function createMultiple (APIName, from, assets) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()
    const txns = []
    txns.push(algosdk.makePaymentTxnWithSuggestedParams(
      from, 'VWZBFLBUN6O5A5W6IWHMDUVP5NH2LPV4ZYFMAHP4FQBBYP627MP6WPOEG4',
      1_000_000,
      undefined, undefined,
      suggestedParams))
    for (const asset of assets) {
      const note = new TextEncoder('utf-8').encode(asset.note)
      txns.push(
        algosdk.makeAssetCreateTxnWithSuggestedParams(
          from, note, asset.total,
          asset.decimals, undefined,
          from, from,
          undefined, undefined,
          asset.asset_unit, asset.asset_name,
          asset.url, asset.metadataHash,
          suggestedParams)
      )
    }
    const signedTxs = await API.signGroup(txns, txns.map(x => true), false)
    return Utils._callServer('/api/sdk/send_all', { signed_tx: signedTxs })
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default create
