import Utils from '../utils'

async function connect (APIName) {
  try {
    const API = Utils._getAPI(APIName)
    const account = await API.connect()
    return account
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default connect
