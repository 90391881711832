import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function cancel (APIName, from, tokenId) {
  const API = Utils._getAPI(APIName)
  let message = {
    token_id: tokenId
  }
  const parsed = await Utils._callServer('/api/cancel/check', message)
  console.log('Cancel - check', parsed)
  if (parsed.version === 1) {
    return cancelV1(API, from, tokenId, parsed)
  }

  const suggestedParams = await Utils.getParams()
  console.log('Cancel - suggestedParams', suggestedParams)
  const txns = []
  if (!parsed.check) {
    txns.push(algosdk.makeAssetTransferTxnWithSuggestedParams(from, from, undefined, undefined, 0, undefined, tokenId, suggestedParams))
  }
  const foreignAssets = [tokenId]
  if (parsed.asa_id !== 1) {
    foreignAssets.push(parsed.asa_id)
  }
  console.log(foreignAssets)
  txns.push(algosdk.makeApplicationDeleteTxn(from, suggestedParams, parsed.app_id, undefined, [from], undefined, foreignAssets, undefined))

  const signedTx = await API.signGroup(txns, txns.map(x => true), txns.length > 1)
  console.log('Cancel - signedTx', signedTx)
  message = {
    token_id: tokenId,
    app_id: parsed.app_id,
    signed_tx: signedTx
  }
  return Utils._callServer('/api/sell/cancel/validation/v2', message)
}

async function cancelV1 (API, from, tokenId, parsed) {
  const to = parsed.to
  const amount = parsed.amount
  const note = (typeof parsed.note === 'string') ? new TextEncoder('utf-8').encode(parsed.note) : parsed.note

  const suggestedParams = await Utils.getParams()
  const txns = []
  if (!parsed.check) {
    txns.push(algosdk.makeAssetTransferTxnWithSuggestedParams(from, from, undefined, undefined, 0, note, tokenId, suggestedParams))
  }
  txns.push(
    algosdk.makePaymentTxnWithSuggestedParams(from, to, amount, undefined, note, suggestedParams)
  )
  const signedTx = await API.signGroup(txns, txns.map(x => true))
  const message2 = {
    token_id: tokenId,
    signed_tx: signedTx
  }
  return Utils._callServer('/api/sell/cancel/validation', message2)
}

export default cancel
