<template>
  <div class="arc69-input">
    <p>
      <span>{{$t('ARC69Details.title')}}</span>
      <TextualButton @click.native="addAttribute">
        {{$t('ARC69Details.add')}}
        <unicon name="plus" height="20" width="20"/>
      </TextualButton>
    </p>
    <div v-for="[i, attribute] in attributes.entries()"
         :key="i"
         class="attribute">
      <input @keydown.enter.prevent.stop
             v-model="attribute.key"
             :placeholder="$t('ARC69Details.placeholder.title')"
             :class="(error && isInvalid(attribute.key, i)) ? 'invalid' : ''"
             type="text" maxlength="100">
      <input @keydown.enter.prevent.stop
             v-model="attribute.value"
             :placeholder="$t('ARC69Details.placeholder.value')"
             type="text" maxlength="100">
      <TextualButton @click.native="() => removeAttribute(i)" class="error-btn">
        <unicon name="times" height="20" width="20"/>
      </TextualButton>
    </div>
  </div>
</template>

<script>
import TextualButton from '../Common/Button/TextualButton'

export default {
  name: 'ARC69Input',
  components: { TextualButton },
  props: ['attributes', 'error'],
  methods: {
    addAttribute () {
      this.attributes.push({
        key: '',
        value: ''
      })
    },
    removeAttribute (index) {
      this.attributes.splice(index, 1)
    },
    isInvalid (key, index) {
      if (!key.length) return true
      for (const [i, x] of this.attributes.entries()) {
        if (i !== index && x.key === key) return true
      }
      return false
    }
  }
}
</script>

<style scoped>
.attribute {
  display: grid;
  grid-template-columns: auto auto 20px;
  grid-gap: 5px;
  margin: 5px;
}

.attribute input {
  margin: 0;
}

p {
  display: flex;
  justify-content: space-between;
}

.invalid {
  border-color: var(--error);
}

.textual-button {
  font-weight: initial;
}

.textual-button .unicon {
  fill: var(--accent);
  background-color: transparent;
}

.textual-button.error-btn .unicon {
  fill: var(--error);
  background-color: transparent;
}
</style>
