<template>
  <svg id="algo-glyph" viewBox="0 0 400 200">
    <path :d="paths" :stroke="color"/>
  </svg>
</template>

<script>
export default {
  name: 'AlgoGlyph',
  props: ['color', 'txId'],
  data () {
    return {
      // txId: 'CTQTPJSHCRZK2CPLP4TAGU6L5K2KUPV2LBKVXVSI2UAE3FFXHCAQ' // @chaf algoglyph
      paths: null,
      lineWidth: 2
    }
  },
  watch: {
    txId () {
      this.handleFetch(this.txId)
    }
  },
  methods: {
    handleFetch (noteTxID) {
      this.fetchNote(noteTxID).then(response => {
        const datab = this.base64ToArrayBuffer(response)
        this.drawData(datab)
      })
    },
    base64ToArrayBuffer (data) {
      const newData = Buffer.from(data, 'base64')
      return newData
    },
    async fetchNote (txid) {
      try {
        const data = await this.$http.get(`/api/glyph/note?tx_id=${txid}`)
        const data2 = data.data.note
        return data2
      } catch (error) {
        console.log(error)
      }
    },
    drawData (input) {
      const newlines = []
      let subarray = []
      for (let i = 0; i < input.length; i += 2) {
        if (input[i] !== 255) {
          if (input[i] && input[i + 1]) { subarray.push([input[i], input[i + 1]]) }
        } else {
          newlines.push(subarray)
          subarray = []
          i -= 1
        }
      }
      newlines.push(subarray)
      console.log(newlines)
      this.toPath(newlines)
    },
    toPath (lines) {
      let s = ''
      for (const line of lines) {
        for (const [i, point] of line.entries()) {
          if (i) {
            s += `L ${point[0] / 255 * 400} ${point[1] / 255 * 200}`
          } else {
            s += `M ${point[0] / 255 * 400} ${point[1] / 255 * 200}`
          }
        }
      }
      this.paths = s
    }
  },
  mounted () {
    this.handleFetch(this.txId)
  }
}
</script>

<style scoped>
#algo-glyph {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

path {
  fill: none;
  stroke-width: 5px;
  stroke-linejoin: round;
  stroke-linecap: round;
}
</style>
