<template>
  <li :class="{ card:true, loading: !thumbnailImage, spotlight }" @mouseover="focus" @mouseleave="unfocus">
    <router-link v-if="thumbnailImage" :to="nftUrl">
      <div class="cover">
        <img :alt="data.token_id" :src="thumbnailUrl" class="thumbnail"/>
        <div class="play-cta"
             v-if="(contentType === 'audio' || contentType === 'video' || thumbnailExtension === 'gif' || data.type === 'fusion')">
          <template v-if="fullsizeImage || videoCanPlay || audioCanPlay || data.type === 'fusion' || data.type === 'pack'">
            <div v-if="data.type === 'fusion'">
              <unicon name="pathfinder-unite" width="30px" height="30px"/>
            </div>
            <div v-else-if="data.type === 'pack'">
              <unicon name="layers" width="30px" height="30px"/>
            </div>
            <div v-else-if="thumbnailExtension === 'gif'">
              GIF
            </div>
            <div v-else-if="contentType === 'audio'" @click.stop.prevent="focus">
              <unicon name="music" width="30px" height="30px" v-if="$refs.audio.paused"/>
              <unicon name="pause" width="30px" height="30px" v-else/>
            </div>
            <div v-else>
              <unicon name="play" width="30px" height="30px"/>
            </div>
          </template>
          <div v-else>
            <Spinner/>
          </div>
        </div>
        <img v-if="fullsizeImage && focused" :alt="`${data.token_id} FullSize`" :src="fullsizeImage" class="full-size"/>
        <audio v-if="contentType === 'audio'" v-show="focused" ref="audio" :src="data.url">
          Your browser does not support the audio tag.
        </audio>
        <video v-if="contentType === 'video'" v-show="focused" ref="video" :src="data.url"
               loop >
          Your browser does not support the video tag.
        </video>
      </div>
      <ul v-if="timeout" class="auction-timeout">
        <li v-if="timeout.days">{{ timeout.days }}d</li>
        <li>{{ timeout.hours | zeroPad }}</li>
        <li>{{ timeout.minutes | zeroPad }}</li>
        <li>{{ timeout.seconds | zeroPad }}</li>
      </ul>
      <div v-if="data.title" class="overview">
        <span class="left">

          <div class="desktop-only">
              <div v-if="data.title.length > 23">
                <h3>{{data.title.slice(0, 21)}}..</h3>
              </div>
              <div v-else>
                <h3>{{data.title}}</h3>
              </div>
          </div>

          <div class="mobile-only">
            <div v-if="data.title.length > 21">
                <h3>{{data.title.slice(0, 19)}}..</h3>
              </div>
              <div v-else>
                <h3>{{data.title}}</h3>
              </div>
          </div>

          <h4>
            <a :href="galleryUrl" @click.prevent.stop="$router.push(galleryUrl)">
              <span v-if="data.username">{{ data.username | formatUserName }}</span>
              <Address v-else :address="data.address"/>
            </a>
          </h4>
        </span>
        <span class="right">
          <h3 v-if="data.price">{{ data.price | numFormat('0.[0]a') }}<ASALogo :asa-id="data.asa_id ? data.asa_id : 1"/></h3>
          <template v-if="data.action">
            <Button v-if="data.action.includes('sell')" class="error-btn button-card"
                    @click.native.prevent.stop="() => startContract('sell')">
                <unicon name="top-arrow-from-top"/>
                {{ $t('nft.button.sell') }}
            </Button>
          </template>
        </span>
      </div>
    </router-link>
    <slot></slot>
  </li>
</template>

<script>
import Address from '../User/Address'
import Button from '../Common/Button/Button'
import Spinner from '@/components/Common/Spinner/Spinner'
import ASALogo from '../Assets/ASALogo'

export default {
  name: 'card',
  components: {
    ASALogo,
    Spinner,
    Address,
    Button
  },
  props: {
    data: Object,
    spotlight: Boolean
  },
  data () {
    return {
      contentType: this.data.content_type,
      thumbnailExtension: this.data.extension,
      thumbnailImage: null,
      fullsizeImage: null,
      timeout: null,
      failed: false,
      videoCanPlay: false,
      audioCanPlay: false,
      focused: false
    }
  },
  computed: {
    galleryUrl () {
      const userId = this.data.username || this.data.address
      return `/gallery/${userId}`
    },
    nftUrl () {
      let path
      if (this.data.to) {
        path = this.data.to
      } else {
        path = `/nft/${this.data.token_id}`
        if (this.data.app_id) {
          if (this.data.type === 'auction') {
            path += `/auction/${this.data.app_id}`
          } else if (this.data.type === 'sale') {
            path += `/sale/${this.data.app_id}`
          } else if (this.data.type === 'offer') {
            path += `/offer/${this.data.app_id}`
          }
        }
      }
      return path
    },
    thumbnailUrl () {
      const imageKey = this.thumbnailExtension ? `images/${this.data.token_id}.${this.thumbnailExtension}` : `images/${this.data.token_id}`
      const imageRequest = JSON.stringify({
        bucket: 'algogems',
        key: imageKey,
        edits: {
          resize: {
            width: this.spotlight ? 650 : 300,
            height: this.spotlight ? 750 : 350
          }
        }
      })
      return `/image/${btoa(imageRequest)}`
    }
  },
  methods: {
    focus () {
      if (this.$refs.video) {
        this.$refs.video.play()
      }
      if (this.$refs.audio) {
        this.$refs.audio.play()
      }
      this.focused = true
    },
    unfocus () {
      if (this.$refs.video) {
        this.$refs.video.pause()
      }
      if (this.$refs.audio) {
        this.$refs.audio.pause()
      }
      this.focused = false
    },
    startContract (kind) {
      this.$emit('startContract', {
        kind: kind,
        tokenId: this.data.token_id,
        price: (this.data.price) ? this.data.price : 1
      })
    },
    countdown () {
      if (this.data.end_date) {
        const t = new Date(this.data.end_date * 1000) - new Date()
        if (t > 0) {
          const seconds = Math.floor((t / 1000) % 60)
          const minutes = Math.floor((t / 1000 / 60) % 60)
          const hours = Math.floor((t / (1000 * 60 * 60)) % 24)
          const days = Math.floor(t / (1000 * 60 * 60 * 24))
          this.timeout = {
            days,
            hours,
            minutes,
            seconds
          }
          setTimeout(this.countdown, 1000)
        } else {
          this.timeout = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
          }
        }
      }
    },
    loadThumbnail () {
      const img = new Image()
      img.onload = () => {
        this.thumbnailImage = img.src
        if (this.contentType === 'video' || this.contentType === 'audio' || this.thumbnailExtension === 'gif') {
          this.loadFullSize()
        }
      }
      img.onerror = () => {
        if (!this.failed) {
          this.failed = true
          this.$http.get(`/api/images/import/${this.data.token_id}`)
            .then(() => {
              this.loadThumbnail()
            }).catch(err => {
              console.error(err)
            })
        }
      }
      img.src = this.thumbnailUrl
    },
    loadFullSize () {
      if (this.contentType === 'video') {
        setTimeout(() => {
          const videoElement = this.$refs.video
          videoElement.addEventListener('loadeddata', (e) => {
            if (videoElement.readyState >= 3) {
              this.videoCanPlay = true
            }
          })
        }, 1)
      }
      if (this.contentType === 'audio') {
        setTimeout(() => {
          const audioElement = this.$refs.audio
          audioElement.addEventListener('loadeddata', (e) => {
            if (audioElement.readyState >= 3) {
              this.audioCanPlay = true
            }
          })
        }, 1)
      }
      if (this.thumbnailExtension === 'gif') {
        const img = new Image()
        img.onload = () => {
          this.fullsizeImage = img.src
        }
        img.onerror = (err) => {
          console.error(`Error while loading fullsize: ${err}`)
        }
        img.src = `/fullsize/images/${this.data.token_id}.gif`
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadThumbnail()
      if (this.data.end_date) {
        setTimeout(this.countdown, 1)
      }
    })
  }
}
</script>

<style scoped>
.card {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: var(--strong-shadow);
  font-size: .8em;
  animation: card-appear .2s ease backwards;
  margin: 0;
  width: 100%;
  height: 100%;
  width: 275px;
  max-height: 350px;
}

.card.loading {
  width: 100%;
  height: 100%;
  box-shadow: none;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f3f3f3;
  background: linear-gradient(to right, #f3f3f3 5%, #ebebeb 20%, #f3f3f3 35%);
  background-size: 1000px 640px;
  position: relative;
}

.card.spotlight, .card.big {
  font-size: 1.1em;
  max-width: 650px;
  width: 585px;
  max-height: 750px;
  height: 732px;
}

.card a {
  text-decoration: none;
  position: relative;
  display: block;
  height: 100%;
}

.cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--foreground-transparent);
  border: 1px solid var(--foreground);
  border-radius: 15px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.cover img, .cover video {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.cover .play-cta {
  position: absolute;
  width: 100%;
  height: calc(100% - 50px);
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .9;
}

.cover .play-cta>div{
  background: var(--foreground);
  color: var(--accent);
  border-radius: 70px;
  font-size: 1.4em;
  box-shadow: var(--strong-shadow);
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.cover .play-cta .unicon{
  fill: var(--accent);
}

.cover .full-size, .cover video{
  animation: fade .4s both;
  z-index: 10;
}

.card.spotlight .cover, .card.big .cover {
  height: 100%;
}

.overview {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  background-color: var(--fake-blur);
  color: var(--text);
  position: absolute;
  bottom: 0;
  z-index: 11;
}

@supports (backdrop-filter: blur(20px)) {
  .overview {
    background-color: var(--foreground-blur);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 0 0 15px 15px;
  }
}

.overview h3 {
  margin: 0;
  text-transform: capitalize;
}

.overview .left h3 {
  font-size: 1.2em;
}

.overview .right h3 {
  font-size: 1.4em;
}

.overview .left h4 {
  margin: 0;
  font-size: 1em;
  font-weight: 400;
  color: var(--text);
}

.auction-timeout {
  position: absolute;
  top: 15px;
  right: 15px;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.auction-timeout li {
  background: rgba(0, 0, 0, .8);
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  margin: 1px;
}

.mobile-only {
    display: none;
  }

/* Portrait  add tablet centering */
@media only screen
and (max-device-width: 1200px) {

  .card.spotlight {
    font-size: 1em; /* add some border here */
    width: 275px;
    max-height: 350px;
  }

  .card.spotlight .cover {
    height: 100%;
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: flex;
  }
}
</style>
