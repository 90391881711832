<template>
<Section :key="reload">
  <h2>Delete NFTs</h2>
  <div class="two-columns">
    <p>Enter a token id:</p> <input type="number" min="0" v-model="deleteNFTId">
    <p>Upload a file with an ASA id per row:</p>
    <input type="file" :id="`upload-delete`" accept="text/plain" @change="(e) => uploadDeleteIds(e)">
    <ul v-if="deleteNFTIds.length">
      <li v-for="id of deleteNFTIds" :key="id">
        {{id}}
      </li>
    </ul>
  </div>
  <Button @click.native="deleteNFTs" class="error-btn">Delete</Button>

  <h2>Multi-mint</h2>
  <div class="flex-column-center">
    <div>
    <p>Upload a tsv file with the header:
    </p>
    <pre><i>{{mintHeader.join('\t')}}</i></pre>
      the note must an empty cell or a valid JSON object.
    </div>
    <input type="file" :id="`upload-multi-mint`" accept="text/comma-separated-values" @change="(e) => uploadMultiMint(e)">
    <ul v-if="NFTsToMint.length">
      <li v-for="nft of NFTsToMint" :key="nft.id">
        {{nft}}
      </li>
    </ul>
    <Button @click.native="multiMintNFTs" class="error-btn">Mint</Button>
  </div>
  <ContractPopUp
    v-if="displayContractPopUp"
    :_state="contractPopUpState"
    :_message="contractPopUpMessage"
    @close="() => { displayContractPopUp = false; reload++ }"
  />
</Section>
</template>
<script>
import Section from '@/components/Layout/Section'
import Button from '@/components/Common/Button/Button'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import Contract from '@/web3/Contract'
import { mapState } from 'vuex'

export default {
  name: 'Desk',
  components: { ContractPopUp, Button, Section },
  data () {
    return {
      reload: 0,
      displayContractPopUp: false,
      contractPopUpState: 'loading',
      contractPopUpMessage: undefined,
      deleteNFTId: 0,
      deleteNFTIds: [],
      NFTsToMint: [],
      mintHeader: ['asset_name', 'asset_unit', 'decimals', 'total', 'metadata_hash', 'url', 'note']
    }
  },
  computed: {
    ...mapState(['address', 'wallet'])
  },
  methods: {
    uploadDeleteIds (e) {
      const file = e.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.readAsText(file)
        reader.onload = e => {
          const data = e.target.result.split('\n')
          this.deleteNFTIds = []
          for (const d of data) {
            if (!isNaN(d) && d.length > 1) {
              this.deleteNFTIds.push(parseInt(d))
            } else {
              this.deleteNFTIds.push(parseInt(d))
            }
          }
        }
      }
    },
    deleteNFTs () {
      if (this.address) {
        this.contractPopUpState = 'loading'
        this.displayContractPopUp = true

        if (this.deleteNFTId) {
          this.deleteNFTIds.push(parseInt(this.deleteNFTId))
        }

        Contract.deleteASAs(this.wallet, this.address, { nfts: this.deleteNFTIds })
          .then((m) => {
            this.contractPopUpState = 'done'
          })
          .catch((e) => {
            this.contractPopUpState = 'error'
            this.contractPopUpMessage = e.toString()
          })
      } else {
        this.$router.push('/authenticate')
      }
    },
    uploadMultiMint (e) {
      const file = e.target.files[0]
      if (file) {
        const formData = new FormData()
        formData.append('file', file)
        this.$http.post('/api/sdk/read_csv', formData,
          { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((d) => {
            this.NFTsToMint = d.data.metadata
          })
          .catch((e) => {
            console.log(e)
          })
      }
    },
    multiMintNFTs () {
      if (this.address) {
        this.contractPopUpState = 'loading'
        this.displayContractPopUp = true

        Contract.create(this.wallet, this.address, this.NFTsToMint)
          .then((m) => {
            this.contractPopUpState = 'done'
          })
          .catch((e) => {
            this.contractPopUpState = 'error'
            this.contractPopUpMessage = e.toString()
          })
      } else {
        this.$router.push('/authenticate')
      }
    }
  }
}
</script>

<style scoped>
ul {
  max-height: 250px;
}

.two-columns {
  max-width: 600px;
  margin: auto;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-gap: 15px;
}
</style>
