<template>
  <AppView id="collections">
    <h1>Collections</h1>
    <Section>
      <template v-slot:header>
        <SortButtonsCollections :_initialSort="sortOption" @selected="sort"/>
        <span class="filters">
          <textual-button @click.native="toggleDisplayUnverifiedArtist">
            <VerifiedLogo :class="(displayUnverifiedArtist) ? 'unverified' : ''"/>
            {{$t('common.verified-artist')}}
          </textual-button>
          <ToggleNSFW v-model="nsfw"/>
        </span>
      </template>
      <CollectionGrid>
        <CollectionCard v-for="(collection, index) in collections" :key="index" :collection="collection" :style="getStyle(index)"/>
      </CollectionGrid>
      <infinite-loading spinner="waveDots" @infinite="infiniteHandler" :identifier="infiniteId" :forceUseInfiniteWrapper="true"><span slot="no-more"></span><span slot="no-results"></span></infinite-loading>
    </Section>
  </AppView>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import AppView from '@/components/Layout/AppView'
import Section from '@/components/Layout/Section'
import CollectionGrid from '@/components/Collection/CollectionGrid'
import CollectionCard from '@/components/Collection/CollectionCard'
import VerifiedLogo from '@/components/Assets/VerifiedLogo'
import TextualButton from '@/components/Common/Button/TextualButton'
import SortButtonsCollections from '@/components/Common/Button/SortButtonsCollections'
import ToggleNSFW from '@/components/Common/Button/ToggleNSFW'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'Collections',
  components: {
    ToggleNSFW,
    SortButtonsCollections,
    TextualButton,
    VerifiedLogo,
    CollectionGrid,
    Section,
    AppView,
    CollectionCard,
    InfiniteLoading
  },
  data () {
    return {
      start: 0,
      end: 12,
      collections: [],
      sortOption: 'spotlight',
      displayUnverifiedArtist: false,
      nsfw: false,
      infiniteId: 0
    }
  },
  watch: {
    nsfw () {
      this.reload()
    }
  },
  computed: {
    ...mapState(['sortOptions']),
    url () {
      return '/api/collections'
    }
  },
  methods: {
    ...mapMutations(['setSortOptions']),
    infiniteHandler ($state) {
      const params = {
        start: this.start,
        end: this.end,
        sort: this.sortOption,
        'verified-only': !this.displayUnverifiedArtist,
        nsfw: this.nsfw
      }
      if (this.$route.query.tag) {
        params.tag = this.$route.query.tag
      }
      this.$http.get(this.url, {
        params: params
      }).then(({ data }) => {
        if (data?.metadata.length) {
          this.start += data.metadata.length
          this.end += data.metadata.length
          this.collections.push(...data.metadata)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    reload () {
      this.start = 0
      this.end = 12
      this.collections = []
      this.setSortOptions({
        sortOptions: {
          displayUnverifiedArtist: this.displayUnverifiedArtist,
          nsfw: this.nsfw
        },
        page: 'collections'
      })

      this.infiniteId += 1
    },
    toggleDisplayUnverifiedArtist () {
      this.displayUnverifiedArtist = !this.displayUnverifiedArtist
      this.reload()
    },
    sort (sortOption) {
      this.sortOption = sortOption
      this.reload()
    },
    getStyle (index) {
      return `animation-delay: ${100 + ((index % 4) * 50)}ms`
    }
  },
  beforeMount () {
    if (this.sortOptions.page === 'collections') {
      for (const [key, value] of Object.entries(this.sortOptions.sortOptions)) {
        this[key] = value
      }
    } else {
      this.setSortOptions({})
    }
  }
}
</script>

<style scoped>
/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  #collections h1{
    text-align: center;
  }

  #collections .sort-buttons-wrapper{
    width: 100%;
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.filters .toggle-nsfw {
  margin-left: 10px;
}
</style>
