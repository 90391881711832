import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function bigVote (APIName, from, vote) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()
    const parsed = await Utils._callServer('/api/governance/bigvote', {}, 'GET')
    const note = new TextEncoder('utf-8').encode(`bigvote1:${vote}`)
    const txn =
      algosdk.makePaymentTxnWithSuggestedParams(
        from,
        parsed.address_vote,
        0, undefined,
        note, suggestedParams)

    const signedTx = await API.sign(txn)

    return Utils._callServer('/api/governance/bigvote', {
      value: vote,
      signed_tx: [signedTx]
    })
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default bigVote
