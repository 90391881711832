<template>
  <div id="auth_addresses">
    <p>{{$t('authenticateAddresses.header')}}</p>
    <ul>
      <li class="mobile-only" v-for="(address, index) in addresses" :key="address" @click="next(address)" :style="`animation-delay: ${100 + (index * 50)}ms`">
        <jazzicon  :address="address" :diameter="50" class="icon"/>
        {{ address.slice(0,5) }}.{{ address.slice(53,58) }}
      </li>
      <li class="desktop-only" v-for="(address, index) in addresses" :key="address" @click="next(address)" :style="`animation-delay: ${100 + (index * 50)}ms`">
        <jazzicon  :address="address" :diameter="50" class="icon"/>
        {{ address }}
      </li>
    </ul>
    <template v-if="wallet === 'algowallet' || wallet === 'defly'">
      <Button @click.native="changeAlgoWalletAddress">Use another address</Button>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Button from '@/components/Common/Button/Button'
import Contract from '@/web3/Contract'

export default {
  name: 'AuthenticateAddresses',
  components: { Button },
  props: ['_viewData'],
  data () {
    return {
      addresses: []
    }
  },
  computed: {
    ...mapState([
      'wallet'
    ])
  },
  methods: {
    next (address) {
      this.$emit('next', { address })
    },
    changeAlgoWalletAddress () {
      Contract.logout(this.wallet).then(this.$emit('previous'))
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this._viewData.addresses && this._viewData.addresses.length) {
        this._viewData.addresses.forEach(a => {
          this.addresses.push(a.address)
        })
      } else {
        this.$emit('previous')
      }
    })
  }
}
</script>

<style scoped>
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

li {
  background: var(--foreground);
  border-radius: 20px;
  box-shadow: var(--light-shadow);
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--text);
  transition: all .2s ease;
  margin: 10px;
  animation: card-appear .2s ease backwards;
}

li:hover{
  transform: scale(1.05);
}

.icon{
  margin-right: 10px;
}
.mobile-only {
    display: none;
  }
/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: flex;
  }

}
</style>
