import Utils from '../utils'
import algosdk from 'algosdk-latest'
import cancelShuffle from './cancelShuffle'
import closeShuffle from './closeShuffle'

async function cancelPack (APIName, from, packId, shuffleId, step) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()
    if (step === 'cancel_pack') {
      const message = {
        pack_id: packId
      }
      const parsed = await Utils._callServer('/api/pack/cancel', message, 'GET')
      const txns = []

      for (const infos of parsed.metadata) {
        const accounts = []
        accounts.push(infos.seller)

        const foreignAssets = infos.nfts.slice()
        if (infos.whitelist) {
          foreignAssets.push(infos.whitelist)
        }
        txns.push(algosdk.makeApplicationDeleteTxn(
          from, suggestedParams, infos.app_id, undefined, accounts, undefined, foreignAssets
        ))
      }
      const signedTxs = await API.signGroup(txns, txns.map(x => true), false)
      const message2 = {
        pack_id: packId,
        signed_tx: signedTxs
      }

      // eslint-disable-next-line no-unused-vars
      const a = await Utils._callServer('/api/pack/cancel', message2)
      step = 'cancel_shuffle'
    }

    if (step === 'cancel_shuffle') {
      // eslint-disable-next-line no-unused-vars
      const a = await cancelShuffle(APIName, from, shuffleId)
    }
    return await closeShuffle(APIName, from, shuffleId)
  } catch (e) {
    console.log('Cancel Shuffle - error', e)
    throw e
  }
}

export default cancelPack
