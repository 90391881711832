<template>
    <router-link v-if="to" :to="to" tag="button"><slot></slot></router-link>
    <button v-else><slot></slot></button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    to: String
  }
}
</script>

<style scoped>
button {
  background: var(--gradient);
  border: none;
  padding: 15px 20px;
  border-radius: 20px;
  color: var(--complement);
  font-size: 1.2em;
  box-shadow: var(--shadow);
  cursor: pointer;
  display: block;
  margin: 30px auto 50px auto;
  font-family: 'Varela Round', sans-serif;
  transition: all .2s ease;
}

button:hover {
  transform: scale(1.05);
}

button.secondary{
  background: var(--headings);
  box-shadow: none;
  color: var(--text);
}

button.error-btn{
    background: var(--error);
    color: #fff;
}

button.report-btn {
  background: none;
  color: var(--error);
  padding: 10px 15px;
  font-size: x-small;
  box-shadow: none;
  margin: 25px 0px;
  display: flex;
  align-items: center;
}

button.report-btn .unicon {
  fill: var(--error);
}

button.linear-button {
  background: none;
  opacity: 0.7;
  color: var(--blue);
  padding: 10px 15px;
  box-shadow: none;
}

button.linear-button:hover {
  transform: scale(1);
  opacity: 1;
}

button.text-logo {
  display: inline-flex;
  align-items: center;
  color: var(--textlight);
  font-size: medium;
  margin: 0;
  padding: 0;
  background: 0;
  box-shadow: none;
  opacity: 0.75;
}

button.text-logo:hover {
  opacity: 1;
  transform: scale(1);
}

button.text-logo .logo {
  margin: 0px 5px;
  display: inline-block;
  color: var(--blue);
}

button.button-card {
  display: inline-flex;
  align-items: center;
  margin: 0;
  background: none;
  box-shadow: none;
  border: none;
  color: var(--blue);
  font-size: small;
}

button.button-card * {
  fill: var(--blue);
}

button.button-card:hover {
  box-shadow: var(--light-shadow);
  transform: scale(1);
}

button.button-card.error-btn {
  color: var(--red);
}

button.button-card.error-btn .unicon {
  fill: var(--red);
}

button a {
  text-decoration: none;
}
</style>
