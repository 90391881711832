<template>
  <div id="nft-upload" :style="background">
    <label for="picture-upload">
      <span>
        {{$t('nftUpload.upload')}}
      </span>
    </label>
    <unicon v-if="!b64Picture" height="50px" name="image-plus" width="50px"/>
    <input id="picture-upload" accept="image/png, image/jpeg, image/gif" type="file" @change=uploadImage>
  </div>
</template>

<script>
export default {
  name: 'NFTUpload',
  data () {
    return {
      b64Picture: null
    }
  },
  computed: {
    background () {
      return `background-image: url(${this.b64Picture});`
    }
  },
  methods: {
    uploadImage (e) {
      const image = e.target.files[0]
      if (image) {
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = e => {
          this.b64Picture = e.target.result
          this.$emit('picture', this.b64Picture)
          this.$emit('file', image)
        }
      }
    }
  }
}
</script>

<style scoped>
#nft-upload {
  position: relative;
  width: 450px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 20px auto;
  box-shadow: var(--accent-shadow);
  cursor: pointer;
  border: 1px solid var(--foreground);
  fill: var(--headings)
}

#nft-upload.small-square {
  width: 250px;
  height: 250px;
}

#nft-upload.invalid > .unicon {
  fill: var(--error);
}

#nft-upload label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 350px;
  background: var(--lightgradient);
  color: var(--text);
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  box-sizing: border-box;
  font-size: 2em;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 2;
}

#nft-upload.small-square label {
  width: 250px;
  height: 250px;
  font-size: 1.5em;
}

#nft-upload:hover label {
  opacity: .95;
}

#nft-upload #picture-upload {
  display: none;
}

#nft-upload.invalid {
  border: 1px solid var(--error);
}
</style>
