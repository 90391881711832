import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueTimeago from 'vue-timeago'
import Jazzicon from 'vue-jazzicon'
import VueApexCharts from 'vue-apexcharts'
import i18n from './il8n'
import numeral from 'numeral'
import numFormat from 'vue-filter-number-format'
import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'
import VueConfetti from 'vue-confetti'

require('./unicons')
require('./unregisterServiceWorker')

Vue.use(VueAxios, axios)
Vue.use(VueTimeago, {
  locale: 'en'
})
Vue.use(VueApexCharts)
Vue.use(Donut)
Vue.use(VueConfetti)

Vue.component('jazzicon', Jazzicon)
Vue.component('apexchart', VueApexCharts)

Vue.filter('numFormat', numFormat(numeral))

Vue.mixin({
  data () {
    return {
      refreshing: false
    }
  },
  filters: {
    formatNftName (val) {
      if (val.length > 15) {
        val = `${val.slice(0, 15)}...`
      }
      return val
    },
    formatUserName (val) {
      if (val.length > 20) {
        val = `${val.slice(0, 20)}...`
      }
      return val
    },
    zeroPad (val) {
      return String(val).padStart(2, '0')
    }
  },
  created () {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', (e) => {
      if (!e.registration || !e.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      e.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }, { once: true })

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  }
})

Vue.config.productionTip = false

// eslint-disable-next-line no-extend-native
String.prototype.hexEncode = function () {
  let hex, i

  let result = ''
  for (i = 0; i < this.length; i++) {
    hex = this.charCodeAt(i).toString(16)
    result += ('000' + hex).slice(-4)
  }
  return result
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
