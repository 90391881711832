import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function claimRoyaltyFees (APIName, from) {
  const API = Utils._getAPI(APIName)
  const parsed = await Utils._callServer('/api/account/claim/royalties', {}, 'GET')

  const message = {}
  if (parsed.asa.length) {
    const suggestedParams = await Utils.getParams()
    const txns = []

    for (const tokenId of parsed.asa) {
      txns.push(
        algosdk.makeAssetTransferTxnWithSuggestedParams(
          from, from,
          undefined, undefined,
          0, undefined,
          tokenId,
          suggestedParams))
    }
    const signedTx = await API.signGroup(txns, txns.map(x => true), true)
    message.signed_tx = signedTx
  }
  return Utils._callServer('/api/account/claim/royalties', message)
}

export default claimRoyaltyFees
