<template>
  <div class="instagram-images" v-if="!hasToConnect">
    <div class="button-container">
      <TextualButton class="reverse" @click.native="previousPage" v-if="this.before">
        <unicon name="angle-left" />previous
      </TextualButton>
      <span v-else></span>
      <TextualButton @click.native="nextPage" v-if="this.after">
        next<unicon name="angle-right" />
      </TextualButton>
      <span v-else></span>
    </div>
    <ul v-if="media.length">
      <li v-for="m of media" :key="m.id" class="media" @click="select(m)">
        <template v-if="m.media_type === 'IMAGE'">
          <img :src="m.media_url">
        </template>
      </li>
    </ul>
    <LinearSpinner v-else />
  </div>
  <div v-else class="connection-div">
    <p>You are not connected to Instagram.</p>
    <a :href="connectionURL" class="instagram-connection-button"><unicon name="instagram"/>Connect to Instagram</a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TextualButton from '../Common/Button/TextualButton'
import LinearSpinner from '../Common/Spinner/LinearSpinner'

export default {
  name: 'InstagramImages',
  components: { LinearSpinner, TextualButton },
  props: ['id'],
  data () {
    return {
      media: [],
      before: undefined,
      after: undefined,
      hasToConnect: false,
      connectionURL: ''
    }
  },
  computed: {
    ...mapState(['userId'])
  },
  methods: {
    previousPage () {
      this.fetchData({ before: this.before })
    },
    nextPage () {
      this.fetchData({ after: this.after })
    },
    fetchData (params = {}) {
      this.before = undefined
      this.after = undefined
      this.media = []
      params.user_id = this.id ? this.id : this.userId

      this.$http.get('/api/instagram/media', { params: params })
        .then((d) => {
          for (const m of d.data.media) {
            if (m.media_type === 'IMAGE') { this.media.push(m) }
            if (m.media_type === 'CAROUSEL_ALBUM') {
              for (const c of m.children.data) {
                if (m.caption) c.caption = m.caption
                this.media.push(c)
              }
            }
          }
          if (d.data.paging.next) {
            this.after = d.data.paging.cursors.after
          }
          if (d.data.paging.previous) {
            this.before = d.data.paging.cursors.before
          }
        })
        .catch((e) => {
          if (e.response.data.url) {
            this.hasToConnect = true
            this.connectionURL = e.response.data.url
          }
        })
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style scoped>
ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
}

.media {
  border-radius: 20px;
  width: 300px;
  height: 350px;
  overflow: hidden;
  margin: 5px;
}

.media:hover {
  box-shadow: var(--shadow);
}

.media img {
  width: 350px;
  height: 350px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  margin: auto
}

.instagram-images {
  max-width: 1000px;
}

.instagram-connection-button {
  background: linear-gradient(-148deg,#B900B4, #F50001);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 200px;
  height: 40px;
  cursor: pointer;
}

.instagram-connection-button .unicon {
  margin-right: 5px;
}
</style>
