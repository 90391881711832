import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function buyShuffle (APIName, from, shuffleId) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()

    const message = {
      shuffle_id: shuffleId
    }
    const parsed = await Utils._callServer('/api/shuffle/buy', message, 'GET')
    // {"app_id": app_id, "price": price, "seller": seller, "gems_address": gems_address, "app_address": app_address}
    console.log('Buy Shuffle - check', parsed)

    const txns = []
    console.log(parsed.check)
    if (!parsed.check) {
      txns.push(
        algosdk.makeApplicationOptInTxn(from, suggestedParams, parsed.app_id)
      )
    }

    if (parsed.whitelist_id) {
      txns.push(
        algosdk.makeAssetTransferTxnWithSuggestedParams(
          from, parsed.app_address,
          undefined, undefined,
          1,
          undefined,
          parsed.whitelist_id,
          suggestedParams))
    }

    if (parsed.asa_id === 1) {
      txns.push(
        algosdk.makePaymentTxnWithSuggestedParams(
          from, parsed.app_address,
          parsed.price,
          undefined, undefined,
          suggestedParams,
          undefined
        )
      )
    } else {
      txns.push(
        algosdk.makeAssetTransferTxnWithSuggestedParams(
          from, parsed.app_address,
          undefined, undefined,
          parsed.price,
          undefined,
          parsed.asa_id,
          suggestedParams))
    }

    const appArgs = []
    appArgs.push(new TextEncoder().encode('buy_in'))
    const accounts = []
    accounts.push(parsed.seller)
    accounts.push(parsed.gems_address)
    const foreignAssets = []
    if (parsed.whitelist_id) {
      foreignAssets.push(parsed.whitelist_id)
    }
    if (parsed.asa_id !== 1) {
      foreignAssets.push(parsed.asa_id)
    }
    txns.push(
      algosdk.makeApplicationNoOpTxn(
        from,
        suggestedParams,
        parsed.app_id, appArgs,
        accounts,
        undefined, foreignAssets,
        undefined, undefined, undefined)
    )

    const signedTxs = await API.signGroup(txns, txns.map(x => true))

    console.log('Buy Shuffle - signed', signedTxs)
    console.log(signedTxs, shuffleId)
    const message2 = {
      signed_tx: signedTxs,
      shuffle_id: shuffleId
    }
    console.log(message2)
    const parsed2 = await Utils._callServer('/api/shuffle/buy', message2)
    console.log('Buy Shuffle - app call done', parsed2)

    const txns2 = []

    if (!parsed2.check) {
      txns2.push(algosdk.makeAssetTransferTxnWithSuggestedParams(from, from, undefined, undefined, 0, undefined, parsed2.token_id, suggestedParams))
    }
    const appArgs2 = []
    appArgs2.push(new TextEncoder().encode('claim'))
    txns2.push(
      algosdk.makeApplicationNoOpTxn(
        from,
        suggestedParams,
        parsed.app_id, appArgs2,
        [parsed.seller, from],
        undefined, [parsed2.token_id],
        undefined, undefined, undefined)
    )

    txns2.push(
      algosdk.makeApplicationCloseOutTxn(
        from,
        suggestedParams,
        parsed.app_id)
    )

    const signedTxs2 = await API.signGroup(txns2, txns2.map(x => true))

    const message3 = {
      token_id: parsed2.token_id,
      signed_tx: signedTxs2
    }
    console.log('Buy shuffle - signed2', message3)
    return Utils._callServer('/api/shuffle/claim', message3)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default buyShuffle
