<template>
  <li class="user-card" v-if="gallery">
    <router-link :to="galleryUrl">
      <Banner :id="gallery.account_id" :version="gallery.v_banner"/>
      <UserPicture :address="gallery.addresses[0]" :account-id="gallery.account_id" :version="gallery.v_pp"/>
      <div class="overview">
        <h1>
          {{ galleryId }}
          <button @click="toggleFollow" v-if="userId && userId.length !== 58">
            <unicon name="favorite" width="25px" height="25px" v-if="isFollowing"/>
            <unicon name="favorite" width="25px" height="25px" icon-style="monochrome" v-else/>
          </button>
        </h1>
      </div>
    </router-link>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import UserPicture from '../User/UserPicture'
import Banner from '../User/Banner'

export default {
  name: 'UserCard',
  components: { Banner, UserPicture },
  props: {
    galleryId: String,
    kind: String,
    isFollowing: Boolean
  },
  data () {
    return {
      gallery: null
    }
  },
  computed: {
    ...mapState([
      'userId'
    ]),
    isAddress () {
      return this.galleryId.length === 58
    },
    galleryUrl () {
      return `/gallery/${this.galleryId}`
    },
    apiUrl () {
      return `/api/account/info/${this.galleryId}`
    }
  },
  methods: {
    focus () {
      this.$router.push(this.galleryUrl)
    },
    toggleFollow () {
      const message = { username: this.galleryId }
      if (this.isFollowing) {
        this.$http.post('/api/account/unfollow', message)
          .then(() => {
            this.isFollowing = false
          })
      } else {
        this.$http.post('/api/account/follow', message)
          .then(() => {
            this.isFollowing = true
          })
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$http.get(this.apiUrl).then(response => {
        if (response.data) {
          this.gallery = response.data
        }
      }).catch(err => {
        console.log(err)
      })
    })
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}

button {
  background: none;
  border: none;
}

button:focus {
  outline: none;
}

.user-card{
  cursor: pointer;
  transition: transform .2s ease;
  box-sizing: border-box;
  position: relative;
  width: 400px;
  margin: 20px 0px;
  font-size: .8em;
  animation: card-appear .2s ease backwards;
}

.user-card .banner{
  height: 200px;
}

.user-card .user-picture{
  margin: -50px auto -20px auto;
}

.overview{
  position: relative;
  padding: 10px 20px;
  box-sizing: border-box;
  color: var(--text);
  text-align: center;
}

.overview h1{
  color: var(--text);
}

.overview h1 button{
  position: absolute;
}

@media only screen
and (max-device-width: 1200px) {
  .user-card{
    width: 300px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .user-card .banner{
    margin-top: 10px;
  }
}
</style>
