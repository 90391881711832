<template>
  <form @keyup.enter="$emit('previous')">
    <h1 class="light flex-center">
      <unicon height="50px" name="exclamation-octagon" width="50px"/>
      {{$t('errorAuthentificate.header')}}
    </h1>
    <p>{{ errorMessage }}</p>
    <div class="button-container">
      <Button class="error-btn" @click.native.prevent="$emit('previous', 0)">{{$t('errorAuthentificate.button.goBack')}}</Button>
    </div>
  </form>
</template>

<script>
import Button from '@/components/Common/Button/Button'

export default {
  name: 'ErrorAuthenticate',
  components: { Button },
  props: ['error'],
  computed: {
    errorMessage () {
      if (this.error) {
        return this.error
      }
      return this.$t('error.standard-error')
    }
  }
}
</script>

<style scoped>
h1 {
  color: var(--error);
  margin: 10px;
}

.unicon {
  fill: var(--error);
}
</style>
