<template>
  <AppView id="crsd-submarket">
    <template v-slot:header>
    <div class="market-header">
      <img src="@/assets/marketHeader/crescendo-header.png" alt="crescendo nft"/>
      <div class="sales-buttons" v-if="hasAccess">
        <router-link :to="`/crsd`">
          <unicon name="estate"/>
          Home
        </router-link>
        <router-link :to="`/crsd/sales`">
          <unicon name="usd-circle"/>
          Sales
        </router-link>
        <router-link :to="`/crsd/auctions`">
          <unicon name="arrow-growth"/>
          Auctions
        </router-link>
        <router-link :to="`/crsd/shuffles`">
          <unicon name="shuffle"/>
          Shuffles
        </router-link>
        <router-link :to="`/crsd/artists`">
          <unicon name="music"/>
          Artists
        </router-link>
        <router-link :to="`/crsd/token`">
          <ASALogo class="no-margin" :asa-id="435335235"/>
          CRSD
        </router-link>
      </div>
    </div>
    </template>
    <router-view v-if="hasAccess"/>
    <div v-else>
      <h2 class="coming-soon">Coming Soon</h2>
    </div>
  </AppView>
</template>

<script>
import 'hooper/dist/hooper.css'
import AppView from '@/components/Layout/AppView'
import { mapMutations, mapState } from 'vuex'
import ASALogo from '@/components/Assets/ASALogo'

export default {
  name: 'CRSDSubmarket',
  components: { ASALogo, AppView },
  computed: {
    ...mapState(['address']),
    hasAccess () {
      return true /* ['TST2PF3APFSTOQS57TTILKU3IV5O4RCU7XK6SXBS4VFDRG2APTD3QOOF7I',
        'WXLHOLGBGWIF4WXCSART3QRIINPM5R3OSULYQ3TWWHSPAI2YHIJMVM4XRI'
      ].includes(this.address) */
    }
  },
  methods: {
    ...mapMutations(['setCustomClass'])
  },
  beforeDestroy () {
    this.setCustomClass({ customClass: '' })
  },
  mounted () {
    this.setCustomClass({ customClass: 'crsd' })
  }
}
</script>

<style scoped>
.market-header{
  height: 570px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.market-header .title{
  position: relative;
  z-index: 1;
}

.market-header .title h2{
  font-family: 'aktiv-grotesk', sans-serif;
  font-size: 8em;
  line-height: .95em;
  text-transform: uppercase;
  text-align: center;
  color: var(--text);
}

.market-header .title h2 a {
  text-decoration: none;
}

.market-header .title h3{
  font-family: 'aktiv-grotesk', sans-serif;
  font-size: 3em;
  font-weight: lighter;
  line-height: 1em;
  text-transform: uppercase;
  text-align: center;
  color: var(--textlight);
  margin: 0;
}

.market-header a{
  display: block;
  text-align: center;
}

.market-header img{
  width: 100%;
  max-width: 1200px;
}

.market-body {
  margin-top: 30px;
}

.sales-buttons {
  display: flex;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
}

.sales-buttons a {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  margin: 0px 25px;
}

.sales-buttons a .unicon, .sales-buttons a .asa-logo {
  margin-bottom: 5px;
  padding: 12px;

  background-color: #000;
  border-radius: 15px;
}

.sales-buttons a .asa-logo {
  width: 24px;
  height: 24px;
  font-size: x-large;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sales-buttons a:hover {
  color: var(--blue);
}

.sales-buttons a:hover .unicon {
  fill: var(--blue);
}

.sales-buttons a.router-link-exact-active {
  color: var(--blue);
}

.sales-buttons a.router-link-exact-active .unicon {
  fill: var(--blue);
}

.market-header .crsd-nft{
  width: 100vw;
  z-index: 1;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .market-header{
  height: 350px;
  }
  .market-header img{
  margin-bottom: 100px;
  }
}
</style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100;400;700&family=Mr+Dafoe&display=swap');

#app.crsd {
  --blue: #f448e0;
  --red: #ca1b52;
  --error: var(--red);
  background: var(--background);
  min-height: 100vh;
  font-family: 'aktiv-grotesk', sans-serif;
  transition: background .5s ease;
  --invert: 0;
  width: 100%;
  overflow-x: hidden;
}

#app.crsd.dark, #app.crsd {
  --background: #0e1117;
  --foreground: #1d222d;
  --foreground-transparent: rgba(29,34,45,.5);
  --foreground-blur: rgba(29,34,45,.5);
  --fake-blur: #1c1d20eb;
  --headings: #202231;
  --nav-background: #f448e0;
  --text: #dae2ff;
  --textlight: rgba(218, 226, 255, 0.6);
  --accent: #f448e0;
  --complement: #fff;
  --blur-overlay: rgba(14, 17, 23, .5);
  --light-shadow: rgba(0, 0, 0, .1) 0px 0px 20px;
  --shadow: rgba(0, 0, 0, .1) 0px 0px 2px, rgba(0, 0, 0, .3) 0px 0px 20px;
  --strong-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  --accent-shadow: 0 0 .5vw rgba(0,0,0,.1), 0 0 10vw rgba(202, 49, 112, 0.3);
  --gradient: linear-gradient(45deg, var(--accent),#4d02b1);
  --lightgradient: linear-gradient(0deg, #103252, #8D2C95);
  --background-alt: #1d222d;
  --foreground-alt: #0e1117;
  --invert: 1;
  --pop-up-background: rgba(14, 17, 23, 0.9);
}

#crsd-submarket .view-body .view-header {
  display: none;
}

.coming-soon {
  display: flex;
  justify-content: center;
  height: 550px;
}
</style>
