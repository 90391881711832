<template>
  <div class="market-body">
    <HotSales :asa-id="137594422" asa-name="HDL"/>
    <Section>
      <template v-slot:header>
        <h3>Explore our universe</h3>
        <TextualButton to="/gallery/headline">See More</TextualButton>
      </template>
      <CardGrid>
        <Card v-for="(auction, index) in headlineSales" :key="auction.token_id" :data="auction" :style="`animation-delay: ${100 + (index * 50)}ms`"/>
      </CardGrid>
    </Section>
  </div>
</template>

<script>
import HotSales from './HotSales'
import CardGrid from '../Cards/CardGrid'
import Section from '../Layout/Section'
import TextualButton from '../Common/Button/TextualButton'
import Card from '../Cards/Card'

export default {
  name: 'HDLBody',
  components: { HotSales, CardGrid, Section, TextualButton, Card },
  data () {
    return {
      headlineSales: []
    }
  },
  methods: {
    getData () {
      this.headlineSales = []
      const salesParams = {
        start: 0,
        end: 8,
        sort: 'new'
      }
      this.$http('/api/sales/HEADLINE', { params: salesParams }).then((response) => {
        if (response.data && response.data.sales) {
          this.headlineSales = response.data.sales
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style scoped>

</style>
