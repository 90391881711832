<template>
  <AppView v-if="incorrectFusion" >
    <Error>
      <template v-if="isNaN(id)">
        There is no fusion with id <i>{{id}}</i>.
      </template>
      <template v-else>This fusion has been closed</template>
    </Error>
  </AppView>
<AppView v-else>
  <template v-if="!displayFuseAnimation">
    <div class="button-container">
      <Button @click.native="buy">Fuse</Button>
      <Button  v-if="isMine" class="error-btn" @click.native="cancel">Cancel</Button>
    </div>
    <div class="flex-center">
      <p v-if="fusion && fusion.number_fusion !== undefined">{{fusion.number_fusion}} remaining</p>
    </div>
    <div class="head">
      <CardGrid class="top">
        <Card :data="firstCard" v-if="firstCard"/>
        <Card :data="secondCard" v-if="secondCard"/>
        <Card :data="thirdCard" v-if="thirdCard"/>
        <Card :data="fourthCard" v-if="fourthCard"/>
      </CardGrid>
      <unicon name="angle-down" height="80" width="80"/>
      <CardGrid class="bottom">
        <Card :data="resultCard" v-if="resultCard"/>
      </CardGrid>
    </div>
  </template>
  <template v-else>
    <template v-if="!thirdCard">
      <CardGrid class="card-holder-animation">
        <Card class="first card-animation" :data="firstCard"/>
      </CardGrid>
      <CardGrid class="card-holder-animation">
        <Card class="second card-animation" :data="secondCard"/>
      </CardGrid>
      <CardGrid class="card-holder-animation">
        <Card class="result card-animation" :data="resultCard"/>
      </CardGrid>
    </template>
    <template v-else-if="!fourthCard">
      <CardGrid class="card-holder-animation">
        <Card class="first-bis card-animation" :data="firstCard"/>
      </CardGrid>
      <CardGrid class="card-holder-animation">
        <Card class="second-bis card-animation" :data="secondCard"/>
      </CardGrid>
      <CardGrid class="card-holder-animation">
        <Card class="third card-animation" :data="thirdCard"/>
      </CardGrid>
      <CardGrid class="card-holder-animation">
        <Card class="result card-animation" :data="resultCard"/>
      </CardGrid>
    </template>
    <template v-else>
      <CardGrid class="card-holder-animation">
        <Card class="first-ter card-animation" :data="firstCard"/>
      </CardGrid>
      <CardGrid class="card-holder-animation">
        <Card class="second-ter card-animation" :data="secondCard"/>
      </CardGrid>
      <CardGrid class="card-holder-animation">
        <Card class="third-ter card-animation" :data="thirdCard"/>
      </CardGrid>
      <CardGrid class="card-holder-animation">
        <Card class="fourth card-animation" :data="fourthCard"/>
      </CardGrid>
      <CardGrid class="card-holder-animation">
        <Card class="result card-animation" :data="resultCard"/>
      </CardGrid>
    </template>
    <div class="button-holder">
      <Button v-if="false" class="secondary" @click.native="displayFuseAnimation = false">Back</Button>
      <Button @click.native="$router.push(`/nft/${resultCard.token_id}`)">See NFT</Button>
    </div>
  </template>
  <ContractPopUp v-if="displayContractPopUp"
                 :_state="contractPopUpState"
                 :_message="contractMessage"
                 @close="() => { displayContractPopUp = false }"
  />
</AppView>
</template>

<script>
import AppView from '@/components/Layout/AppView'
import Card from '@/components/Cards/Card'
import CardGrid from '@/components/Cards/CardGrid'
import Button from '@/components/Common/Button/Button'
import Contract from '@/web3/Contract'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import { mapState } from 'vuex'
import Error from '../components/Common/Error'

export default {
  name: 'Fusion',
  components: { Error, ContractPopUp, Button, CardGrid, Card, AppView },
  data () {
    return {
      incorrectFusion: false,
      firstCard: undefined,
      secondCard: undefined,
      thirdCard: undefined,
      fourthCard: undefined,
      resultCard: undefined,
      id: this.$route.params.id,
      displayFuseAnimation: false,
      displayContractPopUp: false,
      contractPopUpState: 'loading',
      contractMessage: undefined,
      fusion: undefined,
      isMine: false
    }
  },
  computed: {
    ...mapState(['wallet', 'address']),
    dataUrl () {
      return `/api/fusion/${this.id}`
    }
  },
  methods: {
    fetchData () {
      this.$http.get(this.dataUrl)
        .then((d) => {
          if (d.data?.metadata) {
            this.isMine = d.data.metadata.address === this.address
            this.fusion = d.data.metadata
            this.firstCard = d.data.metadata.input_1
            this.secondCard = d.data.metadata.input_2
            if (d.data.metadata.input_3.address) {
              this.thirdCard = d.data.metadata.input_3
            }
            if (d.data.metadata.input_4.address) {
              this.fourthCard = d.data.metadata.input_4
            }
            this.resultCard = d.data.metadata.output
          }
        })
        .catch((e) => {
          console.log(e)
          this.incorrectFusion = true
        })
    },
    buy () {
      if (this.address) {
        this.contractPopUpState = 'loading'
        this.displayContractPopUp = true
        Contract.buyFusion(this.wallet, this.address, this.fusion)
          .then((m) => {
            this.contractPopUpState = 'done'
            this.displayContractPopUp = false
            this.displayFuseAnimation = true
          })
          .catch((e) => {
            this.contractPopUpState = 'error'
            this.contractMessage = e.toString()
          })
      } else {
        this.$router.push('/authenticate')
      }
    },
    cancel () {
      if (this.address) {
        this.contractPopUpState = 'loading'
        this.displayContractPopUp = true
        Contract.cancelFusion(this.wallet, this.address, this.fusion)
          .then((m) => {
            this.contractPopUpState = 'done'
            this.fetchData()
          })
          .catch((e) => {
            this.contractPopUpState = 'error'
            this.contractMessage = e.toString()
          })
      } else {
        this.$router.push('/authenticate')
      }
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style scoped>

@keyframes firstCard {
  0% {
    transform: rotate(0deg) translate(250px) rotate(0deg);
  }
  100% {
    transform: rotate(-720deg) translate(0px) rotate(720deg);
    opacity: 0;
  }
}

@keyframes secondCard {
  0% {
    transform: rotate(-180deg) translate(250px) rotate(180deg);
  }
  100% {
    transform: rotate(-900deg) translate(0px) rotate(900deg);
    opacity: 0;
  }
}

@keyframes firstCardBis {
  0% {
    transform: rotate(-90deg) translate(250px) rotate(90deg);
  }
  100% {
    transform: rotate(-810deg) translate(0px) rotate(810deg);
    opacity: 0;
  }
}

@keyframes secondCardBis {
  0% {
    transform: rotate(-210deg) translate(250px) rotate(210deg);
  }
  100% {
    transform: rotate(-930deg) translate(0px) rotate(930deg);
    opacity: 0;
  }
}

@keyframes thirdCard {
  0% {
    transform: rotate(-330deg) translate(250px) rotate(330deg);
  }
  100% {
    transform: rotate(-1050deg) translate(0px) rotate(1050deg);
    opacity: 0;
  }
}

@keyframes firstCardTer {
  0% {
    transform: rotate(-45deg) translate(250px) rotate(45deg);
  }
  100% {
    transform: rotate(-765deg) translate(0px) rotate(765deg);
    opacity: 0;
  }
}

@keyframes secondCardTer {
  0% {
    transform: rotate(-135deg) translate(250px) rotate(135deg);
  }
  100% {
    transform: rotate(-855deg) translate(0px) rotate(855deg);
    opacity: 0;
  }
}

@keyframes thirdCardTer {
  0% {
    transform: rotate(-225deg) translate(250px) rotate(225deg);
  }
  100% {
    transform: rotate(-945deg) translate(0px) rotate(945deg);
    opacity: 0;
  }
}

@keyframes fourthCard {
  0% {
    transform: rotate(-315deg) translate(250px) rotate(315deg);
  }
  100% {
    transform: rotate(-1035deg) translate(0px) rotate(1035deg);
    opacity: 0;
  }
}

@keyframes resultCard {
  0% {
    opacity: 0;
    transform: perspective(500px) translateZ(-1000px);
  }
  100% {
    opacity: 1;
    transform: perspective(500px) translateZ(0px);
  }
}

.card-animation {
  animation-duration: 2s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.card-animation.first {
  animation-name: firstCard;
}

.card-animation.second {
  animation-name: secondCard;
}

.card-animation.third {
  animation-name: thirdCard;
}

.card-animation.first-bis {
  animation-name: firstCardBis;
}

.card-animation.second-bis {
  animation-name: secondCardBis;
}

.card-animation.fourth {
  animation-name: fourthCard;
}

.card-animation.first-ter {
  animation-name: firstCardTer;
}

.card-animation.second-ter {
  animation-name: secondCardTer;
}

.card-animation.third-ter {
  animation-name: thirdCardTer;
}

.card-animation.result {
  opacity: 0;
  animation-name: resultCard;
  animation-delay: 2s;
}

#app:not(.dark) .card-animation.result {
  box-shadow:
    inset 0 0 50px #fff,
    inset 20px 0 80px #f6ebfb,
    inset -20px 0 80px #e1f2ff,
    inset 20px 0 300px #f6ebfb,
    inset -20px 0 300px #e1f2ff,
    0 0 50px #fff,
    -10px 0 80px #f6ebfb,
    10px 0 80px #e1f2ff;
}

#app.dark .card-animation.result {
  box-shadow:
    inset 0 0 50px #fff,
    inset 20px 0 80px #581e70,
    inset -20px 0 80px #103252,
    inset 20px 0 300px #581e70,
    inset -20px 0 300px #103252,
    0 0 50px #fff,
    -10px 0 80px #581e70,
    10px 0 80px #103252;
}

.card-holder-animation {
  position: absolute;
  left: calc(50%);
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes buttonFadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
.head {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.head > div {
  margin: 0px 60px;
}

.top, .bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px;
  padding: 0;
}

button {
  justify-self: center;
  margin:0 13px;
}

.error-btn {
  margin-right: 0px;
}
.button-holder {
  display: flex;
  position: absolute;
  top: calc(50% + 300px);
  left: 50%;
  transform: translate(-50%,-50%);
  visibility: hidden;
  animation-name: buttonFadeIn;
  animation-delay: 4s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.button-holder button {
  margin: 15px;
}

.button-container {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
}
/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .top, .bottom {
  flex-wrap: wrap;
  width: 75vw;
}

}
</style>
