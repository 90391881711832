import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function vote (APIName, from) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()
    const parsed = await Utils._callServer('/api/governance/vote', {}, 'GET')
    console.log('Vote - list of votes', parsed)

    const txns = []
    const isSigning = []

    for (const vote of parsed.votes) {
      const note = new TextEncoder('utf-8').encode(JSON.stringify(vote))
      txns.push(algosdk.makePaymentTxnWithSuggestedParams(from, parsed.address_vote, 0, undefined, note, suggestedParams))
      isSigning.push(true)
    }

    console.log('Vote - SignGroup', txns, isSigning)
    const signed = await API.signGroup(txns, isSigning)

    console.log('Vote - submit', signed)
    return Utils._callServer('/api/governance/vote', { signed_tx: signed })
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default vote
