<template>
  <div class="sort-buttons-wrapper">
    <input type="radio" id="random" name="sortButtons" value="random" v-model="selected">
    <label for="random" :class="(selected == 'random') ? 'selected' : ''" @click="reloadRandom">
      <unicon class="social-icons" name="arrow-random" height="25px" width="25px"/> {{$t('sortButtons.random')}}
    </label>
    <input type="radio" id="new" name="sortButtons" value="new" v-model="selected">
    <label for="new" :class="(selected == 'new') ? 'selected' : ''">
      <unicon class="social-icons" name="brightness-empty" height="30px" width="30px"/> {{$t('sortButtons.new')}}
    </label>

    <input type="radio" id="old" name="sortButtons" value="old" v-model="selected">
    <label for="old" :class="(selected == 'old') ? 'selected' : ''">
      <unicon class="social-icons" name="clock-ten" height="30px" width="30px"/> {{$t('sortButtons.old')}}
    </label>
  </div>
</template>

<script>
export default {
  name: 'SortButtonsCollections',
  props: {
    _initialSort: String
  },
  data () {
    return {
      selected: (this._initialSort) ? this._initialSort : 'random'
    }
  },
  watch: {
    selected (sortOption) {
      this.$emit('selected', sortOption)
    }
  },
  methods: {
    reloadRandom () {
      if (this.selected === 'random') {
        window.location.reload()
      }
    }
  }
}
</script>

<style scoped>
input {
  display: inline;
  margin: 0;
  width: 0;
  height: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  padding: 0;
}

label {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  margin: 2px;
  color: var(--textlight);
  cursor: pointer;
}

.unicon {
  margin-right: 5px;
  fill: var(--textlight);
}

label.selected {
  background: var(--foreground);
  border-radius: 10px;
  color: var(--accent);
  box-shadow: var(--light-shadow);
}

label.selected .unicon {
  fill: var(--accent);
}

.sort-buttons-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

@media only screen
and (max-device-width: 1200px) {
  .sort-buttons-wrapper {
    flex-wrap: wrap;
    width: 100vw;
  }
}
</style>
