<template>
  <AppView v-if="incorrectCollection">
    <Error>
      There is no collection with this id <i>{{id}}</i>.
    </Error>
  </AppView>
  <AppView id="collection" v-else>
    <template v-slot:header>
      <Banner :id="parseInt(id)" :version="coverVersion" :isCollectionBanner="true"/>
      <div class="owner-info">
        <UserPicture :account-id="accountId" :version="ppVersion" :address="ownerAddress"/>
        <span class="address">
          <router-link :to="ownerUrl">
            @{{ ownerUsername }}
          </router-link>
        </span>
      </div>
    </template>
    <Section>
    <div class="header">
      <h1>{{ title }}</h1>
      <p v-if="description"> <!--Hide if not in use-->
        <span v-for="[i, p] of description.split('\n').entries()" :key="i">
          {{ p }} <br>
        </span>
      </p>
      <span v-if="isMine">
          <router-link :to="editUrl"><Button>{{ $t('collection.button.edit') }}</Button></router-link>
      </span>
    </div>
      <div :class="(arc69) ? 'collection-body' : ''">
        <div class="filter">
          <h2>Filters</h2>
          <textual-button @click.native="toggleOnlySales" v-if="!isMine">
            <unicon name="usd-circle" v-if="onlySales"/><unicon name="circle" v-else/>Sales only
          </textual-button>
          <ARC69Filters :arc69="arc69" @filter="arc69FilterToggle" v-if="arc69"/>
        </div>
        <div class="nftResults">
          <CardGrid :key="infiniteId">
            <Card v-for="(nft, index) in nfts" :key="nft.token_id" :data="nft" :style="getStyle(index)"/>
          </CardGrid>
          <infinite-loading class="loading" spinner="waveDots" @infinite="infiniteHandler" :forceUseInfiniteWrapper="true" :identifier="infiniteId"><span slot="no-more"></span><span
        slot="no-results"></span></infinite-loading>
        </div>
      </div>
    </Section>
  </AppView>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import Card from '@/components/Cards/Card'
import { mapState } from 'vuex'
import Button from '@/components/Common/Button/Button'
import Banner from '@/components/User/Banner'
import AppView from '@/components/Layout/AppView'
import Section from '@/components/Layout/Section'
import CardGrid from '@/components/Cards/CardGrid'
import TextualButton from '@/components/Common/Button/TextualButton'
import UserPicture from '@/components/User/UserPicture'
import ARC69Filters from '../components/Collection/ARC69Filters'
import Error from '../components/Common/Error'

export default {
  name: 'Collection.vue',
  components: { Error, ARC69Filters, UserPicture, TextualButton, CardGrid, Section, Banner, Button, Card, InfiniteLoading, AppView },
  data () {
    return {
      start: 0,
      end: 16,
      title: '',
      description: '',
      nfts: [],
      selectedNFTs: [],
      ownerAddress: '',
      ownerUsername: '',
      infiniteId: 0,
      onlySales: false,
      id: this.$route.params.id,
      accountId: 0,
      coverVersion: 0,
      ppVersion: 0,
      arc69: null,
      arc69Filters: null,
      incorrectCollection: false
    }
  },
  computed: {
    ...mapState([
      'address',
      'userId'
    ]),
    isMine () {
      return (this.userId === this.ownerUsername) || (this.address === this.ownerAddress)
    },
    url () {
      return `/api/collections/info/${this.id}`
    },
    NFTUrl () {
      return `/api/collections/nft/${this.id}`
    },
    editUrl () {
      return `/edit_collection/${this.id}`
    },
    ownerUrl () {
      return `/gallery/${this.ownerUsername}`
    }
  },
  methods: {
    toggleOnlySales () {
      this.onlySales = !this.onlySales
      this.reloadNfts()
    },
    arc69FilterToggle (e) {
      if (!this.arc69Filters) {
        this.arc69Filters = {}
      }
      if (e.value) {
        this.arc69Filters[e.property] = e.value
      } else {
        if (this.arc69Filters[e.property]) {
          delete this.arc69Filters[e.property]
        }
      }
      if (!Object.keys(this.arc69Filters).length) {
        this.arc69Filters = null
      }
      this.reloadNfts()
    },
    reloadNfts () {
      this.start = 0
      this.end = 16
      this.nfts = []
      this.infiniteId++
    },
    infiniteHandler ($state) {
      const params = {
        start: this.start,
        end: this.end,
        sales: this.onlySales
      }
      if (this.arc69Filters) {
        params.arc_69 = this.arc69Filters
      }
      this.$http.get(this.NFTUrl, {
        params: params
      }).then(({ data }) => {
        if (data?.metadata.length) {
          this.start += data.metadata.length
          this.end += data.metadata.length
          this.nfts.push(...data.metadata)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    fetchData () {
      this.start = 0
      this.end = 16
      this.nfts = []
      this.$http.get(this.url).then(({ data }) => {
        if (data) {
          this.title = data.title
          this.description = data.description
          this.ownerAddress = data.address
          this.ownerUsername = (data.username) ? data.username : data.address
          this.accountId = data.account_id
          this.coverVersion = data.v_banner
          this.ppVersion = data.v_pp
          this.arc69 = data.arc_69
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getStyle (index) {
      return `animation-delay: ${100 + ((index % 4) * 50)}ms`
    }
  },
  beforeMount () {
    if (!this.id || isNaN(this.id)) {
      this.incorrectCollection = true
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.incorrectCollection) {
        this.fetchData()
      }
    })
  }
}
</script>

<style scoped>

.dark * {
  color: var(--complement);
}

.dark .address a {
  color: var(--complement);
}

h1{
  margin: 10px 0 0 0;
  text-transform: capitalize;
  color: var(--text);
}

p {
  margin-bottom: 0px;
  margin-top: 5px;
  text-align: center;
}
button {
  margin: 5px auto;
}

a {
  text-decoration: none;
}

.owner-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -60px;
}

.address{
  margin-top: 10px;
}

.address a {
  color: var(--text);
  font-size: 1em;
  margin: 15px;
}

.checkbox {
  height: 10px;
  width: 10px;
  border: 1px solid var(--text);
  border-radius: 5px;
}

.textual-button .unicon {
  background: var(--background);
  fill: var(--text);
  margin-right: 7px;
}

.textual-button:hover .unicon {
  transform: none;
}

#collection {
  max-width: 100vw; /* width: 1200px // max width is much better but then collection needs changing*/
}

.infinite-loading-container {
  display: flex;
}

.collection-body {
  display: flex;
  flex: wrap;
}

.collection-body .filter{
  display: grid;
  margin: auto;
  position: absolute;
  left: 5px;
  z-index: 1;
  width: 80px;
}

.collection-body .nftResults {
  display: flex;
  position: absolute;
  left: 0px;
  background: var(--background); /* because it's absolute */
  width: 100%;
  z-index: 0;
}

.collection-body .nftResults .card-grid{
  display: grid;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  list-style: none;
  gap: 33px;
  grid-auto-rows: 350px;
  min-width: 350px;
  max-width: 87vw; /* work on max width integration for collections */
  margin-top: 20px;
}

.collection-body .textual-button {
  font-weight: initial;
  margin-left: 0;
  position: relative;
  left: -10px;
}

.collection-body h2 {
  text-align: left;
  margin: 20px 0;
}

.header { /* deal with this after*/
  display: grid;
  justify-content: center;
  margin-top: -30px;
}

.filter {
  width: 130px;
  margin-left: 0px;
}
@media only screen
and (max-device-width: 1200px) {
  .collection-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.collection-body {
  display: flex;
}

.collection-body .filter{
  display: grid;
  margin: auto;
  position: relative;
  max-width: 70vw;
  left: 0px;
}

.collection-body .nftResults {
  display: flex;
  position: relative;
  left: 0px;
}
  .filter {
    width: 80vw;
    justify-content: center;
  }

  p {
  padding: 0px;
  margin: 15px;
 }

}

</style>
