<template>
  <label for="tags" :class="['tag input', !tagsValid && error ? 'invalid' : '']">
    <ul>
      <li v-for="tag in tags" :key="tag">{{tag}}</li>
    </ul>
    <input
      @keydown.enter.prevent.stop
      type="text"
      id="tags"
      :placeholder="tags.length?'':'Tags (' + $t('tags.commaSeparated') +')'"
      v-model="tagInput"
    >
  </label>
</template>

<script>
export default {
  name: 'Tags',
  props: {
    defaultTags: Array
  },
  data () {
    return ({
      error: false,
      tags: [],
      tagInput: ''
    })
  },
  computed: {
    tagsValid () {
      return /^ *[a-z0-9]{3,20}[, ]$/.test(this.tagInput)
    },
    tagsUniq () {
      const ret = !this.tags.includes(this.tagInput.slice(0, -1).trim())
      return ret
    }
  },
  watch: {
    tagInput (newValue) {
      if (newValue) {
        const lastChar = newValue[newValue.length - 1]
        if (this.tagInput.length > 0 && ((lastChar === ',' && this.tagInput.trim().length > 3) || (lastChar === ' ' && this.tagInput.trim().length > 2))) {
          this.tagInput = this.tagInput.toLowerCase()
          if (this.tagsValid && this.tagsUniq) {
            this.tags.push(newValue.slice(0, -1).trim().toLowerCase())
            this.tagInput = ' '
            this.error = false
            this.$emit('tags', this.tags)
          } else {
            this.toggleError()
          }
        }
      } else {
        this.tagInput = this.tags.pop()
        this.$emit('tags', this.tags)
      }
    }
  },
  methods: {
    toggleError () {
      if (!this.tagsValid) {
        this.error = true
        this.$emit('error', 'Invalid tag')
      } else if (!this.tagsUniq) {
        this.error = true
        this.$emit('error', 'Same tag cannot be added twice')
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.defaultTags && this.defaultTags.length) {
        this.tags.push(...this.defaultTags)
        this.tagInput = ' '
      }
    })
  }
}
</script>

<style scoped>
.input input {
  width: 320px;
}

.tag.input ul{
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
}

.tag.input ul li{
  font-size: .8em;
  background: var(--accent);
  padding: 5px;
  margin: 0 2px;
  border-radius: 5px;
  box-sizing: border-box;
  color:var(--complement);
}

</style>
