import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function createFusion (APIName, from, fusion) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()

    const messageInfo = {
      number_nfts: fusion.components.length,
      price: fusion.price,
      number_fusion: fusion.nbFusion
    }

    const contractInfo = await Utils._callServer('/api/fusion/contract/info', messageInfo, 'GET')

    const baseU = 6 + messageInfo.number_nfts
    const baseSlice = 5 + messageInfo.number_nfts

    const appArgs = []
    appArgs.push(algosdk.decodeAddress(from).publicKey)
    appArgs.push(Utils.longToByteArray(contractInfo.price))
    appArgs.push(algosdk.decodeAddress(contractInfo.gems_address).publicKey)
    appArgs.push(Utils.longToByteArray(contractInfo.fees))

    for (const nft of fusion.components) {
      appArgs.push(Utils.longToByteArray(nft))
    }
    appArgs.push(Utils.longToByteArray(fusion.result))

    const application =
      algosdk.makeApplicationCreateTxn(
        from,
        suggestedParams,
        algosdk.OnApplicationComplete.NoOpOC,
        new Uint8Array(Buffer.from(contractInfo.approval_program, 'base64')),
        new Uint8Array(Buffer.from(contractInfo.clear_state_program, 'base64')),
        0, 0,
        baseU, baseSlice,
        appArgs,
        undefined, undefined,
        undefined
      )

    const signedApp = await API.sign(application)
    const appInfos = await Utils._callServer('/api/fusion/app/create', {
      signed_tx: signedApp,
      number_fusion: fusion.nbFusion
    })

    const txns = []

    const to = algosdk.getApplicationAddress(appInfos.app_id)

    txns.push(
      algosdk.makePaymentTxnWithSuggestedParams(
        from, to, appInfos.amt,
        undefined, undefined,
        suggestedParams, undefined
      )
    )

    const appArgs2 = []
    appArgs2.push(new TextEncoder().encode('setup'))
    const foreignAssets = [...fusion.components, fusion.result]

    txns.push(algosdk.makeApplicationNoOpTxn(
      from,
      suggestedParams,
      appInfos.app_id, appArgs2,
      [],
      undefined, foreignAssets,
      undefined, undefined, undefined))

    txns.push(algosdk.makeAssetTransferTxnWithSuggestedParams(
      from, to,
      undefined, undefined,
      fusion.nbFusion, undefined,
      fusion.result, suggestedParams,
      undefined
    ))

    const d = await API.signGroup(txns, txns.map(x => true))
    const message = {
      signed_tx: d,
      nft_input: fusion.components,
      nft_output: fusion.result,
      price: contractInfo.price,
      app_id: appInfos.app_id,
      number_fusion: fusion.nbFusion
    }
    return Utils._callServer('/api/fusion/app/create/validate', message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default createFusion
