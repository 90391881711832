<template>
<li class="comment">
  <UserPicture :account-id="data.account_id" :address="data.address" :version="data.v_pp" :diameter="30"/>
  <div>
    <span class="username">
      {{data.username}}
      <VerifiedLogo title="Verified artist" v-if="data.verify"/>
      <ASALogo asa-name="gems" title="Algogems governor" v-if="data.governor"/>
    </span>
    <span class="time"><timeago :auto-update="60" :datetime="data.date * 1000"></timeago></span>
  </div>
  <span></span>
  <div class="content">
    {{data.comment}}
  </div>
</li>
</template>

<script>
import VerifiedLogo from '@/components/Assets/VerifiedLogo'
import ASALogo from '../Assets/ASALogo'
import UserPicture from '../User/UserPicture'

export default {
  name: 'Comment',
  components: { UserPicture, ASALogo, VerifiedLogo },
  props: ['data']
}
</script>

<style scoped>
.comment {
  display: grid;
  grid-template-columns: 50px auto;
}

.user-picture {
  position: relative;
  top: -5px;
}

.username {
  font-size: larger;
  font-weight: bold;
}

.verified-logo {
  font-size: x-small;
}

.time time {
  margin-left: 5px;
  color: var(--textlight);
  font-size: smaller;
}

.content {
  margin-top: 5px;
  min-height: 100px;
}

</style>
