<template>
<a href="https://tinychart.org/asset/230946361" target="_blank" class="price-tracker">{{ price }}$</a>
</template>

<script>
export default {
  name: 'NavPriceTracker',
  data () {
    return {
      p: 0.12
    }
  },
  computed: {
    price () {
      return String(this.p).slice(0, 4)
    }
  },
  methods: {
    updatePrice () {
      this.$http.get('/api/gems/price').then(({ data }) => {
        this.p = data
      }).catch(err => {
        console.error(err)
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.updatePrice()
      setInterval(this.updatePrice, 350000)
    })
  }
}
</script>

<style scoped>
a.price-tracker {
  display: block;
  padding: 10px 15px;
  background: var(--gradient);
  color: var(--foreground)!important;
  border-radius: 10px;
  font-size: .8em;
  font-family: 'Roboto', sans-serif;
}

.dark a.price-tracker {
  color: #fff!important;
}
</style>
