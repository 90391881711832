<template>
  <div id="vip-gallery">
    <h1>VIP gallery</h1>
    <h2>Governance Launch</h2>
    <img src="@/assets/gradient_confetti.svg" alt="confetti" class="confetti" />
    <div class="gallery">
      <div :class="{creator: true, blur: focus}" v-for="v in vip" :key="v.address" @mouseenter="focus = true" @mouseleave="focus = false">
        <div class="wrapper">
          <ul class="nft-list">
            <Card v-for="asa in v.asas" :key="asa.id" :data="{username: v.username, title: asa.title, token_id: asa.id}"/>
          </ul>
          <div class="creator-header">
            <UserPicture :accountId="v.id" :address="v.address" :diameter="75"/>
            <h3><router-link :to="`/gallery/${v.username}`">{{ v.username }}</router-link></h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Cards/Card'
import UserPicture from '@/components/User/UserPicture'

const vip = require('@/assets/vip.json').vip
export default {
  name: 'VIPGallery',
  components: { UserPicture, Card },
  data () {
    return {
      vip: this.shuffleArray(vip),
      focus: false
    }
  },
  methods: {
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]
      }
      return array
    }
  },
  mounted () {
    this.$nextTick(() => {
    })
  }
}
</script>

<style scoped>
#vip-gallery{
  background: linear-gradient(45deg, #e2f1fe,#f4ecfb);
  padding: 200px 0;
  position: relative;
}

#vip-gallery h1{
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 4em;
  text-transform: uppercase;
  color: var(--text);
  text-align: center;
  margin: 0;
  font-weight: 700;
}

#vip-gallery h2{
  text-transform: uppercase;
  font-family: 'Roboto Condensed', sans-serif;
  color: var(--text);
  margin: 0;
  font-size: 2em;
  text-align: center;
  cursor: pointer;
}

.confetti{
  position: absolute;
  width: 900px;
  z-index: 0;
  top: 100px;
  left: calc(50% - 450px);
  opacity: .2;
}

.gallery {
  display: grid;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  padding: 0;
  list-style: none;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 75px;
  grid-auto-rows: 420px;
  width: 1200px;
  margin: 0 auto;
  margin-top: 100px;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  #vip-gallery h1{
    font-size: 3em;
  }

  #vip-gallery h2{
    padding: 0 25px;
  }

  .gallery{
    width: 100%;
    margin: 0;
    overflow: hidden;
    padding: 25px;
    box-sizing: border-box;
  }

  .gallery li.spotlight {
    grid-column: 1;
    grid-row: 1;
  }
}

.creator{
  z-index: 0;
  width: 250px;
  height: 420px;
  margin: 50px;
  transition: opacity .2s ease, filter .2s ease;
}

.creator.blur:not(:hover) h3 {
  opacity: .1;
}

.nft-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 250px;
  height: 350px;
  position: relative;
}

.nft-list li{
  width: 250px;
  height: 350px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.nft-list li.loading{
  position: absolute;
}

.nft-list li:nth-child(2){
  top: -10px;
  left: 10px;
  z-index: 2;
  animation-delay: .1s;
}

.nft-list li:nth-child(3){
  top: -20px;
  left: 20px;
  z-index: 1;
  animation-delay: .2s;
}

.creator-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -40px;
}

.creator-header h3{
  margin-top: 5px;
  padding: 5px 10px;
}

.creator-header a{
  text-decoration: none;
}

.creator:hover{
  z-index: 10;
}

.creator:hover .wrapper{
  position: absolute;
  width: 1200px;
  left: calc(50% - 600px);
  margin-top: -40px;
}

.creator:hover .nft-list{
  background: rgba(255,255,255,.9);
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  width: 900px;
  height: 390px;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: var(--strong-shadow);
}

.creator:hover .nft-list li{
  position: static;
}

.creator:hover .nft-list li:not(.loading){
  animation-name: slide-in;
  animation-duration: .2s;
  animation-timing-function: ease;
}

.creator:hover .nft-list li.loading{
  width: 250px;
}

.creator:hover .creator-header h3{
  background: #d3d9e8;
  border-radius: 10px;
}

</style>
