<template>
  <Section id="creations">
    <template v-slot:header>
      <template v-if="me">
        <Button class="text-logo" @click.native="importCreations" v-if="!isImporting">
          Import your Creations
          <unicon name="download-alt"/>
        </Button>
        <DotLinearLoader v-else/>
      </template>
      <span v-else></span>
      <ToggleNSFW v-model="nsfw" v-if="hasNsfw"/>
      </template>
    <CardGrid v-if="nftList.length">
      <Card v-for="(nft, index) in nftList" :key="nft.token_id" :data="nft" :style="`animation-delay: ${100 + ((index % 4) * 50)}ms`" @startContract="(data) => newContract(data)" :_me="me"/>
    </CardGrid>
    <template v-else-if="id">
      <h4>This user has not minted any NFTs</h4>
    </template>
    <template v-else>
      <h4>You haven’t created any NFTs. Mint your NFTs <router-link to="/create">here</router-link></h4>
    </template>
    <infinite-loading spinner="waveDots" @infinite="infiteListHandler" :identifier="infiniteId" :forceUseInfiniteWrapper="true"><span slot="no-more"></span>
      <span slot="no-results">
      </span>
    </infinite-loading>
    <ContractPopUp v-if='displayContractPopUp' :_kind="contractKind" :_price='contractPrice' :_is-algo=false :_tokenId='contractTokenId' @done='() => {}' @close="displayContractPopUp = false" :_isAlgo="isContractAlgo"></ContractPopUp>
  </Section>
</template>

<script>
import { mapState } from 'vuex'
import Section from '@/components/Layout/Section'
import InfiniteLoading from 'vue-infinite-loading'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import CardGrid from '@/components/Cards/CardGrid'
import Card from '@/components/Cards/Card'
import ToggleNSFW from '@/components/Common/Button/ToggleNSFW'
import Button from '../../Common/Button/Button'
import DotLinearLoader from '../../Common/Spinner/DotLinearLoader'

export default {
  name: 'Creations',
  components: { DotLinearLoader, Button, ToggleNSFW, Card, CardGrid, Section, InfiniteLoading, ContractPopUp },
  props: ['id'],
  data: () => {
    return {
      nftList: [],
      infiniteId: 0,
      increment: 16,
      contractKind: '',
      contractPrice: 1,
      contractTokenId: 0,
      isContractAlgo: true,
      displayContractPopUp: false,
      nsfw: false,
      hasNsfw: false,
      isImporting: false
    }
  },
  computed: {
    ...mapState([
      'userId',
      'wallet'
    ]),
    galleryId () {
      return this.id || this.userId
    },
    creationUrl () {
      return `/api/creation/${this.galleryId}`
    },
    me () {
      return this.$route.path === '/user/creations'
    }
  },
  watch: {
    nsfw () {
      this.reload()
    }
  },
  methods: {
    importCreations () {
      this.isImporting = true
      this.$http.get('/api/account/creation/import').then(() => {
        this.isImporting = false
      }).catch(() => {
        this.isImporting = false
      })
    },
    reload () {
      this.nftList = []
      this.infiniteId++
    },
    newContract (data) {
      this.contractKind = data.kind
      this.contractPrice = data.price
      this.contractTokenId = data.tokenId
      this.isContractAlgo = true
      this.displayContractPopUp = true
    },
    infiteListHandler ($state) {
      const url = this.creationUrl
      this.$http.get(url, {
        params: {
          start: this.nftList.length,
          end: this.nftList.length + this.increment,
          nsfw: this.nsfw
        }
      }).then(({ data }) => {
        if (data?.metadata.length) {
          this.nftList.push(...data.metadata)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  },
  mounted () {
    this.$http.get(this.creationUrl, {
      params: {
        start: 0,
        end: 1,
        nsfw: true
      }
    }).then(({ data }) => {
      if (data?.metadata.length) this.hasNsfw = true
    })
  }
}
</script>

<style scoped>
.text-logo {
  display: flex;
  align-items: center;
  margin: 0;
}

.text-logo .unicon {
  position: relative;
  top: -2px;
  fill: var(--textlight);
}

h4 {
  text-align: center;
}
</style>
