<template>
  <div class="report-card">
    <unicon v-if="data.type === 'spam'" name="adjust" transform="rotate(45)"/>
    <unicon v-if="data.type === 'offensive'" name="eye-slash"/>
    <unicon v-if="data.type === 'stolen'"  name="image-shield"/>
    {{data.info}}
  </div>
</template>

<script>
export default {
  name: 'ReportCard',
  props: {
    data: Object
  }
}
</script>

<style scoped>
.report-card {
  display: flex;
  align-items: center;
  color: var(--textlight);
  margin: 15px 0px;
}

.unicon {
  fill: var(--error);
  margin-right: 10px;
}
</style>
