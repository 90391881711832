<template>
    <img class="verified-logo" src="@/assets/verified-logo.svg" alt="">
</template>

<script>
export default {
  name: 'VerifiedLogo'
}
</script>

<style scoped>
.verified-logo {
  margin-left: 2px;
  margin-right: 5px;
  height: 1.4em;
  position: relative;
  top: 0.1em;
}

h1 .verified-logo {
  height: 0.9em;
}

.button .verified-logo {
  width: 30px;
  height: 30px;
}

.unverified.verified-logo {
  filter: brightness(0);
  opacity: 0.5;
}
</style>
