<template>
  <AppView id="gallery" v-if="gallery">
    <template v-slot:header>
      <Banner :id="gallery.account_id" :version="gallery.v_banner"/>
      <div class="head">
        <button class="top-left-button">
          <router-link to="/settings">
            <unicon name="setting" width="40px" height="40px" :class="redSettingGear ? 'red' : ''"></unicon>
            <h3 v-if="redSettingGear">Settings</h3>
          </router-link>
        </button>
        <button @click="startLogout" class="top-right-button"><unicon name="signout" width="40px" height="40px"></unicon><h3>Logout</h3></button>
        <UserPicture :address="gallery.addresses[0]" :account-id="gallery.account_id" :version="gallery.v_pp"/>
        <h1 class="username">
          <Address v-if="isAddress" :address="galleryId"/>
          <span v-else>@{{ galleryId }}</span>
          <CopyButton :link="sharelink"/>
        </h1>
        <h5><router-link :to="`/gallery/${galleryId}`">See public profile</router-link></h5>
        <ul class="addresses">
          <li v-for="address in gallery.addresses" :key="address">
            {{ address }}<CopyButton :link="address" class="smaller"/>
          </li>
          <li class="nfd-address" v-if="gallery.nfd">
            <img src="@/assets/nfd_logo.png"> {{gallery.nfd}}
          </li>
        </ul>
        <Button class="verify-button" @click.native="signInVGems" v-if="!gallery.vgems && !gallery.is_verify">
          Get Verified
          <VerifiedLogo/>
        </Button>
        <Button @click.native="claimStacking" v-if="isStackCheck">
          Claim Stakings
        </Button>

        <div class="followers-container">
          <router-link :to="`${baseUrl}/follower`">{{ gallery.follower }} follower
            <template v-if="gallery.follower >1">s</template>
          </router-link>
          <span class="separator"></span>
          <router-link :to="`${baseUrl}/following`">{{ gallery.following }} following</router-link>
        </div>
      </div>
    </template>

    <nav>
      <router-link :to="`${baseUrl}`" class="home-button">
        <unicon name="estate"/>
        {{ $t('userGalleryNav.home') }}
      </router-link>
      <router-link :to="`${baseUrl}/listings`">
        <unicon name="list-ul"/>
        Listings
      </router-link>
      <router-link :to="`${baseUrl}/pending`">
        <unicon name="spinner-alt"/>
        Pending
      </router-link>
      <router-link :to="`${baseUrl}/creations`">
        <unicon name="image-edit"/>
        {{ $t('userGalleryNav.creations') }}
      </router-link>
      <router-link :to="`${baseUrl}/collections`">
        <unicon name="folder"/>
        {{ $t('userGalleryNav.collections') }}
      </router-link>
      <router-link :to="`${baseUrl}/ownedNft`">
        <unicon name="image-check"/>
        {{ $t('userGalleryNav.owned') }}
      </router-link>
      <router-link :to="`${baseUrl}/notifications`">
        <unicon name="chat"/>
        Notifications
      </router-link>
      <router-link :to="`${baseUrl}/community`">
        <unicon name="users-alt"/>
        Community
      </router-link>
      <router-link :to="`${baseUrl}/favouriteNft`" v-if="!isAddress">
        <unicon name="favorite"/>
        {{ $t('userGalleryNav.favNFT') }}
      </router-link>
    </nav>

    <router-view :id="galleryId" :gallery="gallery"></router-view>
    <ContractPopUp :_kind="contractKind" v-if="displayContractPopUp"
                   @close="displayContractPopUp = false; forceReload()"/>
  </AppView>
  <Loader v-else/>
</template>

<script>
import Address from '@/components/User/Address'
import { mapMutations, mapState } from 'vuex'
import Loader from '@/components/Common/Spinner/Loader'
import Banner from '@/components/User/Banner'
import CopyButton from '@/components/Common/Button/CopyButton'
import AppView from '@/components/Layout/AppView'
import Contract from '@/web3/Contract'
import UserPicture from '@/components/User/UserPicture'
import VerifiedLogo from '@/components/Assets/VerifiedLogo'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import Button from '@/components/Common/Button/Button'

export default {
  name: 'User',
  components: { Button, ContractPopUp, VerifiedLogo, UserPicture, AppView, CopyButton, Banner, Loader, Address },
  props: {
    id: String
  },
  data () {
    return {
      gallery: null,
      username: '',
      reload: 0,
      displayContractPopUp: false,
      contractKind: 'getVerified',
      isStackCheck: false
    }
  },
  watch: {
    galleryId () {
      this.gallery = null
      this.username = ''
      this.$http.get(this.apiUrl).then(response => {
        if (response.data) {
          this.gallery = response.data
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  computed: {
    ...mapState([
      'userId',
      'address',
      'wallet'
    ]),
    galleryId () {
      return this.id || this.userId
    },
    isAddress () {
      return this.galleryId.length === 58
    },
    gemsNumber () {
      if (this.gallery.gems_number < 1000) return Math.round(this.gallery.gems_number)
      if (this.gallery.gems_number < 1000000) {
        return Math.round(this.gallery.gems_number / 1000).toString() + 'k'
      } else {
        return Math.round(this.gallery.gems_number / 1000000).toString() + 'm'
      }
    },
    baseUrl () {
      return '/user'
    },
    apiUrl () {
      return `/api/account/info/${this.galleryId}`
    },
    stackApi () {
      return '/api/is_stack_check'
    },
    authUrl () {
      return '/authenticate'
    },
    createCollectionURL () {
      return '/create_collection'
    },
    me () {
      return !this.id
    },
    sharelink () {
      return window.location.origin + this.$route.path.replace('user', 'gallery/' + this.galleryId)
    },
    redSettingGear () {
      try {
        return !this.gallery.description ||
          !this.gallery.username ||
          !this.gallery.description.length ||
          !this.gallery.royalties ||
          !this.gallery.email ||
          !Object.keys(JSON.parse(this.gallery.email)).length
      } catch {
        return false
      }
    }
  },
  methods: {
    ...mapMutations(['login', 'logout']),
    startLogout () {
      Contract.logout(this.wallet)
        .then(() => {
          this.$http.get('/api/logout').then(() => {
            this.logout({})
            this.$router.push({ path: '/' })
          })
        })
    },
    signInVGems () {
      this.displayContractPopUp = true
      this.contractKind = 'getVerified'
    },
    claimStacking () {
      this.displayContractPopUp = true
      this.contractKind = 'claimStacking'
    },
    forceReload () {
      this.fetchData()
      this.reload++
    },
    fetchData () {
      this.$http.get(this.apiUrl).then(response => {
        if (response.data) {
          this.gallery = response.data
          this.isFollowing = response.data.follow
        }
      }).catch(err => {
        console.log(err)
      })

      this.$http.get(this.stackApi).then(response => {
        if (response.data) {
          this.isStackCheck = response.data.status
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.galleryId) {
        this.$router.push(this.authUrl)
      }
      this.fetchData()
    })
  }
}
</script>

<style scoped>
h1 {
  color: var(--text);
}

h5 {
  font-size: medium;
  margin: 0;
}

a {
  margin-left: 5px;
  text-decoration: none;
  color: var(--textlight)
}

a:hover {
  color: var(--text)
}

button {
  z-index: 2;
}

.head {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin: -50px auto 20px auto;

}

.username {
  text-transform: initial;
  margin-bottom: 0px;
  z-index: 2;
  color: var(--text);
}

.addresses {
  padding: 0;
  margin: 5px;
  list-style: none;
  text-transform: uppercase;
  z-index: 2;
  word-break: break-all;
}

.addresses li {
  padding: 5px 10px;
  color: var(--textlight);
  border-radius: 10px;
  margin: 5px;
  text-align: center;
}

.followers-container {
  margin-top: 30px;
  padding: 7px 16px;
  border-radius: 15px;
  color: var(--text);
  background: var(--foreground);
  box-shadow: var(--shadow);
  z-index: 2;
}

.followers-container a {
  margin: 10px;
}

.followers-container .separator {
  height: 80%;
  border-left: solid 1px var(--textlight);
  margin: 0 5px;
}

.top-left-button {
  position: absolute;
  top: 60px;
  left: 10px;
  background: none;
  border: none;
}

.top-left-button .unicon {
  fill: var(--text);
  opacity: 0.75;
}

.top-left-button .unicon.red {
  fill: var(--error);
  opacity: 0.75;
}

.top-left-button .unicon:hover {
  opacity: 1;
}

.top-right-button {
  position: absolute;
  top: 60px;
  right: 10px;
  border: none;
  background: none;
}

.top-right-button .unicon {
  fill: var(--text);
  opacity: 0.75;
}

.top-right-button .unicon:hover {
  opacity: 1;
}

.top-right-button:focus {
  outline: none;
}

.head.light .unicon {
  fill: var(--text);
  opacity: 0.8;
}

.head.light .unicon:hover {
  opacity: 1;
}

nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 90vw;
  margin-bottom: 30px;
}

nav a {
  color: var(--textlight);
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

nav a .unicon {
  margin-right: 7px;
  fill: var(--textlight);
}

nav a:hover {
  box-shadow: var(--light-shadow);
  color: var(--text);
}

nav a:hover .unicon {
  fill: var(--text);
}

nav a.is-active:not(.home-button) {
  color: var(--accent);
  box-shadow: var(--accent-shadow);
  background: var(--foreground)
}

nav a.is-active:not(.home-button) .unicon {
  fill: var(--accent);
}

nav a.router-link-exact-active {
  color: var(--accent);
  box-shadow: var(--accent-shadow);
  background: var(--foreground)
}

nav a.router-link-exact-active .unicon {
  fill: var(--accent);
}

.verify-button {
  background: none;
  box-shadow: none;
  border: none;
  color: var(--textlight);
  text-decoration: underline;
  margin: 0;
  padding: 0;
  font-size: initial;
}

.verify-button:hover {
  transform: none;
  color: var(--text);
}

.verify-button .verified-logo {
  top: 0.3em;
}

.royalty-button {
  background: none;
  box-shadow: none;
  border: none;
  color: var(--blue);
  opacity: 0.7;
  margin: 10px;
  padding: 0;
  font-size: initial;
  display: flex;
  align-items: center;
}

.royalty-button:hover {
  transform: none;
  opacity: 1;
}

.royalty-button .unicon {
  fill: var(--blue);
}

.nfd-address img {
  height: 0.8em;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .auction-sale-container {
    padding: 25px;
    box-sizing: border-box;
    width: 100%;
  }

  nav {
    margin: auto;
  }
}
</style>
