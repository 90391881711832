<template>
  <li class="number-card">
    <div class="cover">
      <div>
        <span class="big-number">{{value}}</span>
        <span class="unit">{{unit}}</span>
        <span class="algo" :style="{'--mask-src': 'url(' + require('@/assets/asaLogo/1.svg') + ')' }" v-if="isAlgo"/>
      </div>
      <div class="overview">
        {{text}}
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'NumberCard',
  props: ['number', 'text', 'isAlgo'],
  computed: {
    value () {
      if (this.number < 1000) return this.number
      else if (this.number < 1000000) return Math.round(this.number / 1000)
      else return Math.round(this.number / 1000000)
    },
    unit () {
      if (this.number < 1000) return ''
      else if (this.number < 1000000) return 'k'
      else return 'm'
    }
  }
}
</script>

<style scoped>
.number-card{
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  font-size: .8em;
  animation: card-appear .2s ease backwards;
  margin: 0;
  width: 100%;
  height: 100%;
  max-width: 220px;
  max-height: 220px;
  list-style: none;
}

.cover{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--foreground-transparent);
  border: 1px solid var(--foreground);
  border-radius: 15px;
  box-sizing: border-box;
}

.big-number {
  background: var(--gradient);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 5em;
}

.unit {
  background: var(--gradient);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 4em;
}

.algo {
  background: var(--gradient);
  mask: var(--mask-src) no-repeat;
  mask-size: contain;
  height: 3em;
  width: 3em;
  display: inline-block;
}

.overview{
  color: var(--textlight);
  font-size: 1.5em;
}

</style>
