<template>
<div>
  <nav>
    <router-link :to="`${baseUrl}/listings`">
      <unicon name="arrow-growth"/>
      {{ $t('userGalleryNav.auctions') }}
    </router-link>
    <router-link :to="`${baseUrl}/listings/sales`">
      <unicon name="usd-circle"/>
      {{ $t('userGalleryNav.sales') }}
    </router-link>
    <router-link :to="`${baseUrl}/listings/shuffles`">
      <unicon name="shuffle"/>
      Shuffles
    </router-link>
    <router-link :to="`${baseUrl}/listings/my_offers`">
      <unicon name="money-insert"/>
      My Offers
    </router-link>
    <router-link :to="`${baseUrl}/listings/staking`" v-if="canAccessStaking">
      <unicon name="money-stack"/>
      Stakings
    </router-link>
    <router-link :to="`${baseUrl}/listings/fusions`">
      <unicon name="pathfinder-unite"/>
      Fusions
    </router-link>
    <router-link :to="`${baseUrl}/listings/packs`">
      <unicon name="layers"/>
      Packs
    </router-link>
  </nav>
  <router-view></router-view>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Listings',
  computed: {
    ...mapState(['address']),
    canAccessStaking () {
      return ['PZNGYF4Y25GGO674BW4CRDHFKOKHMHZXSFXIKMYPEJCQAUTDH52WV24XTY', 'TST2PF3APFSTOQS57TTILKU3IV5O4RCU7XK6SXBS4VFDRG2APTD3QOOF7I', 'IL2CNQOSJRI3XJNKQW3BJ2JICZ6RGI752NSJEQ52U6GKWBX5ZZYZ7MI7LQ', 'VXNVFUF5BTXJXM6GFRJ4J57KKAXNNT5AQ6IKI4RTYBFJGHOCVWYLJSU36Y'].includes(this.address)
    },
    baseUrl () {
      return '/user'
    }
  }
}
</script>

<style scoped>
nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 90vw;
  margin-bottom: 30px;
}

nav a {
  color: var(--textlight);
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

nav a .unicon {
  margin-right: 7px;
  fill: var(--textlight);
}

nav a:hover {
  box-shadow: var(--light-shadow);
  color: var(--text);
}

nav a:hover .unicon {
  fill: var(--text);
}

nav a.router-link-exact-active {
  color: var(--accent);
  box-shadow: var(--accent-shadow);
  background: var(--foreground)
}

nav a.router-link-exact-active .unicon {
  fill: var(--accent);
}
</style>
