<template>
  <div id="create" class="view flex-center full-height stepper">
    <div class="loader flex-center" v-if="loading">
      <div>
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <p>{{$t('create.spaceDwarfs')}}<br> {{$t('create.wait')}}</p>
      </div>
    </div>
    <transition :name="transition" mode="out-in">
      <component v-bind:is="views[step]" @next="next" @previous="previous" @error="error" @confirm="confirm" @loading="toggleLoading" :errorMsg="errorMsg" :data="formData" ></component>
    </transition>
  </div>
</template>

<script>
import CreatePicture from './CreatePicture'
import CreateDetails from './CreateDetails'
import CreateAuctionSettings from './CreateAuctionSettings'
import CreateSale from './CreateSale'
import ErrorCreate from './ErrorCreate'

export default {
  name: 'CreateInstagram',
  data () {
    return ({
      views: [CreatePicture, CreateDetails, CreateSale, CreateAuctionSettings, ErrorCreate],
      step: 0,
      transition: 'next',
      errorMsg: false,
      loading: false,
      formData: {}
    })
  },
  methods: {
    next (data) {
      this.formData = {
        ...this.formData,
        ...data
      }
      this.step += 1
      this.transition = 'next'
    },
    previous () {
      this.step -= 1
      this.transition = 'previous'
    },
    error (error) {
      console.log('e', error)
      this.loading = false
      this.step = this.views.length - 1
      this.transition = 'next'
      this.errorMsg = error
    },
    confirm (next) {
      this.loading = false
      this.$router.push(next)
    },
    toggleLoading () {
      this.loading = true
    }
  }
}
</script>

<style>
#create {
  padding-top: 90px;
}

#create .error{
  bottom: 125px;
}
.loader{
  background: var(--background);
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 100;
  color: var(--accent);
  user-select: none;
  opacity: .95;
  text-align: center;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--accent);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
