<template>
<div class='validation-pop-up'>
  <h1><slot></slot></h1>
  <div class="button-container">
    <Button @click.native="$emit('yes')">{{$t('common.button.validate')}}</Button>
    <Button @click.native="$emit('no')" class="secondary">{{$t('common.button.cancel')}}</Button>
  </div>
</div>
</template>

<script>
import Button from '../Button/Button'
import { mapState } from 'vuex'

export default {
  name: 'ValidationPopUp',
  components: { Button },
  computed: {
    ...mapState(['isAdult'])
  },
  beforeMount () {
    if (this.isAdult) {
      this.$emit('yes')
    }
  }
}
</script>

<style scoped>
.validation-pop-up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background: var(--pop-up-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h5 {
  margin: 10px 0;
  text-align: center;
}

button {
  margin: 5px;
}
</style>
