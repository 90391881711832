<template>
  <div class='contract-pop-up'>
    <span v-if='state=="needPrice"'>
      <div class="pop-up-content">
        <template v-if="_kind === 'sell'">
          <p>Select currency</p>
          <CurrencyInput v-model="asa"/>
        </template>
        <IntInput
          :class="{'invalid':!priceValid && error}"
          :default-value="(typeof price === 'string') ? parseInt(price) : price" :max="1000000000000"
          :min="minPrice"
          :step="10"
          :decimals="decimals"
          @val="(val) => { this.price = val }" :key="`price-${minPrice}-${decimals}`">
          <ASALogo :asaId="asa"/>
        </IntInput>

        <template v-if="_kind === 'sell'">
          <p class="royalties-text" v-if="_contractParams && _contractParams.royalties !== undefined">
            {{_contractParams.royalties}}% royalties<br>
            0-2% fees
          </p>
           <span v-if="!auctionParameter.isAuction">
            <p>Amount of ASA units to list</p>
            <IntInput :default-value="salesSupply"
                      :max="(auctionParameter.isAuction) ? 1 : this._contractParams.nftSupply" :min="1"
                      :step="1" class="small"
                      @val="(val) => { this.salesSupply = val }"></IntInput>
            <span class="flex-center">Private sale?
              <Toggle :default-sate="privateSale" @toggle="(state)=>{this.privateSale = state}"/>
            </span>
          </span>
          <span class="flex-center">{{$t('createAuctionSettings.label.auction')}}
            <Toggle :default-sate="auctionParameter.isAuction" @toggle="(state)=>{auctionParameter.isAuction = state}"/>
          </span>
          <span v-if="auctionParameter.isAuction">
            <p>{{$t('createAuctionSettings.durations')}}</p>
            <IntInput :default-value="auctionParameter.duration" :max="200" :min="0" :step="24" class="small"
                      @val="(val) => { this.auctionParameter.duration = val }" :class="{'invalid':!durationValid && error}">h</IntInput>
          </span>
        </template>

        <template v-if="_kind === 'stake' && false">
          <span class="flex-center">{{$t('stake.ledger')}}
            <Toggle :default-sate="stakeParameter.isLedger" @toggle="(state)=>{stakeParameter.isLedger = state}"/>
          </span>
          <span v-if="stakeParameter.isLedger">
            <p>{{$t('stake.address')}}</p>
            <input v-model="stakeParameter.address" maxlength="58" class="small"
                   :class="{'invalid':!addressValid && error}">
          </span>
        </template>
      </div>
      <div class="button-container">
        <Button class="secondary" @click.native='close'>{{$t('contractPopUp.button.cancel')}}</Button> or <Button
        @click.native='submitPrice'>{{$t('contractPopUp.button.submit')}}</Button>
      </div>
    </span>
    <span v-if='state=="needBuySupply"'>
      <div class="pop-up-content">
        <p>How many do you want to buy?</p>
        <IntInput :default-value="buySupply"
                  :max="this._contractParams.buySupply" :min="1"
                  :step="1"
                  @val="(val) => { this.buySupply = val }"></IntInput>
      </div>
      <div class="button-container">
        <Button class="secondary" @click.native='close'>{{$t('contractPopUp.button.cancel')}}</Button> or <Button
        @click.native='completeContract'>{{$t('contractPopUp.button.submit')}}</Button>
      </div>
    </span>
    <span v-if="state==='warnOffer'">
      <div class="pop-up-content warning-offer">
        <h1><unicon name="exclamation-triangle"/>Warning</h1>
        <p>
          If you sign the transaction, you will sell your NFT for:
          <br>
          <br>
          <b>{{price}} <ASALogo :asa-id="asa"/></b>
        <br><br>Do you want to continue ?</p>
        <div class="button-container">
          <Button @click.native="completeContract">Yes</Button>
          <Button @click.native="close" class="error-btn">No</Button>
        </div>
      </div>
    </span>
    <span v-if='state=="loading"'>
      <LinearSpinner/>
      <span v-if='_kind=="bid"'>
        <h5>{{$t('contractPopUp.loading.bid')}}</h5>
      </span>
      <span v-if='_kind=="buy"'>
        <h5>{{$t('contractPopUp.loading.buy')}}</h5>
      </span>
      <span v-if='_kind=="cancel"'>
        <h5>{{$t('contractPopUp.loading.cancel')}}</h5>
      </span>
      <span v-if='_kind=="sell"'>
        <h5>{{$t('contractPopUp.loading.sell')}}</h5>
      </span>
      <span v-if='_kind=="claim"'>
        <h5>{{$t('contractPopUp.loading.claim')}}</h5>
      </span>
    </span>
    <span v-if='state=="done"'>
      <SuccessCheckmark/>
      <span v-if='_kind=="bid"'>
        <h5>{{$t('contractPopUp.done.bid')}}</h5>
      </span>
      <span v-if='_kind=="buy"'>
        <h5>{{$t('contractPopUp.done.buy')}}</h5>
      </span>
      <span v-if='_kind=="sell"'>
        <h5>{{$t('contractPopUp.done.sell')}}</h5>
      </span>
      <span v-if='_kind=="claim"'>
        <h5>{{$t('contractPopUp.done.claim')}}</h5>
      </span>
       <span v-if='_kind=="cancel"'>
        <h5>{{$t('contractPopUp.done.cancel')}}</h5>
      </span>
      <Button @click.native='close'>{{$t('contractPopUp.button.close')}}</Button>
    </span>
    <span v-if='state=="error"'>
      <h2 class="flex-center">
        <unicon height="50px" name="exclamation-octagon" width="50px"/>
        {{$t('contractPopUp.error')}}
      </h2>
      <p v-html="message"></p>
      <div class="button-container">
        <Button class="error-btn" @click.native.prevent="$emit('close', { isCorrect: false })">{{$t('contractPopUp.button.goBack')}}</Button>
      </div>
    </span>
  </div>
</template>

<script>
import IntInput from '../Input/IntInput'
import Button from '../Button/Button'
import LinearSpinner from '../Spinner/LinearSpinner'
import SuccessCheckmark from '../../Assets/SuccessCheckmark'
import Contract from '@/web3/Contract'
import Toggle from '../Input/Toggle'
import { mapState } from 'vuex'
import ASALogo from '../../Assets/ASALogo'
import CurrencyInput from '../Input/CurrencyInput'

export default {
  name: 'ContractPopUp',
  components: {
    CurrencyInput,
    ASALogo,
    SuccessCheckmark,
    LinearSpinner,
    Button,
    IntInput,
    Toggle
  },
  props: {
    _message: String,
    _kind: String,
    _tokenId: Number,
    _toAddress: String,
    _price: Number,
    _asa: Number,
    _state: String,
    _contractParams: Object
  },
  data () {
    return {
      error: false,
      price: this._price,
      auctionParameter: { isAuction: false, duration: 1 },
      stakeParameter: { isLedger: false, address: '' },
      salesSupply: 1,
      buySupply: 1,
      privateSale: false,
      state: (this._state) ? this._state : 'loading',
      message: this._message,
      asa: (this._asa) ? this._asa : 1,
      asaInfos: undefined
    }
  },
  watch: {
    _state () {
      this.state = this._state
    },
    _message () {
      try {
        const e = (typeof this._message === 'string') ? JSON.parse(this._message) : this._message
        if (e.code) {
          this.message = this.$t('error.' + e.code)
        } else if (e.error) {
          this.message = e.error
        } else if (e.e) {
          this.message = e.e
        } else if (e.message) {
          this.message = e.message
        } else if (e.status) {
          this.message = `${e.status}`
        } else {
          this.message = `${JSON.stringify(e)}`
        }
      } catch (error) {
        this.message = `${this._message}`
      }

      if (this.message.includes('Can not open popup window - blocked')) {
        this.message = 'Error: Can\'t open your wallet pop-up window - Blocked.<br> Enable pop-ups and redirects in your browser site settings.<br> Learn how <a href="https://www.technewstoday.com/how-to-turn-off-pop-up-blocker/" target="_blank">here</a>'
      }
    }
  },
  computed: {
    ...mapState([
      'wallet',
      'userId',
      'address'
    ]),
    minPrice () {
      if (this.asaInfos) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.price = Math.max(this.asaInfos[this.asa].min_amount, this.price)
        return this.asaInfos[this.asa].min_amount
      }
      return 1
    },
    decimals () {
      if (this.asaInfos) {
        return this.asaInfos[this.asa].max_decimal
      }
      return 1
    },
    priceValid () {
      return !isNaN(this.price) && this.price === parseFloat(parseFloat(this.price).toFixed(this.decimals)) && this.price >= this.minPrice
    },
    durationValid () {
      return Number.isInteger(this.auctionParameter.duration) && this.auctionParameter.duration >= 0 && this.auctionParameter.duration <= 200
    },
    addressValid () {
      return !this.stakeParameter.isLedger ||
        (this.stakeParameter.isLedger && this.stakeParameter.address.length === 58)
    }
  },
  methods: {
    submitPrice () {
      if (!this.priceValid) {
        this.error = true
        return
      }
      if (this.auctionParameter.isAuction && !this.durationValid) {
        this.error = true
        return
      }
      this.$emit('price', this.price)
      this.completeContract(this.price)
    },
    close () {
      this.$emit('close', { isCorrect: true })
    },
    contractToComplete () {},
    tipHandler () {
      this.asa = 230946361
      this.state = 'needPrice'
      this.contractToComplete = (price) => {
        //
        return Contract.tip(this.wallet, this.address, this._toAddress, parseInt(price))
      }
    },
    buyHandler () {
      this.contractToComplete = () => {
        return Contract.buy(this.wallet, this.address, parseInt(this._tokenId), this.price, this.buySupply, this._contractParams.saleId)
      }
      if (this._contractParams.buySupply === 1) {
        this.completeContract(0)
      } else {
        this.state = 'needBuySupply'
      }
    },
    bidHandler () {
      this.state = 'needPrice'
      this.contractToComplete = (price) => {
        return Contract.bid(this.wallet, this.address, parseInt(this._tokenId), price)
      }
    },
    sellHandler () {
      this.price = 5
      this.state = 'needPrice'
      this.contractToComplete = (price) => {
        if (this.auctionParameter.isAuction) {
          return Contract.auction(
            this.wallet,
            this.address,
            parseInt(this._tokenId),
            price,
            this.auctionParameter.duration,
            this.asa
          )
        } else {
          return Contract.sell(
            this.wallet,
            this.address,
            parseInt(this._tokenId),
            price,
            this.salesSupply,
            this.asa,
            this.privateSale
          )
        }
      }
    },
    completeContract (price) {
      this.state = 'loading'
      this.contractToComplete(price)
        .then((m) => {
          this.state = 'done'
          this.contractMessage = JSON.stringify(m)
          this.$emit('done', m)
        })
        .catch((error) => {
          try {
            if (error instanceof Error) {
              if (error.response && error.response.data && error.response.data.error) {
                this.message = error.response.data.error
              } else {
                this.message = error.toString()
              }
            } else {
              const e = (typeof error === 'string') ? JSON.parse(error) : error
              console.log(e.response && e.response.data && e.response.data.error)
              if (e.message) {
                this.message = e.message
              } else if (e.code) {
                this.message = this.$t('error.' + e.code)
              } else if (e.error) {
                this.message = e.error
              } else if (e.e) {
                this.message = e.e
              } else if (e.status) {
                this.message = e.status
              } else {
                this.message = `${JSON.stringify(e)}`
              }
            }
          } catch {
            this.message = error
          }
          if (this.message.includes('Can not open popup window - blocked')) {
            this.message = 'Error: Can\'t open your wallet pop-up window - Blocked.<br> Enable pop-ups and redirects in your browser site settings.<br> Learn how <a href="https://www.technewstoday.com/how-to-turn-off-pop-up-blocker/" target="_blank">here</a>'
          }
          this.state = 'error'
          this.$emit('error', this.message)
        })
    },
    cancelHandler () {
      this.state = 'loading'
      this.displayContractPopUp = true
      this.contractToComplete = (price) => {
        return Contract.cancel(this.wallet, this.address, parseInt(this._tokenId))
      }
      this.completeContract(0)
    },
    updateHandler () {
      this.state = 'needPrice'
      this.contractToComplete = (price) => {
        return Contract.updateSale(this.wallet, this.address, parseInt(this._tokenId), price)
      }
    },
    swapHandler () {
      this.$http.get(`/api/nft/swap/${this._tokenId}`, {})
        .then(() => {
          this.state = 'done'
          this.$emit('done')
        })
        .catch(() => {
          this.state = 'error'
          this.$emit('error')
        })
    },
    claimHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.claim(this.wallet, parseInt(this._tokenId))
      }
      this.completeContract(0)
    },
    stakeHandler () {
      this.state = 'needPrice'
      this.price = 5
      this.asa = 230946361
      this.contractToComplete = (price) => {
        return Contract.stake(this.wallet, this.address, price, this.stakeParameter)
      }
    },
    voteHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.vote(this.wallet, this.address)
      }
      this.completeContract(0)
    },
    deleteHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.deleteASA(this.wallet, parseInt(this._tokenId))
      }
      this.completeContract(0)
    },
    signInHandler () {
      this.state = 'loading'
      this.contractToComplete = () => {
        return Contract.signIn(this.wallet, this.address, parseInt(this._tokenId))
      }
      this.completeContract()
    },
    getVerifiedHandler () {
      this.state = 'loading'
      this.contractToComplete = () => {
        return Contract.getVerified(this.wallet, this.address)
      }
      this.completeContract()
    },
    closeHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.close(parseInt(this._tokenId))
      }
      this.completeContract(0)
    },
    createShuffleHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.createShuffle(this.wallet, this.address, this._contractParams)
      }
      this.completeContract(0)
    },
    createHandler () {
      if (!this._contractParams.isMintForFree) {
        this.contractToComplete = (price) => {
          return Contract.create(this.wallet, this.address, this._contractParams.asset)
        }
      } else {
        this.contractToComplete = (price) => {
          return this.$http.post('/api/create/mint', this._contractParams.asset)
        }
      }
      this.completeContract(0)
    },
    algoGlyphMintHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.algoglyphMint(this.wallet, this.address, this._contractParams)
      }
    },
    claimRoyaltiesHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.claimRoyaltyFees(this.wallet, this.address)
      }
      this.completeContract(0)
    },
    makeOfferHandler () {
      this.price = 5
      this.state = 'needPrice'
      this.contractToComplete = (price) => {
        return Contract.makeOffer(
          this.wallet,
          this.address,
          parseInt(this._tokenId),
          price
        )
      }
    },
    acceptOfferHandler () {
      this.state = 'warnOffer'
      this.contractToComplete = (price) => {
        return Contract.acceptOffer(this.wallet, this.address, parseInt(this._contractParams.offerId), parseInt(this._tokenId))
      }
      // this.completeContract(0)
    },
    closeOfferHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.closeOffer(this.wallet, this.address, parseInt(this._contractParams.offerId), parseInt(this._tokenId))
      }
      this.completeContract(0)
    },
    bigVoteHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.bigVote(this.wallet, this.address, this._contractParams.vote)
      }
      this.completeContract(0)
    },
    sendTokenHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.sendToken(this.wallet, this.address, this._tokenId, this._contractParams.addresses, this._contractParams.amounts)
      }
      this.completeContract(0)
    },
    joeStartHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.joeStart(this.wallet)
      }
      this.completeContract(0)
    },
    joeClaim1Handler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return this.$http.get('/api/debug/joe/1')
      }
      this.completeContract(0)
    },
    joeClaim10Handler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return this.$http.get('/api/debug/joe/10')
      }
      this.completeContract(0)
    },
    createStakingHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.createStaking(this.wallet, this.address, this._contractParams)
      }
      this.completeContract(0)
    },
    createFusionHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.createFusion(this.wallet, this.address, this._contractParams)
      }
      this.completeContract(0)
    },
    createPackHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.createPack(this.wallet, this.address, this._contractParams)
      }
      this.completeContract(0)
    },
    claimStackingHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return this.$http.post('/api/is_stack_check', { })
      }
      this.completeContract(0)
    },
    auctionCloseHandler () {
      this.state = 'loading'
      this.contractToComplete = (price) => {
        return Contract.auctionClose(this.wallet, this.address, parseInt(this._contractParams.appId))
      }
      this.completeContract(0)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$http.get('/api/home/asa')
        .then((d) => {
          this.asaInfos = d.data.metadata
        })

      if (!this.userId) {
        this.state = 'error'
        console.log(this.$t('not-connected'))
        this.message = this.$t('error.not-connected')
        return
      }
      switch (this._kind) {
        case 'bid':
          this.bidHandler()
          break
        case 'buy':
          this.buyHandler()
          break
        case 'tip':
          this.tipHandler()
          break
        case 'cancel':
          this.cancelHandler()
          break
        case 'update':
          this.updateHandler()
          break
        case 'swap':
          this.swapHandler()
          break
        case 'claim':
          this.claimHandler()
          break
        case 'sell':
          this.sellHandler()
          break
        case 'stake':
          this.stakeHandler()
          break
        case 'vote':
          this.voteHandler()
          break
        case 'delete':
          this.deleteHandler()
          break
        case 'signIn':
          this.signInHandler()
          break
        case 'close':
          this.closeHandler()
          break
        case 'createShuffle':
          this.createShuffleHandler()
          break
        case 'create':
          this.createHandler()
          break
        case 'algoglyphMint':
          this.algoGlyphMintHandler()
          break
        case 'claimRoyalties':
          this.claimRoyaltiesHandler()
          break
        case 'makeOffer':
          this.makeOfferHandler()
          break
        case 'acceptOffer':
          this.acceptOfferHandler()
          break
        case 'closeOffer':
          this.closeOfferHandler()
          break
        case 'bigVote':
          this.bigVoteHandler()
          break
        case 'joeStart':
          this.joeStartHandler()
          break
        case 'joeClaim1':
          this.joeClaim1Handler()
          break
        case 'joeClaim10':
          this.joeClaim10Handler()
          break
        case 'getVerified':
          this.getVerifiedHandler()
          break
        case 'sendToken':
          this.sendTokenHandler()
          break
        case 'createStaking':
          this.createStakingHandler()
          break
        case 'createFusion':
          this.createFusionHandler()
          break
        case 'createPack':
          this.createPackHandler()
          break
        case 'claimStacking':
          this.claimStackingHandler()
          break
        case 'auctionClose':
          this.auctionCloseHandler()
          break
      }
    })
  }
}
</script>

<style scoped>
.contract-pop-up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background: var(--pop-up-background);
  display: flex;
  justify-content: center;
  align-items: center;
}

h5 {
  margin: 10px 0;
  text-align: center;
}

.pop-up-content {
  background: var(--lightgradient);
  border-radius: 20px;
  padding: 10px;
  animation: card-appear .5s ease forwards;
}

.pop-up-content p {
  text-align: center;
}

.asa-selector {
  font-size: xx-large;
  display: flex;
  justify-content: center;
}

.asa-selector .asa-logo {
  margin: 5px 10px;
}

.asa-selector .asa-logo:hover {
  transform: scale(1.3);
}

.royalties-text {
  position: relative;
  top: -15px;
  font-size: smaller;
}

.warning-offer {
  max-width: 350px;
}

.warning-offer h1, .warning-offer p{
  color: var(--text);
}

.warning-offer h1 .unicon {
  margin-right: 5px;
  position: relative;
  top: 3px;
}

.warning-offer b {
  font-size: larger;
}

</style>
