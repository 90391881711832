<template>
  <Section id="packs">
    <template v-slot:header>
      <span></span>
      <ToggleNSFW v-model="nsfw" v-if="false"/>
    </template>
    <CardGrid>
      <Card
        v-for="(pack, index) in nfts"
        :key="`primary-${pack.id}`"
        :data="{ ...pack.pack_picture, type: 'pack', to: `/pack/${pack.id}`}"
        :style="`animation-delay: ${100 + ((index % 4) * 50)}ms`"
      />
    </CardGrid>
    <infinite-loading spinner="waveDots" @infinite="infiteListHandler" :identifier="`primary-${infiniteId}`" :forceUseInfiniteWrapper="true"><span slot="no-more"></span>
      <span slot="no-results">
        <template v-if="id">
            <h4>This user doesn't have any active packs</h4>
          </template>
          <template v-else>
            <h4>You don't have any active packs.</h4>
          </template>
      </span>
    </infinite-loading>
  </Section>
</template>

<script>
import { mapState } from 'vuex'
import Section from '@/components/Layout/Section'
import InfiniteLoading from 'vue-infinite-loading'
import Card from '@/components/Cards/Card'
import CardGrid from '@/components/Cards/CardGrid'
import ToggleNSFW from '@/components/Common/Button/ToggleNSFW'

export default {
  name: 'UserPacks',
  components: { ToggleNSFW, CardGrid, Card, Section, InfiniteLoading },
  props: ['id'],
  data: () => {
    return {
      nfts: [],
      infiniteId: 0,
      increment: 16,
      nsfw: false
    }
  },
  computed: {
    ...mapState([
      'userId',
      'wallet'
    ]),
    galleryId () {
      return this.id || this.userId
    },
    packsUrl () {
      return `/api/pack/user/${this.galleryId}`
    }
  },
  watch: {
    nsfw () {
      this.reload()
    }
  },
  methods: {
    reload () {
      this.nfts = []
      this.infiniteId++
    },
    infiteListHandler ($state) {
      this.$http.get(this.packsUrl, {
        params: {
          start: this.nfts.length,
          end: this.nfts.length + this.increment
        }
      }).then(({ data }) => {
        if (data?.metadata.length) {
          this.nfts.push(...data.metadata)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
