<template>
  <AppView class="app-view">
    <h1 class="big">#terms of use</h1>
    <p>
      By using our website you agree to these terms. Please read them before any registration process.
      Introduction: These terms of use are for all Users, and govern your use of Algogems and your agreement with us.

    </p>
    <h2>Interpretation:</h2>
    <ol class="number">
      <li>
        "Algogems", we refer to the website www.algogems.io
      </li>
      <li>
        "we", "our", "us", we refer to any identity that represents Algogems (for example any member of the Algogems team)
      </li>
      <li>
         "Content", we refer to any material uploaded to Algogems by any User (photos, videos, text, or any other material).
      </li>
      <li>
        "User", we refer to any users with an Algogems account and use our website.
      </li>
      <li>
        "Creator", we refer to any people who upload content to Algogems intending to create an auction.
      </li>
    </ol>

    <h2>Who We Are:</h2>
    <ol class="number">
      <li>
        Algogems is managed by NFX Labs, based in France. Our team is composed of 5 software engineers, helped by our Marketing team.
      </li>
      <li>
        You can contact us directly by email, at contact@Algogems.io.
      </li>
    </ol>

    <h2>Registration:</h2>
    <p>To use Algogems, you need to register to create an account, and provide:</p>
    <ol class="number">
      <li>
        An Algorand address linked to one of your personal ALGO wallet (to fully use Algogems)
      </li>
      <li>
        A username (optional)
      </li>
    </ol>

    <p>To register on Algogems:</p>
    <ol class="number">
      <li>
        You must not have been previously banned from our website because you violated our terms of use
      </li>
      <li>
        If  you provide an identification (or any form of ID), you are responsible  to make sure this ID is yours alone.
      </li>
      <li>
        You are responsible for all of the Content that you post on our website, and you bear full, legal responsibility in the event that you post content that is illegal, stolen or misused in any way. You will ensure that your Content is compliant with our Terms of Use.
      </li>
    </ol>

    <h2>Our Right Over Your Account:</h2>
    <ol class="number">
      <li>
        Should you be in violation of our Terms of Use, we (Algogems) reserve(s) the right to ban your account. If your account is banned you are not able to return to Algogems to create a new one. All bans are considered to be lifetime bans.

      </li>
    </ol>

    <h2>Our responsibilities Over User Content:</h2>
    <ol class="number">
      <li>
        We have no power, or control, over the Content posted by our Users. We are not responsible if one User decides to post Content that does not comply with Terms of Use or any and all laws that may govern Algogems, in any and all countries where users reside.
      </li>
      <li>
        We reserve the right to censor any content that violates our Terms of Use.
      </li>
      <li>
        We bear no responsibility should you post personal Content that is later misused by another user, including but not limited to, the transference of this Content to another website by an Algogems user.
      </li>
      <li>
        Be aware that all the content that you upload on Algogems will be uploaded on IPFS. IPFS is a decentralized storage network. This means that no entity, either Algogems or any user, can remove data from that storage immediately. Consider that data (images, content, etc.) posted on Algogems will be permanently present online.
      </li>
      <li>
        You will be sure to have legal possession of any Content you post on Algogems. Algogems will bear no responsibility for user Content. The responsibility for stolen content, or other content that is in violation of our Terms of Use, is borne solely by the User.
      </li>
      <li>
        Do Not Post Content That :
        <ol class="letter">
          <li>
            Is Illegal, discriminatory, or that encourages violence, racism, hate or other illegal activities.
          </li>
          <li>
            Exploits any individual under 18 years or age.
          </li>
          <li>
            Promotes Escorting, Prostitution, Child Trafficking, Drug Use, Incest, Violence (including self-harm), Racism, Sexism, Sexual Assault, Torture, Mutilation, Necrophilia, Scatalogical Content and/or any and all Illegal Paraphilia

          </li>
          <li>
            All other content and/or topics not present in the list above that are punishable by law in all countries where Algogems have users/activity.

          </li>
          <li>
            Users must tag all adult content, as such, in order to sensor is as NSFW, and prevent users under 18 years of age from viewing it. Should a User publish adult content that is not tagged accordingly, the User bears sole responsibility in the event that an underage User views this Content.
          </li>
          <li>
            Algogems bears no responsibility for any User who suffers loss or damage by viewing adult content on the site.
          </li>
        </ol>
      </li>
    </ol>

    <h2>Algorand & AlgoSigner, MyAlgo and Algorand Wallet:</h2>
    <ol class="number">
      <li>
        We use Algorand to process all OF the transactions on Algogems. We use AlgoSigner, MyAlgo, or Algorand Wallet address to access your Algo or NFT (Non-Fungible Token) that represents unique tradable digital items.
      </li>
      <li>
        We are not responsible in the event that you lose access to your Wallet. You are fully responsible to store your mnemonic words (seed phrase) to be able to access or recover your Wallet.
      </li>
      <li>
        We bear no responsibility in the event that someone accesses your wallet and makes transactions without your authorization.
      </li>
      <li>
        We do not have a refund policy. When you process a payment, you are fully aware that this payment is final.
      </li>
      <li>
        We do not provide any insurance or guarantee to the User that they will make money with their Content.
      </li>
      <li>
        We bear no responsibility in the event that Algorand loses value. We decided to use Algorand for this technology, but its native asset ALGO is potentially highly volatile. We bear no responsibility for any potential loss that you suffer in the event of asset depreciation. Prices are decided by a free market and Algogems has no control over them.
      </li>
      <li>
        We do not promise any returns on any investments in any form. In the case of assets associated with Algogems, they should not be regarded as an investment contract of any kind.
      </li>
    </ol>

    <h2>Content You Can Buy:</h2>
    <ol class="number">
      <li>
        We are not responsible for the quality of private content. When you buy private content you can not complain about the quality, which is fully under the responsibility of the Creator.
      </li>
      <li>
        If you buy private Content, you will need to access Algogems to decrypt it. We bear no responsibility should you not be able to view private Content on any other website.
      </li>
      <li>
        Public Content is available for everyone. As we use NFT technology, everybody can read the data on any of the tokens you buy.
      </li>
      <li>
        We bear no responsibility should you not succeed in the resale of any of the Digital items or Content that you decided to buy.
      </li>
    </ol>

    <h2>Data Policy</h2>
    <ol class="number">
      <li>
        We only keep the information required for your account maintenance in our database.
      </li>
      <li>
        If you decide to use an ID to access adult content, we only  use the ID to confirm your age.
      </li>
      <li>
        We are using your data for commercial activity and will never sell your data.
      </li>
      <li>
        We may use your data in the event of an audit
      </li>
      <li>
        We keep individual token access provided by third parties. We do not keep any third party login information, even in event of an audit. This includes Instagram and other providers.
      </li>
      <li>
        For a digital creation, we may cache the image to improve the performance of the Algogems platform.
      </li>
      <li>
        Creating an NFT or collectible from direct upload, or third party,  will upload the content to IPFS
      </li>
    </ol>
  </AppView>
</template>

<script>
import AppView from '../../components/Layout/AppView'

export default {
  name: 'TermsOfUseEn',
  components: { AppView }
}
</script>

<style scoped>
h2 {
  margin-top: 20px;
  font-size: 1em;
  color: var(--textlight);
  font-family: 'Roboto', sans-serif;
}

ol {
  color: var(--textlight);
  font-size: 0.9em;
  font-weight: 400;
  list-style: none;
}

ol li {
  margin: 3px;
}

ol.number {
  counter-reset: ol-number-counter;
}

ol.number > li {
  counter-increment: ol-number-counter;
}

ol.number > li::before {
  margin-right: 5px;
  content: counter(ol-number-counter) ") ";
}

ol.letter {
  counter-reset: ol-letter-counter;
}

ol.letter > li {
  counter-increment: ol-letter-counter;
}

ol.letter > li::before {
  margin-right: 5px;
  content: counter(ol-letter-counter, lower-alpha) ". ";
}

.app-view {
  padding-bottom: 70px;
}
</style>
