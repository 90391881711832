<template>
  <router-link
    :to="collectionUrl"
    class="collection-wrapper"
  >
    <div class="collection">
      <div :class="{ cover: true, loading: !loaded }" :style="coverStyle"></div>
      <div class="title">
        <h4>{{ collection.title | formatTitle }}</h4>
        <p>{{ collection.description | formatDescription }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'CollectionCard',
  props: {
    collection: Object
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    coverStyle () {
      if (this.loaded) {
        return `background-image: url(${this.coverUrl})`
      }
      return ''
    },
    coverUrl () {
      const bannerId = (!this.collection.v_banner ? this.collection.collection_id : `${this.collection.collection_id}-${this.collection.v_banner}`)
      const imageRequest = JSON.stringify({
        bucket: 'algogems',
        key: `collection/${bannerId}`,
        edits: {
          resize: {
            width: 350,
            height: 275
          }
        }
      })
      return `/image/${btoa(imageRequest)}`
    },
    collectionUrl () {
      return `/collection/${this.collection.collection_id}`
    }
  },
  filters: {
    formatTitle (val) {
      if (val.length > 30) {
        val = `${val.slice(0, 30)}...`
      }
      return val
    },
    formatDescription (val) {
      if (val.length > 150) {
        val = `${val.slice(0, 150)}...`
      }
      return val
    }
  },
  methods: {
    loadCover () {
      const bgImg = new Image()
      bgImg.onload = () => {
        this.loaded = true
      }
      bgImg.src = this.coverUrl
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadCover()
    })
  }
}
</script>

<style scoped>
.collection-wrapper {
  margin: 0;
  padding: 0;
  animation: card-appear .2s ease backwards;
}
.collection {
  width: 350px;
  height: 275px;
  margin: auto;
}

.collection h4{
  margin: 10px 0 0 0;
  text-transform: capitalize;
}

.collection p{
  margin: 5px;
  text-align: justify;
}

.collection .cover{
  width: 100%;
  height: 175px;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  box-shadow: var(--strong-shadow);
  transition: transform 0.2s ease;
}

.cover.loading{
  box-shadow: none;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f3f3f3;
  background: linear-gradient(to right,#f3f3f3 5%,#ebebeb 20%,#f3f3f3 35%);
  background-size: 1000px 640px;
}

.collection:hover .cover{
  transform: scale(1.05);
}
</style>
