<template>
  <div id="auth_challenge">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    <h1>{{$t('authentificateChallenge.header')}}</h1>
    <p v-html="$t('authentificateChallenge.body')"></p>
  </div>
</template>

<script>
import Contract from '@/web3/Contract'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'AuthenticateChallenge',
  props: ['_viewData'],
  computed: {
    ...mapState([
      'userId',
      'wallet',
      'routeBeforeAuthenticate'
    ]),
    galleryUrl () {
      return '/user'
    }
  },
  methods: {
    ...mapMutations([
      'login'
    ]),
    challenge () {
      if (this._viewData.address) {
        Contract.challenge(this.wallet, this._viewData.address).then((response) => {
          this.login(response)
          if (this.$route.params.next != null) {
            this.$router.push(this.$route.params.next)
          } else if (this.routeBeforeAuthenticate !== '/') {
            this.$router.push(this.routeBeforeAuthenticate)
          } else {
            this.$router.push(this.galleryUrl)
          }
        }).catch(error => {
          if (error) {
            this.$emit('error', error)
          } else {
            this.$emit('error', this.$t('error.standard-error'))
          }
        })
      } else {
        this.$emit('previous')
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.challenge()
    })
  }
}
</script>

<style scoped>
#auth_challenge {
  background: var(--lightgradient);
  padding: 50px 30px;
  border-radius: 20px;
}

h1{
  color: var(--text);
}

.lds-spinner {
  color: var(--accent);
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: var(--accent);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
