<template>
<AppView>
  <LinearSpinner/>
</AppView>
</template>

<script>
import { mapState } from 'vuex'
import LinearSpinner from '../components/Common/Spinner/LinearSpinner'
import AppView from '../components/Layout/AppView'

export default {
  name: 'InstagramRedirect',
  components: { AppView, LinearSpinner },
  data () {
    return {
      media: []
    }
  },
  computed: {
    ...mapState(['userId'])
  },
  mounted () {
    console.log(this.$route.query.code)
    if (this.$route.query.code) {
      const code = this.$route.query.code
      this.$http
        .post('/api/instagram/code', { code })
        .then((d) => {
          this.$router.push('/user/instagram')
        })
    } else {
      this.$router.push('/user/instagram')
    }
  }
}
</script>

<style scoped>
ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
}

.media {
  border-radius: 20px;
  width: 300px;
  height: 350px;
  overflow: hidden;
  margin: 5px;
}

.media:hover {
  box-shadow: var(--shadow);
}

.media img {
  width: 350px;
  height: 350px;
}
</style>
