<template>
<AppView>
  <Section>
    <h2>Distribute your token</h2>
    <div class="two-columns">
      <p>Enter your token id:</p> <input type="number" min="0" v-model="tokenId">
      <p>Upload a file with an address and an amount to send separated by a coma per row:</p><input type="file" :id="`upload`" accept="text/plain" @change="(e) => uploadAddresses(e)">
    </div>
    <div v-if="addresses.length" class="uploaded-addresses-container">
      <h3>Uploaded Addresses</h3>
      <ul>
        <li v-for="[i, address] of addresses.entries()" :key="`address-${i}`">
          {{address}}: {{amounts[i]}}
        </li>
      </ul>
    </div>
    <div class="button-holder">
      <h5 v-if="error" class="error"><unicon height="24px" name="exclamation-octagon"/>{{error}}</h5>
      <Button @click.native="sendToken">Send token</Button>
    </div>
    <ContractPopUp v-if='displayContractPopUp'
                 :_kind="'sendToken'"
                 :_tokenId='parseInt(tokenId)'
                 @close="displayContractPopUp = false"
                 :_contractParams="{
                       addresses,
                       amounts
                     }"/>
  </Section>
</AppView>
</template>

<script>
import AppView from '@/components/Layout/AppView'
import Button from '@/components/Common/Button/Button'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import Section from '@/components/Layout/Section'

export default {
  name: 'DistributeToken',
  components: { Section, ContractPopUp, Button, AppView },
  data () {
    return {
      tokenId: 0,
      addressList: undefined,
      addresses: [],
      amounts: [],
      error: undefined,
      displayContractPopUp: false
    }
  },
  methods: {
    uploadAddresses (e) {
      const image = e.target.files[0]
      if (image) {
        const reader = new FileReader()
        reader.readAsText(image)
        reader.onload = e => {
          const data = e.target.result.split('\n').map(x => x.split(','))
          this.addresses = []
          this.amounts = []
          for (const d of data) {
            if (d[0].length) {
              this.addresses.push(d[0])
              if (d.length > 1 && !isNaN(d[1])) {
                this.amounts.push(parseFloat(d[1]))
              } else {
                this.amounts.push(1)
              }
            }
          }
        }
      }
    },
    sendToken () {
      if (this.tokenId === 0) {
        this.error = 'Please enter a valid token ID'
        return
      }
      if (this.addresses.length === 0) {
        this.error = 'Please enter at least one address'
        return
      }
      this.displayContractPopUp = true
    }
  }
}
</script>

<style scoped>
h2 {
  text-align: center;
}

.two-columns {
  max-width: 600px;
  margin: auto;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-gap: 15px;
}

.two-columns > *:nth-child(odd) {
  align-self: center;
  justify-self: right;
}

.button-holder {
  position: relative;
}

ul {
  max-height: 235px;
  overflow-y: scroll;
}

li {
  font-family: 'Roboto Mono', monospace;
}

.uploaded-addresses-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
