import Utils from '../utils'
import algosdk from 'algosdk-latest'
import createShuffle from './createShuffle'

async function createPack (APIName, from, pack) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()

    const packNft = []

    for (let i = 0; i < pack.nbPacks; i++) {
      packNft.push(
        algosdk.makeAssetCreateTxnWithSuggestedParams(
          from, undefined, 1, 0, undefined, from, undefined, undefined, undefined, `${i}PG`.slice(0, 8), `${i}-GEMSPack-${pack.title.slice(0, 10)}`.slice(0, 30), 'ipfs://Qmb1WDNZvSbL6iFQ3nXVkrUe5UrvxHwz188N5Wtbkqz8UY', undefined, suggestedParams
        )
      )
    }

    const signedPackNft = await API.signGroup(packNft, packNft.map(x => true), false)
    const keysInfo = await Utils._callServer('/api/pack/keys', { signed_tx: signedPackNft })

    const selectedNFTs = {}
    for (const nft of keysInfo.keys) {
      selectedNFTs[nft] = 1
    }
    const shuffleInfo = {
      title: `Keys for ${pack.title}`,
      description: `Keys to open the pack ${pack.title} on Algogems`,
      price: pack.price,
      selectedNFTs,
      nsfw: false,
      asaId: pack.asaId,
      whitelist: pack.whitelist,
      whitelistTokenName: pack.whitelistTokenName.slice(0, 30),
      whitelistTokenUnit: pack.whitelistTokenUnit.slice(0, 8),
      whitelistTokenNumber: pack.whitelistTokenNumber,
      startIn: pack.startIn
    }

    const shuffle = await createShuffle(APIName, from, shuffleInfo)

    const messageInfo = { ...pack, shuffle_id: shuffle.id, keys: keysInfo.keys }

    const parsed = await Utils._callServer('/api/pack/create', messageInfo)
    /*
     'approval_program'
     clear_state_program
     'list_pack'
     'seller'
     'gems_fees_wallet'
     'gems_fees_percent'
     'price'
     'whitelist_ASA_id'
     'number_pack'
     'nft_per_pack'
     pack_id
     */
    const packsApps = []
    for (const [i, nftIds] of parsed.list_pack.entries()) {
      const appArgs = []
      appArgs.push(algosdk.decodeAddress(parsed.seller).publicKey)
      appArgs.push(algosdk.decodeAddress(parsed.gems_fees_wallet).publicKey)
      appArgs.push(Utils.longToByteArray(100))
      appArgs.push(Utils.longToByteArray(500_000))
      appArgs.push(Utils.longToByteArray(keysInfo.keys[i]))

      for (const nftId of nftIds) {
        appArgs.push(Utils.longToByteArray(nftId))
      }

      packsApps.push(algosdk.makeApplicationCreateTxn(
        parsed.seller,
        suggestedParams,
        algosdk.OnApplicationComplete.NoOpOC,
        new Uint8Array(Buffer.from(parsed.approval_program, 'base64')),
        new Uint8Array(Buffer.from(parsed.clear_state_program, 'base64')),
        0, 0,
        10, 7,
        appArgs,
        undefined, undefined,
        undefined, new TextEncoder().encode(`pack-${i}`)
      ))
    }

    const signedApps = []
    for (let i = 0; i < Math.ceil(packsApps.length / 60); i++) {
      const temp = packsApps.slice(i * 60, (i + 1) * 60)
      const d = await API.signGroup(temp, temp.map(x => true), false)
      signedApps.push(...d)
    }
    const validation = await Utils._callServer('/api/pack/app', { signed_txs: signedApps, list_pack: parsed.list_pack })
    /*
     seller
     amount: 860000
     data_application: [{nfts: [], app_address, app_id}]}
     */

    const txns = []

    for (const [i, data] of validation.data_application.entries()) {
      const nftIds = data.nfts.map(x => parseInt(x))
      const group = []

      group.push(
        algosdk.makePaymentTxnWithSuggestedParams(
          validation.seller, data.app_address,
          validation.amount,
          undefined, undefined,
          suggestedParams
        )
      )

      const appArgs2 = []
      appArgs2.push(new TextEncoder().encode('setup'))
      const foreignAssets = [...nftIds, keysInfo.keys[i]]

      group.push(
        algosdk.makeApplicationNoOpTxn(
          validation.seller, suggestedParams,
          data.app_id,
          appArgs2, undefined,
          undefined, foreignAssets
        )
      )

      for (const [j, nftId] of nftIds.entries()) {
        group.push(
          algosdk.makeAssetTransferTxnWithSuggestedParams(
            validation.seller, data.app_address,
            undefined, undefined,
            1, new TextEncoder().encode(`pack-${i}-nft-${j}`), nftId,
            suggestedParams
          )
        )
      }
      for (let i = 0; i < Math.ceil(group.length / 60); i++) {
        const temp = group.slice(i * 60, (i + 1) * 60)
        const txngroup = algosdk.assignGroupID(temp)
        for (const [i, tx] of temp.entries()) {
          tx.group = txngroup[i].group
        }
        txns.push(temp)
      }
    }

    const signedTx = []
    for (const groupTx of txns) {
      const d = await API.signGroup(groupTx, groupTx.map(x => true), false)
      signedTx.push(...d)
    }
    const message = {
      pack_id: parsed.pack_id,
      signed_tx: signedTx,
      data_application: validation.data_application,
      number_group_tx: pack.nftPerPack + 2,
      keys: keysInfo.keys
    }
    return Utils._callServer('/api/pack/end', message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default createPack
