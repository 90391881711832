<template>
  <label class="toggle">
    <input type="checkbox" v-model="state" @change="$emit('toggle', state)">
    <span class="slider"></span>
  </label>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    defaultState: Boolean
  },
  data () {
    return {
      state: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.state = this.defaultState
    })
  }
}
</script>

<style scoped>
.toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 10px;
}
.toggle input{
  display: none;
}

.toggle .slider{
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-alt);
  transition: .4s;
  border-radius: 34px;
}

.toggle .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--foreground-alt);
  transition: .4s;
  border-radius: 50%;
  box-shadow: var(--light-shadow);
}

.toggle input:checked + .slider {
  background: var(--gradient);
}

.toggle input:checked + .slider:before {
  transform: translateX(26px);
}

</style>
