import { PeraWalletConnect } from '@perawallet/connect'
import algosdk from 'algosdk-latest'
import * as encoder from 'uint8-to-base64'

let _accounts

export class AlgoWalletAPI {
  static name = 'algowallet'
  static wallet

  static connect () {
    return new Promise((resolve, reject) => {
      AlgoWalletAPI.wallet = new PeraWalletConnect({
        clientMeta: {
          description: '',
          url: 'https://algogems.io',
          icons: [],
          name: 'Algogems'
        }
      })
      AlgoWalletAPI.wallet
        .connect()
        .then((accounts) => {
          console.log(accounts)
          _accounts = accounts
          resolve([{ address: _accounts[0] }])
        })
        .catch((e) => {
          _accounts = AlgoWalletAPI.wallet.connector._accounts
          resolve([{ address: _accounts[0] }])
        })
    })
  }

  static logout (callback = () => { }, errorCallback = () => { }) {
    if (AlgoWalletAPI.wallet) {
      return new Promise((resolve, reject) => {
        AlgoWalletAPI.wallet.disconnect()
          .then(() => {
            AlgoWalletAPI.wallet = null
            localStorage.clear()
            resolve()
          })
          .catch(reject)
      })
    } else {
      return new Promise((resolve, reject) => { resolve() })
    }
  }

  static account (callback = () => { }, errorCallback = () => { }) {
    return AlgoWalletAPI.connect(callback, errorCallback)
  }

  static async sign (txn, callback = () => {}, errorCallback = () => {}, getUint8 = false) {
    if (!AlgoWalletAPI.wallet) {
      await AlgoWalletAPI.connect()
    }

    const result = await AlgoWalletAPI.wallet.signTransaction([[{ txn: txn }]])

    console.log('AlgoWallet - Wallet Sign Results:', result)
    const signedTx = { blob: result[0] }
    if (typeof signedTx.blob === 'object') {
      if (!getUint8) {
        signedTx.blob = encoder.encode(signedTx.blob)
      }
    } else {
      if (getUint8) {
        signedTx.blob = new Uint8Array(Buffer.from(signedTx.blob, 'base64'))
      }
    }
    return signedTx
  }

  static async signGroup (txns, isSigning, isGrouped = true) {
    let txnGroup = []

    if (isGrouped) {
      const txngroup = algosdk.assignGroupID(txns)
      for (let i = 0; i < txns.length; i++) {
        txns[i].group = txngroup[i].group
        txnGroup.push({ txn: txns[i], signers: [txns[i].from] })
      }
      console.log('AlgoWallet - sign group', txnGroup, isSigning)
    } else {
      txnGroup = txns.map(x => ({ txn: x }))
    }

    if (!AlgoWalletAPI.wallet) {
      await AlgoWalletAPI.connect()
    }
    const r = await AlgoWalletAPI.wallet.signTransaction([txnGroup])
    const signedTxs = r.map((x) => {
      const signedTx = { blob: x }
      if (typeof signedTx.blob === 'object') {
        signedTx.blob = encoder.encode(signedTx.blob)
      }
      return signedTx
    })
    return signedTxs
  }
}
