import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function cancelFusion (APIName, from, fusion) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()

    const message = {
      app_id: fusion.id
    }
    const parsed = await Utils._callServer('/api/fusion/cancel', message, 'GET')
    /* app_address seller gems_address */

    const ids = ['input_1', 'input_2', 'input_3', 'input_4']
    const nftIds = []

    for (const id of ids) {
      if (fusion[id].token_id) {
        nftIds.push(fusion[id].token_id)
      }
    }
    nftIds.push(fusion.output.token_id)

    const appArgs = []
    appArgs.push(new TextEncoder().encode('master_cancel'))

    const txn = algosdk.makeApplicationDeleteTxn(
      from,
      suggestedParams,
      fusion.id, undefined,
      [parsed.seller],
      undefined, nftIds,
      undefined, undefined, undefined)

    const signedTx = await API.sign(txn)
    const message2 = {
      app_id: fusion.id,
      signed_tx: [signedTx]
    }

    return await Utils._callServer('/api/fusion/cancel', message2)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default cancelFusion
