import MyAlgoAPI from './Wallets/MyAlgo'
import AlgoSignerAPI from './Wallets/AlgoSigner'
import ExodusAPI from './Wallets/Exodus'
import { AlgoWalletAPI } from './Wallets/PeraWallet'
import { DeflyWalletAPI } from './Wallets/DeflyWallet'

function _getAPI (APIName) {
  if (APIName === 'myalgo') {
    return MyAlgoAPI
  } else if (APIName === 'algowallet') {
    return AlgoWalletAPI
  } else if (APIName === 'defly') {
    return DeflyWalletAPI
  } else if (APIName === 'exodus') {
    return ExodusAPI
  } else {
    return AlgoSignerAPI
  }
}

function _callServer (url, toSend, method = 'POST') {
  return new Promise(function (resolve, reject) {
    let message = JSON.stringify(toSend)
    let params = ''
    for (const [key, value] of Object.entries(toSend)) {
      if (typeof value === 'object') {
        params += `${key}=${JSON.stringify(value)}&`
      } else {
        params += `${key}=${value}&`
      }
    }
    params = params.substr(0, params.length - 1)

    if (method === 'GET') {
      url += '?' + params
      message = {}
    }

    var http = new XMLHttpRequest()
    http.open(method, url, true)
    http.setRequestHeader('Content-type', 'application/json;charset=UTF-8')
    http.onreadystatechange = function () {
      if (http.readyState === 4) {
        if (http.status === 200) {
          try {
            const parsed = JSON.parse(http.responseText)
            resolve(parsed)
          } catch {
            console.log(http.responseText)
            resolve(http.responseText)
          }
        } else {
          console.log(http)
          reject(http.responseText)
        }
      }
    }
    http.onprogress = function (e) {
      if (http.status >= 400) {
        console.log(http.responseText)
        reject(http.responseText)
      }
    }
    http.onerror = function () {
      console.log({
        status: this.status,
        statusText: http.statusText
      })
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        status: this.status,
        statusText: http.statusText
      })
    }
    http.send(message)
  })
}

async function getParams (callback = () => { }, errorCallback = () => { }) {
  const suggestedParams = await _callServer('/api/sdk/param', {}, 'GET')
  suggestedParams.firstRound = suggestedParams.last_round
  suggestedParams.lastRound = suggestedParams.last_round + 1000
  suggestedParams.genesisID = suggestedParams.genesis_id
  suggestedParams.genesisHash = suggestedParams.genesis_hash
  return suggestedParams
}

async function sendAlgo (signedTx, isBlob = false) {
  if (Array.isArray(signedTx)) {
    const tx = signedTx.map(x => (isBlob) ? x : x.blob)
    console.log('Sends array:', tx)
    return _callServer('/api/sdk/send/group', { signed_tx: tx }, 'POST')
  } else {
    const tx = (isBlob) ? signedTx : signedTx.blob
    console.log('Sends:', tx)
    return _callServer('/api/sdk/send', { signed_tx: tx }, 'POST')
  }
}

function _base64ToArrayBuffer (base64) {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}

function longToByteArray (long) {
  const byteArray = [0, 0, 0, 0, 0, 0, 0, 0]

  for (let index = byteArray.length - 1; index >= 0; index--) {
    const byte = long & 0xff
    byteArray[index] = byte
    long = (long - byte) / 256
  }

  return new Uint8Array(byteArray)
}

export default {
  _getAPI,
  _callServer,
  getParams,
  sendAlgo,
  _base64ToArrayBuffer,
  longToByteArray
}
