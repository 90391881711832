import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function editASA (APIName, from, asset) {
  try {
    const API = Utils._getAPI(APIName)
    const parsed = await Utils._callServer(`/api/nft/config/${asset.token_id}`, {}, 'GET')
    console.log('Edit ASA', parsed)

    const suggestedParams = await Utils.getParams()
    const txn = algosdk.makeAssetConfigTxnWithSuggestedParams(from, asset.note, asset.token_id, parsed.manager, parsed.reserve, undefined, undefined, suggestedParams, false)

    const d = await API.sign(txn)
    const message = {
      signed_tx: [d]
    }
    return Utils._callServer(`/api/nft/edit/send/${asset.token_id}`, message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default editASA
