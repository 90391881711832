<template>
  <AppView>
    <div class="head">
      <div>
        <div :key="reloadCover" id="collection_picture" class="cover" :style="collectionPicture"></div>
        <ImageUpload :api="coverApi" :parameters="{ collection_id: this.id }" @done="() => { reloadCover++ }">
          {{$t('editCollection.button.changePicture')}}
        </ImageUpload>
      </div>
      <div>
        <input type="text" v-model="title" :placeholder="$t('editCollection.placeholder.title')">
        <textarea type="text" v-model="description" :placeholder="$t('editCollection.placeholder.description')"/>
        <span v-if="isMine" class="button-container">
        <Button @click.native="done">{{$t('editCollection.button.done')}}</Button>
        <Button @click.native="displayValidationPopUp = true" class="error-btn">{{$t('editCollection.button.delete')}}</Button>
      </span>
      </div>
    </div>
    <div class="nft-list">
      <div class="nft-list-head">
        <TextualButton class="reverse" @click.native="previousPage" v-if="this.start>0">
          <unicon name="angle-left" />{{$t('common.button.back')}}
        </TextualButton>
        <span v-else></span>
        <span>{{start + 1}}-{{start + increment + 1}}</span>
        <TextualButton @click.native="nextPage" v-if="this.morePage">
          {{$t('common.button.next')}}<unicon name="angle-right" />
        </TextualButton>
        <span v-else></span>
      </div>
      <ListCardGrid v-if="!this.isLoadingPage">
        <ListCard v-for="(nft, index) in nfts" :key="nft.token_id" :data="nft" :style="`animation-delay: ${100 + (index * 50)}ms`">
          <Button @click.native.stop.prevent="() => { add(nft.token_id) }" v-if="!selectedNFTs.includes(nft.token_id)">
            <unicon name="plus-circle"></unicon>Add
          </Button>
           <Button class="error-btn" @click.native.stop.prevent="() => { remove(nft.token_id) }" v-else>
             <unicon name="minus-circle"></unicon>Remove
           </Button>
        </ListCard>
      </ListCardGrid>
      <LinearSpinner v-else/>
      <!-- <infinite-loading spinner="waveDots" @infinite="infiniteHandler" v-if="isLoaded"><span slot="no-more" ></span><span slot="no-results"></span></infinite-loading> -->
    </div>
    <ValidationPopUp @yes="deleteCollection" @no="displayValidationPopUp = false" v-if="displayValidationPopUp">{{$t('editCollection.validateDelete')}}</ValidationPopUp>
  </AppView>
</template>

<script>
import ListCard from '@/components/Cards/ListCard'
import { mapState } from 'vuex'
import Button from '@/components/Common/Button/Button'
import ImageUpload from '@/components/Create/ImageUpload'
import ValidationPopUp from '@/components/Common/PopUp/ValidationPopUp'
import TextualButton from '@/components/Common/Button/TextualButton'
import LinearSpinner from '@/components/Common/Spinner/LinearSpinner'
import AppView from '@/components/Layout/AppView'
import ListCardGrid from '@/components/Cards/ListCardGrid'

export default {
  name: 'EditCollection',
  components: { ListCardGrid, AppView, LinearSpinner, TextualButton, ValidationPopUp, ImageUpload, Button, ListCard },
  data () {
    return {
      start: 0,
      end: 16,
      increment: 9,
      morePage: false,
      isLoadingPage: false,
      title: '',
      description: '',
      nfts: [],
      selectedNFTs: [],
      ownerAddress: '',
      ownerUsername: '',
      nsfw: false,
      id: this.$route.params.id,
      isLoaded: false,
      reloadCover: 0,
      displayValidationPopUp: false
    }
  },
  computed: {
    ...mapState([
      'userId',
      'address'
    ]),
    collectionPicture () {
      const bannerId = (!this.bannerVersion ? this.id : `${this.id}-${this.bannerVersion}`)
      const imageRequest = JSON.stringify({
        bucket: 'algogems',
        key: `collection/${bannerId}`,
        edits: {
          resize: {
            width: 350,
            height: 250
          }
        }
      })
      return `background-image: url(/image/${btoa(imageRequest)})`
    },
    isMine () {
      return (this.userId === this.ownerUsername) || (this.address === this.ownerAddress)
    },
    url () {
      return `/api/collections/info/${this.id}`
    },
    removeUrl () {
      return '/api/collections/remove'
    },
    addUrl () {
      return '/api/collections/add'
    },
    deleteUrl () {
      return '/api/collections/delete'
    },
    creationUrl () {
      if (this.address === 'WXLHOLGBGWIF4WXCSART3QRIINPM5R3OSULYQ3TWWHSPAI2YHIJMVM4XRI') {
        return `/api/gallery/${this.ownerUsername}`
      }
      return `/api/collections/creation/${this.ownerUsername}`
    },
    doneUrl () {
      return `/collection/${this.id}`
    },
    coverApi () {
      return '/api/collections/picture'
    },
    titleApi () {
      return '/api/collections/title'
    },
    descriptionApi () {
      return '/api/collections/description'
    },
    NFTUrl () {
      return `/api/collections/nft/${this.id}`
    },
    userUrl () {
      return '/user'
    }
  },
  methods: {
    add (tokenId) {
      const message = { collection_id: this.id, token_id: tokenId }
      this.$http.post(this.addUrl, message)
        .then(() => {
          this.selectedNFTs.push(tokenId)
        })
    },
    remove (tokenId) {
      const message = { collection_id: this.id, token_id: tokenId }
      this.$http.post(this.removeUrl, message)
        .then(() => {
          this.selectedNFTs = this.selectedNFTs.filter(x => x !== tokenId)
        })
    },
    done () {
      const message = { collection_id: this.id, title: this.title, description: this.description }
      this.$http.post(this.titleApi, message)
        .then(() => {
          this.$http.post(this.descriptionApi, message).then(() => {
            this.$router.push(this.doneUrl)
          })
        })
    },
    deleteCollection () {
      const message = { collection_id: this.id }
      this.$http.post(this.deleteUrl, message)
        .then(() => {
          this.$router.push(this.userUrl)
        })
    },
    previousPage () {
      if (this.isLoadingPage) return
      this.start -= this.increment
      this.start = (this.start < 0) ? 0 : this.start
      this.refreshPage()
    },
    nextPage () {
      if (this.isLoadingPage) return
      this.start += this.increment
      this.refreshPage()
    },
    refreshPage () {
      const params = {
        start: this.start,
        end: this.start + this.increment,
        nsfw: this.nsfw
      }
      this.nfts = []
      this.isLoadingPage = true
      this.$http.get(this.creationUrl, {
        params: params
      }).then(({ data }) => {
        this.isLoadingPage = false
        if (data?.metadata.length) {
          this.nfts = data.metadata.filter(x => x)
          this.morePage = !data.bool_end
        }
      }).catch(() => {
        this.isLoadingPage = false
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$http.get(this.url).then(response => {
        if (response.data) {
          this.title = response.data.title
          this.nsfw = response.data.nsfw
          this.selectedNFTs = response.data.all_nft
          this.description = response.data.description
          this.ownerAddress = response.data.address
          this.ownerUsername = (response.data.username) ? response.data.username : response.data.address
          this.bannerVersion = response.data.v_banner
          if (!this.isMine) {
            this.$router.push('/')
          }
          this.$nextTick(() => { this.isLoaded = true })
          this.refreshPage()
        }
      }).catch(err => {
        console.log(err)
      })
    })
  }
}
</script>

<style scoped>

.head {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  flex-wrap: wrap;
}

.head input {
  width: 300px;
}

.cover {
  width: 350px;
  height: 250px;
  border-radius: 15px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 20px;
}

.list-card-grid {
  grid-template-columns: 1fr;
}

.nft-list-head {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 50px;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 25px;
  width: 400px;
  text-align: center;
}

.button-container {
  margin: 0;
}

.button-container button {
  margin: 5px;
}

.list-card {
  position: absolute;
}

.list-card .overview{
  width: 100%;
}

.list-card button {
  margin: 0;
  width: 140px;
  fill: var(--foreground);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
</style>
