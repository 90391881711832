<template>
  <form @submit.prevent="() => {}">
    <p>{{$t('createDetails.header')}}</p>
    <input
      @keydown.enter.prevent.stop
      v-model="title"
      :class="{'invalid':!titleValid && error}"
      :placeholder="$t('createDetails.placeholder.title')"
      type="text" maxlength="32"
    >
    <textarea
      @keyup.enter.prevent.stop
      v-model="description"
      :class="{'invalid':!descriptionValid && error}"
      :placeholder="$t('createDetails.placeholder.description')"
      maxlength="500"/>
    <p class="space-above">{{$t('createDetails.tags')}}</p>
    <Tags :default-tags="tags" @tags="updateTags" @error="toggleTagsError"/>

    <p class="space-above">{{$t('createDetails.settings')}}</p>
    <div class="input-container" :class="(!data.canPayMint) ? 'more-bottom-margin' : ''">
      <span v-if="false" class="flex-center">{{$t('common.Private')}}*
        <Toggle :default-sate="isPrivate" @toggle="(state)=>{isPrivate = state}"/>
      </span>
      <span class="flex-center">{{$t('menu.nsfw')}}
        <Toggle :default-sate="isNsfw" @toggle="toggleNsfw"/>
      </span>
      <span :class="(!data.canPayMint) ? 'not-allowed-cursor' : ''">
        <span class="flex-center">
          {{$t('createDetails.mintWhere')}}
          <Toggle :default-sate="isMintForFree" @toggle="(state) => {isMintForFree = state}" :class="(!data.canPayMint) ? 'not-possible' : ''"/>
        </span>
        <span v-if="!data.canPayMint" class="cannot-pay-mint-error">{{$t('createDetails.cannotPayMint')}}</span>
      </span>
    </div>
    <span class="flex-center">
     <!-- <span>
        <p>{{$t('createAuctionSettings.royalties')}}</p>
        <IntInput :default-value="royalties" :delay="0" :max="10" :min="0" :step="1" class="small"
                  @val="(val) => { this.royalties = val }" :class="{'invalid':!royaltiesValid && error}">%</IntInput>
      </span>-->
      <span v-if="!isMintForFree">
        <p>Total supply</p>
        <IntInput :default-value="number" :delay="0" :max="1000000000" :min="1" :step="1" class="small"
                  @val="(val) => { this.number = val }" :class="{'invalid':!numberValid && error}"></IntInput>
      </span>
      <span v-else>
        <p>Sell price</p>
        <IntInput :default-value="sellPrice" :delay="0" :max="1000000000" :min="1" :step="5" class="small"
                  @val="(val) => { this.sellPrice = val }"><ASALogo :asaName="'algo'"/></IntInput>
      </span>
    </span>

    <template v-if="data.crescendo">
      <p class="space-above">Crescendo's options</p>
      <div class="input-container">
          <select v-model="isLinkedToSomething">
            <option value="none">None</option>
            <option value="physical">Physical item</option>
            <option value="service">Service</option>
            <option value="custom_service">Custom Service</option>
          </select>
      </div>

    </template>

    <p class="advanced-options-title">
      {{$t('algoglyph.advancedOptions')}}
      <Button class="linear-button" @click.native="displayAdvancedOptions = true" v-if="!displayAdvancedOptions">
        {{$t('algoglyph.show')}}
      </Button>
      <Button class="linear-button" @click.native="displayAdvancedOptions = false" v-if="displayAdvancedOptions">
        {{$t('algoglyph.hide')}}
      </Button>
    </p>
    <template v-if="displayAdvancedOptions">

      <span class="flex-center">
        <p style="white-space:nowrap; margin-right: 10px;">{{$t('createAuctionSettings.unitName')}}</p>
        <input @keydown.enter.prevent.stop v-model="unitName" :class="{'invalid':!unitNameValid && error}" :placeholder="$t('createDetails.placeholder.unitName')" type="text" maxlength="8">
      </span>

      <ARC69Input :attributes="attributes" class="space-above" :error="error.length && !this.attributesValid"/>

      <p class="space-above">{{$t('createDetails.algoGlyph')}}</p>
      <template v-if="algoGlyphTxId">
        <span class="algo-glyph-container">
          <span>
            <div class="flex-center">
              <p>{{$t('createDetails.algoGlyphOnOff')}}</p>
              <Toggle :default-sate="hasAlgoGlyph" @toggle="(state) => {hasAlgoGlyph = state}"/>
            </div>
            <div class="flex-center">
              <p>{{$t('createDetails.algoGlyphColor')}}</p>
              <input type="color" v-model="algoGlyphColor"/>
            </div>
          </span>
          <div class="algo-glyph-preview">
            <img :src="data.picture">
            <AlgoGlyph :color="algoGlyphColor" :txId="algoGlyphTxId" v-if="hasAlgoGlyph"/>
          </div>
        </span>
        <p>{{$t('createDetails.algoglyphExplainbis')}}</p>
      </template>
      <span v-else>
        <p v-html="$t('algoglyph.dontHaveOne')"></p>
      </span>
    </template>
    <h5 v-if="error" class="error">
      <unicon height="24px" name="exclamation-octagon"/>
      {{ error }}
    </h5>
    <div class="button-container">
      <Button class="secondary" @click.native.prevent="$emit('previous')">{{$t('createDetails.button.back')}}</Button>
      or
      <Button class="blue" @click.native.prevent="create()">{{$t('createDetails.button.mint')}}</Button>
    </div>
    <h5 class="footnotes" v-html="$t('createDetails.footer')"></h5>
    <ContractPopUp v-if='isContractPopUpDisplayed' :_state="contractPopUpState" :_kind="contractPopUpKind" :_contractParams="contractPopUpParameters" @done="done" @close="closePopUp"></ContractPopUp>
  </form>
</template>

<script>
import Button from '@/components/Common/Button/Button'
import Toggle from '@/components/Common/Input/Toggle'
import Tags from '@/components/Create/Tags'
import { mapState } from 'vuex'
import IntInput from '@/components/Common/Input/IntInput'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import ARC69Input from '@/components/Create/ARC69Input'
import AlgoGlyph from '@/components/Assets/AlgoGlyph'
import ASALogo from '../../../components/Assets/ASALogo'

export default {
  name: 'CreateDetails',
  props: {
    data: Object
  },
  components: {
    ASALogo,
    AlgoGlyph,
    ARC69Input,
    ContractPopUp,
    IntInput,
    Tags,
    Toggle,
    Button
  },
  data () {
    return ({
      error: false,
      tagsError: false,
      isPhysical: false,
      isNsfw: false,
      isMintForFree: false,
      tags: [],
      title: '',
      description: '',
      royalties: 0,
      number: 1,
      sellPrice: 1,
      unitName: '',
      attributes: [],
      algoGlyphTxId: null,
      hasAlgoGlyph: false,
      algoGlyphColor: '#ffffff',
      isContractPopUpDisplayed: false,
      contractMessage: '',
      contractPopUpState: 'loading',
      contractPopUpKind: 'create',
      contractPopUpParameters: {},
      displayAdvancedOptions: false,
      isLinkedToSomething: 'none'
    })
  },
  computed: {
    ...mapState([
      'wallet',
      'address'
    ]),
    titleValid () {
      return /^.{1,32}$/.test(this.title)
    },
    descriptionValid () {
      return true // /^.{0,255}$/.test(this.description)
    },
    royaltiesValid () {
      return Number.isInteger(this.royalties) && this.royalties >= 0 && this.royalties <= 10
    },
    numberValid () {
      return Number.isInteger(this.number) && this.number >= 0 && this.number <= 100000000000
    },
    unitNameValid () {
      return new TextEncoder('utf-8').encode(this.unitName).length < 9
    },
    attributesValid () {
      for (const x of this.attributes) {
        if (!x.key.length) return false
      }
      const keys = this.attributes.map(x => x.key)
      if (new Set(keys).size !== keys.length) return false
      return true
    },
    noteSizeValid () {
      return this.getNote().length < 1024
    }
  },
  methods: {
    getNote () {
      const properties = {}
      for (const x of this.attributes) {
        properties[x.key] = (x.value.length) ? x.value : 'none'
      }
      const note = {
        creator: this.address,
        description: this.description,
        external_link: this.data.ipfs_url,
        mime_type: this.data.mimeType,
        properties: properties,
        sensitive_content: this.isNsfw,
        standard: 'arc69'
      }
      if (this.hasAlgoGlyph) {
        note.glyph = this.algoGlyphTxId
      }
      return new TextEncoder('utf-8').encode(JSON.stringify(note))
    },
    toggleTagsError (err) {
      this.tagsError = err
      this.toggleError()
    },
    toggleError () {
      this.error = false
      setTimeout(() => {
        if (this.tagsError) {
          this.error = this.tagsError
        } else if (!this.titleValid) {
          if (!this.title.length) {
            this.error = 'Title is required'
          } else {
            this.error = 'Title is invalid'
          }
        } else if (!this.descriptionValid) {
          this.error = 'Description is invalid'
        } else if (!this.royaltiesValid) {
          this.error = 'Royalties is invalid'
        } else if (!this.numberValid) {
          this.error = 'Total supply is invalid'
        } else if (!this.unitNameValid) {
          this.error = 'Unit name is too long or use too many special characters'
        } else if (!this.attributesValid) {
          this.error = 'Attributes key cannot be empty nor duplicated'
        } else if (!this.noteSizeValid) {
          this.error = 'Too much informations for your NFT. Please reduce the description or the number of ARC69 properties'
        }
      }, 100)
    },
    updateTags (tags) {
      this.tagsError = false
      this.tags = tags
      this.toggleError()
    },
    toggleNsfw (state) {
      this.isNsfw = state
    },
    create () {
      if (this.titleValid && this.descriptionValid && !this.tagsError && this.royaltiesValid && this.unitNameValid && this.attributesValid && this.noteSizeValid && this.numberValid) {
        this.isContractPopUpDisplayed = true
        const properties = {}
        for (const x of this.attributes) {
          properties[x.key] = (x.value.length) ? x.value : 'none'
        }

        const asset = {
          description: this.description,
          extension: 'jpeg',
          mime_type: this.data.mimeType,
          ipfs_url: this.data.ipfs_url,
          ipfs_hash: this.data.ipfs_hash,
          nsfw: this.isNsfw,
          royalties: this.royalties,
          tags: this.tags,
          title: this.title,
          unit: (this.unitName.length) ? this.unitName : 'GEMS NFT',
          properties: properties,
          note: this.getNote(),
          glyph: this.hasAlgoGlyph,
          glyph_color: this.algoGlyphColor,
          crescendo: this.isLinkedToSomething
        }
        if (!this.data.canPayMint || this.isMintForFree) {
          this.isMintForFree = true
          asset.price = this.sellPrice
        } else {
          asset.number = this.number
        }
        this.contractPopUpParameters = {
          isMintForFree: this.isMintForFree,
          asset: asset
        }
      } else {
        this.toggleError()
      }
    },
    done (m) {
      this.token_id = (m.token_id) ? m.token_id : m.data.token_id
      this.$router.push(`/nft/${this.token_id}`)
    },
    next () {
      this.$emit('next', {
        title: this.title,
        description: this.description,
        tags: this.tags,
        nsfw: this.isNsfw,
        isMintOnUserSide: !this.isMintForFree,
        royalties: this.royalties,
        token_id: this.token_id
      })
    },
    closePopUp (e) {
      if (e.isCorrect) {
        this.next()
      } else {
        this.isContractPopUpDisplayed = false
        this.contractMessage = ''
        this.contractPopUpState = 'loading'
        this.contractPopUpKind = 'create'
      }
    }
  },
  mounted () {
    if (!this.data?.picture) {
      // this.$emit('previous')
    }
    if (localStorage.nft_title) {
      this.title = localStorage.nft_title
    }
    if (localStorage.nft_description) {
      this.description = localStorage.nft_description
    }
    if (localStorage.nft_tags) {
      this.tags = JSON.parse(localStorage.nft_tags)
    }
    if (localStorage.nft_private) {
      this.isPrivate = JSON.parse(localStorage.nft_private)
    }
    if (!this.data.canPayMint) {
      this.isMintForFree = true
    }
    this.$http.get('/api/glyph/check')
      .then((d) => {
        console.log(d)
        this.algoGlyphTxId = d.data.glyph
      })
  }
}
</script>

<style scoped>
.footnotes{
  font-style: italic;
  font-size: small;
  text-align: center;
  width: 100%;
  margin-top: 30px;
  max-width: 450px;
}

.input-container {
  position: relative;
  width: 500px;
}

.more-bottom-margin {
  margin-bottom: 40px;
}

.not-allowed-cursor {
  cursor: not-allowed;
}

.not-possible {
  pointer-events: none;
}

.cannot-pay-mint-error {
  position: absolute;
  bottom: -35px;
  font-size: small;
  color: var(--error);
}

.unit-input {
  width: 150px;
  margin: 25px 10px;
}

.space-above {
  margin-top: 80px;
}

.algo-glyph-container {
  max-width: 451px;
  display: grid;
  grid-template-columns: auto auto;
}

.algo-glyph-container .flex-center {
  justify-content: space-between;
}

.algo-glyph-preview {
  position: relative;
  display: inline-block;
  width: 200px;
}

.algo-glyph-preview img {
  width: 100%;
  height: auto;
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0 25px;
  border-radius: 5px;
  overflow: hidden;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}

.advanced-options-title {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.advanced-options-title .linear-button {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: initial;
}

.metadata-option {
  position: relative;
  width: 100%;
}

.metadata-option:before {
  content: "[m]";
  position: absolute;
  top: 31px;
  left: -30px;
}

.button-container button {
  margin: 0 10px;
}

select {
  display: block;
  width: 100%;
  max-width: 450px;
  margin: 20px auto;
  padding: 10px 20px;
  background: var(--foreground);
  box-shadow: var(--light-shadow);
  border: 1px solid transparent;
  border-radius: 20px;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: var(--text);
  box-sizing: border-box;
}

.error {
  bottom: 168px;
}
</style>
