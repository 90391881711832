<template>
  <Section>
    <ul>
      <li
        v-for="notification of notifications"
        :key="`notif-${notification.i}`"
        :ref="`notif-${notification.i}`"
        @click="() => {
          if (!notification.read) readNotif(notification.id)
        }"
        :class="notification.read ? 'read' : ''"
      >
        <div>
          <div class="time">
            <div class="read-bullet" />
            <timeago :auto-update="60" :datetime="notification.date * 1000"></timeago>
          </div>
          <div v-html="notification.message"/>
        </div>
        <div @click.stop.prevent="deleteNotif(notification.id)">
          <unicon name="trash-alt"/>
        </div>
      </li>
    </ul>
    <infinite-loading spinner="waveDots" @infinite="infiteListHandler" :identifier="`${infiniteId}`" :forceUseInfiniteWrapper="true">
    <span slot="no-results">
    </span>
      <span slot="no-more"></span>
    </infinite-loading>
  </Section>
</template>

<script>
import Section from '../../Layout/Section'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'Notifications',
  components: { Section, InfiniteLoading },
  data () {
    return {
      notifications: [],
      increment: 30,
      infiniteId: 0
    }
  },
  methods: {
    readNotif (id) {
      this.$http.post('/api/notification/read', { id: id })
        .then(() => {
          this.notifications.filter((x) => x.id === id)[0].read = false
        })
    },
    deleteNotif (id) {
      this.$http.post('/api/notification/delete', { id: id })
        .then(() => {
          this.notifications = this.notifications.filter((x) => x.id !== id)
        })
    },
    infiteListHandler ($state) {
      this.$http.get('/api/notification/list', {
        params: {
          start: this.notifications.length,
          end: this.notifications.length + this.increment
        }
      }).then(({ data }) => {
        if (data?.metadata.length) {
          this.notifications.push(...data.metadata)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  }
}
</script>

<style scoped>
.time time {
  margin-left: 5px;
  color: var(--textlight);
  font-size: smaller;
}

ul {
  list-style: none;
}

li {
  display: flex;
  align-items: center;
  padding: 15px 25px;
  border-radius: 25px;
}

li > div:first-child {
  flex: 1;
}

li.read > div:first-child {
  opacity: 0.4;
}

li:hover {
  box-shadow: var(--shadow);
}

.read-bullet {
  display: inline-block;
  height: 10px;
  width: 10px;
  background: var(--accent);
  border-radius: 100%;
}

.read .read-bullet {
  background: transparent;
}

li .unicon:hover {
  fill: var(--error);
}
</style>
