<template>
  <AppView>
    <div class="head">
      <h1>Create Shuffle</h1>
      <div>
        <input type="text" v-model="title" :placeholder="$t('createShuffle.placeholder.title')" @change="save" maxlength="50">
        <textarea type="text" v-model="description" :placeholder="$t('createShuffle.placeholder.description')"  @change="save" maxlength="500"/>
      </div>
      <div class="double-column-grid">
          <p>Shuffle Currency:</p>
          <p>Shuffle Price:</p>
          <CurrencyInput v-model="asa"/>
          <IntInput
            :min="minPrice"
            :max="500000000000000"
            :defaultValue="price"
            :step="5"
            :decimals="decimals"
            @val ="(val) => { price = val; save() } "
          >
            <ASALogo :asa-id="asa"/>
          </IntInput>
        </div>
      <div>
        <span class="flex-center">
          <p class="vertical-flex-center">
            NSFW
            <Toggle :default-sate="isNsfw" @toggle="(state) => this.isNsfw = state"/>
          </p>
          <p class="vertical-flex-center">
            Whitelist
            <Toggle :default-sate="isWhitelist" @toggle="(state) => this.isWhitelist = state"/>
          </p>
          <p class="vertical-flex-center">
            Delay start
            <Toggle :default-sate="delayStart" @toggle="(state) => this.delayStart = state"/>
          </p>
        </span>
        <div class="double-column-grid">
          <div v-if="isWhitelist">
            <span class="flex-center">
              <input maxlength="30" v-model="whitelistTokenName"
                     placeholder="Whitelist Token Name" type="text">
            </span>
            <span class="flex-center">
              <input maxlength="8" v-model="whitelistTokenUnit"
                     placeholder="Whitelist Token Unit" type="text">
            </span>
            <div class="vertical-flex-center">
              Number of whitelist tokens:
              <IntInput
                :min="1"
                :max="50000000"
                :defaultValue="whitelistTokenNumber"
                :step="5"
                :decimals="0"
                @val ="(val) => { whitelistTokenNumber = val } "
              />
            </div>
          </div>
          <div class="vertical-flex-center" v-if="delayStart">
            Starts in (hours):
            <IntInput
              :min="0"
              :max="50000000"
              :defaultValue="startIn"
              :step="5"
              :decimals="0"
              @val ="(val) => { startIn = val; save() } "
            />
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative">
      <p class="nft-added-text">
        {{numberOfNftAdded}} NFTs added!
      </p>
      <Button @click.native="startContract">
        {{$t('createShuffle.button.create')}}
      </Button>
      <h5 v-if="error" class="error">
        <unicon height="24px" name="exclamation-octagon"/>
        {{ error }}
      </h5>
    </div>
    <div class="vertical-flex-center upload-button">
      <p>Add NFT(s) from file</p>
      <span>Each row of the format <i>nft id,amount</i></span>
      <input type="file" :id="`upload`" accept="text/plain" @change="(e) => uploadNFTs(e)">
    </div>
    <div class="selected-nfts">
      <p>Selected NFT(s)</p>
      <ul>
        <li v-for="[nft, amount] of Object.entries(selectedNFTs)" :key="nft">
          {{nft}}: {{amount}}
        </li>
      </ul>
    </div>
    <div class="nft-list">
      <div class="nft-list-head">
        <TextualButton class="reverse" @click.native="previousPage" v-if="this.start>0">
          <unicon name="angle-left" />{{$t('common.button.back')}}
        </TextualButton>
        <span v-else></span>
        <span>{{start + 1}}-{{start + increment + 1}}</span>
        <TextualButton @click.native="nextPage" v-if="this.morePage">
          {{$t('common.button.next')}}<unicon name="angle-right" />
        </TextualButton>
        <span v-else></span>
      </div>
      <ListCardGrid v-if="!this.isLoadingPage">
        <ListCard v-for="(nft, index) in nfts" :key="nft.token_id" :data="nft" :style="`animation-delay: ${100 + (index * 50)}ms`">
          <div v-if="selectedNFTs[nft.token_id]" @click.stop.prevent class="selected-item-menu" :class="(nft.number > 1) ? 'raise' : ''">
            <IntInput @click.stop
                      :min="1"
                      :max="nft.number"
                      :defaultValue="selectedNFTs[nft.token_id]"
                      :step="1"
                      @val ="(val) => { updateVal(nft.token_id, val)} "
                      v-if="nft.number > 1"
            />
            <Button class="error-btn" @click.native.stop.prevent="() => { remove(nft.token_id) }" style="width:30px; height: 40px">
              <unicon name="minus-circle" style="fill:white"></unicon>
            </Button>
          </div>
          <Button @click.native.stop.prevent="() => { add(nft.token_id) }"  style="width:30px; height: 40px" v-else>
            <unicon name="plus-circle" style="fill:white"></unicon>
          </Button>
        </ListCard>
      </ListCardGrid>
      <LinearSpinner v-else/>
    </div>
    <ContractPopUp v-if='displayContractPopUp' :_contractParams="contractParams" @done='done' @close="displayContractPopUp = false" :_kind="'createShuffle'"/>
  </AppView>
</template>

<script>
import ListCard from '@/components/Cards/ListCard'
import { mapState } from 'vuex'
import Button from '@/components/Common/Button/Button'
import TextualButton from '@/components/Common/Button/TextualButton'
import LinearSpinner from '@/components/Common/Spinner/LinearSpinner'
import AppView from '@/components/Layout/AppView'
import ListCardGrid from '@/components/Cards/ListCardGrid'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import IntInput from '@/components/Common/Input/IntInput'
import Toggle from '@/components/Common/Input/Toggle'
import ASALogo from '@/components/Assets/ASALogo'
import CurrencyInput from '@/components/Common/Input/CurrencyInput'

export default {
  name: 'CreateShuffle',
  components: { CurrencyInput, ASALogo, Toggle, IntInput, ContractPopUp, ListCardGrid, AppView, LinearSpinner, TextualButton, Button, ListCard },
  data () {
    return {
      start: 0,
      end: 16,
      increment: 9,
      morePage: false,
      isLoadingPage: false,
      title: '',
      description: '',
      nfts: [],
      price: 5,
      selectedNFTs: {},
      displayNFTs: [],
      reloadCover: 0,
      displayContractPopUp: false,
      contractParams: {},
      filterOption: 'all',
      contractMessage: '',
      id: null,
      isNsfw: false,
      isWhitelist: false,
      whitelistTokenName: '',
      whitelistTokenUnit: '',
      whitelistTokenNumber: 1_000_000,
      error: null,
      asa: 1,
      asaInfos: undefined,
      startIn: 0,
      delayStart: false
    }
  },
  watch: {
    title () {
      if (!this.whitelistTokenName.length || this.whitelistTokenName === `Whitelist ${this.title.slice(0, this.title.length - 1)}`.slice(0, 30)) {
        this.whitelistTokenName = `Whitelist ${this.title}`.slice(0, 30)
      }
    },
    delayStart () {
      if (!this.delayStart) {
        this.startIn = 0
      }
    }
  },
  computed: {
    ...mapState([
      'wallet',
      'userId',
      'address'
    ]),
    minPrice () {
      if (this.asaInfos) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.price = Math.max(this.asaInfos[this.asa].min_amount, this.price)
        return this.asaInfos[this.asa].min_amount
      }
      return 1
    },
    decimals () {
      if (this.asaInfos) {
        return this.asaInfos[this.asa].max_decimal
      }
      return 1
    },
    allNftsUrl () {
      return '/api/shuffle/nfts'
    },
    doneUrl () {
      return `/shuffle/${this.id}`
    },
    createApi () {
      return '/api/shuffle/create'
    },
    userUrl () {
      return '/user'
    },
    numberOfNftAdded () {
      let tot = 0
      for (const v of Object.values(this.selectedNFTs)) {
        tot += v
      }
      return tot
    }
  },
  methods: {
    uploadNFTs (e) {
      const image = e.target.files[0]
      if (image) {
        const reader = new FileReader()
        reader.readAsText(image)
        reader.onload = e => {
          const data = e.target.result.split('\n').map(x => x.split(','))
          for (const d of data) {
            if (d[0].length) {
              this.$set(this.selectedNFTs, parseInt(d[0]), parseFloat(d[1]))
            }
          }
        }
      }
    },
    filter (e) {
      this.filterOption = e
      if (this.filterOption === 'removed') {
        this.displayNFTs = this.nft.filter(x => !this.selectedNFTs.includes(x))
      } else if (this.filterOption === 'added') {
        this.displayNFTs = this.nft.filter(x => this.selectedNFTs.includes(x))
      } else {
        this.displayNFTs = this.nft
      }
    },
    add (tokenId) {
      this.$set(this.selectedNFTs, tokenId, 1)
      localStorage.setItem('shuffle-selected-nfts', JSON.stringify(this.selectedNFTs))
    },
    remove (tokenId) {
      this.$delete(this.selectedNFTs, tokenId)
      localStorage.setItem('shuffle-selected-nfts', JSON.stringify(this.selectedNFTs))
    },
    save () {
      localStorage.setItem('shuffle-metadata', JSON.stringify({
        title: this.title,
        description: this.description,
        price: this.price
      }))
    },
    updateVal (tokenId, val) {
      this.selectedNFTs[tokenId] = val
      localStorage.setItem('shuffle-selected-nfts', JSON.stringify(this.selectedNFTs))
    },
    startContract () {
      if (this.title.length === 0) {
        this.error = 'A title is required'
      } else if (!Object.keys(this.selectedNFTs).length) {
        this.error = 'Please select at least one NFT'
      } else {
        this.contractParams = {
          title: this.title,
          description: this.description,
          price: this.price,
          selectedNFTs: this.selectedNFTs,
          nsfw: this.isNsfw,
          asaId: this.asa,
          whitelist: this.isWhitelist,
          whitelistTokenName: this.whitelistTokenName,
          whitelistTokenUnit: (this.whitelistTokenUnit).length ? this.whitelistTokenUnit : 'WHG',
          whitelistTokenNumber: this.whitelistTokenNumber,
          startIn: (this.delayStart) ? this.startIn : 0
        }
        this.displayContractPopUp = true
      }
    },
    done (m) {
      this.id = m.id
      localStorage.removeItem('shuffle-selected-nfts')
      localStorage.removeItem('shuffle-metadata')
      this.$router.push(this.doneUrl)
    },
    previousPage () {
      if (this.isLoadingPage) return
      this.start -= this.increment
      this.start = (this.start < 0) ? 0 : this.start
      this.refreshPage()
    },
    nextPage () {
      if (this.isLoadingPage) return
      this.start += this.increment
      this.refreshPage()
    },
    refreshPage () {
      const params = {
        start: this.start,
        end: this.start + this.increment
      }
      this.nfts = []
      this.isLoadingPage = true
      this.$http.get(this.allNftsUrl, {
        params: params
      }).then(({ data }) => {
        this.isLoadingPage = false
        if (data?.metadata.length) {
          this.nfts = data.metadata.filter(x => x)
          this.morePage = !data.bool_end
        }
      }).catch(() => {
        this.isLoadingPage = false
      })
    },
    infiniteHandler ($state) {
      const params = {
        start: this.start,
        end: this.end
      }
      this.$http.get(this.creationUrl, {
        params: params
      }).then(({ data }) => {
        if (data?.metadata.length) {
          this.start += data.metadata.length
          this.end += data.metadata.length
          this.nfts.push(...data.metadata)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  },
  beforeMount () {
    if (localStorage.getItem('shuffle-selected-nfts')) {
      // this.selectedNFTs = JSON.parse(localStorage.getItem('shuffle-selected-nfts'))
    }
    if (localStorage.getItem('shuffle-metadata')) {
      /* const metadata = JSON.parse(localStorage.getItem('shuffle-metadata'))
      this.title = metadata.title
      this.description = metadata.description
      this.price = metadata.price */
    }
  },
  mounted () {
    this.$http.get('/api/home/asa')
      .then((d) => {
        this.asaInfos = d.data.metadata
      })
    this.$nextTick(() => {
      this.refreshPage()
    })
  }
}
</script>

<style scoped>

.head {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.head > div {
  margin: 0px 60px;
}

.head button {
  margin-bottom: 31px;
}

.head input {
  width: 300px;
}

.list-card-grid {
  grid-template-columns: 1fr, 1fr;
}

.nft-list-head {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 50px;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 25px;
  width: 400px;
  text-align: center;
}

.button-container {
  margin: 0;
}

.button-container button {
  margin: 5px;
}

.list-card {
  position: absolute;
}

.list-card .overview{
  width: 100%;
}

.list-card button {
  margin: 0;
  width: 140px;
  fill: var(--foreground);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.filter-selector {
  margin: 40px 0;
  min-width: 300px;
  text-align: left;
}

.filter-selector select {
  margin-top: 15px;
}

.double-column-grid {
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
}

.selected-item-menu {
  display: flex;
  align-items: center;
}

.selected-item-menu.raise button.error-btn{
  display: flex;
  align-items: center;
  position: relative;
  top: -45px;
}

.selected-item-menu button {
  height: 54px;
}

.selected-item-menu ul {
  margin: 0;
}

.selected-item-menu.raise {
  position: relative;
  top: 25px;
}

.error {
  bottom: -50px;
}

.currency-selector {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nft-added-text {
  text-align: center;
  width: 100%;
  position: relative;
  top: 26px;
}

.vertical-flex-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen
and (max-device-width: 1200px) {
  .double-column-grid {
    grid-template-columns: auto;
  }
}

.selected-nfts {
  margin: auto;
  width: 200px;
  max-height: 300px;
  overflow-y: scroll;
}

.upload-button span {
  color: var(--textlight);
  font-size: smaller;
  position: relative;
  top: -10px;
}
</style>
