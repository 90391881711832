<template>
  <div v-if="socialMedia">
    <a :href="socialMedia.instagram" target="_blank" v-if="socialMedia.instagram">
      <unicon name="instagram" height="20px" width="20px"/>
    </a>
    <a :href="socialMedia.twitter" target="_blank" v-if="socialMedia.twitter">
      <unicon name="twitter" height="20px" width="20px"/>
    </a>
    <a :href="socialMedia.telegram" target="_blank" v-if="socialMedia.telegram">
      <unicon name="telegram" height="20px" width="20px"/>
    </a>
    <a :href="socialMedia.reddit" target="_blank" v-if="socialMedia.reddit">
      <unicon name="reddit-alien-alt" height="20px" width="20px"/>
    </a>
    <a :href="socialMedia.facebook" target="_blank" v-if="socialMedia.facebook">
      <unicon name="facebook-f" height="20px" width="20px"/>
    </a>
    <a :href="socialMedia.discord" target="_blank" v-if="socialMedia.discord">
      <unicon name="discord" height="20px" width="20px"/>
    </a>
    <a :href="socialMedia.website" target="_blank" v-if="socialMedia.website">
      <unicon name="link-h" height="20px" width="20px"/>
    </a>
    <a :href="'mailto:' +socialMedia.email" target="_blank" v-if="socialMedia.email">
      <unicon name="envelope" height="20px" width="20px"/>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ContactButton',
  props: ['_socialMedia'],
  computed: {
    socialMedia () {
      try {
        if (typeof this._socialMedia === 'object') return this._socialMedia
        const temp = JSON.parse(this._socialMedia)
        if (temp.website && temp.website.length > 0 && !temp.website.includes('https')) {
          temp.website = `https://${temp.website}`
        }
        if (temp.twitter && temp.twitter.length > 0 && !temp.twitter.includes('https')) {
          temp.twitter = `https://twitter.com/${temp.twitter}`
        }
        if (temp.instagram && temp.instagram.length > 0 && !temp.instagram.includes('https')) {
          temp.instagram = `https://instagram.com/${temp.instagram}`
        }
        if (temp.facebook && temp.facebook.length > 0 && !temp.facebook.includes('https')) {
          temp.facebook = `https://facebook.com/${temp.facebook}`
        }
        if (temp.reddit && temp.reddit.length > 0 && !temp.reddit.includes('https')) {
          temp.reddit = `https://reddit.com/user/${temp.reddit}`
        }
        if (temp.telegram && temp.telegram.length > 0 && !temp.telegram.includes('https')) {
          temp.telegram = `https://t.me/${temp.telegram}`
        }
        return temp
      } catch {
        if (this._socialMedia.includes('twitter')) {
          return { twitter: this._socialMedia }
        } else if (this._socialMedia.includes('instagram')) {
          return { instagram: this._socialMedia }
        } else if (this._socialMedia.includes('telegram')) {
          return { telegram: this._socialMedia }
        } else if (this._socialMedia.includes('facebook')) {
          return { facebook: this._socialMedia }
        } else if (this._socialMedia.includes('reddit')) {
          return { reddit: this._socialMedia }
        } else if (this._socialMedia.includes('https')) {
          return { website: this._socialMedia }
        } else {
          return { email: this._socialMedia }
        }
      }
    }
  }
}
</script>

<style scoped>
a {
  margin: 0 5px;
}
</style>
