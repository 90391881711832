import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function signIn (APIName, from, tokenId) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParams(from, from, undefined, undefined, 0, new TextEncoder('utf-8').encode(`Sign In: ${tokenId}`), tokenId, suggestedParams)

    const signed = await API.sign(txn)
    return Utils._callServer('/api/sdk/optin', { signed_tx: signed })
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default signIn
