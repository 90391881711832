<template>
  <Section class="algoglyph-section">
    <h1>#algoglyph</h1>
    <template v-if="contractState === 'loading'">
      <p v-html="$t('algoglyph.explain')"/>
      <canvas
        @pointerdown.prevent="startDraw"
        @pointermove.prevent="continueDraw"
        @pointerup.prevent="endDraw"
        ref="algoglyph-canvas"
        style="touch-action: none"
        :width="canvas.width" :height="canvas.height"/>
      <div class="undo-redo">
        <span class="button" :class="(lines.length) ? 'activated' : ''">
          <unicon name="redo" class="flipped" @click.native="undo"/>
          <span>{{$t('algoglyph.undo')}}</span>
        </span>
        <span class="button" :class="(lines.length) ? 'activated' : ''">
          <unicon name="times-circle" @click.native="clear" :class="(lines.length) ? 'activated' : ''"/>
          <span>{{$t('algoglyph.clear')}}</span>
        </span>
        <span class="button" :class="(redoLines.length) ? 'activated' : ''">
          <unicon name="redo" @click.native="redo" :class="(redoLines.length) ? 'activated' : ''"/>
          <span>{{$t('algoglyph.redo')}}</span>
        </span>
      </div>
      <p class="advanced-options-title">
        {{$t('algoglyph.advancedOptions')}}
        <Button class="linear-button" @click.native="displayAdvancedOptions = true" v-if="!displayAdvancedOptions">
          {{$t('algoglyph.show')}}
        </Button>
        <Button class="linear-button" @click.native="displayAdvancedOptions = false" v-if="displayAdvancedOptions">
          {{$t('algoglyph.hide')}}
        </Button>
      </p>
      <div class="two-columns" v-if="displayAdvancedOptions">
        <p>{{$t('algoglyph.title')}}:</p>
        <input @keydown.enter.prevent.stop v-model="title" :placeholder="$t('algoglyph.title')" type="text" maxlength="32">
        <p>{{$t('algoglyph.unitName')}}:</p>
        <input @keydown.enter.prevent.stop v-model="unitName" :placeholder="$t('algoglyph.unitName')" type="text" maxlength="8">
        <p>{{$t('algoglyph.url')}}:</p>
        <input @keydown.enter.prevent.stop v-model="url" :placeholder="$t('algoglyph.url')" type="text" maxlength="32">
      </div>
      <div class="button-container">
        <Button @click.native="mint">{{$t('algoglyph.mint')}}</Button>
      </div>
    </template>
    <template v-if="contractState === 'done'">
      <p>{{$t('algoglyph.done')}}</p>
      <p>{{txID}}</p>
    </template>
    <template v-if="contractState === 'error'">
      {{errorMessage}}
    </template>
    <ContractPopUp
      v-if="displayContractPopUp"
      :_contractParams="contractParams"
      :_kind="'algoglyphMint'"
      @done="(d) => this.txID = d.tx_id"
      @close="closePopUp"/>
  </Section>
</template>

<script>
import Button from '@/components/Common/Button/Button'
import Section from '@/components/Layout/Section'
import { mapState } from 'vuex'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'

export default {
  name: 'AlgoGlyph',
  components: { ContractPopUp, Section, Button },
  data () {
    return {
      lines: [],
      redoLines: [],
      line: [],
      mode: false,
      drawing: false,
      compression: 2,
      canvas: { width: 400, height: 200 },
      context: null,
      displayContractPopUp: false,
      errorMessage: '',
      txID: null,
      title: '',
      unitName: '',
      url: '',
      displayAdvancedOptions: false,
      contractParams: {}
    }
  },
  computed: {
    ...mapState([
      'wallet',
      'address'
    ])
  },
  methods: {
    undo () {
      if (!this.lines.length) return
      const last = this.lines.pop()
      this.redoLines.push([last])
      this.redraw()
    },
    redo () {
      if (!this.redoLines.length) return
      const last = this.redoLines.pop()
      for (const x of last) {
        this.lines.push(x)
      }
      this.redraw()
    },
    clear () {
      this.redoLines.push(this.lines.slice())
      this.lines = []
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    },
    startDraw (event) {
      if (this.mode === true) return
      this.redoLines = []
      const pos = this.mouseXY(event)
      this.drawing = true
      this.context.beginPath()
      this.context.moveTo(pos.x, pos.y)
      this.line = []
      this.line.push([pos.x, pos.y])
    },
    continueDraw (event) {
      if (this.drawing) {
        const pos = this.mouseXY(event)
        this.context.lineTo(pos.x, pos.y)
        this.context.stroke()
        this.context.beginPath()
        this.context.moveTo(pos.x, pos.y)
        this.line.push([pos.x, pos.y])
      }
    },
    endDraw (event) {
      if (this.drawing) {
        this.drawing = false
        this.lines.push(this.line)
      }
    },
    redraw () {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
      for (const line of this.lines) {
        this.context.beginPath()
        this.context.moveTo(line[0][0], line[0][1])
        for (const pos of line.slice(1)) {
          this.context.lineTo(pos[0], pos[1])
        }
        this.context.stroke()
      }
    },
    compress (array) {
      const newline = []
      for (let i = 0; i < array.length; i += this.compression) {
        const xnew = ((array[i][0] / this.canvas.width) * 254).toFixed()
        const ynew = ((array[i][1] / this.canvas.height) * 254).toFixed()
        newline.push([(xnew < 255) ? xnew : 254, (ynew < 255) ? ynew : 254])
      }
      return newline
    },
    mouseXY (e) {
      const x = e.clientX || e.touches[0].clientX
      const y = e.clientY || e.touches[0].clientY
      const r = this.$refs['algoglyph-canvas'].getBoundingClientRect()
      return { x: (x - r.left), y: (y - r.top) }
    },
    bin2String () {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
      for (let i = 0; i < this.lines.length; i++) {
        this.lines[i] = this.compress(this.lines[i])
      }

      for (let i = 0; i < (this.lines.length - 1); i++) {
        this.lines[i].push(255)
      }

      this.lines = this.lines.flat(6)

      for (let i = 0; i < this.lines.length; i++) {
        this.lines[i] = parseInt(this.lines[i])
      }

      const data = String.fromCharCode.apply(null, this.lines)
      var buf = new Array(data.length)
      var encodedNote = new Uint8Array(buf)
      for (var i = 0, strLen = data.length; i < strLen; i++) {
        encodedNote[i] = data.charCodeAt(i)
      }

      return encodedNote
    },
    mint () {
      this.contractParams = {
        note: this.bin2String(),
        amount: 1,
        decimals: 0,
        unit: this.unitName,
        url: this.url,
        title: this.title
      }
      this.displayContractPopUp = true
    },
    closePopUp () {
      this.displayContractPopUp = false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.context = this.$refs['algoglyph-canvas'].getContext('2d')
      this.context.lineCap = 'round'
      this.context.lineJoin = 'round'
      this.context.lineWidth = 5
      this.context.strokeStyle = '#000000'
    })
  }
}
</script>

<style scoped>
h1 {
  text-align: center;
}

canvas {
  border: 2px var(--text) solid;
  background-color: white;
}

.algoglyph-section {
  padding-top: 90px;
  width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  width: 100%;
}

.two-columns {
  display: grid;
  grid-template-columns: 100px auto;
  grid-gap: 15px;
  align-items: center;
}

.two-columns > *:nth-child(odd) {
  justify-self: right;
}

.advanced-options-title {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.advanced-options-title .linear-button {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: initial;
}

.flipped {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.undo-redo {
  display: flex;
}

.undo-redo .unicon {
  fill: var(--textlight);
}

.undo-redo .activated:hover .unicon{
  fill: var(--text);
}

.undo-redo .activated:hover {
  color: var(--text);
}

.undo-redo .button {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--textlight);
  font-size: smaller;
  margin: 15px;
}
</style>
