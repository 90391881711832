<template>
  <Section id="followings">
    <CardGrid>
      <UserCard v-for="(user) in nftList" :key="user" :gallery-id="user" :kind="'following'" :isFollowing="true" />
    </CardGrid>
    <infinite-loading spinner="waveDots" @infinite="infiteListHandler" :identifier="infiniteId" :forceUseInfiniteWrapper="true"><span slot="no-more"></span><span slot="no-results"></span></infinite-loading>
  </Section>
</template>

<script>
import { mapState } from 'vuex'
import Section from '@/components/Layout/Section'
import InfiniteLoading from 'vue-infinite-loading'
import UserCard from '../../Cards/UserCard'
import CardGrid from '../../Cards/CardGrid'

export default {
  name: 'Following',
  components: { CardGrid, UserCard, Section, InfiniteLoading },
  props: ['id'],
  data: () => {
    return {
      nftList: [],
      infiniteId: 0,
      increment: 16,
      contractKind: '',
      contractPrice: 1,
      contractTokenId: 0,
      isContractAlgo: true,
      displayContractPopUp: false
    }
  },
  computed: {
    ...mapState([
      'userId',
      'wallet'
    ]),
    galleryId () {
      return this.id || this.userId
    },
    followingUrl () {
      return `/api/account/following/${this.galleryId}`
    }
  },
  methods: {
    infiteListHandler ($state) {
      const url = this.followingUrl
      this.$http.get(url, {
        params: {
          start: this.nftList.length,
          end: this.nftList.length + this.increment
        }
      }).then(({ data }) => {
        if (data?.usernames.length) {
          this.nftList.push(...data.usernames)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  }
}
</script>

<style scoped>
.card-grid {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  padding: 0;
  gap: 20px;
}
</style>
