<template>
  <li>
    <router-link :to="nftUrl" class="list-card" target="_blank">
      <div class="cover" :style="coverStyle" :class="{loading: !backgroundImage}"></div>
      <div class="overview" v-if="backgroundImage">
          <span class="nft-infos">
            <div class="mobile-only">
              <div v-if="data.title && data.title.length > 14">
                <h3>{{data.title.slice(0, 12)}}..</h3>
              </div>
              <div v-else>
                <h3>{{data.title}}</h3>
              </div>
            </div>
            <div class="desktop-only">
              <div v-if="data.title && data.title.length > 17">
                <h3>{{data.title.slice(0, 15)}}..</h3>
              </div>
              <div v-else>
                <h3>{{data.title}}</h3>
              </div>
            </div>
            <h4>
              <a :href="galleryUrl" @click.prevent.stop="$router.push(galleryUrl)">
                <span v-if="data.username">@{{data.username}}</span>
                <Address v-else :address="data.address"/>
              </a>
            </h4>
          </span>
        <span class="nft-price" @click.stop>
            <h3 v-if="data.price">{{fancyPrice(data.price)}}<ASALogo :asaId="data.asa_id"/></h3>
            <slot></slot>
            <template v-if="data.action">
              <Button @click.stop.prevent="() => startContract('bid')" v-if="data.action.includes('bid')">
                    <unicon name="trophy" v-if="data.winner"/>
                    <unicon name="arrow-growth" v-else/>
                    {{$t('nft.button.bid')}}
              </Button>
              <Button @click.stop.prevent="() => startContract('swap')" v-if="data.action.includes('swap')">
                <unicon name="exchange"/>
                {{$t('nft.button.swap')}}
              </Button>
              <Button @click.stop.prevent="() => startContract('update')" v-if="data.action.includes('update')">
                <unicon name="edit-alt"/>
                {{$t('nft.button.update')}}
              </Button>
              <Button @click.stop.prevent="() => startContract('cancel')" v-if="data.action.includes('cancel')" class="error-btn">
                <unicon name="times-circle"/>
                {{$t('nft.button.cancel')}}
              </Button>
              <Button @click.stop.prevent="() => startContract('buy')" v-if="data.action.includes('buy')">
                <unicon name="usd-circle"/>
                {{$t('nft.button.buy')}}
              </Button>
              <Button @click.stop.prevent="() => startContract('claim')" v-if="data.action.includes('claim')">
                <unicon name="download-alt"/>
                {{$t('nft.button.claim')}}
              </Button>
              <Button @click.stop.prevent="() => startContract('close')" class="" v-if="data.action.includes('close')">
                <unicon name="times-circle"/>
                {{$t('nft.button.close')}}
              </Button>
              <Button @click.stop.prevent="() => startContract('auctionToSales')" class="error-btn" v-if="data.action.includes('sell_back')">
                <unicon name="top-arrow-from-top"/>
                {{$t('nft.button.sell')}}
              </Button>
              <Button @click.stop.prevent="() => startContract('sell')" class="error-btn" v-if="data.action.includes('sell')">
                <unicon name="top-arrow-from-top"/>
                {{$t('nft.button.sell')}}
              </Button>
            </template>
        </span>
      </div>
    </router-link>
  </li>
</template>

<script>
import Address from '../User/Address'
import ASALogo from '../Assets/ASALogo'

export default {
  name: 'listCard',
  components: { ASALogo, Address },
  props: {
    data: Object
  },
  data () {
    return {
      thumbnailExtension: this.data.extension,
      backgroundImage: null
    }
  },
  computed: {
    coverStyle () {
      return `background-image: url(${this.backgroundImage});`
    },
    galleryUrl () {
      const userId = this.data.username || this.data.address
      return `/gallery/${userId}`
    },
    nftUrl () {
      let path = `/nft/${this.data.token_id}`
      if (this.data.app_id) {
        if (this.data.type === 'auction') {
          path += `/auction/${this.data.app_id}`
        } else if (this.data.type === 'sale') {
          path += `/sale/${this.data.app_id}`
        } else if (this.data.type === 'offer') {
          path += `/offer/${this.data.app_id}`
        }
      }
      return path
    },
    thumbnailUrl () {
      const imageKey = this.thumbnailExtension ? `images/${this.data.token_id}.${this.thumbnailExtension}` : `images/${this.data.token_id}`
      const imageRequest = JSON.stringify({
        bucket: 'algogems',
        key: imageKey,
        edits: {
          resize: {
            width: this.spotlight ? 650 : 300,
            height: this.spotlight ? 750 : 350
          }
        }
      })
      return `/image/${btoa(imageRequest)}`
    }
  },
  methods: {
    fancyPrice (p) {
      if (p < 1000) {
        return p
      } else if (p < 1_000_000) {
        return `${Math.floor(p / 1000)}k`
      } else if (p < 1_000_000_000) {
        return `${Math.floor(p / 1_000_000)}m`
      }
      return `${Math.floor(p / 1_000_000_000)}b`
    },
    loadThumbnail () {
      const bgImg = new Image()
      bgImg.onload = () => {
        this.backgroundImage = bgImg.src
      }
      bgImg.onerror = (e) => {
        this.backgroundImage = '@/assets/asaLogo/230946361.svg'
      }
      bgImg.src = this.thumbnailUrl
    },
    startContract (kind) {
      this.$emit('startContract', {
        kind: kind,
        tokenId: this.data.token_id,
        price: (this.data.price) ? this.data.price : 1
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadThumbnail()
    })
  }
}
</script>

<style scoped>

li{
  width: 100%;
}
.list-card{
  display: flex;
  border-radius: 15px;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  padding: 2px;
  font-size: small;
  animation: card-appear .2s ease backwards;
  max-width: 350px; /* makes sales and auction cards fit ipads and laptops. */
  background: var(--foreground);
  margin: auto;
}

.overview{
  position: relative;
  width: calc(100% - 100px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  color: var(--text);
  border-radius: 0 0 15px 15px;
}

.overview  h3{
  margin:0;
  text-transform: capitalize;
}

.overview .nft-infos {
  position: absolute;
  width: 250px;
}

.overview .nft-infos h3{
  font-size: 1.2em;
}

.overview .nft-price {
  position: absolute;
  right: 0;
  top: 29px;
}

.overview .nft-price h3{
  font-size: 1.8em;
  text-align: right;
  padding-right: 6px;
}

.overview .nft-infos h4{
  margin:0;
  font-size: 1em;
  font-weight: 400;
  color: var(--text);
}
.list-card:hover{
  background: var(--foreground);
  box-shadow: var(--strong-shadow);
}

.cover{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.cover.loading{
  height: 100px;
  width: 100px;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f3f3f3;
  background: linear-gradient(to right,#f3f3f3 5%,#ebebeb 20%,#f3f3f3 35%);
  background-size: 1000px 640px;
  position: relative;
}
.list-card button {
  display: inline-flex;
  align-items: center;
  margin: 0;
  background: none;
  box-shadow: none;
  border: none;
  color: var(--blue);
  font-size: small;
}

.list-card button * {
  fill: var(--blue);
}

.list-card button:hover {
  box-shadow: var(--light-shadow);
  transform: scale(1);
}

.list-card button.error-btn {
  color: var(--red);
}

.list-card button.error-btn .unicon {
  fill: var(--red);
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .list-card{
    width: 90vw;
    justify-content: center;
  }

  .overview {
    width: 60vw;
  }
}
</style>
