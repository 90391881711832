<template>
<AppView>
  <p>
    <img src="@/assets/logo_nsfw.png" alt="algogems"/>Sorry, we are currently under maintenance. Algogems.io should be back online soon.
  </p>
</AppView>
</template>

<script>
import AppView from '../components/Layout/AppView'

export default {
  name: 'Maintenance',
  components: { AppView }
}
</script>

<style scoped>
p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  color: var(--text);
}

p img {
  margin-right: 16px;
}
</style>
