import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function tip (APIName, from, to, amount) {
  try {
    const API = Utils._getAPI(APIName)
    const tokenId = 230946361
    const suggestedParams = await Utils.getParams()

    const txn = algosdk.makeAssetTransferTxnWithSuggestedParams(from, to, undefined, undefined, amount * 1000000, undefined, tokenId, suggestedParams)
    const signedTx = await API.sign(txn)
    return Utils.sendAlgo(signedTx)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default tip
