import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function claimShuffle (APIName, from, shuffleId) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()

    const message = {
      shuffle_id: shuffleId
    }
    const parsed = await Utils._callServer('/api/shuffle/claim', message, 'GET')
    // {'token_id': token_id, 'check': check, 'app_id': app_id, 'address': address}

    const txns = []

    if (!parsed.check) {
      txns.push(algosdk.makeAssetTransferTxnWithSuggestedParams(from, from, undefined, undefined, 0, undefined, parsed.token_id, suggestedParams))
    }
    const appArgs = []
    appArgs.push(new TextEncoder().encode('claim'))
    txns.push(
      algosdk.makeApplicationNoOpTxn(
        from,
        suggestedParams,
        parsed.app_id, appArgs,
        [parsed.address, from],
        undefined, [parsed.token_id],
        undefined, undefined, undefined)
    )

    txns.push(
      algosdk.makeApplicationCloseOutTxn(
        from,
        suggestedParams,
        parsed.app_id)
    )

    const signedTxs = await API.signGroup(txns, txns.map(x => true))

    console.log('Claim shuffle - signed', signedTxs)
    const message2 = {
      token_id: parsed.token_id,
      signed_tx: signedTxs
    }
    return Utils._callServer('/api/shuffle/claim', message2)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default claimShuffle
