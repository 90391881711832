import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function joeStart (APIName) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()

    const appArgs = []
    appArgs.push(new TextEncoder().encode('start'))
    const foreignAssets = [31566704]
    const txn =
      algosdk.makeApplicationNoOpTxn(
        'MTCXJLFYN76VXTS7ADPCJ4UOJS4C6QM6PQFEGZTQAZ6AI3AHVBWK7KT4DQ',
        suggestedParams,
        725421388, appArgs,
        undefined,
        undefined, foreignAssets,
        undefined, undefined, undefined)

    const signedTx = await API.sign(txn)

    const message = {
      signed_tx: [signedTx]
    }
    return Utils._callServer('/api/debug/joe', message)
  } catch (e) {
    console.log('Help Joe - error', e)
    throw e
  }
}

export default joeStart
