<template>
  <div class="spinner"></div>
</template>

<script>
export default {
  name: 'Spinner'
}
</script>

<style scoped>
.spinner,
.spinner:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.spinner {
  margin: 0;
  position: relative;
  border-top: 5px solid var(--background);
  border-right: 5px solid var(--background);
  border-bottom: 5px solid var(--background);
  border-left: 5px solid var(--accent);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.8s infinite linear;
  animation: load8 0.8s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
