import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function makeOffer (APIName, from, tokenId, price) {
  try {
    const API = Utils._getAPI(APIName)
    const contractInfo = await Utils._callServer('/api/offers/contract/info', { amount: price, token_id: tokenId }, 'GET')

    const suggestedParams = await Utils.getParams()
    let appArgs = []
    appArgs.push(algosdk.decodeAddress(from).publicKey)
    appArgs.push(Utils.longToByteArray(contractInfo.micro_amount))
    appArgs.push(algosdk.decodeAddress(contractInfo.gems_address).publicKey)
    appArgs.push(algosdk.decodeAddress(contractInfo.creator).publicKey)
    appArgs.push(Utils.longToByteArray(contractInfo.fees))
    appArgs.push(Utils.longToByteArray(contractInfo.royalties))
    appArgs.push(Utils.longToByteArray(tokenId))

    const application =
      algosdk.makeApplicationCreateTxn(
        from,
        suggestedParams,
        algosdk.OnApplicationComplete.NoOpOC,
        new Uint8Array(Buffer.from(contractInfo.approval_program, 'base64')),
        new Uint8Array(Buffer.from(contractInfo.clear_state_program, 'base64')),
        0, 0,
        8, 7,
        appArgs
      )

    const signedApp = await API.sign(application)

    let message = {
      token_id: tokenId,
      price: price,
      signed_tx: signedApp
    }

    const createAppValidation = await Utils._callServer('/api/offers/app/create', message)

    const txns = []
    const appAddr = algosdk.getApplicationAddress(createAppValidation.app_id)

    if (!createAppValidation.check) {
      txns.push(algosdk.makeAssetTransferTxnWithSuggestedParams(from, from, undefined, undefined, 0, undefined, tokenId, suggestedParams))
    }

    txns.push(
      algosdk.makePaymentTxnWithSuggestedParams(
        from, appAddr,
        createAppValidation.amt,
        undefined, undefined,
        suggestedParams)
    )

    appArgs = []
    appArgs.push(new TextEncoder().encode('setup'))
    const foreignAssets = [tokenId]
    txns.push(
      algosdk.makeApplicationNoOpTxn(
        from,
        suggestedParams,
        createAppValidation.app_id, appArgs,
        [],
        undefined, foreignAssets,
        undefined, undefined, undefined)
    )

    const signedTxs = await API.signGroup(txns, txns.map(x => true))
    console.log('Make offers - signedTxs', signedTxs)

    message = {
      token_id: tokenId,
      price: price,
      app_id: createAppValidation.app_id,
      signed_tx: signedTxs
    }
    return Utils._callServer('/api/offers/create/validate', message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default makeOffer
