<template>
  <AppView v-if="incorrectShuffle" >
    <Error>
      <template v-if="isNaN(id)">
        There is no shuffle with id <i>{{id}}</i>.
      </template>
      <template v-else>This shuffle has been closed</template>
    </Error>
  </AppView>
  <AppView id="shuffle" :key="reloadIndex" v-else-if="!wonNFT">
    <div class="owner-info">
      <UserPicture :account-id="accountId" :version="ppVersion" :address="ownerAddress"/>
      <span class="address">
        <router-link :to="ownerUrl">
          @{{ ownerUsername }}
        </router-link>
      </span>
    </div>
    <Section>
      <h1>{{ title }}</h1>
      <p class="description" v-if="description.length">
        {{ description }}
      </p>
      <p class="price">
        <span>
          {{price}}<ASALogo :asaId="asaId"/>
        </span> <br>
        {{remainingNft}} NFTs remaining
      </p>

      <p v-if="startTime" class="timer">
        Shuffle starts in: <template v-if="timeBeforeStart.days">{{timeBeforeStart.days}}d </template>{{timeBeforeStart.hours}}h {{timeBeforeStart.minutes}}m {{timeBeforeStart.seconds}}s
      </p>
      <div class="button-container">
        <Button @click.native="buy" v-if="action === 'buy'">
            {{ $t('shuffle.button.buyOne') }}
        </Button>
        <Button @click.native="startShuffle" v-if="action === 'start'">
          Start
        </Button>
        <Button @click.native="claim" v-else-if="action === 'claim'">
            {{ $t('shuffle.button.claim') }}
        </Button>
        <Button @click.native="close" class="error-btn" v-else-if="action === 'close'">
          {{ $t('shuffle.button.close') }}
        </Button>
        <Button @click.native="cancel" class="error-btn" v-if="action === 'cancel'">
          {{ $t('shuffle.button.cancel') }}
        </Button>
        <Button @click.native="optIn" v-if="whitelistId">
          Whitelist Token Opt-in
        </Button>
      </div>
      <div>
        <CardGrid :key="infiniteId">
          <Card v-for="(nft, index) in nfts" :key="nft.token_id" :data="nft" :style="getStyle(index)"/>
        </CardGrid>
        <infinite-loading spinner="waveDots" @infinite="infiniteHandler" :forceUseInfiniteWrapper="true" :identifier="infiniteId"><span slot="no-more"></span><span
      slot="no-results"></span></infinite-loading>
      </div>
    </Section>
    <ContractPopUp v-if="displayContractPopUp"
                   :_state="contractPopUpState"
                   :_message="contractMessage"
                   @close="() => { displayContractPopUp = false; reload() }"
    />
  </AppView>
  <AppView v-else>
    <div class="won-nft-body">
      <ul>
        <Card :data="wonNFT"/>
      </ul>
      <span class="won-nft-infos">
        <h3>{{wonNFT.title}}</h3>
        <p>
          {{wonNFT.description}}
        </p>
        <ul class="arc69-attributes">
          <li v-for="attribute in wonNFT.attributes" :key="attribute.key">
            <span>{{attribute.key}}:</span>
            <span>{{attribute.value}}</span>
          </li>
        </ul>
      </span>
    </div>
    <Button @click.native="wonNFT = null; reload()">Close</Button>
  </AppView>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import Card from '@/components/Cards/Card'
import { mapMutations, mapState } from 'vuex'
import Button from '@/components/Common/Button/Button'
import AppView from '@/components/Layout/AppView'
import Section from '@/components/Layout/Section'
import CardGrid from '@/components/Cards/CardGrid'
import UserPicture from '@/components/User/UserPicture'
import ContractPopUp from '../components/Common/PopUp/ContractPopUp'
import Error from '../components/Common/Error'
import ASALogo from '../components/Assets/ASALogo'
import Contract from '../web3/Contract'

export default {
  name: 'Shuffle',
  components: { ASALogo, Error, ContractPopUp, UserPicture, CardGrid, Section, Button, Card, InfiniteLoading, AppView },
  data () {
    return {
      appId: 0,
      start: 0,
      end: 16,
      asaId: 1,
      title: '',
      description: '',
      ownerAddress: '',
      ownerUsername: '',
      price: 0,
      accountId: 0,
      ppVersion: 0,
      nfts: [],
      infiniteId: 0,
      id: this.$route.params.id,
      displayContractPopUp: false,
      contractPopUpState: 'loading',
      contractMessage: '',
      action: null,
      remainingNft: undefined,
      wonNFTId: undefined,
      wonNFT: null,
      incorrectShuffle: false,
      whitelistId: null,
      startTime: 0,
      now: Date.now() / 1000,
      reloadIndex: 0
    }
  },
  computed: {
    ...mapState([
      'wallet',
      'address',
      'userId',
      'routeBeforeAuthenticate'
    ]),
    timeBeforeStart () {
      let delta = this.startTime - this.now
      if (delta > 0) {
        const days = Math.floor(delta / 86400)
        delta -= days * 86400
        const hours = Math.floor(delta / 3600) % 24
        delta -= hours * 3600
        const minutes = Math.floor(delta / 60) % 60
        delta -= minutes * 60
        const seconds = Math.floor(delta % 60)
        return {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds
        }
      } else {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        }
      }
    },
    isMine () {
      return (this.userId === this.ownerUsername) || (this.address === this.ownerAddress)
    },
    ownerUrl () {
      return `/gallery/${this.ownerUsername}`
    },
    url () {
      return `/api/shuffle/info/${this.id}`
    },
    NFTUrl () {
      return `/api/shuffle/nft/${this.id}`
    },
    wonNFTUrl () {
      return `/api/nft/${this.wonNFTId}`
    }
  },
  methods: {
    ...mapMutations(['setRouteBeforeAuthenticate']),
    reloadNfts () {
      this.start = 0
      this.end = 16
      this.nfts = []
      this.infiniteId++
    },
    reload () {
      this.fetchData(() => {
        this.reloadIndex++
        this.reloadNfts()
      })
    },
    infiniteHandler ($state) {
      const params = {
        start: this.start,
        end: this.end
      }
      this.$http.get(this.NFTUrl, {
        params: params
      }).then(({ data }) => {
        if (data?.metadata.length) {
          this.start += data.metadata.length
          this.end += data.metadata.length
          this.nfts.push(...data.metadata)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    fetchData (callback = () => {}) {
      this.start = 0
      this.end = 16
      this.nfts = []
      this.$http.get(this.url).then(({ data }) => {
        if (data) {
          this.title = data.metadata.title
          this.description = data.metadata.description
          this.ownerAddress = data.metadata.address
          this.ownerUsername = (data.metadata.username) ? data.metadata.username : data.metadata.address
          this.accountId = data.metadata.account_id
          this.ppVersion = data.metadata.v_pp
          this.action = data.metadata.action
          this.remainingNft = data.metadata.total
          this.price = data.metadata.price
          this.asaId = data.metadata.asa_id
          this.whitelistId = data.metadata.whitelist_id
          this.startTime = data.metadata.start
          this.appId = data.metadata.app_id

          if (this.routeBeforeAuthenticate === this.$route.path) {
            this.setRouteBeforeAuthenticate({ routeBeforeAuthenticate: '/' })
            if (this.action.includes('buy')) {
              this.buy()
            }
          }
          setInterval(() => {
            this.now = Date.now() / 1000
          }, 1000)
          callback()
        }
      }).catch(err => {
        console.log(err)
        this.incorrectShuffle = true
      })
    },
    getStyle (index) {
      return `animation-delay: ${100 + ((index % 4) * 50)}ms`
    },
    startShuffle () {
      if (this.address) {
        this.contractPopUpState = 'loading'
        this.displayContractPopUp = true
        Contract.startShuffle(this.wallet, this.address, {
          whitelist: this.whitelistId,
          token_id: this.whitelistId,
          asaId: this.asaId,
          app_id: parseInt(this.appId)
        })
          .then((m) => {
            this.contractPopUpState = 'done'
          })
          .catch((e) => {
            this.contractPopUpState = 'error'
          })
      } else {
        this.$router.push('/authenticate')
      }
    },
    buy () {
      if (this.address) {
        this.contractPopUpState = 'loading'
        this.displayContractPopUp = true
        Contract.buyShuffle(this.wallet, this.address, this.id)
          .then((m) => {
            this.contractPopUpState = 'done'
            this.remainingNft--
            this.doneBuy(m.token_id)
          })
          .catch((e) => {
            this.contractPopUpState = 'error'
            this.contractMessage = e.toString()
            this.fetchData()
          })
      } else {
        this.$router.push('/authenticate')
      }
    },
    claim () {
      this.contractPopUpState = 'loading'
      this.displayContractPopUp = true
      Contract.claimShuffle(this.wallet, this.address, this.id)
        .then((m) => {
          this.contractPopUpState = 'done'
          this.doneBuy(m.token_id)
        })
        .catch((e) => {
          this.contractPopUpState = 'error'
          this.contractMessage = e.toString()
        })
    },
    doneBuy (nftId) {
      this.wonNFTId = nftId
      this.$http.get(this.wonNFTUrl).then(response => {
        this.displayContractPopUp = false
        if (response.data && response.data.nft && response.data.bids) {
          this.wonNFT = {}
          for (const key of Object.keys(response.data.nft)) {
            this.$set(this.wonNFT, key, response.data.nft[key])
          }
          if (response.data.attribute) {
            this.$set(this.wonNFT, 'attributes', response.data.attribute)
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    close () {
      this.contractPopUpState = 'loading'
      this.displayContractPopUp = true
      Contract.closeShuffle(this.wallet, this.address, this.id)
        .then((m) => {
          this.contractPopUpState = 'done'
          // this.done()
        })
        .catch((e) => {
          this.contractPopUpState = 'error'
          this.contractMessage = e.toString()
        })
    },
    cancel () {
      this.contractPopUpState = 'loading'
      this.displayContractPopUp = true
      Contract.cancelShuffle(this.wallet, this.address, this.id)
        .then((m) => {
          this.contractPopUpState = 'done'
        })
        .catch((e) => {
          this.contractPopUpState = 'error'
          this.contractMessage = e.toString()
        })
    },
    optIn () {
      this.contractPopUpState = 'loading'
      this.displayContractPopUp = true
      Contract.signIn(this.wallet, this.address, this.whitelistId)
        .then((m) => {
          this.contractPopUpState = 'done'
        })
        .catch((e) => {
          this.contractPopUpState = 'error'
          this.contractMessage = e.toString()
        })
    }
  },
  beforeMount () {
    if (!this.id || isNaN(this.id)) {
      this.incorrectShuffle = true
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.incorrectShuffle) {
        this.fetchData()
      }
    })
  }
}
</script>

<style scoped>

.dark * {
  color: var(--complement);
}

.dark .address a {
  color: var(--complement);
}

h1{
  color: var(--text);
  margin-top: -20px;
  text-transform: capitalize;
}

p{
  padding: 0px;
  margin: 0px 12px 12px 12px;
}

a {
  text-decoration: none;
}

.owner-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.address{
  margin-top: 10px;
}

.address a {
  color: var(--text);
  font-size: 1em;
  margin: 15px;
}

.checkbox {
  height: 10px;
  width: 10px;
  border: 1px solid var(--text);
  border-radius: 5px;
}

.textual-button .unicon {
  background: var(--background);
  fill: var(--text);
  margin-right: 7px;
}

.textual-button:hover .unicon {
  transform: none;
}

.shuffle-body {
  display: grid;
  width: 100%;
  grid-template-columns: 250px auto;
  grid-column-gap: 50px;
}

.shuffle-body .textual-button {
  font-weight: initial;
  margin-left: 0;
  position: relative;
  left: -10px;
}

.shuffle-body h2 {
  text-align: left;
  margin: 20px 0;
}

.won-nft-body {
  display: grid;
  grid-template-columns: 300px auto;
  max-width: 900px;
  margin: auto;
  position: relative;
}

.won-nft-infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2em;
  align-self: center;

  opacity: 0;
  animation-name: fade;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.won-nft-infos p, ul {
  margin-left: 0;
  padding-left: 0;
}

.won-nft-body > ul {
  position: relative;
  left: calc(50% + 300px);
  opacity: 0;
  animation-name: won-nft-card-appears;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

@media only screen
and (max-device-width: 1200px) {
  .shuffle-body, .won-nft-infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .won-nft-body {
    grid-template-columns: auto;
  }
}

.description {
  text-align: center;
}

.price {
  text-align: center;
  margin: 0;
  padding: 0;
}

.price span {
  color: var(--text);
  font-size: xx-large;
}

.arc69-attributes li {
  box-sizing: border-box;
  display: inline-block;
  padding: 7px 10px;
  margin-right: 10px;
  margin-bottom: 15px;
  border-radius: 10px;
  font-weight: 400;
  font-size: .8em;
  color: var(--textlight);
}

.won-nft-infos li {
  margin-left: 0;
  padding-left: 0;
}

.arc69-attributes li span {
  text-align: center;
  margin: 5px;
}

.arc69-attributes li span:first-child {
  font-weight: bolder;
  color: var(--accent);
}

.button-container {
  max-width: 500px;
  margin: auto;
}

.timer {
  text-align: center;
  font-size: larger;
}

@keyframes won-nft-card-appears {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  40% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
</style>
