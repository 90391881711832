import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function createShuffle (APIName, from, shuffle) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()

    const messageInfo = { asa_id: shuffle.asaId, amount: shuffle.price, start: shuffle.startIn }

    if (shuffle.whitelist) {
      const txn = algosdk.makeAssetCreateTxnWithSuggestedParams(
        from,
        undefined,
        shuffle.whitelistTokenNumber, 0,
        undefined, from, from,
        undefined, from,
        shuffle.whitelistTokenUnit, shuffle.whitelistTokenName, 'ipfs://Qmb1WDNZvSbL6iFQ3nXVkrUe5UrvxHwz188N5Wtbkqz8UY',
        undefined, suggestedParams)
      const d = await API.sign(txn)
      messageInfo.signed_tx = d
    }

    const parsed = await Utils._callServer('/api/shuffle/contract/info', messageInfo, 'POST')

    const appArgs = []
    appArgs.push(algosdk.decodeAddress(from).publicKey)
    appArgs.push(Utils.longToByteArray(parsed.micro_amount))
    appArgs.push(algosdk.decodeAddress(parsed.gems_address).publicKey)
    appArgs.push(Utils.longToByteArray(parsed.fees))
    if (shuffle.asaId !== 1) {
      appArgs.push(Utils.longToByteArray(shuffle.asaId))
    }
    if (parsed.start) {
      appArgs.push(Utils.longToByteArray(parsed.start))
    }
    if (shuffle.whitelist) {
      appArgs.push(Utils.longToByteArray(parsed.token_id))
    }

    let foreignAssets
    if (shuffle.asaId !== 1) {
      foreignAssets = [shuffle.asaId]
    }
    const ints = [2, 2, 9, 3]
    const application =
      algosdk.makeApplicationCreateTxn(
        from,
        suggestedParams,
        algosdk.OnApplicationComplete.NoOpOC,
        new Uint8Array(Buffer.from(parsed.approval_program, 'base64')),
        new Uint8Array(Buffer.from(parsed.clear_state_program, 'base64')),
        // Utils._base64ToArrayBuffer(parsed.approval_program),
        // Utils._base64ToArrayBuffer(parsed.clear_state_program),
        ints[0], ints[1],
        ints[2], ints[3],
        appArgs,
        undefined, undefined,
        foreignAssets
      )

    const signedApp = await API.sign(application)
    const validation = await Utils._callServer('/api/shuffle/app/create', { signed_tx: signedApp })

    let txns = []
    const signedTx = []

    const numberOfAsaDistinct = Object.keys(shuffle.selectedNFTs).length
    let numberOfAsa = 0
    for (const nb of Object.values(shuffle.selectedNFTs)) {
      numberOfAsa += nb
    }
    const amount = 100_000 + 100_000 * numberOfAsaDistinct + 450000 + 10_000 * (3 * numberOfAsa + 2) + 2_000_000
    // const amount = 100_000 + (25_000 + 3_500) * ints[2] + (25_000 + 25_000) * ints[3]
    // const amount = 100000 + 100000 * numberOfAsa + 1000 * (numberOfAsa + 2)

    const to = algosdk.getApplicationAddress(validation.app_id)

    txns.push(
      algosdk.makePaymentTxnWithSuggestedParams(
        from, to, amount,
        undefined, undefined,
        suggestedParams, undefined
      )
    )

    let group = []
    for (const [_tokenId, amount] of Object.entries(shuffle.selectedNFTs)) {
      if (group.length + 2 > 16) {
        if (txns.length + group.length >= 60) {
          const d = await API.signGroup(txns, txns.map(x => true), false)
          signedTx.push(...d)
          txns = []
        }
        const txngroup = algosdk.assignGroupID(group)
        for (const [i, tx] of group.entries()) {
          tx.group = txngroup[i].group
          txns.push(tx)
        }
        group = []
      }
      const tokenId = (typeof _tokenId === 'string') ? parseInt(_tokenId) : _tokenId
      const appArgs2 = []
      appArgs2.push(new TextEncoder().encode('add_asa'))
      appArgs2.push(Utils.longToByteArray(tokenId))

      foreignAssets = [tokenId]
      if (shuffle.asaId !== 1) {
        foreignAssets.push(shuffle.asaId)
      }
      group.push(algosdk.makeApplicationNoOpTxn(
        from,
        suggestedParams,
        validation.app_id, appArgs2,
        [],
        undefined, foreignAssets,
        undefined, undefined, undefined))

      group.push(algosdk.makeAssetTransferTxnWithSuggestedParams(
        from, to,
        undefined, undefined,
        amount, undefined,
        tokenId, suggestedParams,
        undefined
      ))
    }
    if (group.length) {
      if (txns.length + group.length + 1 >= 60) {
        const d = await API.signGroup(txns, txns.map(x => true), false)
        signedTx.push(...d)
        txns = []
      }
      const txngroup = algosdk.assignGroupID(group)
      for (const [i, tx] of group.entries()) {
        tx.group = txngroup[i].group
        txns.push(tx)
      }
    }

    const appArgs3 = []
    appArgs3.push(new TextEncoder().encode('start'))

    foreignAssets = []
    if (shuffle.whitelist) {
      foreignAssets.push(parsed.token_id)
    }
    if (shuffle.asaId !== 1) {
      foreignAssets.push(shuffle.asaId)
    }
    txns.push(
      algosdk.makeApplicationNoOpTxn(
        from,
        suggestedParams,
        validation.app_id, appArgs3,
        [],
        undefined, foreignAssets,
        undefined, undefined, undefined)
    )

    const d = await API.signGroup(txns, txns.map(x => true), false)
    d.forEach((x) => signedTx.push(x))
    const message = {
      ...shuffle,
      app_id: validation.app_id,
      asa_id: shuffle.asaId,
      signed_tx: signedTx
    }
    return Utils._callServer('/api/shuffle/app/fund', message)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default createShuffle
