<template>
  <AppView>
    <div class="head">
      <h1>Create Pack</h1>
      <div>
        <input
          type="text"
          v-model="title"
          :placeholder="'Title'"
          maxlength="50"
        >
        <textarea
          type="text"
          v-model="description"
          :placeholder="'Description'"
          maxlength="500"
        />
      </div>
      <div>
          <p class="vertical-flex-center">Pack Price</p>
          <IntInput
            :min="minPrice"
            :max="500000000000000"
            :defaultValue="price"
            :step="5"
            :decimals="decimals"
            @val ="(val) => { price = val } "
          >
            <ASALogo :asa-id="asa"/>
          </IntInput>
      </div>
      <div>
        <div>
          <p class="vertical-flex-center">
            Whitelist
            <Toggle :default-state="isWhitelist" @toggle="(state) => this.isWhitelist = state"/>
          </p>
          <div v-if="isWhitelist">
            <span class="flex-center">
              <input maxlength="30" v-model="whitelistTokenName"
                     placeholder="Whitelist Token Name" type="text">
            </span>
            <span class="flex-center">
              <input maxlength="8" v-model="whitelistTokenUnit"
                     placeholder="Token Unit Name" type="text">
            </span>
            <div class="vertical-flex-center">
              Number of whitelist tokens:
              <IntInput
                :min="1"
                :max="50000000"
                :defaultValue="whitelistTokenNumber"
                :step="5"
                :decimals="0"
                @val ="(val) => { whitelistTokenNumber = val } "
              />
            </div>
          </div>
        </div>
        <div>
          <p class="vertical-flex-center">
            Delay start
            <Toggle :default-sate="delayStart" @toggle="(state) => this.delayStart = state"/>
          </p>
          <div class="flex-center" v-if="delayStart">
            Starts in (hours):
            <IntInput
              :min="0"
              :max="50000000"
              :defaultValue="startIn"
              :step="5"
              :decimals="0"
              class="small"
              @val ="(val) => { startIn = val; save() } "
            />
          </div>
        </div>
        <div>
          <p class="vertical-flex-center">Number of NFTs per pack</p>
          <IntInput
            :min="2"
            :max="4"
            :defaultValue="nftPerPack"
            :step="1"
            :decimals="0"
            @val ="(val) => { nftPerPack = val } "
          />
        </div>

        <div>
          <p class="vertical-flex-center">Categories</p>
          <div>
            <div v-for="[i, cat] of nftCategories.entries()" :key="i" class="flex-center">
              <input type="text" v-model="cat.name" >
              <int-input
                class="small"
                :min="0"
                :max="cat.number + maxCatPerPack"
                :defaultValue="cat.number"
                :step="1"
                :decimals="0"
                @val ="(val) => { cat.number = val } "
              /> / {{nftPerPack}}
              <unicon name="times-circle" class="delete-cat-button" v-if="i > 0" @click.native="removeCat(cat.name)"/>
              <div style="width: 30px" v-else/>
            </div>
            <Button @click.native="addCat" class="text-logo">Add<unicon name="plus-circle"/></Button>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative">
      <p class="nft-added-text">
        {{numberOfNftAdded}} NFTs added!
      </p>
      <Button @click.native="startContract">
        Create
      </Button>
      <h5 v-if="error" class="error">
        <unicon height="24px" name="exclamation-octagon"/>
        {{ error }}
      </h5>
    </div>
    <div class="nft-list">
      <div class="nft-list-head">
        <TextualButton class="reverse" @click.native="previousPage" v-if="this.start>0">
          <unicon name="angle-left" />{{$t('common.button.back')}}
        </TextualButton>
        <span v-else></span>
        <span>{{start + 1}}-{{start + increment + 1}}</span>
        <TextualButton @click.native="nextPage" v-if="this.morePage">
          {{$t('common.button.next')}}<unicon name="angle-right" />
        </TextualButton>
        <span v-else></span>
      </div>
      <ListCardGrid v-if="!this.isLoadingPage">
        <ListCard v-for="(nft, index) in nfts" :key="nft.token_id" :data="nft" :style="`animation-delay: ${100 + (index * 50)}ms`">
          <div v-if="selectedNFTs[nft.token_id]" @click.stop.prevent class="selected-item-menu" :class="(nft.number > 1) ? 'raise' : ''">
            <span>Amount:</span>
            <IntInput @click.stop
                      :min="1"
                      :max="nft.number"
                      :defaultValue="selectedNFTs[nft.token_id].amount"
                      :step="1"
                      class="small"
                      @val ="(val) => { updateAmount(nft.token_id, val)} "
            />
            <span>Cat:</span>
            <Select @click.stop
                    :options="cat"
                    @selected="(val) => {updateCat(nft.token_id, val)}"
            />
            <Button class="error-btn" @click.native.stop.prevent="() => { remove(nft.token_id) }">
              <unicon name="minus-circle"></unicon>Remove
            </Button>
          </div>
          <Button @click.native.stop.prevent="() => { add(nft.token_id) }" v-else>
            <unicon name="plus-circle"></unicon>Add
          </Button>
        </ListCard>
      </ListCardGrid>
      <LinearSpinner v-else/>
    </div>
    <ContractPopUp v-if='displayContractPopUp' :_contractParams="contractParams" @done='done' @close="displayContractPopUp = false" :_kind="'createPack'"/>
  </AppView>
</template>

<script>
import ListCard from '@/components/Cards/ListCard'
import { mapState } from 'vuex'
import Button from '@/components/Common/Button/Button'
import TextualButton from '@/components/Common/Button/TextualButton'
import LinearSpinner from '@/components/Common/Spinner/LinearSpinner'
import AppView from '@/components/Layout/AppView'
import ListCardGrid from '@/components/Cards/ListCardGrid'
import ContractPopUp from '@/components/Common/PopUp/ContractPopUp'
import IntInput from '@/components/Common/Input/IntInput'
import Toggle from '@/components/Common/Input/Toggle'
import ASALogo from '@/components/Assets/ASALogo'
import Select from '../../components/Common/Input/Select'

export default {
  name: 'CreatePack',
  components: { Select, ASALogo, Toggle, IntInput, ContractPopUp, ListCardGrid, AppView, LinearSpinner, TextualButton, Button, ListCard },
  data () {
    return {
      start: 0,
      end: 16,
      increment: 9,
      morePage: false,
      isLoadingPage: false,
      title: '',
      description: '',
      price: 5,
      nftPerPack: 2,
      nftCategories: [
        { name: 'common', number: 1 },
        { name: 'rare', number: 1 }
      ],
      nfts: [],
      selectedNFTs: {},
      displayNFTs: [],
      displayContractPopUp: false,
      contractParams: {},
      filterOption: 'all',
      contractMessage: '',
      id: null,
      isNsfw: false,
      isWhitelist: false,
      whitelistTokenName: '',
      whitelistTokenUnit: '',
      whitelistTokenNumber: 1_000_000,
      error: null,
      asa: 1,
      asaInfos: undefined,
      delayStart: false,
      startIn: 0
    }
  },
  watch: {
    title () {
      if (!this.whitelistTokenName.length || this.whitelistTokenName === `Whitelist ${this.title.slice(0, this.title.length - 1)}`.slice(0, 30)) {
        this.whitelistTokenName = `Whitelist ${this.title}`.slice(0, 30)
      }
    }
  },
  computed: {
    ...mapState([
      'wallet',
      'userId',
      'address'
    ]),
    cat () {
      const temp = []
      for (const [i, x] of Object.values(this.nftCategories).entries()) {
        temp.push({ name: x.name, value: i })
      }
      return temp
    },
    minPrice () {
      if (this.asaInfos) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.price = Math.max(this.asaInfos[this.asa].min_amount, this.price)
        return this.asaInfos[this.asa].min_amount
      }
      return 1
    },
    decimals () {
      if (this.asaInfos) {
        return this.asaInfos[this.asa].max_decimal
      }
      return 1
    },
    allNftsUrl () {
      return '/api/shuffle/nfts'
    },
    doneUrl () {
      return `/pack/${this.id}`
    },
    createApi () {
      return '/api/shuffle/create'
    },
    userUrl () {
      return '/user'
    },
    numberOfNftAdded () {
      let tot = 0
      for (const v of Object.values(this.selectedNFTs)) {
        tot += v.amount
      }
      return tot
    },
    maxCatPerPack () {
      let n = 0
      for (const c of this.nftCategories) {
        n += c.number
      }
      return this.nftPerPack - n
    }
  },
  methods: {
    hasEnoughNFT () {
      const temp = {}
      for (const x of Object.values(this.selectedNFTs)) {
        if (!temp[x.cat]) temp[x.cat] = 0
        temp[x.cat] += x.amount
      }

      const nbPacks = Math.round(temp[0] / this.nftCategories[0].number)
      for (const [i, x] of Object.values(this.nftCategories).entries()) {
        if (temp[i] % x.number) {
          this.error = `Please add ${x.number - temp[i] % x.number} nfts to category: ${x.name}`
          return false
        }
        if (temp[i] / x.number !== nbPacks) {
          const need = nbPacks * x.number - temp[i]
          if (need > 0) {
            this.error = `Please add ${need} nfts to category: ${x.name}`
          } else if (need < 0) {
            this.error = `Please remove ${-1 * need} nfts from category: ${x.name}`
          } else {
            this.error = `Please add ${nbPacks * x.number} nfts to category: ${x.name}`
          }
          return false
        }
      }
      return true
    },
    filter (e) {
      this.filterOption = e
      if (this.filterOption === 'removed') {
        this.displayNFTs = this.nft.filter(x => !this.selectedNFTs.includes(x))
      } else if (this.filterOption === 'added') {
        this.displayNFTs = this.nft.filter(x => this.selectedNFTs.includes(x))
      } else {
        this.displayNFTs = this.nft
      }
    },
    addCat () {
      this.nftCategories.push({})
      this.$set(this.nftCategories[this.nftCategories.length - 1], 'name', '')
      this.$set(this.nftCategories[this.nftCategories.length - 1], 'number', 0)
    },
    removeCat (name) {
      this.nftCategories = this.nftCategories.filter(x => x.name !== name)
    },
    add (tokenId) {
      this.$set(this.selectedNFTs, tokenId, { amount: 1, cat: 0 })
    },
    remove (tokenId) {
      this.$delete(this.selectedNFTs, tokenId)
    },
    updateAmount (tokenId, val) {
      this.selectedNFTs[tokenId].amount = val
    },
    updateCat (tokenId, cat) {
      this.selectedNFTs[tokenId].cat = cat
    },
    startContract () {
      this.error = ''
      if (!this.hasEnoughNFT()) return
      if (this.title.length === 0) {
        this.error = 'A title is required'
      } else if (!Object.keys(this.selectedNFTs).length) {
        this.error = 'Please select at least one NFT'
      } else {
        const temp = {}
        let nbPacks = 0
        for (const [tokenId, infos] of Object.entries(this.selectedNFTs)) {
          const cat = this.nftCategories[infos.cat]
          if (!temp[cat.name]) {
            temp[cat.name] = { number_per_pack: cat.number, nfts: [] }
          }
          temp[cat.name].nfts.push({ id: tokenId, number_send: infos.amount })
          nbPacks += infos.amount
        }
        nbPacks /= this.nftPerPack
        this.contractParams = {
          title: this.title,
          description: this.description,
          price: this.price,
          asaId: 1,
          data: temp,
          nftPerPack: this.nftPerPack,
          whitelist: this.isWhitelist,
          whitelistTokenName: this.whitelistTokenName,
          whitelistTokenUnit: (this.whitelistTokenUnit).length ? this.whitelistTokenUnit : 'WHG',
          whitelistTokenNumber: this.whitelistTokenNumber,
          startIn: (this.delayStart) ? this.startIn : 0,
          nbPacks
        }
        this.displayContractPopUp = true
      }
    },
    done (m) {
      this.id = m.id
      this.$router.push(this.doneUrl)
    },
    previousPage () {
      if (this.isLoadingPage) return
      this.start -= this.increment
      this.start = (this.start < 0) ? 0 : this.start
      this.refreshPage()
    },
    nextPage () {
      if (this.isLoadingPage) return
      this.start += this.increment
      this.refreshPage()
    },
    refreshPage () {
      const params = {
        start: this.start,
        end: this.start + this.increment
      }
      this.nfts = []
      this.isLoadingPage = true
      this.$http.get(this.allNftsUrl, {
        params: params
      }).then(({ data }) => {
        this.isLoadingPage = false
        if (data?.metadata.length) {
          this.nfts = data.metadata.filter(x => x)
          this.morePage = !data.bool_end
        }
      }).catch(() => {
        this.isLoadingPage = false
      })
    },
    infiniteHandler ($state) {
      const params = {
        start: this.start,
        end: this.end
      }
      this.$http.get(this.creationUrl, {
        params: params
      }).then(({ data }) => {
        if (data?.metadata.length) {
          this.start += data.metadata.length
          this.end += data.metadata.length
          this.nfts.push(...data.metadata)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  },
  mounted () {
    this.$http.get('/api/home/asa')
      .then((d) => {
        this.asaInfos = d.data.metadata
      })
    this.$nextTick(() => {
      this.refreshPage()
    })
  }
}
</script>

<style scoped>

.head {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.head > div {
  margin: 0px 60px;
}

.head button {
  margin-bottom: 31px;
}

.head input {
  width: 300px;
}

.list-card-grid {
  grid-template-columns: 1fr, 1fr;
}

.nft-list-head {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 50px;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 25px;
  width: 400px;
  text-align: center;
}

.button-container {
  margin: 0;
}

.button-container button {
  margin: 5px;
}

.list-card {
  position: absolute;
}

.list-card .overview{
  width: 100%;
}

.list-card button {
  margin: 0;
  width: 80px;
  fill: var(--foreground);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.filter-selector {
  margin: 40px 0;
  min-width: 300px;
  text-align: left;
}

.filter-selector select {
  margin-top: 15px;
}

.two-column-grid {
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
}

select {
  width: 60px;
}

.selected-item-menu.raise button{
  display: flex;
  align-items: center;
  position: relative;
  top: -53px;
}

.selected-item-menu {
  display: flex;
  align-items: center;
  position: relative;
  width: 350px;
  top: 53px;
}

.selected-item-menu button {
  height: 54px;
  position: relative;
  top: -53px;
  width: 100px;
}

.selected-item-menu ul {
  margin: 0;
}

.selected-item-menu.raise {
  position: relative;
  top: 53px;
}

.error {
  bottom: -50px;
}

.currency-selector {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nft-added-text {
  text-align: center;
  width: 100%;
  position: relative;
  top: 26px;
}

.vertical-flex-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.delete-cat-button {
  fill: var(--error);
  margin-left: 5px;
  opacity: 0.6;
}

.delete-cat-button:hover {
  opacity: 1;
}

.text-logo .unicon {
  fill: var(--accent);
  margin-left: 5px;
}

@media only screen
and (max-device-width: 1200px) {
  .double-column-grid {
    grid-template-columns: auto;
  }
}

.vertical-flex-center {
  margin-top: 30px;
  font-size: larger;
}
</style>
