<template>
  <div class="shuffle-grid">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ShuffleGrid'
}
</script>

<style scoped>
.shuffle-grid {
  display: grid;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  padding: 0;
  list-style: none;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 45px;
  grid-auto-rows: 370px;
  min-width: 350px;
  max-width: 1200px; /* messes with scaling everywhere when not fixed */
  margin: 20px auto;
  padding: 0;
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  .shuffle-grid{
    width: 100%;
    margin: 0;
    overflow: hidden;
    padding: 25px;
    box-sizing: border-box;
  }
}
</style>
