import Utils from '../utils'
import algosdk from 'algosdk-latest'

async function cancelShuffle (APIName, from, shuffleId) {
  try {
    const API = Utils._getAPI(APIName)
    const suggestedParams = await Utils.getParams()

    const message = {
      shuffle_id: shuffleId
    }
    const parsed = await Utils._callServer('/api/shuffle/cancel', message, 'GET')

    const txns = []

    const appArgs = []
    appArgs.push(new TextEncoder().encode('close'))
    txns.push(algosdk.makeApplicationNoOpTxn(
      from,
      suggestedParams,
      parsed.app_id, appArgs,
      [],
      undefined, undefined,
      undefined, undefined, undefined))

    let group = []
    for (const token of parsed.token_ids) {
      if (group.length + 1 > 16) {
        const txngroup = algosdk.assignGroupID(group)
        for (const [i, tx] of group.entries()) {
          tx.group = txngroup[i].group
          txns.push(tx)
        }
        group = []
      }
      const appArgs = []
      appArgs.push(new TextEncoder().encode('withdraw_asa'))
      appArgs.push(Utils.longToByteArray(token.token_id))

      group.push(
        algosdk.makeApplicationNoOpTxn(
          from,
          suggestedParams,
          parsed.app_id, appArgs,
          [from],
          undefined, [token.token_id],
          undefined, undefined, undefined
        )
      )
    }
    for (const token of parsed.nft_list) {
      if (group.length + 1 > 16) {
        const txngroup = algosdk.assignGroupID(group)
        for (const [i, tx] of group.entries()) {
          tx.group = txngroup[i].group
          txns.push(tx)
        }
        group = []
      }

      group.push(
        algosdk.makeAssetTransferTxnWithSuggestedParams(parsed.sender, parsed.gems_address, undefined, undefined, 1, undefined, token, suggestedParams
        )
      )
    }
    if (group.length) {
      const txngroup = algosdk.assignGroupID(group)
      for (const [i, tx] of group.entries()) {
        tx.group = txngroup[i].group
        txns.push(tx)
      }
    }

    const signedTxs = await API.signGroup(txns, txns.map(x => true), false)
    const message2 = {
      shuffle_id: shuffleId,
      signed_tx: signedTxs
    }

    return await Utils._callServer('/api/shuffle/cancel', message2)
  } catch (e) {
    console.log('Cancel Shuffle - error', e)
    throw e
  }
}

export default cancelShuffle
