<template>
  <div id="nav" :style="navStyle">
    <div class="container">
      <div class="logo" @click="$router.push('/')">
        <img v-if="false" src="@/assets/logo_nsfw.png" alt="logo">
        <img v-else src="@/assets/logo.svg" alt="logo">
        <h1>algogems</h1>
        <h2>BETA V2.0</h2>
      </div>
      <span>
          <ul class="tabs">
            <li class="desktop-only">
              <unicon name="search" />
              <Search class='drop-down left up' :show-suggestions="true"/>
            </li>
            <li class="desktop-only">
              <NavPriceTracker/>
            </li>
            <li class="desktop-only" style="cursor: pointer;" @mouseenter="confetti()" v-if="false">
              0% fees 🎉
              <ul class="drop-down">
                <li style="color: var(--accent)" hr>
                  Enjoy 0% fees on any <router-link to="/VIP">VIP</router-link> collections and creations!<br>Ask us on socials if you need further details.</li>
              </ul>
            </li>
            <li>
              <router-link to="/">{{ $t('menu.market') }}</router-link>
              <ul class="drop-down">
                <li>
                  <router-link to="/auctions">{{ $t('market.auctions') }}</router-link>
                </li>
                <li>
                  <router-link to="/shuffles">Shuffles</router-link>
                </li>
                <li>
                  <router-link to="/sales">{{ $t('market.sales') }}</router-link>
                </li>
                <li>
                  <router-link to="/collections">{{ $t('market.collections') }}</router-link>
                </li>
                <li>
                  Shops
                  <ul class="drop-down">
                    <li>
                      <router-link to="/shops/CosmicChamps">Cosmic Champs</router-link>
                    </li>
                    <li>
                      <router-link to="/shops/AlgoLeagues">Algo Leagues</router-link>
                    </li>
                    <!--<li>
                      <router-link to="/shops/Bkartel">B-Kartel</router-link>
                    </li>
                    <li>
                      <router-link to="/shops/Crescendo">Crescendo</router-link>
                    </li>
                    <li>
                      <router-link to="/shops/Headline">Headline</router-link>
                    </li>
                    <li>
                      <router-link to="/shops/Honda">Honda</router-link>
                    </li>
                    <li>
                      <router-link to="" class="inactive">Gems (Coming Soon)</router-link>
                    </li>-->
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/create">{{ $t('menu.create') }}</router-link>
              <ul class="drop-down">
                <li>
                  <router-link to="/create">Mint your NFT(s)</router-link>
                </li>
                <li>
                  <router-link to="/user/ownedNft">List your NFT(s)</router-link>
                </li>
                <li>
                  <router-link to="/create_collection">New collection</router-link>
                </li>
                <li>
                  <router-link to="/shuffle/create">New shuffle</router-link>
                </li>
                <li>
                  <router-link to="/staking/create" v-if="canAccessStaking">New staking</router-link>
                </li>
                <li>
                  <router-link to="/fusion/create">New fusion</router-link>
                </li>
                <li>
                  <router-link to="/pack/create">New pack</router-link>
                </li>
              </ul>
            </li>
            <li>
              <a href="https://algogemsnft.github.io" target="_blank" class="desktop-only">{{ $t('menu.about') }}</a>
              <a href="https://algogemsnft.github.io" target="_blank" class="mobile-only">
                <unicon name="question-circle"/>
              </a>
              <ul class="drop-down left">
                <li>
                  <a href="https://algogemsnft.github.io/leaderboard.html" target="_blank">Leaderboard</a>
                </li>
                <li>
                  <a href="https://algogemsnft.github.io/FAQ.html" target="_blank">Algogems and $GEMS</a>
                </li>
                <li>
                  <a href="https://algogemsnft.github.io/gettingstarted.html" target="_blank">New users</a>
                </li>
                <li>
                  <a href="https://algogemsnft.github.io/governance.html" target="_blank">Governors</a>
                </li>
              </ul>
            </li>
            <li v-if="!userId"><router-link to="/authenticate"><unicon name="lock" class="red"/></router-link></li>
            <li v-else>
              <UserNotificationsTracker/>
              <ul class="drop-down left">
                <li>
                  <router-link to="/user">Gallery</router-link>
                </li>
                <li>
                  <router-link to="/governance">Governance</router-link>
                </li>
                <li v-if="false">
                  <router-link to="/governance/stake">Stake GEMS</router-link>
                </li>
                <li>
                  <a href="https://vestige.fi/swap?asset_in=0&asset_out=230946361" target="_blank">Buy GEMS</a>
                </li>
                <li @click="startLogout">
                  Logout
                </li>
              </ul>
            </li>
            <li>
              <DarkThemeSwitch/>
            </li>
          </ul>
        </span>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import NavPriceTracker from '@/components/Common/NavPriceTracker'
import DarkThemeSwitch from './Button/DarkThemeSwitch'
import Contract from '../../web3/Contract'
import UserNotificationsTracker from './UserNotificationsTracker'
import Search from '@/components/Common/Search'

export default {
  name: 'Nav',
  components: { UserNotificationsTracker, DarkThemeSwitch, NavPriceTracker, Search },
  data () {
    return {
      navStyle: 'background: none',
      bgColor: ''
    }
  },
  computed: {
    ...mapState([
      'userId',
      'dark',
      'wallet',
      'address',
      'customClass'
    ]),
    canAccessStaking () {
      return ['PZNGYF4Y25GGO674BW4CRDHFKOKHMHZXSFXIKMYPEJCQAUTDH52WV24XTY', 'TST2PF3APFSTOQS57TTILKU3IV5O4RCU7XK6SXBS4VFDRG2APTD3QOOF7I', 'IL2CNQOSJRI3XJNKQW3BJ2JICZ6RGI752NSJEQ52U6GKWBX5ZZYZ7MI7LQ', 'VXNVFUF5BTXJXM6GFRJ4J57KKAXNNT5AQ6IKI4RTYBFJGHOCVWYLJSU36Y'].includes(this.address)
    }
  },
  watch: {
    dark () {
      this.setBgColor()
      this.handleScroll()
    },
    customClass () {
      this.setBgColor()
      this.handleScroll()
    }
  },
  methods: {
    ...mapMutations(['logout']),
    handleScroll () {
      const opacity = 1
      if (window.scrollY / 150 > 0.01) {
        if (window.scrollY / 150 > 1) {
          this.navStyle = `background: rgba(${this.bgColor},${opacity});`
        } else {
          this.navStyle = `background: rgba(${this.bgColor},${window.scrollY / 150 < opacity ? window.scrollY / 150 : opacity});`
        }
      } else {
        this.navStyle = 'background: none'
      }
    },
    confetti () {
      this.$confetti.start({
        defaultColors: [
          'Gold'
        ]
      })
      setTimeout(() => {
        this.$confetti.stop()
      }, 3000)
    },
    startLogout () {
      Contract.logout(this.wallet)
        .then(() => {
          this.$http.get('/api/logout').then(() => {
            this.logout({})
            this.$router.push({ path: '/' })
          })
        })
    },
    setBgColor () {
      let bgColor
      const navColor = getComputedStyle(document.getElementById('app')).getPropertyValue('--nav-background')
      if (navColor) {
        bgColor = navColor.replace(' ', '')
      } else {
        bgColor = getComputedStyle(document.getElementById('app')).getPropertyValue('--background').replace(' ', '')
      }
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(bgColor)
      this.bgColor = `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
    }
  },
  created () {
    document.addEventListener('scroll', this.handleScroll)
  },
  mounted () {
    this.$nextTick(() => {
      this.setBgColor()
    })
  },
  destroyed () {
    document.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style scoped>
#nav {
  padding: 15px 30px;
  font-family: 'Varela Round', sans-serif;
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 999999999;
  transition: background .5s ease;
  background: var(--nav-background);
}

#nav .container{
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

#nav .logo{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

#nav .logo h1 {
  margin: 0;
  color: var(--text);
}

#nav .logo h2 {
  font-size: .6em;
  font-family: 'Roboto', sans-serif;
  position: absolute;
  right:0;
  bottom: -5px;
  color: var(--accent);
}

#nav .logo img {
  height: 30px;
  margin-right: 10px;
}

#nav .tabs {
  list-style: none;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

#nav .tabs  li {
  margin: 0 15px;
  position: relative;
}

#nav a {
  font-weight: bold;
  color: var(--text);
  fill: var(--text);
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: var(--accent);
  fill: var(--accent);
}

#nav li.token-sale {
  background: var(--gradient);
  padding: 10px 15px;
  border-radius: 10px;
}

#nav li.token-sale a{
  color: var(--complement);
}

#nav li:hover > .drop-down, #nav li:hover > .drop-down:hover, #nav li:focus > .drop-down:focus {
  display: block;
}

#nav .drop-down{
  display: none;
  list-style: none;
  padding: 10px 20px;
  margin: 10px 0;
  position: absolute;
  background: var(--foreground);
  border-radius: 20px;
  box-shadow: var(--strong-shadow);
  animation: slide-bottom .5s ease;
}

#nav .drop-down .drop-down{
  bottom: -40px;
  left: calc(100% - 20px);
}

#nav .drop-down.left{
  right: 0;
}

#nav .drop-down.left.up{
  right: -45px;
  top: -32px;
  width: 350px;
  height: 85px;
  background: none;
  box-shadow: none;
}

#nav .drop-down:before{
  display: block;
  content: '';
  height: 10px;
  width: 100%;
  position: absolute;
  top: -10px;
  left: 0;
}

#nav .drop-down li{
  margin: 10px;
  cursor: pointer;
  white-space: nowrap;
}

#nav .drop-down li:hover{
  color: var(--accent);
}

#nav .drop-down li:hover > a{
  color: var(--accent);
}

.unicon.red {
  fill: var(--red);
}

.unicon.blue {
  fill: var(--blue);
}

#nav a.inactive {
  pointer-events: none;
  cursor: none;
  color: var(--textlight);
}

#nav .drop-down li:hover > a.inactive {
  color: var(--textlight);
}

/* Portrait */
@media only screen
and (max-device-width: 1200px) {
  #nav .logo h1 {
    display: none;
  }

  #nav .logo h2 {
    display: none;
  }

  #nav .tabs li {
    margin: 0 10px;
  }
}
</style>
